import React, { useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { SmallUp } from 'components/Responsive/breakpoints'
import ChatBotRoom from './ChatRoom/ChatBotRoom'

const ClientChatBot = ({ isExpanded, closePanel }) => {
  const listRef = useRef(0)

  return (
    <div
      ref={listRef}
      id='support-notifications'
      className={`support-panel-wrapper ${
        isExpanded ? 'support-panel-visible' : 'support-panel-hidden'
      }`}
    >
      <div className='support-panel-titlebar'>
        <div className='support-panel-title'>Rolzo AI</div>
        <div>
          <img
            className='support-panel-img'
            src='/images/icon_close.png'
            alt=''
            onClick={() => {
              closePanel()
            }}
          />
        </div>
      </div>
      <SmallUp>
        <div className='support-chat'>
          <div className='client-chat-container'>
            <ChatBotRoom />
          </div>
        </div>
      </SmallUp>
    </div>
  )
}

const mapStateToProps = ({ appWebsocket, auth, vehicleSearch }) => ({
  lastMessage: appWebsocket.lastMessage,
  editMsg: appWebsocket.editMsg,
  chatsList: appWebsocket.chatsList,
  company: auth.company,
  vehicleSearch: vehicleSearch,
  activeUserId: appWebsocket.activeUserId,
})

export default withRouter(connect(mapStateToProps, {})(ClientChatBot))
