// import React, { useState } from 'react'
// import { Carousel } from 'react-bootstrap'

// const CarouselComponent = () => {
//   const [activeIndex, setActiveIndex] = useState(0)
//   let carouselItems = [
//     {
//       header: 'Elevate every event',
//       body:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//       learnMore: '',
//       image: '',
//     },
//     {
//       header: 'Elevate every event 2',
//       body:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//       learnMore: '',
//       image: '',
//     },
//     {
//       header: 'Elevate every event 3',
//       body:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//       learnMore: '',
//       image: '',
//     },
//   ]

//   const handleSelect = selectedIndex => {
//     if (selectedIndex === -1) {
//       setActiveIndex(carouselItems.length - 1)
//     } else if (selectedIndex === carouselItems.length) {
//       setActiveIndex(0)
//     } else {
//       setActiveIndex(selectedIndex)
//     }
//   }

// //   <div className='carousel-controls'>
// // <button
// //   onClick={() => handleSelect(activeIndex - 1)}
// //   className='carousel-control-prev'
// // >
// //   <span className='carousel-control-prev-icon' aria-hidden='true'>
// //     {'<'}
// //   </span>
// // </button>
// // <div className='carousel-indicators-container'>
// //   <ol className='carousel-indicators'>
// //     {carouselItems.map((item, index) => (
// //       <li
// //         key={index}
// //         className={index === activeIndex ? 'active' : ''}
// //         onClick={() => handleSelect(index)}
// //       />
// //     ))}
// //   </ol>
// // </div>
// // <button
// //   onClick={() => handleSelect(activeIndex + 1)}
// //   className='carousel-control-next'
// // >
// //   <span className='carousel-control-next-icon' aria-hidden='true'>
// //     {'>'}
// //   </span>
// // </button>
// // </div>

//   return (
//     <div className='my-carousel-container' style={{ maxWidth: '1000px' }}>
//       <Carousel
//         activeIndex={activeIndex}
//         onSelect={handleSelect}
//         interval={null}
//         class='carousel slide'
//       >
//         {carouselItems.map((item, index) => (
//           <Carousel.Item key={index}>
//             <div className='carousel-content'>
//               <div className='text-content'>
//                 <h3>{item.header}</h3>
//                 <p>{item.body}</p>
//                 <button
//                   style={{ marginTop: '7px' }}
//                   className='btn btn-anchor btn-search align-self-start'
//                   type='button'
//                 >
//                   Learn more
//                 </button>
//               </div>
//               <div className='image-content'>
//                 <div className='image-placeholder'></div>
//               </div>
//             </div>
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     </div>
//   )
// }

// export default CarouselComponent

import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselComponent = () => {
  let carouselItems = [
    {
      header: 'Elevate every event',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      learnMore: '',
      image: '',
    },
    {
      header: 'Elevate every event 2',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      learnMore: '',
      image: '',
    },
    {
      header: 'Elevate every event 3',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      learnMore: '',
      image: '',
    },
    {
      header: 'Elevate every event 4',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      learnMore: '',
      image: '',
    },
    {
      header: 'Elevate every event 5',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      learnMore: '',
      image: '',
    },
  ]
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex)
  }

  const goToPrev = () => {
    // Logic for going to previous slide
    const prevIndex =
      activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1
    setActiveIndex(prevIndex)
  }

  const goToNext = () => {
    // Logic for going to next slide
    const nextIndex =
      activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }
  return (
    <div
      className='my-carousel-container'
      style={{ maxWidth: '1000px', position: 'relative' }}
    >
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={null}
        indicators={false} // Disable default indicators
        nextIcon={null} // Disable next icon
        prevIcon={null} // Disable prev icon
      >
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <div className='carousel-content'>
              <div className='text-content'>
                <h3>{item.header}</h3>
                <p>{item.body}</p>
                <span className='d-flex'>
                  <button
                    style={{ marginTop: '7px' }}
                    className='btn btn-anchor-event align-self-start'
                    type='button'
                  >
                    Learn more
                  </button>
                  <img
                    src='/images/learn_more_brown.png'
                    alt='Next'
                    onClick={goToNext}
                    className='learn_more_img'
                  />
                </span>
              </div>

              <div className='image-content'>
                <div className='image-placeholder'></div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      {/* Static custom indicators */}
      {/* <div className='carousel-indicators-container'>
        {carouselItems.map((item, idx) => (
          <span
            key={idx}
            className={`carousel-indicator-dot ${
              idx === activeIndex ? 'active' : ''
            }`}
            onClick={() => handleSelect(idx)}
          />
        ))}
      </div> */}

      <div className='carousel-indicators-container'>
        <img
          src='/images/icons/Icon_brown_arrow.png'
          alt='Previous'
          onClick={goToPrev}
          className='prev-arrow'
        />
        {/* Indicators */}
        {carouselItems.map((item, idx) => (
          <span
            key={idx}
            className={`carousel-indicator-dot ${
              idx === activeIndex ? 'active' : ''
            }`}
            onClick={() => handleSelect(idx)}
          />
        ))}
        <img
          src='/images/icons/Icon_brown_arrow.png'
          alt='Next'
          onClick={goToNext}
          className='next-arrow'
        />
      </div>
    </div>
  )
}

export default CarouselComponent
