import { hideAt, hideAtHeight } from './make-responsive-component'

export const ExtraSmallDown = hideAt({ min: '576px' })
export const ExtraSmallUp = hideAt({ max: '576px' })
export const SmallDown = hideAt({ min: '768.02px' })
export const Small = hideAt({ max: '576px', min: '768px' })
export const SmallUp = hideAt({ max: '768.02px' })
export const MediumDown = hideAt({ min: '992px' })
export const Medium = hideAt({ max: '768px', min: '992px' })
export const MediumUp = hideAt({ max: '992px' })
export const Large = hideAt({ max: '992px', min: '1200px' })
export const ExtraLargeDown = hideAt({ min: '1200px' })
export const ExtraLargeUp = hideAt({ max: '1200px' })

// height management start here
export const ExtraSmallHeight = hideAtHeight({ max: '600px' })
/*
  Sure, it's just sugar for:

  styled.div`
    @media (max-width: 320px) {
      display: none;
    }
  `

  but...
  it's just an example...
  ees jas for fun.
*/

// export const SmallHide = removeAt({ max: '576px', min: '768px' }) => true/false
