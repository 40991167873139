import React from 'react'
import { useParams } from 'react-router'
import { useGetInitialFormValues } from '../../../../../hooks'
import DefaultDriverLayout from '../../../../../components/layout/DefaultDriverLayout'

const Settings = () => {
  const { token } = useParams()

  const { currentItem, isLoading } = useGetInitialFormValues(
    `external/company/${token}`
  )

  return (
    <DefaultDriverLayout
      settingData={currentItem}
      isSettingsLoading={isLoading}
    />
  )
}

export default Settings
