import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { Field, Form, Formik, setIn } from 'formik'
import FormSelectCurrency from '../../../../components/FormSelectCurrency'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { currencies } from '../../../../../src/constants/CurrencyCountry'
import { editCurrencySettings, getUserInfo, logger } from '../../../../actions'
import { useGetInitialFormValues } from '../../../../hooks'
import { currencySettingsInitialValues } from '../../../../models/user'
import {
  stringValidator,
  handleSubmitValidationErrors,
} from '../../../../form-validation'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import * as Yup from 'yup'
import _ from 'lodash'
import FormRadio from '../../../../components/FormRadio'
import SubmitBtn from '../../../../components/SubmitBtn'
import ResponsiveFixedComponents from '../../../../components/Responsive/ResponsiveFixedComponents'
import {
  SmallUp,
  SmallDown,
} from '../../../../components/Responsive/breakpoints'
import DefaultLayout from '../../../../components/layout/DefaultLayout'
import { isMobile } from '../../../../helpers'
const heading = {
  fontSize: '18px',
}
const validationSchema = Yup.object().shape({
  currencyType: stringValidator(),
  currencyMethod: stringValidator(),
})
const isMobileBrowser = isMobile()
const itemDataParser = (initialValues, data) =>
  new currencySettingsInitialValues({ ...initialValues, ...data }, currencies)

const Currency = ({
  editCurrencySettings,
  getUserInfo,
  logger,
  companyId,
  userId,
  title,
  hideSidebar,
  ...props
}) => {
  const [getUserUrl, setUserUrl] = useState('')
  useEffect(() => {
    if (companyId && userId) {
      const userUrlPath = `company/client/${companyId}/user/${userId}`

      setUserUrl(userUrlPath)
    }
  }, [setUserUrl, companyId, userId])

  const { currentItem, isLoading, initialValues } = useGetInitialFormValues(
    getUserUrl,
    validationSchema,
    itemDataParser
  )

  const currencyName = str => {
    return str.toLowerCase()
  }
  const currencyList = () => {
    var list = []
    _.each(currencies, item => {
      list.push({
        label: item.description,
        value: `${item.description} ${currencyName(item.code)}`,
      })
    })
    return list
  }
  const onSubmit = async (values, form) => {
    try {
      var data = {}
      if (values.singleCurrency) {
        if (!values.currencyChoice) {
          form.setSubmitting(false)
        } else {
          data = {
            currencyType: `currency_${values.currencyChoice.value}`,
            currencyMethod: values.multipleCurrency
              ? 'multipleCurrency'
              : 'singleCurrency',
          }
          await editCurrencySettings(data, companyId, userId)
          await getUserInfo()
          form.setSubmitting(false)
        }
      } else {
        data = {
          currencyMethod: values.multipleCurrency
            ? 'multipleCurrency'
            : 'singleCurrency',
        }
        await editCurrencySettings(data, companyId, userId)
        await getUserInfo()
        form.setSubmitting(false)
      }
    } catch (error) {
      logger({ fileName: 'currency', error: error })
      console.log(error)
      handleSubmitValidationErrors(error, form)
    }
  }

  const onChangeRadio = (values, form, field) => {
    const {
      touched = {},
      errors = {},
      setFieldValue = () => {},
      setTouched = () => {},
    } = form
    setFieldValue(field.name, !field.value)
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
    if (field.name === 'multipleCurrency') {
      setFieldValue('singleCurrency', false)
      setFieldValue('currencyChoice', null)
      const data = {
        multipleCurrency: true,
      }
      onSubmit(data, form)
    } else {
      setFieldValue('multipleCurrency', false)
    }
  }
  const updateOnCurrencySelect = (values, field, form) => {
    if (field && field.value) {
      form.setFieldValue('singleCurrency', true)
      form.setFieldValue('multipleCurrency', false)
      if (values.currencyChoice) {
        values.currencyChoice.value = field.value.split(' ')[
          field.value.split(' ').length - 1
        ]
      } else {
        values.currencyChoice = {
          value: field.value.split(' ')[field.value.split(' ').length - 1],
        }
      }
      values.singleCurrency = true
      values.multipleCurrency = false
      onSubmit(values, form)
    }
  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isMobileBrowser ? (
            <DefaultLayout
              title={'Currency'}
              className='light-theme'
              showBack={true}
            >
              <Formik
                {...props}
                onSubmit={onSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                isInitialValid
              >
                {form => {
                  const { isSubmitting, values } = form
                  return (
                    <div className='d-flex align-items-center justify-content-between mb-3 mt-2'>
                      <Form noValidate>
                        <h4 className='sub-header mb-0'>
                          Choose your currency
                        </h4>
                        <div className='pt-5 pl-3 pr-1 mr-1 d-flex justify-content-around'>
                          <Field
                            component={FormRadio}
                            name='multipleCurrency'
                            id='multipleCurrency'
                            label=''
                            //onChange={}
                            handleChange={field =>
                              onChangeRadio(form.values, form, field)
                            }
                            className='mt-1'
                          />
                          <br />
                          <Col>
                            <div>
                              <p className='currency-heading'>
                                Multi-currency pricing
                              </p>
                              <p className='currency-discription'>
                                Services are charged in three currencies based
                                on where in the world the service takes place.
                                Services in the United Kingdom{' '}
                                {!isMobileBrowser && <br />} are charged in GBP,
                                services in Europe are charged in EUR, services
                                in UAE are charged in AED, services in Canada
                                are charged in CAD, and services in any other
                                destinations are charged in USD.
                              </p>
                            </div>
                          </Col>
                        </div>
                        <div className='pt-3 pl-3 pr-1 mr-1 d-flex '>
                          <br />
                          <Field
                            component={FormRadio}
                            name='singleCurrency'
                            id='singleCurrency'
                            label=''
                            handleChange={field =>
                              onChangeRadio(form.values, form, field)
                            }
                            className='mt-1'
                          />
                          <Col>
                            <div>
                              <p className='currency-heading'>
                                Single currency pricing
                              </p>
                              <p className='currency-discription'>
                                Select your preferred currency to view prices
                                and pay for services in a single currency.
                              </p>
                            </div>
                          </Col>
                        </div>
                        <div className='pt-2 currency-DropDown'>
                          <Col md={6}>
                            <Field
                              component={FormSelectCurrency}
                              name='currencyChoice'
                              isSearchable={true}
                              options={currencyList()}
                              // disabled={values.multipleCurrency}
                              handleChange={field => {
                                updateOnCurrencySelect(values, field, form)
                              }}
                            ></Field>
                          </Col>
                        </div>
                      </Form>
                    </div>
                  )
                }}
              </Formik>
            </DefaultLayout>
          ) : (
            <>
              <Formik
                {...props}
                onSubmit={onSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                isInitialValid
              >
                {form => {
                  const { isSubmitting, values } = form
                  return (
                    <div className='d-flex align-items-center justify-content-between mb-3 mt-2'>
                      <Form noValidate>
                        <h4 className='sub-header mb-0'>
                          Choose your currency
                        </h4>
                        <div className='pt-5 pl-3 pr-1 mr-1 d-flex justify-content-around'>
                          <Field
                            component={FormRadio}
                            name='multipleCurrency'
                            id='multipleCurrency'
                            label=''
                            //onChange={}
                            handleChange={field =>
                              onChangeRadio(form.values, form, field)
                            }
                            className='mt-1'
                          />
                          <br />
                          <Col>
                            <div>
                              <p className='currency-heading'>
                                Multi-currency pricing
                              </p>
                              <p className='currency-discription'>
                                Services are charged in three currencies based
                                on where in the world the service takes place.
                                Services in the United Kingdom{' '}
                                {!isMobileBrowser && <br />} are charged in GBP,
                                services in Europe are charged in EUR, services
                                in UAE are charged in AED, services in Canada
                                are charged in CAD, and services in any other
                                destinations are charged in USD.
                              </p>
                            </div>
                          </Col>
                        </div>
                        <div className='pt-3 pl-3 pr-1 mr-1 d-flex '>
                          <br />
                          <Field
                            component={FormRadio}
                            name='singleCurrency'
                            id='singleCurrency'
                            label=''
                            handleChange={field =>
                              onChangeRadio(form.values, form, field)
                            }
                            className='mt-1'
                          />
                          <Col>
                            <div>
                              <p className='currency-heading'>
                                Single currency pricing
                              </p>
                              <p className='currency-discription'>
                                Select your preferred currency to view prices
                                and pay for services in a single currency.
                              </p>
                            </div>
                          </Col>
                        </div>
                        <div className='pt-2 currency-DropDown'>
                          <Col md={6}>
                            <Field
                              component={FormSelectCurrency}
                              name='currencyChoice'
                              isSearchable={true}
                              options={currencyList()}
                              // disabled={values.multipleCurrency}
                              handleChange={field => {
                                updateOnCurrencySelect(values, field, form)
                              }}
                            ></Field>
                          </Col>
                        </div>
                      </Form>
                    </div>
                  )
                }}
              </Formik>
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    userId: auth.userId,
    companyId: auth.companyId,
  }
}

export default withRouter(
  connect(mapStateToProps, { editCurrencySettings, getUserInfo, logger })(
    Currency
  )
)
