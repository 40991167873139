import { changePasswordFormData, myAccessFormData } from '../models/access'
import { createOrEditItem, deleteItem } from './form'

export const AddVehicleToMapping = data => async dispatch =>
  await dispatch(
    createOrEditItem(data, {
      endpoint: `vehicleMapping`,
      successMessage: 'Vehicle Successfully Mapped',
    })
  )

export const editVehicleMapping = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vehicleMapping/${id}`,
      successMessage: 'Vehicle Mapping successfully Updated',
    })
  )
