import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { getIn } from 'formik'
import * as Yup from 'yup'
import {
  isMobile,
  hideResponsiveButton,
  showResponsiveButton,
} from '../helpers'
import 'react-phone-input-2/lib/bootstrap.css'
import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()
const { Group, Label } = Form

var countryCodeForValidation = null
let longDigitCountries = ['ar', 'at', 'mx']
const isMobileBrowser = isMobile()

export const phoneNumberRequiredValidator = () =>
  Yup.string().test('phoneNumber', 'Enter phone number', function(value) {
    if (!value) {
      return false
    } else if (countryCodeForValidation) {
      let code = '+' + countryCodeForValidation
      if (value === code || value === '+') {
        return false
      } else {
        return true
      }
    }
    countryCodeForValidation = null
    return true
  })

export const phoneNumberValidator = ({ message } = {}) =>
  Yup.string().test('phoneNumber', 'Invalid phone number format', function(
    value
  ) {
    if (value && countryCodeForValidation) {
      let code = '+' + countryCodeForValidation
      let testString = value.replace(code, '')
      if (testString[0] === '0') {
        return false
      } else {
        return true
      }
    }
    countryCodeForValidation = null
    return true
  })

export default function FormInputPhoneNew({
  field: { value = '', ...field } = {},
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  countryCode = null,
  checkZeroInvalid = false,
  enableLongNumbers = false,
  className,
  callOnClick = false,
  equalFields = false,
  isBook = false,
  isCarRental = false,
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const [isCountry, setCountry] = useState(countryCode ? countryCode : 'gb')
  const onChange = e => {
    countryCodeForValidation = isCountry.countryCode
    setFieldValue(field.name, `+${e}`)
    if (handleChange) {
      handleChange(value, isCountry)
    }
  }
  // const checkIfStartsWithZero = e => {
  //   if (checkZeroInvalid) {
  //     let enteredValues = e.target.value.split(isCountry.countryCode)
  //     if (enteredValues.length === 2) {
  //       let valAfterCountryCode = enteredValues[1]
  //       if (valAfterCountryCode === '0' || valAfterCountryCode === ' 0') {
  //         setFieldValue(field.name, `+${isCountry.countryCode}`)
  //       } else {
  //         setFieldValue(field.name, `${e.target.value}`)
  //       }
  //     }
  //   } else {
  //     let phoneVal = e.target.value.replace('+', '').replace(/\s/g, '')
  //     onChange(phoneVal)
  //   }
  // }
  const onFocus = () => {
    hideResponsiveButton()
  }

  const onBlur = () => {
    setTimeout(() => {
      showResponsiveButton()
    }, 1000)
  }

  useEffect(() => {
    countryCodeForValidation = isCountry.countryCode
  }, [isCountry])

  return (
    <Group controlId={controlId || field.name}>
      {!!label && <Label className='form-label'>{label}</Label>}
      <Row
        className={
          equalFields
            ? `phone-field-equal ${!isMobileBrowser && isBook ? 'is-book' : ''}`
            : `phone-field-row ${!isMobileBrowser && isBook ? 'is-book' : ''}`
        }
      >
        <Col xs={12} md={12} className={className || ''}>
          <PhoneInput
            inputClass={`phone-input ${showError ? 'is-invalid' : ''}`}
            placeholder=''
            isValid={(value, country, showError) => {
              setCountry(country)
              if (country && value.length > 2) {
                const number = phoneUtil.parseAndKeepRawInput(
                  value,
                  country.iso2
                )
                if (phoneUtil.isValidNumber(number)) {
                  return true
                } else if (!phoneUtil.isValidNumber(number)) {
                  return false
                }
              } else {
                if (value.match(/12345/)) {
                  return false
                } else if (value.match(/1234/)) {
                  return false
                } else {
                  return true
                }
              }
            }}
            containerClass='phone-number-input'
            country={countryCode ? countryCode : 'gb'}
            value={value}
            inputProps={
              longDigitCountries.includes(isCountry.iso2) && enableLongNumbers
                ? {
                    type: 'text',
                    value: value,
                  }
                : {
                    type: 'text',
                    // onChange: checkIfStartsWithZero,
                  }
            }
            onChange={phone => onChange(phone)}
            onFocus={onFocus}
            onBlur={onBlur}
            enableLongNumbers={
              longDigitCountries.includes(isCountry.iso2) ? 1 : false
            }
          />
          {callOnClick && (
            <Col className='phone-button-form' lg={12}>
              <a href={`tel:${value}`}>
                <img width='15px' src='/images/icons/phone.svg' alt='' />
              </a>
            </Col>
          )}
          {!!showError && (
            <div className='invalid-feedback d-block'>
              {getIn(errors, field.name)}
            </div>
          )}
        </Col>
      </Row>
    </Group>
  )
}
