import React, { useCallback } from 'react'

import { Form } from 'react-bootstrap'
import { setIn } from 'formik'

const { Check, Control, Group } = Form

export default function FormRadio({
  field = {},
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  groupClassName = '',
  handleChange,
  checked,
  name,
  labelClass = '',
  className = '',
  isUnselectable = null,
  ...props
}) {
  const isChecked = checked !== undefined ? !!checked : !!field.value

  const onChange = useCallback(() => {
    if (handleChange) {
      handleChange(field)
    } else {
      setFieldValue(field.name, !field.value)

      const _touched = setIn({ ...touched }, field.name, true)
      setTouched(_touched)
    }
  }, [field, touched, setFieldValue, setTouched, handleChange])

  return (
    <Group className={`${groupClassName ? groupClassName : ''}`}>
      {isUnselectable ? (
        <Check
          custom
          type='radio'
          name='formHorizontalRadios'
          id={props.id}
          {...field}
          {...props}
          onClick={handleChange}
          checked={isChecked}
          className={`${labelClass || ''} ${className || ''}`}
        />
      ) : (
        <Check
          custom
          type='radio'
          name='formHorizontalRadios'
          id={props.id}
          {...field}
          {...props}
          onChange={onChange}
          checked={isChecked}
          className={`${labelClass || ''} ${className || ''}`}
        />
      )}
      <Control.Feedback type='invalid'>{errors[field.name]}</Control.Feedback>
    </Group>
  )
}
