export const countries = [
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
    code: 'AF',
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
    code: 'AX',
  },
  {
    label: 'Albania',
    value: 'Albania',
    code: 'AL',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
    code: 'DZ',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    code: 'AS',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
    code: 'AD',
  },
  {
    label: 'Angola',
    value: 'Angola',
    code: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
    code: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
    code: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
    code: 'AR',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
    code: 'AM',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
    code: 'AW',
  },
  {
    label: 'Australia',
    value: 'Australia',
    code: 'AU',
  },
  {
    label: 'Austria',
    value: 'Austria',
    code: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
    code: 'AZ',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
    code: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
    code: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
    code: 'BD',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
    code: 'BB',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
    code: 'BY',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    code: 'BE',
  },
  {
    label: 'Belize',
    value: 'Belize',
    code: 'BZ',
  },
  {
    label: 'Benin',
    value: 'Benin',
    code: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
    code: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
    code: 'BT',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
    code: 'BO',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
    code: 'BW',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
    code: 'BV',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    code: 'BR',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    code: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
    code: 'BF',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
    code: 'BI',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    code: 'KH',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
    code: 'CM',
  },
  {
    label: 'Canada',
    value: 'Canada',
    code: 'CA',
  },
  {
    label: 'Cape Verde',
    value: 'Cape Verde',
    code: 'CV',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
    code: 'KY',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    code: 'CF',
  },
  {
    label: 'Chad',
    value: 'Chad',
    code: 'TD',
  },
  {
    label: 'Chile',
    value: 'Chile',
    code: 'CL',
  },
  {
    label: 'China',
    value: 'China',
    code: 'CN',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
    code: 'CX',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    code: 'CO',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
    code: 'KM',
  },
  {
    label: 'Congo',
    value: 'Congo',
    code: 'CG',
  },
  {
    label: 'Congo',
    value: 'Congo',
    code: 'CD',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
    code: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
    code: 'CR',
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
    code: 'CI',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    code: 'HR',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
    code: 'CU',
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
    code: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    code: 'CY',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
    code: 'CZ',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    code: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
    code: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
    code: 'DM',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    code: 'DO',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
    code: 'EC',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    code: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
    code: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
    code: 'ER',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    code: 'EE',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
    code: 'ET',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
    code: 'FO',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
    code: 'FJ',
  },
  {
    label: 'Finland',
    value: 'Finland',
    code: 'FI',
  },
  {
    label: 'France',
    value: 'France',
    code: 'FR',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
    code: 'GF',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    code: 'PF',
  },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
    code: 'TF',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
    code: 'GA',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
    code: 'GM',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    code: 'GE',
  },
  {
    label: 'Germany',
    value: 'Germany',
    code: 'DE',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
    code: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
    code: 'GI',
  },
  {
    label: 'Greece',
    value: 'Greece',
    code: 'GR',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
    code: 'GL',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
    code: 'GD',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
    code: 'GP',
  },
  {
    label: 'Guam',
    value: 'Guam',
    code: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
    code: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
    code: 'GG',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    code: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
    code: 'GY',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
    code: 'HT',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
    code: 'HM',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
    code: 'HN',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
    code: 'HK',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    code: 'HU',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
    code: 'IS',
  },
  {
    label: 'India',
    value: 'India',
    code: 'IN',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
    code: 'ID',
  },
  {
    label: 'Iran',
    value: 'Iran',
    code: 'IR',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
    code: 'IQ',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    code: 'IE',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
    code: 'IM',
  },
  {
    label: 'Israel',
    value: 'Israel',
    code: 'IL',
  },
  {
    label: 'Italy',
    value: 'Italy',
    code: 'IT',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
    code: 'JM',
  },
  {
    label: 'Japan',
    value: 'Japan',
    code: 'JP',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
    code: 'JE',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
    code: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
    code: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    code: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
    code: 'KI',
  },
  {
    label: 'Korea',
    value: 'Korea',
    code: 'KP',
  },
  {
    label: 'Korea',
    value: 'Korea',
    code: 'KR',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
    code: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    label: 'Lao',
    value: 'Lao',
    code: 'LA',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    code: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
    code: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
    code: 'LS',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
    code: 'LR',
  },
  {
    label: 'Libya',
    value: 'Libya',
    code: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    code: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    code: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    code: 'LU',
  },
  {
    label: 'Macao',
    value: 'Macao',
    code: 'MO',
  },
  {
    label: 'Macedonia',
    value: 'Macedonia',
    code: 'MK',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
    code: 'MG',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
    code: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    code: 'MY',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
    code: 'MV',
  },
  {
    label: 'Mali',
    value: 'Mali',
    code: 'ML',
  },
  {
    label: 'Malta',
    value: 'Malta',
    code: 'MT',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    code: 'MH',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
    code: 'MQ',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
    code: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
    code: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
    code: 'YT',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    code: 'MX',
  },
  {
    label: 'Micronesia',
    value: 'Micronesia',
    code: 'FM',
  },
  {
    label: 'Moldova',
    value: 'Moldova',
    code: 'MD',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
    code: 'MC',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
    code: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
    code: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    code: 'MS',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    code: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
    code: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
    code: 'MM',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
    code: 'NA',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
    code: 'NR',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
    code: 'NP',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    code: 'NL',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
    code: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    code: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
    code: 'NI',
  },
  {
    label: 'Niger',
    value: 'Niger',
    code: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    code: 'NG',
  },
  {
    label: 'Niue',
    value: 'Niue',
    code: 'NU',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
    code: 'NF',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    label: 'Norway',
    value: 'Norway',
    code: 'NO',
  },
  {
    label: 'Oman',
    value: 'Oman',
    code: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    code: 'PK',
  },
  {
    label: 'Palau',
    value: 'Palau',
    code: 'PW',
  },
  {
    label: 'Palestine',
    value: 'Palestine',
    code: 'PS',
  },
  {
    label: 'Panama',
    value: 'Panama',
    code: 'PA',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
    code: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
    code: 'PY',
  },
  {
    label: 'Peru',
    value: 'Peru',
    code: 'PE',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    code: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
    code: 'PN',
  },
  {
    label: 'Poland',
    value: 'Poland',
    code: 'PL',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    code: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    code: 'PR',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    code: 'QA',
  },
  {
    label: 'Réunion',
    value: 'Réunion',
    code: 'RE',
  },
  {
    label: 'Romania',
    value: 'Romania',
    code: 'RO',
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
    code: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
    code: 'RW',
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
    code: 'BL',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
    code: 'LC',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
    code: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
    code: 'WS',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
    code: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    code: 'SA',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    code: 'SN',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    code: 'RS',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
    code: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
    code: 'SL',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    code: 'SG',
  },
  {
    label: 'Sint Maarten',
    value: 'Sint Maarten',
    code: 'SX',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    code: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    code: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
    code: 'SB',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
    code: 'SO',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    code: 'ZA',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
    code: 'SS',
  },
  {
    label: 'Spain',
    value: 'Spain',
    code: 'ES',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    code: 'LK',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
    code: 'SD',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
    code: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    label: 'Swaziland',
    value: 'Swaziland',
    code: 'SZ',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    code: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    code: 'CH',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
    code: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
    code: 'TJ',
  },
  {
    label: 'Tanzania',
    value: 'Tanzania',
    code: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    code: 'TH',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
    code: 'TL',
  },
  {
    label: 'Togo',
    value: 'Togo',
    code: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
    code: 'TK',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
    code: 'TO',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
    code: 'TN',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    code: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
    code: 'TM',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
    code: 'TV',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
    code: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    code: 'UA',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    code: 'AE',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
    code: 'GB',
  },
  {
    label: 'United States',
    value: 'United States',
    code: 'US',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
    code: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
    code: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
    code: 'VU',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
    code: 'VE',
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
    code: 'VN',
  },
  {
    label: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
    code: 'EH',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
    code: 'YE',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
    code: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
    code: 'ZW',
  },
]
export const continentsList = {
  AD: 'Europe',
  AE: 'Middle East',
  AF: 'Asia',
  AG: 'Caribbeans',
  AI: 'Caribbeans',
  AL: 'Europe',
  AM: 'Asia',
  AN: 'United States',
  AO: 'Africa',
  AQ: 'LATAM',
  AR: 'LATAM',
  AS: 'Oceania',
  AT: 'Europe',
  AU: 'Oceania',
  AW: 'Caribbeans',
  AZ: 'Europe',
  BA: 'Europe',
  BB: 'Caribbeans',
  BD: 'Asia',
  BE: 'Europe',
  BF: 'Africa',
  BG: 'Europe',
  BH: 'Middle East',
  BI: 'Africa',
  BJ: 'Africa',
  BM: 'Caribbeans',
  BN: 'Asia',
  BO: 'LATAM',
  BR: 'Brazil',
  BS: 'Caribbeans',
  BT: 'Asia',
  BW: 'Africa',
  BY: 'Europe',
  BZ: 'LATAM',
  CA: 'Canada',
  CC: 'Asia',
  CD: 'Africa',
  CF: 'Africa',
  CG: 'Africa',
  CH: 'Europe',
  CI: 'Africa',
  CK: 'Oceania',
  CL: 'LATAM',
  CM: 'Africa',
  CN: 'Asia',
  CO: 'LATAM',
  CR: 'LATAM',
  CU: 'Caribbeans',
  CV: 'Africa',
  CX: 'Asia',
  CY: 'Europe',
  CZ: 'Europe',
  DE: 'Europe',
  DJ: 'Africa',
  DK: 'Europe',
  DM: 'Caribbeans',
  DO: 'Caribbeans',
  DZ: 'Africa',
  EC: 'LATAM',
  EE: 'Europe',
  EG: 'Africa',
  EH: 'Africa',
  ER: 'Africa',
  ES: 'Europe',
  ET: 'Africa',
  FI: 'Europe',
  FJ: 'Oceania',
  FK: 'LATAM',
  FM: 'Oceania',
  FO: 'Europe',
  FR: 'Europe',
  GA: 'Africa',
  GB: 'United Kingdom',
  GD: 'Caribbeans',
  GE: 'Asia',
  GF: 'LATAM',
  GG: 'Europe',
  GH: 'Africa',
  GI: 'Europe',
  GL: 'Europe',
  GM: 'Africa',
  GN: 'Africa',
  GP: 'Caribbeans',
  GQ: 'Africa',
  GR: 'Europe',
  GS: 'Antarctica',
  GT: 'LATAM',
  GU: 'Oceania',
  GW: 'Africa',
  GY: 'LATAM',
  HK: 'Asia',
  HN: 'LATAM',
  HR: 'Europe',
  HT: 'Caribbeans',
  HU: 'Europe',
  ID: 'Asia',
  IE: 'Europe',
  IL: 'Asia',
  IM: 'Europe',
  IN: 'Asia',
  IO: 'Asia',
  IQ: 'Asia',
  IR: 'Asia',
  IS: 'Europe',
  IT: 'Europe',
  JE: 'Europe',
  JM: 'Caribbeans',
  JO: 'Asia',
  JP: 'Asia',
  KE: 'Africa',
  KG: 'Asia',
  KH: 'Asia',
  KI: 'Australia',
  KM: 'Africa',
  KN: 'Caribbeans',
  KP: 'Asia',
  KR: 'Asia',
  KW: 'Asia',
  KY: 'Caribbeans',
  KZ: 'Asia',
  LA: 'Asia',
  LB: 'Asia',
  LC: 'Caribbeans',
  LI: 'Europe',
  LK: 'Asia',
  LR: 'Africa',
  LS: 'Africa',
  LT: 'Europe',
  LU: 'Europe',
  LV: 'Europe',
  LY: 'Africa',
  MA: 'Africa',
  MC: 'Europe',
  MD: 'Europe',
  ME: 'Europe',
  MG: 'Africa',
  MH: 'Australia',
  MK: 'Europe',
  ML: 'Africa',
  MM: 'Asia',
  MN: 'Asia',
  MO: 'Asia',
  MP: 'Australia',
  MQ: 'Caribbeans',
  MR: 'Africa',
  MS: 'Caribbeans',
  MT: 'Europe',
  MU: 'Africa',
  MV: 'Asia',
  MW: 'Africa',
  MX: 'Mexico',
  MY: 'Asia',
  MZ: 'Africa',
  NA: 'Africa',
  NC: 'Oceania',
  NE: 'Africa',
  NF: 'Oceania',
  NG: 'Africa',
  NI: 'LATAM',
  NL: 'Europe',
  NO: 'Europe',
  NP: 'Asia',
  NR: 'Oceania',
  NU: 'Oceania',
  NZ: 'Oceania',
  OM: 'Asia',
  PA: 'LATAM',
  PE: 'LATAM',
  PF: 'Oceania',
  PG: 'Oceania',
  PH: 'Asia',
  PK: 'Asia',
  PL: 'Europe',
  PM: 'United States',
  PN: 'Oceania',
  PR: 'Caribbeans',
  PS: 'Asia',
  PT: 'Europe',
  PW: 'Oceania',
  PY: 'LATAM',
  QA: 'Asia',
  RE: 'Africa',
  RO: 'Europe',
  RS: 'Europe',
  RU: 'Europe',
  RW: 'Africa',
  SA: 'Asia',
  SB: 'Oceania',
  SC: 'Africa',
  SD: 'Africa',
  SE: 'Europe',
  SG: 'Asia',
  SH: 'Africa',
  SI: 'Europe',
  SJ: 'Europe',
  SK: 'Europe',
  SL: 'Africa',
  SM: 'Europe',
  SN: 'Africa',
  SO: 'Africa',
  SR: 'LATAM',
  ST: 'Africa',
  SV: 'LATAM',
  SY: 'Asia',
  SZ: 'Africa',
  TC: 'Caribbeans',
  TD: 'Africa',
  TF: 'Antarctica',
  TG: 'Africa',
  TH: 'Asia',
  TJ: 'Asia',
  TK: 'Oceania',
  TM: 'Asia',
  TN: 'Africa',
  TO: 'Oceania',
  TR: 'Asia',
  TT: 'Africa',
  TV: 'Oceania',
  XK: 'Europe',
  TW: 'Asia',
  TZ: 'Africa',
  UA: 'Europe',
  UG: 'Africa',
  US: 'United States',
  UY: 'LATAM',
  UZ: 'Asia',
  VC: 'Caribbeans',
  VE: 'LATAM',
  VG: 'Caribbeans',
  VI: 'Caribbeans',
  VN: 'Asia',
  VU: 'Oceania',
  WF: 'Oceania',
  WS: 'Oceania',
  YE: 'Asia',
  YT: 'Africa',
  ZA: 'Africa',
  ZM: 'Africa',
  ZW: 'Africa',
}

export const unitSystems = [
  {
    country: 'BG',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'AS',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'AF',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BR',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'LK',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'AI',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'DO',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'ME',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'PS',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'GL',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'HU',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'IE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'JO',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'SL',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'UG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'IQ',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'FO',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'TV',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'WF',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'YE',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'AG',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'CU',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'GQ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'IM',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MP',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'MX',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'NF',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'DE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'KW',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'KY',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'MV',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'CF',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'GH',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'KG',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BZ',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'ES',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'KN',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'SE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'ZA',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'GB',
    continent: 'Europe',
    unitSystem: 'MILES',
  },
  {
    country: 'GE',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'NL',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'PT',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MD',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MQ',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'NI',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'SB',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'VG',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'LY',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'BJ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'SG',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'US',
    continent: 'North America',
    unitSystem: 'MILES',
  },
  {
    country: 'DK',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'PL',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'SO',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'BI',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'BS',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'UZ',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'AR',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'CR',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'IS',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'LA',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'LU',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'VN',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'FM',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'CK',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'DZ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'SJ',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'AD',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'GF',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'GG',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'HN',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'BA',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'EC',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'LI',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'NA',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'EE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'GM',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NP',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BO',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'GP',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'LB',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'RO',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'VI',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'JM',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'KH',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'LS',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MM',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'CI',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'CM',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'GN',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'PA',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'VE',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'CO',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'EG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'GI',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'IN',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'IR',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'TG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'CH',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'PF',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'DJ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'GW',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MK',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'WS',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'AM',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BD',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'MA',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'PW',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'QA',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'MY',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'SY',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'TH',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'KI',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'LC',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'MW',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NR',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'NZ',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'PG',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'SZ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'BW',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'SC',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'TJ',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'CN',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'EH',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'KM',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MC',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MT',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'SV',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'YT',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'SN',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'AQ',
    continent: 'Antarctica',
    unitSystem: 'KM',
  },
  {
    country: 'KZ',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'LR',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'AZ',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'ET',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'HR',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'HT',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'PM',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'SM',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'ID',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'ST',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'TO',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'AE',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'AO',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'CG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NC',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'SH',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'TM',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'MR',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MS',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'CL',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'BM',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'SI',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'FI',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'FR',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'KP',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'SD',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'ML',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'BT',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'CD',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'FJ',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'IO',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BB',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'NE',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'PE',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'TT',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'BH',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'IT',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'SK',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'TR',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'ZM',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'ZW',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'AL',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'LT',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MO',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'PK',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'PR',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'SA',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'TF',
    continent: 'Antarctica',
    unitSystem: 'KM',
  },
  {
    country: 'KE',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MH',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'TK',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'CV',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'FK',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'BY',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'KR',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'VU',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'PN',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'TN',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'VC',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'AW',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'AT',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'BF',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'UA',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'LV',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'PY',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'SR',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'TZ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'CA',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'MN',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'RE',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'AU',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'CY',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'GR',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'DM',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'ER',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'RU',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'RW',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MG',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'MZ',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NU',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'OM',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'PH',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'CX',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'TW',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'GD',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'GT',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'GY',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'AN',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'UY',
    continent: 'South America',
    unitSystem: 'KM',
  },
  {
    country: 'CZ',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'GS',
    continent: 'Antarctica',
    unitSystem: 'KM',
  },
  {
    country: 'RS',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'CC',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'GA',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'NO',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'TD',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'HK',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'BN',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'GU',
    continent: 'Australia',
    unitSystem: 'KM',
  },
  {
    country: 'IL',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'JP',
    continent: 'Asia',
    unitSystem: 'KM',
  },
  {
    country: 'JE',
    continent: 'Europe',
    unitSystem: 'KM',
  },
  {
    country: 'MU',
    continent: 'Africa',
    unitSystem: 'KM',
  },
  {
    country: 'TC',
    continent: 'North America',
    unitSystem: 'KM',
  },
  {
    country: 'CW',
    continent: 'South America',
    unitSystem: 'KM',
  },
]

export const countryNames = [
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AX',
    name: 'Aland Islands',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'AG',
    name: 'Antigua And Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
  {
    code: 'BA',
    name: 'Bosnia And Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CG',
    name: 'Congo',
  },
  {
    code: 'CD',
    name: 'Congo, Democratic Republic',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    name: 'Cote D"Ivoire',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard Island & Mcdonald Islands',
  },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic Of',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle Of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'KR',
    name: 'Korea',
  },
  {
    code: 'KP',
    name: 'North Korea',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'Lao People"s Democratic Republic',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LY',
    name: 'Libyan Arab Jamahiriya',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States Of',
  },
  {
    code: 'MD',
    name: 'Moldova',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'AN',
    name: 'Netherlands Antilles',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestinian Territory, Occupied',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russian Federation',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    name: 'Saint Helena',
  },
  {
    code: 'KN',
    name: 'Saint Kitts And Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
  },
  {
    code: 'PM',
    name: 'Saint Pierre And Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent And Grenadines',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome And Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'GS',
    name: 'South Georgia And Sandwich Isl.',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SJ',
    name: 'Svalbard And Jan Mayen',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
  },
  {
    code: 'TW',
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad And Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks And Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UM',
    name: 'United States Outlying Islands',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    name: 'Venezuela',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
  },
  {
    code: 'WF',
    name: 'Wallis And Futuna',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
]

export const DispatchCurrencyMappings = [
  {
    country: 'HR',
    currency: 'currency_eur',
  },
  {
    country: 'TR',
    currency: 'currency_eur',
  },
  {
    country: 'BG',
    currency: 'currency_eur',
  },
  {
    country: 'PL',
    currency: 'currency_eur',
  },
  {
    country: 'VE',
    currency: 'currency_usd',
  },
  {
    country: 'RO',
    currency: 'currency_eur',
  },
  {
    country: 'PA',
    currency: 'currency_usd',
  },
  {
    country: 'TH',
    currency: 'currency_usd',
  },
  {
    country: 'AR',
    currency: 'currency_usd',
  },
  {
    country: 'MA',
    currency: 'currency_eur',
  },
  {
    country: 'HU',
    currency: 'currency_eur',
  },
  {
    country: 'GT',
    currency: 'currency_usd',
  },
  {
    country: 'DO',
    currency: 'currency_usd',
  },
  {
    country: 'EG',
    currency: 'currency_usd',
  },
  {
    country: 'IL',
    currency: 'currency_usd',
  },
  {
    country: 'BR',
    currency: 'currency_usd',
  },
  {
    country: 'VN',
    currency: 'currency_usd',
  },
  {
    country: 'TH',
    currency: 'currency_usd',
  },
  {
    country: 'BB',
    currency: 'currency_usd',
  },
  {
    country: 'SX',
    currency: 'currency_usd',
  },
  {
    country: 'TW',
    currency: 'currency_usd',
  },
  {
    country: 'KR',
    currency: 'currency_usd',
  },
  {
    country: 'CZ',
    currency: 'currency_eur',
  },
  {
    country: 'MX',
    currency: 'currency_usd',
  },
  {
    country: 'CL',
    currency: 'currency_usd',
  },
  {
    country: 'PE',
    currency: 'currency_usd',
  },
  {
    country: 'CO',
    currency: 'currency_usd',
  },
  {
    country: 'UY',
    currency: 'currency_usd',
  },
  {
    country: 'BS',
    currency: 'currency_usd',
  },
  {
    country: 'JP',
    currency: 'currency_usd',
  },
  {
    country: 'QA',
    currency: 'currency_usd',
  },
  {
    country: 'HK',
    currency: 'currency_usd',
  },
  {
    country: 'CN',
    currency: 'currency_usd',
  },
  {
    country: 'ID',
    currency: 'currency_usd',
  },
  {
    country: 'CR',
    currency: 'currency_usd',
  },
]
