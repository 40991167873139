import { CHECK_IS_UNIQ_MODEL, CHECK_IS_UNIQ_MODEL_FAIL, CHECK_IS_UNIQ_MODEL_SUCCESS } from '../actions'

const INITIAL_STATE = {
  loading: false,
  isUniq: true,
  errors: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_IS_UNIQ_MODEL:
      return {
        ...state,
        loading: true,
      }
    case CHECK_IS_UNIQ_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniq: action.isUniq,
      }
    case CHECK_IS_UNIQ_MODEL_FAIL:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
