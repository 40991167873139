import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import loadable from '../../../../loadable'
const isPartnerHost =
  window.location.hostname === partnerHostLive ||
  window.location.hostname === partnerHostStaging
    ? true
    : false
const routes = [
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/login`,
    exact: true,
    component: loadable(() => import('./Login')),
    title: 'Login',
    type: 'login',
  },
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/register`,
    exact: true,
    component: loadable(() => import('./Register')),
    type: 'register',
  },
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/password`,
    exact: true,
    component: loadable(() => import('./EnterPassword')),
    type: 'login',
  },
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/forgot-password`,
    exact: true,
    component: loadable(() => import('./ForgotPassword')),
    title: 'Reset your password',
    type: 'reset-password',
  },
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/setpassword/:token`,
    exact: true,
    component: loadable(() => import('./SetPassword')),
    title: 'Mail Sent',
    type: 'login',
  },
  {
    path: `${
      isPartnerHost ? '/' : '/partner/'
    }auth/reset-password/:email/:token`,
    exact: true,
    component: loadable(() => import('./ResetPassword')),
    title: 'Mail Sent',
    type: 'login',
  },
  {
    path: `${isPartnerHost ? '/' : '/partner/'}auth/:email/emailverification`,
    exact: true,
    component: loadable(() => import('./VerifyPartnerEmail')),
    title: 'Mail Sent',
    type: 'login',
  },
]

const AuthRoutes = () => {
  return (
    <Switch>
      {routes.map(route => {
        const { path, component } = route
        return <Route key={path} path={path} component={component} />
      })}
      <Redirect
        from={`${isPartnerHost ? '/auth' : '/partner/auth'}`}
        to={`${isPartnerHost ? '/auth/login' : '/partner/auth/login'}`}
      />
    </Switch>
  )
}

export default withRouter(AuthRoutes)

export { routes }
