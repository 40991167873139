import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import loadable from '../../../loadable'

const routes = [
  {
    path: '/app/new-bookings',
    exact: true,
    component: loadable(() => import('../Pages/PartnerMarketing')),
  },
]

const AuthRoutes = () => {
  return (
    <Switch>
      {routes.map(route => {
        const { path, component } = route
        return <Route key={path} path={path} component={component} />
      })}
      <Redirect from={'/app'} to={'/partner/auth/login'} />
    </Switch>
  )
}

export default withRouter(AuthRoutes)

export { routes }
