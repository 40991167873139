export const chargesFormData = ({ items, note, currency, partnerCurrency }) => {
  let data = {
    note,
    currency,
    partnerCurrency,
  }
  data.items = items.map(
    ({ quantity, rate, amount, partnerRate, partnerAmount, ...item }) => {
      return {
        ...item,
        quantity: parseFloat(quantity),
        rate: parseFloat(rate),
        amount: parseFloat(amount),
        partnerRate: partnerRate ? parseFloat(partnerRate) : parseFloat(0),
        partnerAmount: partnerAmount
          ? parseFloat(partnerAmount)
          : parseFloat(0),
      }
    }
  )

  return data
}

export const updateChargesFormData = ({ items, note, currency, id }) => {
  let data = {
    note,
    currency,
    id,
  }
  data.items = items.map(({ quantity, rate, amount, ...item }) => {
    return {
      ...item,
      quantity: parseFloat(quantity),
      rate: parseFloat(rate),
      amount: parseFloat(amount),
    }
  })

  return data
}

export class AdditionalChargesInitialValues {
  constructor({ rate, amount, quantity, description, id, sentDate } = {}) {
    this.id = id
    this.rate = rate
    this.amount = amount
    this.quantity = quantity
    this.description = description
    this.sentDate = sentDate
  }
}
