import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useState,
} from 'react'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import {
  removeNotification,
  markAsReadNotification,
  fetchNotificationsList,
} from '../../../../actions'
import DefaultLayout from '../../../../components/layout/DefaultLayout'
import DataTable from '../../../../components/DataTable'
import MatRaisedLink from '../../../../components/MatRaisedLink'
import NoData from '../../../../components/NoData'
import {
  SmallDown,
  SmallUp,
} from '../../../../components/Responsive/breakpoints'
import { filter } from 'lodash'
import { isMobile } from '../../../../helpers'
const isMobileBrowser = isMobile()

const NotificationsClient = ({
  notificationsList,
  notificationLoaded,
  removeNotification,
  markAsReadNotification,
  fetchNotificationsList,
  isPanel,
  isExpanded,
  closePanel,
  isCompanyAdmin,
}) => {
  const [isLoaded, setloaded] = useState(false)

  const renderTextWithMentions = (text, links) => {
    const splited = text.split(' ')
    const invoice = links
      ? links.find(link => link.type === 'private_file')
      : null

    return splited.map((item, index) => {
      if (invoice) {
        const foundInvoice = item.match(/^@private_file\d+/)
        if (foundInvoice) {
          let invoiceText
          if (invoice.meta && invoice.meta.caption) {
            invoiceText = invoice.meta.caption
          } else {
            invoiceText = foundInvoice[0]
          }
          const rest = foundInvoice.input.replace(foundInvoice[0], '')
          return (
            <span key={`${invoiceText}${index}`}>
              <Button variant='link' className='text-brown pointer-link'>
                {invoiceText}
              </Button>
              {rest.length ? rest : ' '}
            </span>
          )
        }
      }
      // const foundMention = item.match(/^@booking\d{4}/)
      const foundMention = item.match(
        /^@booking\d{4,6}-R\b|@booking\d{4,6}-Z\b|@bookingL-\d{4,6}|@bookingI-\d{4,6}|@booking\d{4,6}/
      )
      if (foundMention) {
        let mentionText
        const foundLink = links
          ? links.find(
              link =>
                foundMention[0].substring(8) === link.bookingNumber.toString()
            )
          : null
        if (foundLink && foundLink.meta && foundLink.meta.caption) {
          mentionText = 'Booking #' + foundLink.meta.caption
        } else {
          mentionText = foundMention[0]
        }
        const rest = foundMention.input.replace(foundMention[0], '')

        return (
          <span key={`${mentionText}${index}`}>
            <MatRaisedLink
              node={Link}
              to={`/booking/my-bookings/details/${
                foundLink && foundLink.bookingId ? foundLink.bookingId : ''
              }${isMobileBrowser ? '/?show_events=1' : ''}`}
              className='btn-sm-achor'
            >
              {mentionText}
            </MatRaisedLink>
            {rest.length ? rest : ' '}
          </span>
        )
      }
      // const foundTeamMention = item.match(/^@TeamBooking\d{4}/)
      const foundTeamMention = item.match(
        /^@TeamBooking\d{4,6}-R\b|@TeamBooking\d{4,6}-Z\b|@TeamBookingL-\d{4,6}|@bookingI-\d{4,6}|@TeamBooking\d{4,6}/
      )
      if (foundTeamMention) {
        let mentionText
        const foundLink = links
          ? links.find(
              link =>
                foundTeamMention[0].substring(12) ===
                link.bookingNumber.toString()
            )
          : null
        if (foundLink && foundLink.meta && foundLink.meta.caption) {
          mentionText = 'Booking #' + foundLink.meta.caption
        } else {
          mentionText = foundTeamMention[0]
        }
        const rest = foundTeamMention.input.replace(foundTeamMention[0], '')

        return (
          <span key={`${mentionText}${index}`}>
            <MatRaisedLink
              node={Link}
              to={`/booking/team-bookings/details/${
                foundLink && foundLink.bookingId ? foundLink.bookingId : ''
              }${isMobileBrowser ? '/?show_events=1' : ''}`}
              className='btn-sm-achor'
            >
              {mentionText}
            </MatRaisedLink>
            {rest.length ? rest : ' '}
          </span>
        )
      }
      const foundCompany = item.match(/^@company/)
      if (foundCompany) {
        return (
          <span key={`${item}${index}`}>
            <Link
              to={`/settings/company-profile`}
              className='text-brown pointer-link btn btn-anchor'
            >
              company profile
            </Link>
          </span>
        )
      }
      return <span key={`${item}${index}`}>{`${item} `}</span>
    })
  }

  const columns = [
    {
      title: '',
      selector: 'text',
      renderCol: ({ text, links, createdAt }) => {
        // if (text === 'update company profile') {
        //   if (isCompanyAdmin) {
        //     return (
        // <>
        //   <img
        //     src='/images/icons/icon-important-yellow.png'
        //     className='warning-img'
        //     alt=''
        //   />
        //   <Button
        //     className='inline-edit-btn btn-anchor btn-anchor mr-3'
        //     variant='link'
        //     onClick={() => {
        //       updateModal()
        //     }}
        //   >
        //     Update company profile
        //   </Button>
        // </>
        //     )
        //   } else {
        //     return ''
        //   }
        // }
        return (
          <>
            {renderTextWithMentions(text, links)}
            <div className='notification-moment'>
              {moment(createdAt).fromNow()}
            </div>
          </>
        )
      },
    },
    {
      title: '',
      colClassName: 'remove-col',
      renderCol: ({ text, id }) => {
        if (text === 'update company profile') {
          return ''
        }
        return (
          <Button
            variant='link'
            className='remove-btn pointer-link'
            onClick={() => {
              removeNotification(id)
            }}
          >
            <img
              className='remove-btn-img'
              src='/images/icon_close_notification.png'
              alt=''
            />
          </Button>
        )
      },
    },
  ]
  const columns2 = [
    {
      title: '',
      selector: 'createdAt',
      // renderCol: renderTextWithMentions,
      renderCol: ({ text, links, createdAt }) => {
        if (text === 'update company profile') {
          // if (isCompanyAdmin) {
          //   return (
          //     <>
          //       <img
          //         src='/images/icons/icon-important-yellow.png'
          //         className='warning-img'
          //         alt=''
          //       />
          //       <Button
          //         className='inline-edit-btn btn-anchor btn-anchor mr-3'
          //         variant='link'
          //         onClick={() => {
          //           updateModal()
          //         }}
          //       >
          //         Update company profile
          //       </Button>
          //     </>
          //   )
          // } else {
          //   return ''
          // }
          return ''
        }
        let date = ''
        if (moment().diff(createdAt, 'hours', true) < 24) {
          date = moment(createdAt).fromNow()
        } else {
          date = moment(createdAt).format('MM/DD/YYYY')
        }
        return (
          <>
            <p className='title-cat mb-0'>
              {renderTextWithMentions(text, links)}
            </p>
            <p className='sub-cat mb-0'>{date}</p>
          </>
        )
      },
    },
    {
      title: '',
      colClassName: 'remove-col',
      renderCol: ({ text, id }) => {
        if (text === 'update company profile') {
          return ''
        }
        return (
          <Button
            variant='link'
            className='remove-btn pointer-link'
            onClick={() => {
              removeNotification(id)
            }}
          >
            <img
              className='remove-btn-img'
              src='/images/icon_close_notification.png'
              alt=''
            />
          </Button>
        )
      },
    },
  ]
  const listRef = useRef(0)

  const readAllNotifications = useCallback(() => {
    let filterList = filter(notificationsList, function(o) {
      return !o.readStatus
    })
    if (filterList.length) {
      filterList.forEach((notification, i) => {
        markAsReadNotification(notification.id)
      })
      fetchNotificationsList()
    }
  }, [notificationsList, fetchNotificationsList, markAsReadNotification])

  useEffect(() => {
    setloaded(notificationLoaded)
  }, [
    isPanel,
    isExpanded,
    listRef,
    notificationsList,
    fetchNotificationsList,
    notificationLoaded,
  ])

  useEffect(() => {
    if (isPanel && isExpanded) {
      readAllNotifications()
    } else if (!isPanel && !isExpanded && listRef.current === 0) {
      readAllNotifications()
    }
  }, [isPanel, isExpanded, listRef, notificationsList, readAllNotifications])

  if (isPanel) {
    return (
      <div
        ref={listRef}
        id='support-notifications'
        className={`support-panel-wrapper ${
          isExpanded ? 'support-panel-visible' : 'support-panel-hidden'
        }`}
      >
        <div className='support-panel-titlebar'>
          <div className='support-panel-title'>Notifications</div>
          <div>
            <img
              className='support-panel-img'
              src='/images/icon_close.png'
              alt=''
              onClick={() => {
                closePanel()
              }}
            />
          </div>
        </div>
        <SmallUp>
          <DataTable
            list={notificationsList}
            columns={columns}
            className='light-theme-table no-action support-panel-table'
            renderNoData={() => (
              <NoData
                image='/images/icon_notification_inactive.png'
                width={40}
                title='No notifications'
                description=''
                className='no-notifications'
              />
            )}
          />
        </SmallUp>
      </div>
    )
  }

  return (
    <DefaultLayout
      className='nested-layout light-theme notifications-loyout show-header-line'
      showClose
      title='Notifications'
      hideSidebar={true}
      ref={listRef}
      isChatNotification
      // showBack
    >
      <SmallDown>
        <DataTable
          list={notificationsList}
          columns={columns2}
          className='light-theme-table no-action'
          isLoading={!isLoaded}
          renderNoData={() => (
            <NoData
              image='/images/icon_notification_inactive.png'
              width={40}
              title='No notifications'
              description=''
              className='no-notifications'
            />
          )}
        />
      </SmallDown>
      <SmallUp>
        <DataTable
          list={notificationsList}
          columns={columns}
          className='light-theme-table no-action'
          renderNoData={() => (
            <NoData
              image='/images/icon_notification_inactive.png'
              width={40}
              title='No notifications'
              description=''
              className='no-notifications'
            />
          )}
        />
      </SmallUp>
    </DefaultLayout>
  )
}

const mapStateToProps = ({
  auth: { isCompanyAdmin, companyId },
  appWebsocket,
}) => ({
  notificationsList: appWebsocket.notificationsList,
  notificationLoaded: appWebsocket.notificationLoaded,
  isCompanyAdmin: isCompanyAdmin,
  companyId: companyId,
})

export default withRouter(
  connect(mapStateToProps, {
    removeNotification,
    markAsReadNotification,
    fetchNotificationsList,
  })(NotificationsClient)
)
