import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../actions'

const ClientRegisterPage = ({
  isLoggedIn,
  needLogout,
  history = {},
  logout = () => {},
  component: View = null,
}) => {
  // const [isPageLoaded, setIsPageLoaded] = useState(false)
  // useEffect(() => {}, [needLogout, isLoggedIn, isPageLoaded, logout])
  //
  // if (isLoggedIn && (!needLogout || isPageLoaded)) {
  //   return <Redirect to={history.location.from || '/'} />
  // }
  return (
    // <div className='auth-page'>
    //   <div className='image-container-new' />
    //   <div className='auth-container'>
    //     { clientAuth? clientAuth.credentials? clientAuth.credentials.email: 'n' : 'o' }
    //     <View />
    //   </div>
    // </div>
    <View />
  )
}

const mapStateToProps = ({ auth, clientAuth }) => {
  const { isLoggedIn } = auth

  return { isLoggedIn, clientAuth }
}

export default withRouter(
  connect(
    mapStateToProps,
    { logout }
  )(ClientRegisterPage)
)
