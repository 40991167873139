import React from 'react'
import { useParams, withRouter } from 'react-router-dom'
import AuthForm from './AuthForm'
import * as Yup from 'yup'
import { minLengthStringValidator } from '../../../../../form-validation'

function ResetPassword({ showNotification }) {
  const { email, token } = useParams()
  const validationSchema = Yup.object().shape({
    password: minLengthStringValidator({
      limit: 6,
      message: 'Password must be at least 6 characters',
    }),
  })

  const initialValues = validationSchema.cast()
  const formSections = [
    {
      title: `Create a new password for ${email}`,
      sectionClass: 'pb-3',
      fields: [
        {
          name: 'password',
          type: 'password',
          label: 'PASSWORD',
        },
      ],
    },
  ]
  return (
    <>
      {/* <div className='auth-page justify-content-start'> */}
      <AuthForm
        title='Partner Register'
        initialValues={initialValues}
        validationSchema={validationSchema}
        formSections={formSections}
        token={token}
        email={email}
        isResetPassword
        buttonLabel='Submit'
        submitStyle='login-btn'
      />
      {/* </div> */}
    </>
  )
}

export default withRouter(ResetPassword)
