import { createOrEditItem, deleteItem } from './form'

export const surgePricing = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'surgePricing',
      successMessage: 'Surge Pricing was successfully created',
    })
  )
export const editSurgePricing = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `surgePricing/${id}`,
      successMessage: 'Surge Pricing was successfully updated',
    })
  )
