import { useCallback, useEffect, useRef, useState } from 'react'
import { connectedApiService } from '..'
import { logger } from '../actions'
export const useGetTimeZone = (Data = {}) => {
  const currentItem = useRef(null)
  const [isLoading, setIsloading] = useState(false)
  const getCurrentItem = useCallback(
    async (reload = true, triggerIsLoading = true) => {
      try {
        if (Data && (!currentItem || !currentItem.current || reload)) {
          if (triggerIsLoading) {
            setIsloading(true)
          }
          const { data } =
            (await connectedApiService.post(`booking-timeZone`, Data)) || {}
          currentItem.current = data
          setIsloading(false)
        }
      } catch (error) {
        logger({ fileName: 'useGetTimeZone', error: error })
        console.log(error)
        setIsloading(false)
      }
    },
    [currentItem, Data]
  )

  useEffect(() => {
    getCurrentItem(false)
  }, [getCurrentItem, Data])

  return {
    isLoading,
    currentItem: currentItem.current,
    getCurrentItem,
  }
}
