import React from 'react'

export default function MatRaisedBtn({
  className = '',
  title = 'Save',
  disabled = false,
  ...props
}) {
  return (
    <button
      className={`btn btn-anchor btn-search ${className || ''}`}
      disabled={disabled}
      type='submit'
      {...props}
    >
      {title}
    </button>
  )
}
