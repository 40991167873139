import {
  send,
  WEBSOCKET_BROKEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_OPEN,
} from '@giantmachines/redux-websocket'
import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { WEBSOCKET_PREFIX } from '../constants'
import { logger } from '../actions'
import appWebsocket from 'reducers/appWebsocket'
export const APP_WEBSOCKET_BROKEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BROKEN}`
export const APP_WEBSOCKET_OPEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`
export const APP_WEBSOCKET_CLOSED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`
export const APP_WEBSOCKET_MESSAGE = `${WEBSOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`

export const UPDATE_CHATS_LIST = 'UPDATE_CHATS_LIST'
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER'
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST'
export const UNSET_TYPING = 'UNSET_TYPING'

export const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST'
export const SET_OPERATIONALERT_COUNT = 'SET_OPERATIONALERT_COUNT'
export const SET_BOOKING_PENDING_COUNT = 'SET_BOOKING_PENDING_COUNT'
export const SET_UNREAD_CHATS_BY_ADMIN_COUNT = 'SET_UNREAD_CHATS_BY_ADMIN_COUNT'
export const SET_SENT_BY_ADMIN_QUOTES_COUNT = 'SET_SENT_BY_ADMIN_QUOTES_COUNT'
export const SET_PARTNER_COUNT = 'SET_PARTNER_COUNT'

export const updateChatsList = payload => ({
  type: UPDATE_CHATS_LIST,
  payload,
})

export const setNotificationsList = data => ({
  type: SET_NOTIFICATIONS_LIST,
  payload: {
    data,
  },
})

export const setOperationAlertCount = data => ({
  type: SET_OPERATIONALERT_COUNT,
  payload: {
    data,
  },
})
export const setBookingPendingCount = data => ({
  type: SET_BOOKING_PENDING_COUNT,
  payload: {
    data,
  },
})
export const setPartnerCount = data => ({
  type: SET_PARTNER_COUNT,
  payload: {
    data,
  },
})
export const setUnreadChatsByAdminCount = data => ({
  type: SET_UNREAD_CHATS_BY_ADMIN_COUNT,
  payload: {
    data,
  },
})
export const setSentByAdminQuotesCount = data => ({
  type: SET_SENT_BY_ADMIN_QUOTES_COUNT,
  payload: {
    data,
  },
})

export const updateMessagesList = payload => ({
  type: UPDATE_MESSAGE_LIST,
  payload,
})

export const unsetTyping = chatId => ({
  type: UNSET_TYPING,
  payload: {
    chatId,
  },
})

export const fetchFilteredChatsList = (
  filters,
  page,
  limit,
  archived = false,
  chatId = null
) => async dispatch => {
  try {
    let url = 'chat'
    if (filters) {
      const queryParams = []
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          queryParams.push(`filter[${key},like]=${filters[key]}`)
        }
      })
      url = !chatId
        ? `${url}?${queryParams.join(
            '&'
          )}&page=${page}&limit=${limit}&archived=${archived}`
        : `${url}?${queryParams.join(
            '&'
          )}&page=${page}&limit=${limit}&archived=${archived}&chatId=${chatId}`
    }
    const { data, meta } = await api.get(url)
    dispatch(updateChatsList({ data, meta, page }))
    return data
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchFilteredChatsList_LS = (
  filters,
  page,
  limit,
  archived = false,
  chatId = null,
  userId
) => async dispatch => {
  try {
    let url = 'chat/chats'
    if (filters) {
      const queryParams = []
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          queryParams.push(`filter[${key},like]=${filters[key]}`)
        }
      })
      url = !chatId
        ? `${url}?${queryParams.join(
            '&'
          )}&page=${page}&limit=${limit}&archived=${archived}`
        : `${url}?${queryParams.join(
            '&'
          )}&page=${page}&limit=${limit}&archived=${archived}&chatId=${chatId}`
    }
    const { data, meta } = await api.post_LS(
      url,
      { userId: userId },
      false,
      null,
      null,
      false,
      true,
      'Chat_LS'
    )
    dispatch(updateChatsList({ data, meta, page }))
    return data
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchNewChat_LS = (chatId, page = 1, userId) => async dispatch => {
  try {
    const { data } = await api.post_LS(
      `chat/chats/${chatId}`,
      { page, userId },
      false,
      null,
      null,
      false,
      true,
      'Chat_LS'
    )

    dispatch(updateMessagesList({ ...data, page: page }))
    return data
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const getChatsList = () => (dispatch, getState) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    const {
      auth: { userId },
    } = getState()
    dispatch(
      send(
        {
          command: 'chat_list',
          payload: {
            userId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const fetchNewChat = (chatId, page = 1) => async dispatch => {
  try {
    const { data } = await api.post(`chat/${chatId}`, { page })
    dispatch(updateMessagesList({ ...data, page: page }))
    return data
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}

export const sendCommandSendMessageToChat = (chatId, data) => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'add_message_to_chat',
          payload: {
            chatId,
            ...data,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const sendCommandTyping = (chatId, userId) => (dispatch, getState) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    const payloadData = { chatId }
    if (userId) {
      payloadData.toUserId = userId
    }
    dispatch(
      send(
        {
          command: 'send_typing',
          payload: payloadData,
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}
export const sendCommandEditMsg = (data, chatId, messageId) => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'edit_message_in_chat',
          payload: {
            chatId,
            messageId,
            ...data,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}
export const sendCommandDeleteMsg = (text, chatId, messageId) => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'delete_message_in_chat',
          payload: {
            chatId,
            text,
            messageId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const setConversationOwnerToChatWithAdmin = chatId => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'set_conversation_owner_to_chat',
          payload: {
            chatId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const takeHandConversationOwnerToChatWithAdmin = chatId => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'take_hand_conversation_owner_to_chat',
          payload: {
            chatId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const untakeHandConversationOwnerToChatWithAdmin = chatId => (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'untake_hand_conversation_owner_to_chat',
          payload: {
            chatId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const markAsReadMessageInChatWithAdmin = (chatId, messageId) => async (
  dispatch,
  getState
) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'mark_as_read_message_in_chat',
          payload: {
            chatId,
            messageId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const fetchOperationAlertCount = () => async dispatch => {
  try {
    let url = 'operationAlertsCount'
    const { data } = await api.get(url)
    dispatch(setOperationAlertCount(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchBookingPendingCount = () => async dispatch => {
  try {
    let url = 'bookingPendingCount'
    const { data } = await api.get(url)
    dispatch(setBookingPendingCount(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchPartnerCount = () => async dispatch => {
  try {
    let url = 'partnerStatusCount'
    const { data } = await api.get(url)
    dispatch(setPartnerCount(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchUnreadChatsByAdminCount = () => async dispatch => {
  try {
    // let url = 'unreadChatsByAdminCount'
    // const { data } = await api.get(url)
    let url = 'chat/chats/unreadChatsByAdminCount'
    const { data } = await api.get(url, false, true, 'Chat_LS')
    dispatch(setUnreadChatsByAdminCount(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}
export const fetchSentByAdminQuotesCount = () => async dispatch => {
  try {
    let url = 'sentByAdminQuotesCount'
    const { data } = await api.get(url)
    dispatch(setSentByAdminQuotesCount(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}

export const fetchNotificationsList = () => async dispatch => {
  try {
    let url = 'notification'
    const { data } = await api.get(url)
    dispatch(setNotificationsList(data))
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}

export const removeNotification = id => async (dispatch, getState) => {
  try {
    const { meta } = await api.del(`notification/${id}`)
    if (meta.success) {
      const {
        appWebsocket: { notificationsList },
      } = getState()
      const filteredData = notificationsList.filter(ntf => ntf.id !== id)
      dispatch(setNotificationsList(filteredData))
    }
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}

export const markAsReadNotification = id => async (dispatch, getState) => {
  try {
    const { meta } = await api.post(`notification/${id}`)
    if (meta.success) {
      const {
        appWebsocket: { notificationsList },
      } = getState()
      dispatch(setNotificationsList(notificationsList))
    }
  } catch (error) {
    logger({ fileName: 'appWebsocket', error: error })
    return handleActionError(error)
  }
}

export const assignAlertOwner = alertId => (dispatch, getState) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'assign_alert_owner',
          payload: {
            alertId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}

export const unassignAlertOwner = alertId => (dispatch, getState) => {
  const { appWebsocket: connected } = getState()
  if (connected) {
    dispatch(
      send(
        {
          command: 'unassign_alert_owner',
          payload: {
            alertId,
          },
        },
        WEBSOCKET_PREFIX
      )
    )
  }
}
