import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, logger } from '../../../actions'

const AuthPage = ({
  isLoggedIn,
  ipLocation,
  needLogout,
  history = {},
  logout = () => {},
  logger = () => {},
  component: View = null,
}) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  useEffect(() => {
    const logoutIfNeed = async () => {
      try {
        if (isLoggedIn && needLogout && !isPageLoaded) {
          await logout()
        }
        setIsPageLoaded(true)
      } catch (error) {
        logger({ fileName: 'Auth page', error: error })
        console.log(error)
      }
    }

    logoutIfNeed()
  }, [needLogout, isLoggedIn, ipLocation, isPageLoaded, logout])

  if (isLoggedIn && (!needLogout || isPageLoaded)) {
    return <Redirect to={history.location.from || '/'} />
  }
  return (
    <div className='auth-page'>
      <div className={`image-container image-container-${ipLocation}`} />
      <div className='auth-container'>
        <View />
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { isLoggedIn, ipLocation } = auth
  // var location =
  //   ipLocation === 'GB'
  //     ? 'GB'
  //     : ipLocation === 'FR'
  //     ? 'FR'
  //     : ipLocation === 'US'
  //     ? 'US'
  //     : ipLocation === 'MX'
  //     ? 'MX'
  //     : 'REST'

  // return { isLoggedIn, ipLocation: location }
  return { isLoggedIn, ipLocation: ipLocation }
}

export default withRouter(
  connect(mapStateToProps, { logout, logger })(AuthPage)
)
