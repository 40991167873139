import { fileToBase64, getLabelFromValue, getOptionFromValue } from '../helpers'
import {
  defaultCurrencies,
  defaultFleetOptions,
  defaultPayoutTerms,
} from '../constants/index.js'
import { logger } from '../actions'
export const companySupplierFormData = async ({
  logo,
  contactPhoneNumber1 = '',
  contactPhoneNumber2 = '',
  selectChauffeur,
  manualDispatch,
  vipMeetAndGreet,
  website,
  selectRental,
  coveredCities,
  contactDetails,
  vehicles,
  currency,
  address,
  rateCard,
  brochure,
  brochGoogle,
  otherFileGoogle,
  rateCardGoogle,
  gdpr,
  otherFile,
  payoutCurrency,
  ...values
}) => {
  try {
    let data = {
      ...values,
      contactPhones: [contactPhoneNumber1, contactPhoneNumber2],
      website: website ? website : '',
      currency: currency ? currency.value : '',
      selectChauffeur: selectChauffeur ? selectChauffeur : false,
      selectRental: selectRental ? selectRental : false,
      manualDispatch: manualDispatch ? manualDispatch : false,
      vipMeetAndGreet: vipMeetAndGreet ? vipMeetAndGreet : false,
      coveredCities: coveredCities ? coveredCities : [],
      contactDetails: contactDetails ? contactDetails : [],
      vehicles: vehicles ? vehicles : [],
      rateCard: rateCard ? rateCard : null,
      brochure: brochure ? brochure : null,
      otherFileGoogle: otherFileGoogle ? true : false,
      rateCardGoogle: rateCardGoogle ? true : false,
      brochGoogle: brochGoogle ? true : false,
      gdpr: gdpr ? gdpr : null,
      otherFile: otherFile ? otherFile : null,
      active: true,
    }

    if (address) {
      data.address = address.fullAddress || ''
    }

    if (logo && typeof logo === 'object' && logo.size) {
      const logoBase64 = await fileToBase64(logo)
      data.logo = logoBase64 || null
    }
    if (rateCard && typeof rateCard === 'object' && rateCard.size) {
      const logoBase64 = await fileToBase64(rateCard)
      data.rateCard = { name: logoBase64 } || null
    }
    if (brochure && typeof brochure === 'object' && brochure.size) {
      const logoBase64 = await fileToBase64(brochure)
      data.brochure = { name: logoBase64 } || null
    }
    if (otherFile && typeof otherFile === 'object' && otherFile.size) {
      const logoBase64 = await fileToBase64(otherFile)
      data.otherFile = { name: logoBase64 } || null
    }
    if (gdpr && typeof gdpr === 'object' && gdpr.size) {
      const logoBase64 = await fileToBase64(gdpr)
      data.gdpr = { name: logoBase64 } || null
    }
    // if (payoutCurrency !== null && typeof payoutCurrency === 'object') {
    //   data.payoutCurrency = payoutCurrency.value
    // } else {
    //   data.payoutCurrency = null
    // }

    return data
  } catch (error) {
    logger({ fileName: 'companySupplier', error: error })
    console.log(error)
    return {}
  }
}

export class CompanySupplierInitialValues {
  constructor({
    name = '',
    address = '',
    registrationNumber = '',
    notes = '',
    logo = '',
    rateCard = {},
    otherFile = {},
    brochure = {},
    brochGoogle,
    otherFileGoogle,
    rateCardGoogle,
    gdpr = {},
    website = '',
    selectChauffeur,
    selectRental,
    manualDispatch,
    vipMeetAndGreet,
    coveredCities = [],
    chooseCurrency = '',
    contactDetails = [],
    vehicles = [],
    contactEmail = '',
    contactFullName = '',
    contactPhones = [],
    currency,
    taxName,
    taxValue,
    taxNumber,
    place = null,
    status = '',
    verified,
    payoutTerms,
    taxRateId = '',
    taxRate = 0,
    transferPolicy = '',
    byTheHourPolicy = '',
    carRentalPolicy = '',
    vipMeetGreetPolicy = '',
    airportWaitingTime = '',
    otherWaitingTime = '',
    isOperative = false,
    dispatchWeight = 0,
    billing = {
      name: '',
      contacts: [{ fullName: '', email: '' }],
      currency: { label: '', value: '' },
      taxNumber: '',
      taxRate: 0,
      isTaxRegistered: false,
      registrationNumber: '',
      address: null,
      city: null,
      address2: '',
      postalCode: '',
    },
    fleetSize = '',
  } = {}) {
    const foundCurrency = getOptionFromValue(defaultCurrencies, currency)
    // console.log('payoutCurrency', payoutCurrency)
    this.name = name
    this.address = address
    this.registrationNumber = registrationNumber
    this.notes = notes
    this.website = website
    this.logo = logo
      ? {
          name: logo,
        }
      : null
    this.rateCard = rateCard
      ? {
          name: rateCard,
          google: rateCardGoogle ? true : false,
        }
      : null
    this.brochure = brochure
      ? {
          name: brochure,
          google: brochGoogle ? true : false,
        }
      : null
    this.otherFile = otherFile
      ? {
          name: otherFile,
          google: otherFileGoogle ? true : false,
        }
      : null
    this.gdpr = gdpr
      ? {
          name: gdpr,
        }
      : null
    this.contactEmail = contactEmail
    this.contactFullName = contactFullName
    this.contactPhoneNumber1 = contactPhones ? contactPhones[0] || '' : ''
    this.contactPhoneNumber2 = contactPhones ? contactPhones[1] || '' : ''
    this.currency = foundCurrency
    this.taxName = taxName
    this.taxValue = taxValue
    this.taxNumber = taxNumber
    this.selectChauffeur = selectChauffeur
    this.selectRental = selectRental
    this.manualDispatch = manualDispatch
    this.vipMeetAndGreet = vipMeetAndGreet
    this.coveredCities = coveredCities ? coveredCities : []
    this.chooseCurrency = chooseCurrency
    this.contactDetails = contactDetails ? contactDetails : []
    this.vehicles = vehicles ? vehicles : []
    this.place = place
    this.address = {
      fullAddress: address,
    }
    this.status = status ? status : { label: 'NEW', value: 'new' }

    this.verified = verified ? verified : false
    this.payoutTerms = payoutTerms
      ? {
          label: getLabelFromValue(defaultPayoutTerms, payoutTerms),
          value: payoutTerms,
        }
      : { label: 'Monthly', value: 'payout_term_monthly' }
    this.taxRate = taxRate
    this.taxRateId = taxRateId
    this.transferPolicy = transferPolicy
    this.byTheHourPolicy = byTheHourPolicy
    this.carRentalPolicy = carRentalPolicy
    this.vipMeetGreetPolicy = vipMeetGreetPolicy
    this.airportWaitingTime = airportWaitingTime
    this.otherWaitingTime = otherWaitingTime
    this.isOperative = isOperative
    this.dispatchWeight = dispatchWeight
    this.billing = {
      name: billing.name || '',
      contacts: billing.contacts || [{ fullName: '', email: '' }],
      taxNumber: billing.taxNumber || '',
      taxRate: billing.taxRate || 0,
      currency: billing.currency
        ? {
            label: getLabelFromValue(defaultCurrencies, billing.currency),
            value: billing.currency,
          }
        : { label: '', value: '' },
      registrationNumber: billing.registrationNumber || '',
      isTaxRegistered: billing.isTaxRegistered || false,
      address: billing.address || null,
      city: billing.city || null,
      address2: billing.address2 || '',
      postalCode: billing.postalCode || '',
    }
    this.fleetSize = fleetSize
      ? {
          label: getLabelFromValue(defaultFleetOptions, fleetSize),
          value: fleetSize,
        }
      : null
  }
}
