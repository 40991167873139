import { defaultModes } from '../constants/index.js'
import { getOptionFromValue } from '../helpers/index.js'
import { logger } from '../actions'
import _ from 'lodash'
export const globalRateFormData = async ({
  mode,
  globalVehicleId,
  years,
  locations,
  id,
}) => {
  try {
    const data = {
      globalVehicleId: globalVehicleId.value,
      years: years.map(({ value }) => value),
      active: true,
    }

    if (!id) {
      data.mode = mode ? mode.value : ''
      data.locations = locations.map(place => ({
        place,
        active: true,
      }))
    }

    return data
  } catch (error) {
    logger({ fileName: 'globalRate', error: error })
    console.log(error)
    return {}
  }
}
// export const globalRateEditFormData = async ({
//   globalVehicleId,
//   years,
//   locations,
// }) => {
//   try {
//     const data = {
//       globalVehicleId: globalVehicleId.value,
//       years: years.map(({ value }) => value),
//       active: true,
//     }
//     if (locations) {
//       locations = _.each(locations, item => {
//         delete item.rates
//         delete item.active
//         delete item.bookings
//         delete item.id
//       })
//       data.locations = locations.map(place => ({
//         place,
//         active: true,
//       }))
//     }
//     return data
//   } catch (error) {
//     logger({ fileName: 'globalRate', error: error })
//     console.log(error)
//     return {}
//   }
// }

const checkPassenger = (mode, globalVehicle) => {
  // mode === 'chauffeured'  ? globalVehicle.PassengerChauffeured : mode === 'self_drive' ? globalVehicle.PassengerChauffeured : 0,

  if (
    mode === 'chauffeured' &&
    globalVehicle.chauffeurEnabled &&
    globalVehicle.PassengerChauffeured &&
    typeof globalVehicle.PassengerChauffeured === 'number'
  ) {
    return globalVehicle.PassengerChauffeured
  } else if (
    mode === 'self_drive' &&
    globalVehicle.selfDriveEnabled &&
    globalVehicle.PassengerSelfDrive &&
    typeof globalVehicle.PassengerSelfDrive === 'number'
  ) {
    return globalVehicle.PassengerSelfDrive
  }
  // else if (
  //   globalVehicle.Passenger &&
  //   typeof globalVehicle.Passenger === 'number'
  // ) {
  //   return globalVehicle.Passenger
  // }
  else {
    return 0
  }
}
const checkLuggage = (mode, globalVehicle) => {
  if (
    mode === 'chauffeured' &&
    globalVehicle.luggageChauffeured &&
    typeof globalVehicle.luggageChauffeured === 'number'
  ) {
    return globalVehicle.luggageChauffeured
  } else if (
    mode === 'self_drive' &&
    globalVehicle.luggageSelfDrive &&
    typeof globalVehicle.luggageSelfDrive === 'number'
  ) {
    return globalVehicle.luggageSelfDrive
  }
  // else if (
  //   globalVehicle.luggage &&
  //   typeof globalVehicle.luggage === 'number'
  // ) {
  //   return globalVehicle.Passenger
  // }
  else {
    return 0
  }
}
export class GlobalRateInitialValues {
  constructor({
    mode,
    globalVehicleId,
    years,
    active,
    globalVehicleLabel,
    locations,
    globalVehicle,
  } = {}) {
    const foundMode = getOptionFromValue(defaultModes, mode)
    this.globalVehicleId = {
      value: globalVehicleId,
      label: globalVehicleLabel,
      passenger: checkPassenger(mode, globalVehicle),
      luggage: checkLuggage(mode, globalVehicle),
    }
    this.mode = foundMode || mode
    this.active = active
    this.years = years.map(year => ({ label: year, value: year }))
    this.locations = locations
  }
}
