import { createOrEditItem, deleteItem } from './form'

export const createBanner = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'warningBannersList',
      successMessage: 'Banner was successfully created',
    })
  )

export const editBanner = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `warningBannersList/${id}`,
      successMessage: 'Banner was successfully updated',
    })
  )

export const deleteBanner = bannerId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `warningBannersList/${bannerId}`,
      successMessage: 'Banner was successfully deleted',
    })
  )
