import { fileToBase64, getOptionFromValue } from '../helpers'
import {
  defaultChauffeuredСategory,
  defaultSelfDriveСategory,
} from '../constants/index.js'
import { logger } from '../actions'
export const globalFleetFormData = async ({
  image,
  categorySelfDrive,
  categoryChauffeured,
  brandId,
  description,
  model,
  luggage,
  Passenger,
  luggageSelfDrive,
  PassengerSelfDrive,
  luggageChauffeured,
  PassengerChauffeured,
  selfDriveEnabled,
  selfDriveWebShow,
  chauffeurEnabled,
  chauffeurWebShow,
  active = true,
  electricVehicle = false,
}) => {
  try {
    let data = {
      description,
      brandId: brandId ? brandId.value : '',
      categorySelfDrive: categorySelfDrive ? categorySelfDrive.value : '',
      categoryChauffeured: categoryChauffeured ? categoryChauffeured.value : '',
      active: !!active,
      luggage: luggage,
      Passenger: Passenger,
      luggageSelfDrive: luggageSelfDrive,
      PassengerSelfDrive: PassengerSelfDrive,
      luggageChauffeured: luggageChauffeured,
      PassengerChauffeured: PassengerChauffeured,
      selfDriveEnabled: selfDriveEnabled,
      selfDriveWebShow: selfDriveWebShow,
      chauffeurEnabled: chauffeurEnabled,
      chauffeurWebShow: chauffeurWebShow,
      electricVehicle: electricVehicle,
    }

    if (brandId) {
      data.model = model || ''
    }

    if (image && typeof image === 'object' && image.size) {
      const imageBase64 = await fileToBase64(image)
      data.image = imageBase64 || null
    }

    return data
  } catch (error) {
    logger({ fileName: 'globalFleet', error: error })
    console.log(error)
    return {}
  }
}

export class GlobalFleetInitialValues {
  constructor({
    categorySelfDrive,
    categoryChauffeured,
    brandId,
    modelName,
    description = '',
    luggage,
    Passenger,
    luggageSelfDrive,
    PassengerSelfDrive,
    luggageChauffeured,
    PassengerChauffeured,
    selfDriveEnabled,
    selfDriveWebShow,
    chauffeurEnabled,
    chauffeurWebShow,
    image = '',
    label = '',
    categorySelfDriveLabel,
    categoryChauffeuredLabel,
    brandName = '',
    active = true,
    electricVehicle = false,
  } = {}) {
    const foundSelfDriveСategory = getOptionFromValue(
      defaultSelfDriveСategory,
      categorySelfDrive
    )

    const foundChauffeuredСategory = getOptionFromValue(
      defaultChauffeuredСategory,
      categoryChauffeured
    )

    this.brandId = {
      value: brandId,
      label: brandName,
    }
    this.model = modelName
    this.description = description
    this.luggage = luggage
    this.Passenger = Passenger
    this.luggageSelfDrive = luggageSelfDrive
    this.PassengerSelfDrive = PassengerSelfDrive
    this.luggageChauffeured = luggageChauffeured
    this.PassengerChauffeured = PassengerChauffeured
    this.selfDriveEnabled = selfDriveEnabled
    this.selfDriveWebShow = selfDriveWebShow
    this.chauffeurEnabled = chauffeurEnabled
    this.chauffeurWebShow = chauffeurWebShow
    this.image = image
      ? {
          name: image,
        }
      : null
    this.label = label
    this.categorySelfDriveLabel = categorySelfDriveLabel || ''
    this.categoryChauffeuredLabel = categoryChauffeuredLabel || ''
    this.categorySelfDrive = foundSelfDriveСategory
    this.categoryChauffeured = foundChauffeuredСategory
    this.active = active
    this.electricVehicle = electricVehicle
  }
}
