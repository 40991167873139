import React from 'react'
import loadable from '../../../loadable'
import moment from 'moment'

let days = new Array(6).fill().map((e, i) => {
  return moment
    .utc(new Date())
    .add(i + 2, 'days')
    .format('ddd DD MMM')
})

export const systemRoutes = [
  // 'system_agent', 'system_manager'
  {
    path: '/dashboard',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        // component: () => <div>Dashboard</div>,
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Dashboard')
        ),
        isPrivate: true,
        exact: true,
        title: 'Dashboard',
        showBottomMenu: true,
      },
    ],
    showBottomMenu: true,
  },
  {
    path: '/automation/confirmation',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Automation/serviceTimeList')
        ),
        isPrivate: true,
        exact: true,
        title: 'Bookings',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Automation/addNewLocation')
        ),
        isPrivate: true,
        title: 'Add new location',
        hideInSidebar: true,
      },
      {
        path: '/edit/:recordId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Automation/addNewLocation')
        ),
        isPrivate: true,
        title: 'Edit location',
        hideInSidebar: true,
      },
    ],
  },
  {
    path: '/rolzoCountries',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/RolzoLocations/Countries/countriesList'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Countries',
      },
    ],
  },
  {
    path: '/rolzoCities',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/RolzoLocations/Cities/citiesList'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Cities',
      },
    ],
  },
  {
    path: '/support-admin',
    component: loadable(() =>
      import('../../../../pages/App/SystemPages/ChatAdmin')
    ),
    showAlertBadge: true,
    isPrivate: true,
    title: 'Support',
    icon: '/icons/icon-support.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/ChatAdmin')
        ),
        exact: true,
        isPrivate: true,
        title: 'Support',
        iconActive: '/icons/icon-support.png',
        iconInactive: '/icons/icon-support.png',
        // showBottomMenu: true,
        // hideSidebar: true,
      },
      {
        path: '/:selectedChatId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/ChatAdmin/SelectChat')
        ),
        exact: true,
        isPrivate: true,
        title: 'Support',
        hideInSidebar: true,
        iconActive: '/icons/icon-support.png',
        iconInactive: '/icons/icon-support.png',
        // hideSidebar: true,
      },
    ],
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-dispatch',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-supply',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-ops',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-cs',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-mod',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-dispatch',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    title: 'Alerts',
    path: '/alerts-supply',
    showAlertBadge: true,
    icon: '/icons/icon-alerts.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Alerts/AlerstList')
        ),
        isPrivate: true,
        title: 'Alerts',
        iconActive: '/icons/icon-alerts.png',
        iconInactive: '/icons/icon-alerts.png',
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/sales-bookings',
    showAlertBadge: true,
    icon: '/icons/icon-bookings.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/list/tab',
        component: loadable(() =>
          import(`../../../../pages/App/SystemPages/BookingsPoc/BookingsList`)
        ),
        isPrivate: true,
        title: 'Bookings',
        iconActive: '/icons/icon-bookings.png',
        iconInactive: '/icons/icon-bookings.png',
        showBottomMenu: true,
        tabs: [
          {
            title: 'All',
            path: '/all',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/AllBookings'
              )
            ),
            isPrivate: true,
            exact: true,
          },
          {
            title: 'Yesterday',
            path: '/yesterday',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/YesterdayBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Today',
            path: '/today',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TodaysBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Tomorrow',
            path: '/tomorrow',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TomorrowsBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: `${days[0]}`,
            day: 2,
            path: '/2-days-after',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TomorrowsBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: `${days[1]}`,
            day: 3,
            path: '/3-days-after',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TomorrowsBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: `${days[2]}`,
            day: 4,
            path: '/4-days-after',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TomorrowsBookings'
              )
            ),
            isPrivate: true,
          },
          {
            title: `${days[3]}`,
            day: 5,
            path: '/5-days-after',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/BookingsPoc/TomorrowsBookings'
              )
            ),
            isPrivate: true,
          },
        ],
      },
      {
        path: '/:bookingId',
        component: loadable(() =>
          import(
            // '../../../../pages/App/SystemPages/Bookings/BookingDetails/BookingDetails'
            '../../../../pages/App/SystemPages/Bookings/BookingDetails/Details'
          )
        ),
        exact: true,
        isPrivate: true,
        title: 'My Booking Details',
        iconActive: '/icons/icon-bookings.png',
        iconInactive: '/icons/icon-bookings.png',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/:bookingId/:serviceType',
        component: loadable(() =>
          import(
            // '../../../../pages/App/SystemPages/Bookings/BookingDetails/BookingDetails'
            '../../../../pages/App/SystemPages/Bookings/BookingDetails/Details'
          )
        ),
        exact: true,
        isPrivate: true,
        title: 'My Booking Details',
        iconActive: '/icons/icon-bookings.png',
        iconInactive: '/icons/icon-bookings.png',
        hideInSidebar: true,
        hideSidebar: false,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/sales-quotes',
    showAlertBadge: true,
    icon: '/icons/icon-quotes.png',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Quotes/QuotesList')
        ),
        isPrivate: true,
        exact: true,
        iconActive: '/icons/icon-quotes.png',
        iconInactive: '/icons/icon-quotes.png',
        title: 'Quotes',
      },
    ],
  },
  // {
  //   title: 'Client events',
  //   path: '/client-events',
  //   isPrivate: true,
  //   icon: '/icons/icon-events.png',
  //   allowedRoles: {
  //     system: ['system_admin', 'system_agent', 'system_manager'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Events/EventsList')
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'Client events',
  //       iconActive: '/icons/icon-events.png',
  //       iconInactive: '/icons/icon-events.png',
  //     },
  //     {
  //       path: '/:eventId',
  //       title: 'My Event Details',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Events/EventDetails')
  //       ),
  //       isPrivate: true,
  //       hideInSidebar: true,
  //       hideSidebar: true,
  //       tabs: [
  //         // Define child routes here
  //         {
  //           path: '/service',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Service')
  //           ),
  //           isPrivate: true,
  //           title: 'Service',
  //         },
  //         {
  //           path: '/comments',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Comments')
  //           ),
  //           isPrivate: true,
  //           title: 'Comments',
  //         },
  //         {
  //           path: '/coordinates',
  //           component: loadable(() =>
  //             import(
  //               '../../../../pages/App/SystemPages/Events/Tabs/Coordinates'
  //             )
  //           ),
  //           isPrivate: true,
  //           title: 'Coordinates',
  //         },
  //         {
  //           path: '/pricing',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Pricing')
  //           ),
  //           isPrivate: true,
  //           title: 'Pricing',
  //         },

  //         {
  //           path: '/summary',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Summary')
  //           ),
  //           isPrivate: true,
  //           title: 'Summary',
  //         },
  //         {
  //           path: '/map',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Map')
  //           ),
  //           isPrivate: true,
  //           title: 'Map',
  //         },
  //         {
  //           path: '/settings',
  //           component: loadable(() =>
  //             import('../../../../pages/App/SystemPages/Events/Tabs/Settings')
  //           ),
  //           isPrivate: true,
  //           title: 'Settings',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    isPrivate: true,
    path: '/events',
    showAlertBadge: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Banners/BannersList')
        ),
        isPrivate: true,
        exact: true,
        title: 'Events',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Banners/AddBanner')
        ),
        isPrivate: true,
        title: 'Add Event',
        hideInSidebar: true,
      },
      {
        path: '/edit/:bannerId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Banners/AddBanner')
        ),
        isPrivate: true,
        title: 'Edit banner',
        hideInSidebar: true,
      },
      {
        path: '/clone',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Banners/AddBanner')
        ),
        isPrivate: true,
        title: 'Clone event',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/cancellation-policies',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/CancellationPolicies/CancellationForm'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Cancellation policies',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/restricted-locations',
    allowedRoles: {
      system: ['system_admin', 'system_manager', 'system_agent'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/RestrictedLocations/locationList'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Restricted Locations',
      },
      {
        path: '/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/RestrictedLocations/AddRestrictedLocation.jsx'
          )
        ),
        isPrivate: true,
        title: 'Add Restricted Location',
        hideInSidebar: true,
      },
      {
        path: '/edit/:restrictedLocationId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/RestrictedLocations/AddRestrictedLocation.jsx'
          )
        ),
        isPrivate: true,
        title: 'Edit restricted location',
        hideInSidebar: true,
      },
    ],
  },
  // {
  //   isPrivate: true,
  //   path: '/rolzo-gpt',
  //   allowedRoles: {
  //     system: ['system_admin', 'system_manager', 'system_agent'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/RolzoGPT/RolzoGPT')
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'Rolzo GPT',
  //     },
  //   ],
  // },
  {
    isPrivate: true,
    path: '/surge-pricing',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/SurgePricing/surgePricing')
        ),
        isPrivate: true,
        exact: true,
        title: 'Surge Pricing',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/rates-comp',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/RatesComparison/index')
        ),
        isPrivate: true,
        exact: true,
        title: 'Rates Overview',
        hideSidebar: false,
      },
    ],
  },
  // {
  //   isPrivate: true,
  //   path: '/rates-comp-thirty',
  //   allowedRoles: {
  //     system: ['system_admin'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import(
  //           '../../../../pages/App/SystemPages/RatesComparisonThirty/RatesComparisonThirty'
  //         )
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'Rates Comp. (30)',
  //       hideSidebar: true,
  //     },
  //   ],
  // },
  // {
  //   isPrivate: true,
  //   path: '/rates-comp-sixty',
  //   allowedRoles: {
  //     system: ['system_admin'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import(
  //           '../../../../pages/App/SystemPages/RatesComparisonSixty/RatesComparisonSixty'
  //         )
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'Rates Comp. (60)',
  //       hideSidebar: true,
  //     },
  //   ],
  // },
  // {
  //   isPrivate: true,
  //   path: '/event-card-transfers',
  //   allowedRoles: {
  //     system: ['system_admin'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import(
  //           '../../../../pages/App/SystemPages/EventCardTransfers/EventCardTransfers'
  //         )
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'Transfer Event Card',
  //       hideSidebar: false,
  //     },
  //     {
  //       path: '/edit/:eventId',
  //       component: loadable(() =>
  //         import(
  //           '../../../../pages/App/SystemPages/EventCardTransfers/EventCardTransfers'
  //         )
  //       ),
  //       isPrivate: true,
  //       title: 'Transfer Event Card',
  //       hideInSidebar: true,
  //     },
  //   ],
  // },
  // {
  //   isPrivate: true,
  //   path: '/event-card-vip',
  //   allowedRoles: {
  //     system: ['system_admin'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/EventCardVip/EventCardVip')
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'M&G Event Card',
  //       hideSidebar: false,
  //     },
  //     {
  //       path: '/edit/:eventId',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/EventCardVip/EventCardVip')
  //       ),
  //       isPrivate: true,
  //       title: 'M&G Event Card',
  //       hideInSidebar: true,
  //     },
  //   ],
  // },
  // {
  //   isPrivate: true,
  //   path: '/event-card-bth',
  //   allowedRoles: {
  //     system: ['system_admin'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/EventCardBth/EventCardBth')
  //       ),
  //       isPrivate: true,
  //       exact: true,
  //       title: 'BTH Event Card',
  //       hideSidebar: false,
  //     },
  //     {
  //       path: '/edit/:eventId',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/EventCardBth/EventCardBth')
  //       ),
  //       isPrivate: true,
  //       title: 'BTH Event Card',
  //       hideInSidebar: true,
  //     },
  //   ],
  // },
  {
    isPrivate: true,
    path: '/geo-price',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/GeoPricing/GeoPriceList')
        ),
        isPrivate: true,
        exact: true,
        title: 'Geo-Pricing',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/GeoPricing/AddGeoPrice.jsx')
        ),
        isPrivate: true,
        title: 'Add geo-price',
        hideInSidebar: true,
      },
      {
        path: '/edit/:rateId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/GeoPricing/AddGeoPrice.jsx')
        ),
        isPrivate: true,
        title: 'Edit geo-price',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/clients',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/ClientsList'
          )
        ),
        isPrivate: true,
        title: 'B2B Clients',
        exact: true,
        showBottomMenu: true,
      },
      {
        path: '/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/AddClient'
          )
        ),
        isPrivate: true,
        title: 'Add a new client',
        hideInSidebar: true,
      },
      {
        path: '/edit/:clientId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/AddClient'
          )
        ),
        isPrivate: true,
        title: 'Edit client',
        hideInSidebar: true,
        // childRoutes: [
        //   {
        //     path: '/Benefits',
        //     component: loadable(() =>
        //       import('../../../../pages/App/SystemPages/Companies/Clients/benefits/index')
        //     ),
        //     isPrivate: true,
        //     title: 'Benefits',
        //     hideInSidebar: true,
        tabs: [
          {
            title: 'Corporate benefits',
            path: '/corporate',
            component: () => <div>corporate</div>,
            // component: loadable(() =>
            //   import('../../../../pages/App/SystemPages/Companies/Clients/benefits/corporate')
            // ),
            isPrivate: true,
          },
          {
            title: 'Member benefits',
            path: '/member',
            component: () => <div>member</div>,
            // component: loadable(() =>
            //   import(
            //     '../../../../pages/App/SystemPages/Companies/Clients/benefits/member'
            //   )
            // ),
            isPrivate: true,
          },
        ],
        //   },
        // ]
      },
      {
        path: '/:clientId/invite',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/InviteTeam'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Team',
        hideInSidebar: true,
      },
      {
        path: '/:clientId/multiple-invite',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/MultipleAddressForm'
          )
        ),
        isPrivate: true,
        title: 'Team',
        hideInSidebar: true,
      },
      {
        path: '/:clientId/user/:userId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Clients/clientUser'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Edit Client User',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/referrals-page',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Referrals/ReferralsList'
          )
        ),
        isPrivate: true,
        title: 'Referrals',
        exact: true,
        showBottomMenu: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/individuals',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/B2CUsers/IndividualsList'
          )
        ),
        isPrivate: true,
        title: 'B2C Clients',
        exact: true,
        showBottomMenu: true,
      },
      // {
      //   path: '/add',
      //   component: loadable(() =>
      //     import('../../../../pages/App/SystemPages/Companies/B2CUsers/AddIndividual')
      //   ),
      //   isPrivate: true,
      //   title: 'Add a new client',
      //   hideInSidebar: true,
      // },
      {
        path: '/edit/:userId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/B2CUsers/AddIndividual'
          )
        ),
        isPrivate: true,
        title: 'Edit client (individual)',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/host-agencies',
    allowedRoles: {
      system: ['system_admin', 'system_manager', 'system_agent'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/HostAgencies/List')
        ),
        isPrivate: true,
        exact: true,
        title: 'Host Agencies',
      },
      {
        path: '/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/HostAgencies/AddHostAgency.jsx'
          )
        ),
        isPrivate: true,
        title: 'Add a Host Agency',
        hideInSidebar: true,
      },
      {
        path: '/edit/:hostAgencyId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/HostAgencies/AddHostAgency.jsx'
          )
        ),
        isPrivate: true,
        title: 'Edit Host Agency',
        hideInSidebar: true,
        tabs: [
          {
            title: 'Corporate benefits',
            path: '/corporate',
            component: () => <div>corporate</div>,
            isPrivate: true,
          },
          {
            title: 'Member benefits',
            path: '/member',
            component: () => <div>member</div>,
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    isPrivate: true,
    path: '/consortiums',
    allowedRoles: {
      system: ['system_admin', 'system_manager', 'system_agent'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Consortiums/List')
        ),
        isPrivate: true,
        exact: true,
        title: 'Consortiums',
      },
      {
        path: '/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Consortiums/AddConsortium.jsx'
          )
        ),
        isPrivate: true,
        title: 'Add a Consortium',
        hideInSidebar: true,
      },
      {
        path: '/edit/:consortiumId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Consortiums/AddConsortium.jsx'
          )
        ),
        isPrivate: true,
        title: 'Edit Consortium',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/access',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Access/AccessList')
        ),
        isPrivate: true,
        title: 'Access',
        exact: true,
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Access/AddAdminAgent')
        ),
        isPrivate: true,
        title: 'Create a new access',
        hideInSidebar: true,
      },
      {
        path: '/edit/:adminAgentId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Access/AddAdminAgent')
        ),
        isPrivate: true,
        title: 'Edit access',
        hideInSidebar: true,
      },
      {
        path: '/changePassword',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Access/accessChangePassword'
          )
        ),
        isPrivate: true,
        title: 'Change Access Password',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/whats-new',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'What’s new',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/WhatsNew/index')
        ),
        isPrivate: true,
        exact: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/cities',
    allowedRoles: {
      system: ['system_admin', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Rolzo CMS',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/WebsiteCities/CitiesList')
        ),
        isPrivate: true,
        exact: true,
      },
      {
        path: '/add',
        title: 'Add a city',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/WebsiteCities/AddCity')
        ),
        isPrivate: true,
        hideInSidebar: true,
      },
      {
        path: '/edit/:cityId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/WebsiteCities/AddCity')
        ),
        isPrivate: true,
        title: 'Add a payout',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/externalApiLogs',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/ExternalApis/ExternalApisList'
          )
        ),
        isPrivate: true,
        title: 'Logs',
        // title: 'External Api Logs',
        exact: true,
      },
      {
        path: '/:ExternalApiId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/ExternalApis/ExternalApisView'
          )
        ),
        isPrivate: true,
        title: 'External Api',
        // exact: true,
        hideInSidebar: true,
      },
    ],
  },
  // {
  //   isPrivate: true,
  //   path: '/tests',
  //   allowedRoles: {
  //     system: ['system_admin', 'system_agent'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Tests/AllTests')
  //       ),
  //       isPrivate: true,
  //       title: 'Tests',
  //       exact: true,
  //     },
  //     {
  //       path: '/:testId',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Tests/TestDetails')
  //       ),
  //       isPrivate: true,
  //       title: 'Tests',
  //       exact: true,
  //       hideInSidebar: true,
  //     },
  //   ],
  // },
  {
    isPrivate: true,
    path: '/partners',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        exact: true,
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Suppliers/SuppliersList'
          )
        ),
        isPrivate: true,
        title: 'Partners',
      },
      {
        path: '/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Companies/Suppliers/AddSuppliers'
          )
        ),
        isPrivate: true,
        title: 'Add a new partner',
        hideInSidebar: true,
      },
      {
        path: '/edit/:supplierId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Companies/Suppliers/index')
        ),
        isPrivate: true,
        title: 'Edit partner',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/fleet-global',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Fleet/GlobalFleet/FleetList'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Global fleet',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/GlobalFleet/AddCar')
        ),
        isPrivate: true,
        hideInSidebar: true,
        exact: true,
        title: 'Add new car',
      },
      {
        path: '/edit/:globalFleetId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/GlobalFleet/AddCar')
        ),
        isPrivate: true,
        hideInSidebar: true,
        exact: true,
        title: 'Edit car',
      },
      {
        path: '/edit/:globalFleetId/add-asset',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Fleet/GlobalFleet/AssetForm'
          )
        ),
        isPrivate: true,
        hideInSidebar: true,
        title: 'Add new asset',
      },
      {
        path: '/edit/:globalFleetId/asset/edit/:assetId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Fleet/GlobalFleet/AssetForm'
          )
        ),
        isPrivate: true,
        hideInSidebar: true,
        title: 'Edit asset',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/fleet-supply',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Fleet/SupplyFleet/SupplyFleet'
          )
        ),
        exact: true,
        isPrivate: true,
        title: 'Supply fleet',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/SupplyFleet/AddCar')
        ),
        isPrivate: true,
        title: 'Add a new car',
        hideInSidebar: true,
      },
      {
        path: '/edit/:supplierVehicleId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/SupplyFleet/AddCar')
        ),
        isPrivate: true,
        title: 'Edit car',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/fleet-brands',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/Brands/BrandsList')
        ),
        exact: true,
        isPrivate: true,
        title: 'Brands',
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/Brands/AddBrand')
        ),
        isPrivate: true,
        title: 'Add a new brand',
        hideInSidebar: true,
      },
      {
        path: '/edit/:brandId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Fleet/Brands/AddBrand')
        ),
        isPrivate: true,
        title: 'Edit brand',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/rates-monitoring',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/monitoring/monitoring')
        ),
        isPrivate: true,
        title: 'Rates Monitoring',
        exact: true,
      },
      {
        path: '/edit/:monitoringId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/monitoring/monitoringAb')
        ),
        isPrivate: true,
        title: 'Monitoring A-B',
        exact: true,
        hideInSidebar: true,
      },
      {
        path: '/vehicle-mapping',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/monitoring/vehicleMapping/mapping'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Vehicle Mapping',
        hideInSidebar: true,
      },
      {
        path: '/vehicle-mapping/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/monitoring/vehicleMapping/addMapping'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Add Vehicle Mapping',
        hideInSidebar: true,
      },
      {
        path: '/vehicle-mapping/edit/:mappingVehicleId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/monitoring/vehicleMapping/addMapping'
          )
        ),
        isPrivate: true,
        title: 'Edit access',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/rates-global',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          // import('../../../../pages/App/SystemPages/Rates/Global/RatesList')
          import('../../../../pages/App/SystemPages/Rates/Global/globalRates')
        ),
        isPrivate: true,
        title: 'Global rates',
        exact: true,
      },
      {
        path: '/clone',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Rates/Global/CloneRates')
        ),
        isPrivate: true,
        exact: true,
        title: 'Clone Rates',
        hideInSidebar: true,
      },
      {
        path: '/cloneVehicle/:rateId/:mode',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Rates/Global/vehicleClone')
        ),
        isPrivate: true,
        exact: true,
        title: 'Clone Vehicle',
        hideInSidebar: true,
      },
      {
        path: '/CloneVehicleToGlobalRate/:rateId/:mode',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/Global/vehicleCloneToGlobalRate'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Clone Vehicle to global rate',
        hideInSidebar: true,
      },
      {
        path: '/add',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Rates/Global/AddGlobalRate')
        ),
        isPrivate: true,
        exact: true,
        title: 'Add a global rate',
        hideInSidebar: true,
      },
      {
        path: '/edit/:rateId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Rates/Global/AddGlobalRate')
        ),
        isPrivate: true,
        exact: true,
        title: 'Edit global rate',
        hideInSidebar: true,
      },
      {
        path: '/edit/:rateId/location/:locationId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/Global/AddLocationRates'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Edit location rates',
        hideInSidebar: true,
      },
      {
        // path: '/edit/:rateId/location/:locationId',
        path: '/edit/:rateId/location/:locationId/type/:type',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/Global/AddLocationRates'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Edit location rates',
        hideInSidebar: true,
      },

      {
        // path: '/edit/:rateId/location/:locationId/item/:itemId',
        path: '/edit/:rateId/location/:locationId/item/:itemId/card/:cardValue',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/Global/AddLocationRates'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Edit location rates',
        hideInSidebar: true,
      },
    ],
  },
  // {
  //   path: '/anomalies',
  //   component: loadable(() =>
  //     import('../../../../pages/App/SystemPages/Anomalies/anomalies')
  //   ),
  //   isPrivate: true,
  //   title: 'Rates anomalies',
  //   allowedRoles: {
  //     system: ['system_admin', 'system_agent'],
  //   },
  // },

  // Correct one is below
  // {
  //   path: '/Anomalies',
  //   component: loadable(() =>
  //     import('../../../../pages/App/SystemPages/Anomalies')
  //   ),
  //   isPrivate: true,
  //   title: 'Rates anomalies',
  //   allowedRoles: {
  //     system: ['system_admin', 'system_agent'],
  //   },
  //   tabs: [
  //     {
  //       title: 'Anomalies',
  //       path: '/anomalies',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Anomalies/anomalies')
  //       ),
  //       isPrivate: true,
  //     },
  //     {
  //       title: 'Smart Rate Blockers',
  //       path: '/SmartRateBlockers',
  //       component: loadable(() =>
  //         import('../../../../pages/App/SystemPages/Anomalies/blockers')
  //       ),
  //       isPrivate: true,
  //     },
  //   ],
  // },

  {
    path: '/rates-anomalies',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Anomalies')
        ),
        isPrivate: true,
        title: 'Rates anomalies',
        tabs: [
          {
            title: 'Anomalies',
            path: '/anomalies',
            component: loadable(() =>
              import('../../../../pages/App/SystemPages/Anomalies/anomalies')
            ),
            isPrivate: true,
          },
          {
            title: 'Smart Rates',
            path: '/smartRateBlockers',
            component: loadable(() =>
              import('../../../../pages/App/SystemPages/Anomalies/blockers')
            ),
            isPrivate: true,
          },
          {
            title: 'Rate Duplicates',
            path: '/rateDuplicates',
            component: loadable(() =>
              import('../../../../pages/App/SystemPages/Anomalies/duplicates')
            ),
            isPrivate: true,
          },
          {
            title: 'Update Rate Location',
            path: '/upDateRate',
            component: loadable(() =>
              import('../../../../pages/App/SystemPages/Anomalies/updateRates')
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    path: '/globalRateDuplicates/:globalRateId',
    allowedRoles: {
      system: ['system_admin'],
    },
    component: loadable(() =>
      import('../../../../pages/App/SystemPages/Anomalies/globalRateDuplicates')
    ),
    isPrivate: true,
    exact: true,
    title: 'Global Rates Duplicates',
    hideInSidebar: true,
  },
  {
    isPrivate: true,
    title: 'Rates',
    path: '/rates-supply',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: () => <div>Supply rates</div>,
        isPrivate: true,
        title: 'Supply rates',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/meet-and-greet-rates',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/MeetAssistRates/MeetAssistRates'
          )
        ),
        isPrivate: true,
        title: 'VIP M&G Rates',
        exact: true,
      },
      {
        path: '/airports/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/MeetAssistRates/Airports/AddRate'
          )
        ),
        isPrivate: true,
        title: 'Add a rate',
        hideInSidebar: true,
      },
      {
        path: '/airports/:rateId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/MeetAssistRates/Airports/AddRate'
          )
        ),
        isPrivate: true,
        title: 'Edit rate',
        exact: true,
        hideInSidebar: true,
      },
      {
        path: '/trains/add',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/MeetAssistRates/Trains/AddRate'
          )
        ),
        isPrivate: true,
        title: 'Add a rate',
        hideInSidebar: true,
      },
      {
        path: '/trains/:rateId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Rates/MeetAssistRates/Trains/AddRate'
          )
        ),
        isPrivate: true,
        title: 'Edit rate',
        exact: true,
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/statementOfAccount',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/StatementsOfAccount/CompaniesList'
          )
        ),
        isPrivate: true,
        title: 'Statements of account',
        exact: true,
      },
      {
        path: '/:companyId/:currency',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/StatementsOfAccount/CompanyInvoices'
          )
        ),
        isPrivate: true,
        title: 'SOA-Company',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/promotion-code',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Promotion codes',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/PromotionCodes/PromotionCodesList'
          )
        ),
        isPrivate: true,
        exact: true,
      },
      {
        path: '/add',
        title: 'Add a promotion code',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/PromotionCodes/AddPromotionCode'
          )
        ),
        isPrivate: true,
        hideInSidebar: true,
      },
      {
        path: '/edit/:promoCodeId',
        title: 'Edit promotion code',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/PromotionCodes/AddPromotionCode'
          )
        ),
        isPrivate: true,
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/extractions',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Extractions',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Extractions/Extractions')
        ),
        isPrivate: true,
        exact: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/taxRates',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Tax Rates',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Taxes/TaxesList')
        ),
        isPrivate: true,
        exact: true,
      },
      {
        path: '/add',
        title: 'Add a rate',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Taxes/AddTax')
        ),
        isPrivate: true,
        hideInSidebar: true,
      },
      {
        path: '/edit/:rateId',
        title: 'Edit rate',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Taxes/AddTax')
        ),
        isPrivate: true,
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/chat-quotes',
    allowedRoles: {
      system: ['system_admin', 'system_agent', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/:chatId/quote/:messageId',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Quotes/viewQuote')
        ),
        isPrivate: true,
        exact: true,
        title: 'Quotes',
        hideInSidebar: true,
      },
      {
        path: '/:chatId/quote/:messageId/:quoteIndex',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Quotes/viewQuote')
        ),
        isPrivate: true,
        exact: true,
        title: 'Quotes',
        hideInSidebar: true,
      },
    ],
  },

  {
    isPrivate: true,
    path: '/transactions-payments',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: () => <div>Payments</div>,
        isPrivate: true,
        title: 'Payments',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/transactions-invoices',
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Transactions/Invoices/InvoicesList'
          )
        ),
        exact: true,
        isPrivate: true,
        title: 'Invoices',
      },
    ],
  },
  {
    isPrivate: true,
    path: '/payouts/clients',
    allowedRoles: {
      system: ['system_admin', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Clients',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Payouts/Clients/ClientPayoutList'
          )
        ),
        isPrivate: true,
        exact: true,
      },
      {
        path: '/edit/:payoutId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Payouts/Clients/AddClientPayout'
          )
        ),
        isPrivate: true,
        title: 'Add a payout',
        hideInSidebar: true,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/payouts/suppliers',
    allowedRoles: {
      system: ['system_admin', 'system_manager'],
    },
    childRoutes: [
      {
        path: '/',
        title: 'Partners',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Payouts/Suppliers/PartnerPayoutList'
          )
        ),
        isPrivate: true,
        exact: true,
      },
      {
        path: '/edit/:payoutId',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Payouts/Suppliers/AddPartnerPayout'
          )
        ),
        isPrivate: true,
        title: 'Add a payout',
        hideInSidebar: true,
      },
    ],
  },
  // for tech use only
  {
    path: '/techUse',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/FilesToCDN',
        // component: () => <div>Dashboard</div>,
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/techuse/FilestoCDN')
        ),
        isPrivate: true,
        exact: true,
        title: 'Files to CDN',
        showBottomMenu: false,
      },
    ],
    showBottomMenu: false,
  },
  /**
   * routes for analytics start
   */
  {
    path: '/finance',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Analytics/Finance')
        ),
        isPrivate: true,
        title: 'Finance',
        tabs: [
          {
            title: 'All',
            path: '/finance',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Finance/finance'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'LTD',
            path: '/LTD',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Finance/financeLtd'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'INC',
            path: '/INC',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Finance/financeInc'
              )
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    path: '/accountManagement',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import(
            '../../../../pages/App/SystemPages/Analytics/AccountManagement'
          )
        ),
        isPrivate: true,
        title: 'Account Management',
        tabs: [
          {
            title: 'Revenue Per User Per Month',
            path: '/AccountManagement',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/AccountManagement/userRevenueMonth'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Revenue Per User Per Quater',
            path: '/AccountManagement2',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/AccountManagement/userRevenueQuarter'
              )
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    path: '/Sales',
    isPrivate: true,
    allowedRoles: {
      system: ['system_admin'],
    },
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/SystemPages/Analytics/Sales')
        ),
        isPrivate: true,
        title: 'Sales',
        tabs: [
          {
            title: 'Companies',
            path: '/Companies',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Sales/companyTable'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Users',
            path: '/Users',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Sales/userTable'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Bookings',
            path: '/bookings',
            component: loadable(() =>
              import(
                '../../../../pages/App/SystemPages/Analytics/Sales/bookingsTable'
              )
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  /**
   * routes for analytics end
   */
]
