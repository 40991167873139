import React from 'react'

export default function MatRaisedLink({
    node,
    children,
    className,
    ...props
}) {
  const Node = node || 'a'
  return (
    <Node
      className={`add-link text-brown btn btn-anchor ${className ||
      ''}`}
      {...props}
    >
      {children}
    </Node>
  )
}
