import { geocodeByAddress } from 'react-places-autocomplete'
import _ from 'lodash'
import { logger } from '../actions'
import { connectedApiService as api } from 'index'
const LocationType = async (pickUpLocation, dropOffLocation = null) => {
  let type = {}
  if (dropOffLocation) {
    type.dropOff = await onConfirm(
      dropOffLocation.fullAddress,
      'dropOff',
      dropOffLocation.placeId
    )
  }
  if (pickUpLocation) {
    type.pickup = await onConfirm(
      pickUpLocation.fullAddress,
      'pickUp',
      pickUpLocation.placeId
    )
  }
  return type
}

const onConfirm = async (_selectedLocation, isType, placeId) => {
  try {
    let location = _selectedLocation
    if (location) {
      let results = {}

      const resultsGeoPlace = await api.get(
        `GeoPlaceDetailsById?placeId=${placeId}`
      )
      results =
        resultsGeoPlace && resultsGeoPlace.data && resultsGeoPlace.data.info
          ? resultsGeoPlace.data.info.result
          : { types: [] }

      let item = {
        type: isType,
        isAirport:
          _selectedLocation.toLowerCase().search('airport') !== -1 ||
          (_selectedLocation.toLowerCase().search('terminal') !== -1 &&
            _selectedLocation.toLowerCase().search('cruise terminal') === -1) ||
          _selectedLocation.toLowerCase().search('aeropuerto') !== -1 ||
          _selectedLocation.toLowerCase().search('aeroporto') !== -1 ||
          _selectedLocation.toLowerCase().search('aéroport') !== -1 ||
          _selectedLocation.toLowerCase().search('aeroport') !== -1 ||
          _selectedLocation.toLowerCase().search('mxp t1') !== -1 ||
          _selectedLocation.search('LHR') !== -1 ||
          _selectedLocation.search('(ICN)') !== -1 ||
          _selectedLocation.search('4561 West Empire Avenue, Burbank') !== -1
            ? !_selectedLocation.toLowerCase().includes('ihg hotel')
              ? true
              : false
            : _.indexOf(results.types, 'airport') !== -1 ||
              _.indexOf(results.types, 'aeroway') !== -1
            ? true
            : false,
        isTrain:
          _selectedLocation.toLowerCase().search('airport') !== -1
            ? false
            : _selectedLocation.toLowerCase().search('train station') !== -1
            ? true
            : checkIsTrain(results.types),
        isStadium:
          _selectedLocation.search('Stadium') !== -1 ||
          _selectedLocation.search('Arena') !== -1 ||
          _selectedLocation.search('Stade') !== -1 ||
          _.indexOf(results.types, 'stadium') !== -1,
        isPort: results.name
          ? results.name.match(new RegExp('\\b' + 'Port' + '\\b')) !== null ||
            results.name.match(new RegExp('\\b' + 'Cruise Terminal' + '\\b')) !=
              null
          : false,
        isHotel:
          _selectedLocation.search('Hotel') !== -1 ||
          _selectedLocation.search('Hôtel') !== -1 ||
          _selectedLocation.search('Resort') !== -1 ||
          _.indexOf(results.types, 'lodging') !== -1,
      }
      if (item.isTrain) {
        item.isMetro =
          _selectedLocation.toLowerCase().search('metro station') !== -1
            ? true
            : false
      }
      return item
    }
    return null
  } catch (error) {
    logger({ fileName: 'locationType', error: error })
    console.error(error)
  }
}
const checkIsTrain = types => {
  let isTrain = false
  var typesTrain = [
    'subway_station',
    'train_station',
    'transit_station',
    'railway',
  ]
  _.each(typesTrain, train => {
    let index = _.indexOf(types, train)
    if (!isTrain && index !== -1) {
      isTrain = true
    }
  })
  return isTrain
}

export default LocationType
