import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import { loginUser } from '../../../../../actions'
import { minLengthStringValidator } from '../../../../../form-validation'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
const Host = window.location.hostname
const fields = [
  {
    name: 'password',
    type: 'password',
    label: 'PASSWORD*',
  },
]

const validationSchema = Yup.object().shape({
  password: minLengthStringValidator({
    limit: 6,
    message: 'Password must be at least 6 characters',
  }),
})

const initialValues = {
  password: '',
}

function EnterPassword({ history }) {
  const partnerEmail = history.location.email ? history.location.email : null
  if (!partnerEmail) {
    history.push(
      `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }auth/login`
    )
  }
  return (
    <div className='auth-page'>
      <div className='image-container-new-partner' />
      <div className='auth-container'>
        <AuthForm
          title='Log in'
          initialValues={initialValues}
          validationSchema={validationSchema}
          fields={fields}
          isEnterPassword
          buttonLabel='Log in'
          submitStyle='login-btn'
          partnerEmail={partnerEmail}
        />
      </div>
    </div>
  )
}

export default connect(
  null,
  { loginUser }
)(EnterPassword)
