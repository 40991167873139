import { show } from 'react-notification-system-redux'
import { createOrEditItem } from './form'
const notificationTitles = {
  success: 'Success',
  error: 'Error',
}
export const notification = ({ type = 'success', message = '' }) => ({
  title: notificationTitles[type],
  message,
  position: 'tr',
  autoDismiss: 5,
})

export const showNotification = ({
  message = '',
  type = 'success',
}) => dispatch => {
  dispatch(show(notification({ message, type }), type))
}

export const toggleNotificationStatus = (
  id,
  active = false
) => async dispatch =>
  dispatch(
    createOrEditItem(
      { id, active },
      {
        isEdit: true,
        endpoint: `fcmNotificationStatus`,
        successMessage: 'Device status successfully updated',
      }
    )
  )
