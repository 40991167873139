import * as Yup from 'yup'
import {
  requiredObject,
  requiredStringValidator,
  fileRequired,
} from '../form-validation'
import _ from 'lodash'
import { fileToBase64 } from '../helpers'
import { logger } from '../actions'

export const TravelDocumentsFormData = async data => {
  try {
    const formatedData = await Promise.all(
      data.items.map(async item => {
        // item.title = item.title.value
        item.travelClass = item.travelClass.value
        if (
          item.passportFile &&
          typeof item.passportFile === 'object' &&
          item.passportFile.size
        ) {
          if (item.passportFile.name) {
            item.passportFileName = item.passportFile.name //here
          }
          const fileBase64 = await fileToBase64(item.passportFile)
          item.passportFile = fileBase64 || null
        }
        if (item.extraDocuments.length > 0) {
          item.extraDocuments = await Promise.all(
            item.extraDocuments.map(async extra => {
              if (extra && typeof extra === 'object' && extra.size) {
                const fileBase64 = await fileToBase64(extra)
                extra = fileBase64 || null
                return extra
              } else {
                return extra
              }
            })
          )
          return item
        } else {
          return item
        }
      })
    )
    return formatedData
  } catch (error) {
    logger({ fileName: 'Travel Document', error: error })
    console.log(error)
    return {}
  }
}
export const TravelInformationFormData = async data => {
  try {
    const formatedData = await Promise.all(
      data.items.map(async item => {
        return item
      })
    )
    return formatedData
  } catch (error) {
    logger({ fileName: 'Travel Information', error: error })
    console.log(error)
    return {}
  }
}
export const pasport = Yup.object().shape({
  pasport: fileRequired(),
})
export const extraDocuments = Yup.object().shape({
  documents: requiredObject(),
})
export const travelDocuments = Yup.object().shape({
  // title: requiredObject({
  //   defaultValue: '',
  //   message: 'Select a title',
  // }),
  fullName: requiredStringValidator({ message: 'Enter a name' }),
  travelClass: requiredStringValidator({ message: 'Select a class' }),
  // passportFile: fileRequired(),
  documents: Yup.array()
    .of(extraDocuments)
    .default([pasport.cast()]),
})
export const initalTravelDocuments = Yup.object().shape({
  travelDocuments: Yup.array()
    .of(travelDocuments)
    .default([travelDocuments.cast()]),
})

export const inititalDocuments = () => {
  return {
    schema: initalTravelDocuments,
  }
}
export class TravelDocumentsInitialValues {
  constructor({ ...data } = {}) {
    const { schema } = inititalDocuments()
    const defaultModeRates = schema.cast()
    let formattedData = {}
    formattedData = {
      ...defaultModeRates,
    }
    this.data = formattedData
  }
}

// export class TravelDocumentsInitialValues {
//   constructor({ driversInfo } = {}) {
//     this.driversInfo = driversInfo.map(
//       ({
//         title,
//         driverAge,
//         drivingLicenceFrontFileId,
//         drivingLicenceBackFileId,
//         passportFileId,
//         insuranceFileId,
//         ...driver
//       }) => {
//         return {
//           ...driver,
//           title: { value: title, label: title },
//           driverAge: { value: driverAge, label: driverAge },
//           drivingLicenceFrontFile: drivingLicenceFrontFileId
//             ? {
//                 name: drivingLicenceFrontFileId,
//               }
//             : null,
//           drivingLicenceBackFile: drivingLicenceBackFileId
//             ? {
//                 name: drivingLicenceBackFileId,
//               }
//             : null,
//           passportFile: passportFileId
//             ? {
//                 name: passportFileId,
//               }
//             : null,
//           insuranceFile: insuranceFileId
//             ? {
//                 name: insuranceFileId,
//               }
//             : null,
//         }
//       }
//     )
//   }
// }
