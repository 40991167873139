import React from 'react'
import styled from 'styled-components'

const makeResponsiveComponent = (rulesets, tagName = 'div') =>
  styled(tagName)`
    ${buildStyles(rulesets)}
  `

const buildStyles = rulesets =>
  rulesets.reduce(
    (cssString, { constraint, width, rules }) =>
      `${cssString} @media (${constraint}-width: ${width}) { ${rules} }`,
    ''
  )

export const hideAt = breakpoints => {
  const rulesets = Object.entries(breakpoints).reduce(
    (rulesets, [constraint, width]) => [
      ...rulesets,
      {
        constraint,
        width,
        rules: `display: none;`,
      },
    ],
    []
  )

  return makeResponsiveComponent(rulesets)
}

const makeResponsiveComponentHeight = (rulesets, tagName = 'div') =>
  styled(tagName)`
    ${buildStylesHeight(rulesets)}
  `

const buildStylesHeight = rulesets =>
  rulesets.reduce(
    (cssString, { constraint, height, rules }) =>
      `${cssString} @media (${constraint}-height: ${height}) { ${rules} }`,
    ''
  )

export const hideAtHeight = breakpoints => {
  const rulesets = Object.entries(breakpoints).reduce(
    (rulesets, [constraint, height]) => [
      ...rulesets,
      {
        constraint,
        height,
        rules: `display: none;`,
      },
    ],
    []
  )

  return makeResponsiveComponentHeight(rulesets)
}

export const Breakpoint = ({ min, max, children }) => {
  const Component = hideAt({ min, max })
  return <Component>{children}</Component>
}

export default makeResponsiveComponent
