import {
  SET_USER,
  SET_MASQUERADE_USER,
  SET_USER_COMPANY,
  UPDATE_USER_INFO,
  SET_IS_PARTNER,
  SET_USER_IP_LOCATION,
} from '../actions'

import User from '../models/user'

const INITIAL_STATE = {
  ...new User(),
  company: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...new User(action.payload),
      }
    case SET_USER_IP_LOCATION:
      return {
        ...state,
        ipLocation: action.payload.ipCountry,
      }
    case SET_MASQUERADE_USER:
      return {
        ...state,
        ...new User(action.payload),
      }
    case SET_USER_COMPANY:
      return {
        ...state,
        company: action.payload || null,
      }
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case SET_IS_PARTNER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
