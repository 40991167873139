import React, { useState, useEffect } from 'react'
import { connectedApiService } from 'index'
import '../assets/styles/components/dynamic-dropdown.css'

const DynamicDropdown = ({ onChange, fieldValue }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (searchTerm.length > 2) {
          const response = await connectedApiService.get(
            `company/companyOptions/${searchTerm}`
          )
          setOptions(response.data.companyNames)
        } else {
          setOptions([])
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchOptions()
  }, [searchTerm])

  useEffect(() => {
    if (fieldValue === '') {
      setSearchTerm('')
    }
  }, [fieldValue])

  const handleInputChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen)
  }
  const handleDropdownBlur = () => {
    if (!isOpen) {
      setIsOpen(false)
    }
  }

  const handleOptionSelect = option => {
    setSearchTerm(option)
    setFilteredOptions([])
    setIsOpen(false)
    onChange(option)
  }

  useEffect(() => {
    setFilteredOptions(
      options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  }, [searchTerm, options])

  return (
    <div className='searchable-dropdown'>
      <input
        type='text'
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleDropdownToggle}
        onBlur={handleDropdownBlur}
        placeholder='Search...'
        className='search-input'
      />
      {isOpen && filteredOptions.length > 0 && (
        <ul className='dropdown-options'>
          {filteredOptions.map(option => (
            <li
              key={option}
              onClick={() => {
                handleOptionSelect(option)
              }}
              tabIndex='0'
              className='dropdown-option'
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default DynamicDropdown
