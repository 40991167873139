import TooltipOnHover from 'components/TooltipOnHover'
import React from 'react'

const ValueChangeIndicator = ({ percentage }) => {
  return (
    <>
      {percentage > 0 ? (
        <>
          <span class='increase'>{`↑ ${Math.abs(percentage).toFixed(
            2
          )}%`}</span>
          <TooltipOnHover
            delay={200}
            placement='right-start'
            // isMobileBrowser={isMobileBrowser}
            onMouseEnter={() => {}}
            component={<div>In comparison to previous month.</div>}
          >
            <img
              alt=''
              className={'new-info-icon'}
              style={{ marginLeft: '0.5rem' }}
              src='/images/icons/new-info-icon.png'
            />
          </TooltipOnHover>
        </>
      ) : percentage < 0 ? (
        <>
          <span class='decrease'>{`↓ ${Math.abs(percentage).toFixed(
            2
          )}%`}</span>
          <TooltipOnHover
            delay={200}
            placement='right-start'
            // isMobileBrowser={isMobileBrowser}
            onMouseEnter={() => {}}
            component={<div>In comparison to previous month.</div>}
          >
            <img
              alt=''
              className={'new-info-icon'}
              style={{ marginLeft: '0.5rem' }}
              src='/images/icons/new-info-icon.png'
            />
          </TooltipOnHover>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ValueChangeIndicator
