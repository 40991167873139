import { fileToBase64 } from '../helpers'
import { logger } from '../actions'
export const bookingNotesFormData = async ({ files, ...values }) => {
  try {
    let data = values
    let formattedFiles = []

    if (files && files.length) {
      formattedFiles = await Promise.all(
        files.map(async file => {
          try {
            let fileData = {}
            if (typeof file === 'object' && file.size) {
              fileData = await fileToBase64(file)
            } else {
              fileData = file
            }

            return fileData
          } catch (error) {
            logger({ fileName: 'bookingNotes', error: error })
            console.log(error)
            return null
          }
        })
      )
    }

    data.files = formattedFiles

    return data
  } catch (error) {
    logger({ fileName: 'bookingNotes', error: error })
    console.log(error)
    return {}
  }
}

export class BookingNoteInitialValues {
  constructor({ message = '', files = [] } = {}) {
    this.message = message
    this.files = []
  }
}
