import { fileToBase64 } from '../helpers'
import { defaultYears } from '../constants'
import { logger } from '../actions'
export const globalFleetAssetFormData = async ({ images, year, ...values }) => {
  try {
    let data = {
      ...values,
      year: year ? +year.value : year,
    }

    if (images && images.length) {
      data.images = await Promise.all(
        images.map(image => {
          if (image && typeof image === 'object' && image.size) {
            return fileToBase64(image)
          }
          return null
        })
      )
    }

    return data
  } catch (error) {
    logger({ fileName: 'globalFleetAsset', error: error })
    console.log(error)
    return {}
  }
}

export const globalFleetAssetImageFormData = async ({ image }) => {
  try {
    let data = {}

    if (image && typeof image === 'object' && image.size) {
      data.image = await fileToBase64(image)
    } else {
      data.image = null
    }

    return data
  } catch (error) {
    logger({ fileName: 'globalFleetAsset', error: error })
    console.log(error)
    return {}
  }
}

export class GlobalFleetAssetInitialValues {
  constructor({
    brand = '',
    model = '',
    images = [],
    year = '',
    color = '',
  } = {}) {
    this.images =
      images && images.length
        ? images.map(({ image, id }) => {
            if (image) {
              return {
                name: image,
                id,
              }
            }
            return null
          })
        : []

    this.model = model
    this.brand = brand
    this.year = defaultYears.find(y => +y.value === year) || year
    this.color = color
  }
}
