import { changePasswordFormData, myAccessFormData } from '../models/access'
import { createOrEditItem, deleteItem } from './form'

export const createAccess = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'access',
      formatFormData: myAccessFormData,
      successMessage: 'Client was successfully created',
    })
  )
export const editAccess = (values, userId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `access/user/${userId}`,
      formatFormData: myAccessFormData,
      successMessage: 'Your access was successfully updated',
    })
  )

export const deleteAccess = id => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `access/user/${id}`,
      successMessage: 'Access was successfully Deleted',
    })
  )
export const toggleAccessStatus = (id, active = false) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { active },
      {
        isEdit: true,
        endpoint: `access/user/${id}`,
        successMessage: 'Client was successfully updated',
      }
    )
  )

export const changeAccessPassword = (values, userId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `access/user/${userId}/password`,
      formatFormData: changePasswordFormData,
      successMessage: 'Your password was successfully changed',
    })
  )

export const getAccess = userId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'get',
        endpoint: `access/user/${userId}`,
      }
    )
  )
