import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap'
import { useGetTableData } from '../../../../../hooks'
import { isEmpty } from 'lodash'
import DefaultDriverDesktopLayout from '../../../../../components/layout/DefaultDriverDesktopLayout'
import DefaultDriverLayout from 'components/layout/DefaultDriverLayout'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import moment from 'moment'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isMobile, numberWithCommas } from 'helpers'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import { getPartnerDetails } from 'actions'
const Host = window.location.hostname
const HomePage = ({ history, isCarRental, isChauffeur }) => {
  const isMobileBrowser = isMobile()

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const { token } = useParams()
  const [data, setData] = useState({})
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  let [currentPartnerType, setCurrentPartnerType] = useState(partnerType)

  const {
    data: { list },
  } = useGetTableData(`external/partnerToken/${token}/home-page`)

  useEffect(() => {
    if (list && list.hasOwnProperty('newOffers')) {
      setData({
        numberOfVehicles: list.vehicles,
        numberOfChauffeurs: list.chauffeurs,
        numberOfRentalVehicles: list.rentalVehicles,
        numberOfAgents: list.agents,
        newOffers: list.newOffers,
        newRentalOffers: list.newRentalOffers,
        numberOfNewOffers: list.newOffers.length,
        totalEarnings: list.totalEarnings,
        currency: list.currency.toUpperCase(),
        transferCounter: list.transferBookingsCounter,
        transferEarnings: list.transferEarnings,
        hourlyCounter: list.hourlyBookingsCounter,
        carRentalCounter: list.carRentalBookingsCounter,
        hourlyEarnings: list.hourlyEarnings,
        carRentalEarnings: list.carRentalEarnings,
        todaysBookings: list.todaysBookings,
        todaysRentalBookings: list.todaysRentalBookings,
        selectRental: list.selectRental,
        selectChauffeur: list.selectChauffeur,
      })
      setCompany(list.company)
      const [first_name] = list.userName.split(' ')
      setName(first_name)
    } else if (list && list.userName) {
      const [first_name] = list.userName.split(' ')
      setName(first_name)
    } else if (list && list.newUser) {
      setName('newUser')
    }

    // if (list && list.isIncompleteCompany) {
    //   setShowModal(true)
    // }
  }, [list])

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        await getPartnerDetails(token)
      } catch (err) {
        console.log(err)
      }
    }
    checkTokenValidity()
  }, [])

  useEffect(() => {
    if (isCarRental && isChauffeur) {
    } else {
      if (isCarRental) {
        localStorage.setItem('partnerType', 'rental')
        setCurrentPartnerType('rental')
      } else {
        localStorage.setItem('partnerType', 'chauffeur')
        setCurrentPartnerType('chauffeur')
      }
    }
  }, [isCarRental])

  const togglePartnerType = type => {
    localStorage.setItem('partnerType', type)
    setCurrentPartnerType(type)
  }

  useEffect(() => {}, [token])
  useEffect(() => {
    if (!isEmpty(data)) {
      setIsLoading(false)
    }
  }, [data])

  const renderContent = () => {
    const viewBooking = bookingId => {
      history.push(
        `${
          Host === partnerHostLive || Host === partnerHostStaging
            ? '/'
            : '/partner/'
        }driver/app/details/${token}/${bookingId}`
      )
    }

    return (
      <>
        {!!isLoading ? (
          <LoadingSpinner />
        ) : (
          <div
            className='d-flex driver-booking-container'
            style={{ margin: 0 }}
          >
            {data && name ? (
              <>
                {/* New offers */}
                {partnerType !== 'rental' ? (
                  <Col
                    md={6}
                    style={{ height: 'fit-content' }}
                    // className='driver-service-card'
                  >
                    <div className='driver-service-card'>
                      <span className='driver-service-card-header mb-4'>
                        Today
                      </span>
                      <div>
                        <div className='d-flex mb-4'>
                          <div className='align-self-center'>
                            <img
                              src='/images/icons/icon-bookings-gold.png'
                              alt=''
                              width={25}
                            />
                          </div>
                          <div
                            className='d-flex flex-column'
                            style={{ paddingLeft: '1.7rem' }}
                          >
                            <span className='counter'>
                              {data.todaysBookings.length}
                            </span>
                            <span className='card-message'>Bookings</span>
                          </div>
                        </div>
                      </div>
                      <>
                        {data && data.todaysBookings
                          ? data.todaysBookings.map(item => (
                              <div className='grey-background'>
                                <Row>
                                  <Col md={6}>
                                    <span className='offer-date'>
                                      {moment
                                        .utc(item.date)
                                        .format('ddd D MMM YY, HH:mm')}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end'
                                  >{`${item.price} ${item.currency}`}</Col>
                                </Row>
                                <Row className='mt-1'>
                                  <Col className='pt-1'>
                                    {item.status === 'confirmed' ||
                                    item.status === 'change_request' ||
                                    item.status === 'completed' ? (
                                      <span className='newOffer-message'>
                                        {`${item.type} • ${item.passengerName}`}
                                      </span>
                                    ) : (
                                      <span className='newOffer-message'>
                                        {item.type}
                                      </span>
                                    )}
                                    <span className='newOffer-message pt-1'>
                                      {item.vehicle}
                                    </span>
                                  </Col>
                                  <div
                                    // md={6}
                                    className='d-flex justify-content-end col-md-auto pt-1'
                                  >
                                    <Button
                                      className='partner-btn-desktop-primary select-vehicle'
                                      variant='brown'
                                      onClick={() => {
                                        viewBooking(item.bookingId)
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            ))
                          : ''}
                      </>
                    </div>
                    <div className='driver-service-card'>
                      <span className='driver-service-card-header mb-4'>
                        New offers
                      </span>
                      <div>
                        <div className='d-flex mb-4'>
                          <div className='align-self-center'>
                            <img
                              src='/images/icons/icon-bookings-gold.png'
                              alt=''
                              width={25}
                            />
                          </div>
                          <div
                            className='d-flex flex-column'
                            style={{ paddingLeft: '1.7rem' }}
                          >
                            <span className='counter'>
                              {data.numberOfNewOffers}
                            </span>
                            <span className='card-message'>New offers</span>
                          </div>
                        </div>
                      </div>
                      <>
                        {data && data.newOffers
                          ? data.newOffers.map(item => (
                              <div className='grey-background'>
                                <Row>
                                  <Col md={6}>
                                    <span className='offer-date'>
                                      {moment
                                        .utc(item.date)
                                        .format('ddd D MMM YY, HH:mm')}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end'
                                  >{`${numberWithCommas(
                                    parseFloat(item.price).toFixed(2)
                                  )} ${item.currency}`}</Col>
                                </Row>
                                <Row className='mt-1'>
                                  <Col md={6} className='pt-1'>
                                    <span className='newOffer-message'>
                                      {item.type}
                                    </span>
                                    <span className='newOffer-message pt-1'>
                                      {item.vehicle}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end pt-1'
                                  >
                                    <Button
                                      className='partner-btn-desktop-primary select-vehicle'
                                      variant='brown'
                                      onClick={() => {
                                        viewBooking(item.bookingId)
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          : ''}
                      </>
                    </div>
                  </Col>
                ) : (
                  <Col
                    md={6}
                    style={{ height: 'fit-content' }}
                    // className='driver-service-card'
                  >
                    <div className='driver-service-card'>
                      <span className='driver-service-card-header mb-4'>
                        Today
                      </span>
                      <div>
                        <div className='d-flex mb-4'>
                          <div className='align-self-center'>
                            <img
                              src='/images/icons/icon-bookings-gold.png'
                              alt=''
                              width={25}
                            />
                          </div>
                          <div
                            className='d-flex flex-column'
                            style={{ paddingLeft: '1.7rem' }}
                          >
                            <span className='counter'>
                              {data.todaysRentalBookings.length}
                            </span>
                            <span className='card-message'>Bookings</span>
                          </div>
                        </div>
                      </div>
                      <>
                        {data && data.todaysRentalBookings
                          ? data.todaysRentalBookings.map(item => (
                              <div className='grey-background'>
                                <Row>
                                  <Col md={6}>
                                    <span className='offer-date'>
                                      {moment
                                        .utc(item.date)
                                        .format('ddd D MMM YY, HH:mm')}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end'
                                  >{`${item.price} ${item.currency}`}</Col>
                                </Row>
                                <Row className='mt-1'>
                                  <Col className='pt-1'>
                                    {item.status === 'confirmed' ||
                                    item.status === 'change_request' ||
                                    item.status === 'completed' ? (
                                      <span className='newOffer-message'>
                                        {`${item.type} • ${item.passengerName}`}
                                      </span>
                                    ) : (
                                      <span className='newOffer-message'>
                                        {item.type}
                                      </span>
                                    )}
                                    <span className='newOffer-message pt-1'>
                                      {item.vehicle}
                                    </span>
                                  </Col>
                                  <div
                                    // md={6}
                                    className='d-flex justify-content-end col-md-auto pt-1'
                                  >
                                    <Button
                                      className='partner-btn-desktop-primary select-vehicle'
                                      variant='brown'
                                      onClick={() => {
                                        viewBooking(item.bookingId)
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            ))
                          : ''}
                      </>
                    </div>
                    <div className='driver-service-card'>
                      <span className='driver-service-card-header mb-4'>
                        New offers
                      </span>
                      <div>
                        <div className='d-flex mb-4'>
                          <div className='align-self-center'>
                            <img
                              src='/images/icons/icon-bookings-gold.png'
                              alt=''
                              width={25}
                            />
                          </div>
                          <div
                            className='d-flex flex-column'
                            style={{ paddingLeft: '1.7rem' }}
                          >
                            <span className='counter'>
                              {data.newRentalOffers &&
                              data.newRentalOffers.length
                                ? data.newRentalOffers.length
                                : 0}
                            </span>
                            <span className='card-message'>New offers</span>
                          </div>
                        </div>
                      </div>
                      <>
                        {data && data.newRentalOffers
                          ? data.newRentalOffers.map(item => (
                              <div className='grey-background'>
                                <Row>
                                  <Col md={6}>
                                    <span className='offer-date'>
                                      {moment
                                        .utc(item.date)
                                        .format('ddd D MMM YY, HH:mm')}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end'
                                  >{`${numberWithCommas(
                                    parseFloat(item.price).toFixed(2)
                                  )} ${item.currency}`}</Col>
                                </Row>
                                <Row className='mt-1'>
                                  <Col md={6} className='pt-1'>
                                    <span className='newOffer-message'>
                                      {item.type}
                                    </span>
                                    <span className='newOffer-message pt-1'>
                                      {item.vehicle}
                                    </span>
                                  </Col>
                                  <Col
                                    md={6}
                                    className='d-flex justify-content-end pt-1'
                                  >
                                    <Button
                                      className='partner-btn-desktop-primary select-vehicle'
                                      variant='brown'
                                      onClick={() => {
                                        viewBooking(item.bookingId)
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          : ''}
                      </>
                    </div>
                  </Col>
                )}
                <Col md={6}>
                  {/* Total earnings card */}
                  <div className='driver-service-card'>
                    <span className='driver-service-card-header mb-4'>
                      Earnings
                    </span>
                    <div className='d-flex '>
                      <div className='align-self-center'>
                        <img
                          src='/images/icons/icon-earnings.png'
                          alt=''
                          width={25}
                        />
                      </div>
                      <div
                        className='d-flex flex-column'
                        style={{ paddingLeft: '2.7rem' }}
                      >
                        <span className='counter'>{`${numberWithCommas(
                          numberWithCommas(
                            parseFloat(
                              partnerType === 'rental'
                                ? data.carRentalEarnings
                                : parseFloat(data.totalEarnings) -
                                    parseFloat(data.carRentalEarnings)
                            ).toFixed(2)
                          )
                        )} ${data.currency}`}</span>
                        <span className='card-message'>Total earnings</span>
                      </div>
                    </div>
                    {partnerType !== 'rental' ? (
                      <>
                        <div className='chauffeur-card-item'>
                          <span className='partner-bookingType'>{`Transfers (${data.transferCounter}) `}</span>
                          <span>{`${numberWithCommas(
                            numberWithCommas(
                              parseFloat(data.transferEarnings).toFixed(2)
                            )
                          )} ${data.currency}`}</span>
                        </div>
                        <div className='chauffeur-card-item'>
                          <span className='partner-bookingType'>{`By the hour (${data.hourlyCounter}) `}</span>
                          <span>{`${numberWithCommas(
                            numberWithCommas(
                              parseFloat(data.hourlyEarnings).toFixed(2)
                            )
                          )} ${data.currency}`}</span>
                        </div>
                      </>
                    ) : (
                      <div className='chauffeur-card-item'>
                        <span className='partner-bookingType'>{`Car rental (${data.carRentalCounter}) `}</span>
                        <span>{`${numberWithCommas(
                          numberWithCommas(
                            parseFloat(data.carRentalEarnings).toFixed(2)
                          )
                        )} ${data.currency}`}</span>
                      </div>
                    )}
                  </div>
                  {/* Chauffeur card */}
                  <div className='driver-service-card'>
                    {partnerType === 'rental' ? (
                      <span className='driver-service-card-header mb-4'>
                        Agents
                      </span>
                    ) : (
                      <span className='driver-service-card-header mb-4'>
                        Chauffeurs
                      </span>
                    )}
                    <div className='d-flex '>
                      <div className='align-self-center'>
                        <img
                          src='/images/icons/icon-chauffeur-gold.png'
                          alt=''
                          width={25}
                        />
                      </div>
                      <div
                        className='d-flex flex-column'
                        style={{ paddingLeft: '2.7rem' }}
                      >
                        <span className='counter'>
                          {partnerType === 'rental'
                            ? data.numberOfAgents
                            : data.numberOfChauffeurs}
                        </span>
                        {partnerType === 'rental' ? (
                          <span className='card-message'>Active agents</span>
                        ) : (
                          <span className='card-message'>
                            Active chauffeurs
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Vehicle card */}
                  <div className='driver-service-card'>
                    <span className='driver-service-card-header mb-3'>
                      Vehicles
                    </span>
                    <div className='d-flex '>
                      <div className='align-self-center'>
                        <img
                          style={{ verticalAlign: 'bottom' }}
                          src='/images/icons/icon-vehicle-gold.png'
                          alt=''
                          width={40}
                        />
                      </div>
                      <div
                        className='d-flex flex-column'
                        style={{ paddingLeft: '1.9rem' }}
                      >
                        <span className='counter'>
                          {partnerType === 'rental'
                            ? data.numberOfRentalVehicles
                            : data.numberOfVehicles}
                        </span>
                        <span className='card-message'>Active vehicles</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {isMobileBrowser ? (
        <DefaultDriverLayout
          data={[list]}
          userName={name}
          togglePartnerType={togglePartnerType}
          currentPartnerType={currentPartnerType}
        />
      ) : (
        <DefaultDriverDesktopLayout
          // data={list}
          renderContent={renderContent}
          userName={name}
          company={company}
          togglePartnerType={togglePartnerType}
          currentPartnerType={currentPartnerType}
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  auth: { isCarRental = null, isChauffeur = null, companyId },
  appWebsocket,
}) => ({
  isCarRental,
  isChauffeur,
  showComPopup: appWebsocket.showComPopup,
})

export default withRouter(connect(mapStateToProps, {})(HomePage))
