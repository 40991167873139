export class rolzoCitiesInitialVals {
  constructor({
    city = '',
    country = '',
    countryCode = '',
    state = null,
    lat,
    lng,
    region = null,
    locationName = null,
  } = {}) {
    this.city = city
    this.country = country
    this.state = countryCode === 'US' || countryCode === 'CA' ? state : null
    this.lat = lat
    this.lng = lng
    this.region = region ? region : null
    this.countryCode = countryCode
    this.locationName = locationName ? locationName : null
  }
}

export class rolzoCountriesInitialVals {
  constructor({ country = '', countryCode = '', region = null } = {}) {
    this.country = country
    this.countryCode = countryCode
    this.region = region ? region : null
  }
}
