import { fileToBase64, getLabelFromValue } from '../helpers'
import { logger } from '../actions'
import { defaultUserStatus } from '../constants/index'

export class IndividualUser {
  constructor({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    roles,
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.roles = roles ? roles.value : []
  }
}
export class IndividualEditUser {
  constructor({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    roles,
    image,
    password,
    userApiKey = '',
    userLabel = '',
    verified = false,
    notes = '',
  } = {}) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.roles = roles ? roles.value : []
    this.password = password
    this.image = image
      ? {
          name: image,
        }
      : null
    this.userApiKey = userApiKey
    this.userLabel = userLabel
      ? {
          label: getLabelFromValue(defaultUserStatus, userLabel),
          value: userLabel,
        }
      : { label: 'INACTIVE', value: 'inactive' }
    this.verified = verified
    this.notes = notes
  }
}

export const individualEditUserFormData = async ({ image, ...values }) => {
  try {
    let data = {
      ...values,
    }
    if (image && typeof image === 'object' && image.size) {
      const logoBase64 = await fileToBase64(image)
      data.image = logoBase64 || null
    }
    return data
  } catch (error) {
    logger({ fileName: 'companyClientUser', error: error })
    console.log(error)
    return {}
  }
}
