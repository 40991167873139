import React from 'react'
import { Form } from 'react-bootstrap'
import { getIn } from 'formik'
import {
  isMobile,
  hideResponsiveButton,
  showResponsiveButton,
} from '../helpers'
import { useRef } from 'react'

const { Control, Group, Label } = Form

export default function FormField({
  field: { value = '', ...field } = {},
  form: { touched = {}, errors = {} } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  disabled = false,
  vehicleMismatch = false,
  groupClassName,
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const isMobileBrowser = isMobile()
  const fieldRef = useRef(null)
  const windowHeight = window.innerHeight
  var isFocused = false
  var isScrolled = false
  const onChange = e => {
    field.onChange(e)
    if (handleChange) {
      handleChange(e.target.value)
    }
  }

  const onFocus = () => {
    isScrolled = false
    isFocused = true
    hideResponsiveButton()
  }

  const onBlur = () => {
    isFocused = false
    showResponsiveButton()

    if (props.handleBlur && props.isRegisterEmail) {
      props.handleBlur()
    }
  }

  var timer = null

  if (isMobileBrowser) {
    if (timer) {
      clearInterval(timer)
    }
    timer = setInterval(() => {
      var allScrollables = document.getElementsByClassName(
        'scrollable-page-content'
      )

      var elScrollable = null

      if (allScrollables.length > 0) {
        elScrollable = allScrollables[allScrollables.length - 1]
      }

      if (!fieldRef || !fieldRef.current || !elScrollable) {
        clearInterval(timer)
        return
      }

      if (!isFocused) {
        return
      }

      if (windowHeight > window.innerHeight && !isScrolled) {
        setTimeout(() => {
          var bottom_gap =
            elScrollable.offsetHeight -
            fieldRef.current.getBoundingClientRect().bottom

          if (bottom_gap < 150) {
            const addScroll = 150 - bottom_gap
            elScrollable.scrollTop = elScrollable.scrollTop + addScroll
          }
        }, 200)
        isScrolled = true
      }
    }, 100)
  }

  return (
    <Group
      className={
        isMobileBrowser && field.name === 'promoCode'
          ? 'promoCode-mobile'
          : field.name === 'vehicleMismatch' && vehicleMismatch
          ? 'mb-0'
          : groupClassName || ''
      }
      style={
        field.name === 'name-cancellation-policy' && !isMobileBrowser
          ? {
              position: 'absolute',
              bottom: '0.5rem',
              left: '25px',
            }
          : field.name === 'cancelMeetGreetPolicy' && !isMobileBrowser
          ? { width: '10.8rem' }
          : {}
      }
      controlId={controlId || field.name}
    >
      {!!label && <Label className='form-label'>{label}</Label>}

      <Control
        ref={fieldRef}
        {...field}
        value={value || ''}
        {...props}
        isInvalid={showError}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        className={`${props.inputClass || ''} ${props.className || ''}`.trim()}
      />

      {/* <Control.Text> */}

      {/* </Control.Text> */}

      {!!hintText && (
        <Form.Text className='text-muted textarea-hint'>{hintText}</Form.Text>
      )}
      {!!showError && (
        <Control.Feedback type='invalid'>
          {getIn(errors, field.name)}
        </Control.Feedback>
      )}
    </Group>
  )
}
