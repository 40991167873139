import { createOrEditItem } from './form'

export const addSingleQuoteNote = (
  values,
  messageId,
  quoteIndex
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `quotes/single/adminNote/${messageId}/${quoteIndex}`,
      successMessage: 'Note was successfully added',
    })
  )

export const editSingleQuoteNote = (
  values,
  messageId,
  quoteIndex
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `quotes/single/adminNote/${messageId}/${quoteIndex}`,
      successMessage: 'Note was successfully updated',
    })
  )

export const deleteSingleQuoteNote = (
  values,
  messageId,
  quoteIndex
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `quotes/single/adminNote/${messageId}/${quoteIndex}`,
      successMessage: 'Note was successfully deleted',
    })
  )

export const addMultipleQuotesNote = (
  values,
  chatId,
  messageId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `quotes/multi/adminNote/${chatId}/${messageId}`,
      successMessage: 'Note was successfully added',
    })
  )

export const editMultipleQuotesNote = (
  values,
  chatId,
  messageId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `quotes/multi/adminNote/${chatId}/${messageId}`,
      successMessage: 'Note was successfully updated',
    })
  )

export const deleteMultipleQuotesNote = (
  values,
  chatId,
  messageId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `quotes/multi/adminNote/${chatId}/${messageId}`,
      successMessage: 'Note was successfully deleted',
    })
  )
