import React from 'react'
import Loadable from 'react-loadable'

export default (loader, extraProps) => {
  const options = {
    loader,
    delay: false,
    loading: () => <div />,
  }

  if (extraProps) {
    options.render = (loaded, props) => {
      let Component = loaded.default
      return <Component {...props} {...extraProps} />
    }
  }
  return Loadable(options)
}
