import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { SmallUp, SmallDown } from './Responsive/breakpoints'
import { isMobile } from 'helpers'
const isMobileBrowser = isMobile()
const ConfirmationModal = ({
  show,
  onHide,
  handleFunction,
  confirmationText,
  confirmationTitle = null,
  driver = false,
  values = null,
  submitOnClose = false,
  updateAllModal = false,
  changeConfirmationTitle = false,
  partnerSupport = false,
  declineOffer = false,
  declineChanges = false,
  addCompanyCard = false,
}) => {
  const onClose = () => {
    onHide()
  }
  const onConfirmationClose = () => {
    if (values) {
      handleFunction(values, false)
    }
  }
  const onConfirmation = () => {
    if (values) {
      handleFunction(values, true)
    } else {
      handleFunction()
    }
  }
  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='cancel-booking-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <div className='close-btn-wrap'>
            <Button
              style={{ outline: 'none', boxShadow: 'none' }}
              variant='link'
              onClick={onClose}
              className={isMobileBrowser ? 'btn-link-draft-mobilee m-0' : ''}
            >
              <img
                style={{ maxWidth: '15px', maxHeight: '15px' }}
                src='/images/icons/close-icon-black.png'
                alt=''
              />
              {/* <img
                src={`${
                  isMobileBrowser
                    ? '/images/icons/close.svg'
                    : '/images/icons/close.svg'
                }`}
                alt=''
              /> */}
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateAllModal ? (
          <>
            <h1 className='title'>Notification settings</h1>
            <p className='modal-description'>
              Would you like to update the notification <br />
              settings for all team members?
            </p>
          </>
        ) : (
          <>
            {changeConfirmationTitle ? (
              <>
                {' '}
                <h1 className='title'>{confirmationTitle}</h1>
                <p
                  dangerouslySetInnerHTML={{ __html: confirmationText }}
                  className='modal-description'
                />
              </>
            ) : (
              <>
                {declineOffer ? (
                  <>
                    <h1 className='title'>Decline offer</h1>
                    <p
                      className='modal-description'
                      style={{ fontSize: '15px' }}
                    >
                      {confirmationText}
                    </p>
                  </>
                ) : (
                  <>
                    {declineChanges ? (
                      <>
                        {' '}
                        <h1 className='title'>Decline changes</h1>
                        <p
                          className={`modal-description ${
                            isMobileBrowser
                              ? 'd-flex justify-content-center align-content-center'
                              : ''
                          }`}
                          style={{ fontSize: '15px' }}
                        >
                          {confirmationText}
                        </p>
                      </>
                    ) : (
                      <>
                        {addCompanyCard ? (
                          <>
                            <h1 className='title'>Payment method</h1>
                            <p
                              style={{ fontSize: '15px' }}
                              className='modal-description'
                            >
                              Would you like to make this payment method <br />
                              accessible to all team members?
                            </p>
                          </>
                        ) : (
                          <>
                            {' '}
                            <h1 className='title'>Confirmation</h1>
                            <p className='modal-description'>
                              {confirmationText}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {driver ? (
          <Button
            className='select-btn h-40'
            variant='brown'
            onClick={onConfirmation}
          >
            OK
          </Button>
        ) : (
          <>
            {partnerSupport ? (
              <Button
                className='select-btn h-40'
                variant='brown'
                onClick={onClose}
              >
                OK
              </Button>
            ) : (
              <>
                <SmallUp>
                  <Button
                    className='h-40 mr-1 confirmation-modal-decline-btn'
                    variant='brown'
                    onClick={submitOnClose ? onConfirmationClose : onClose}
                  >
                    No
                  </Button>
                  <Button
                    className='select-btn h-40 ml-1'
                    variant='brown'
                    onClick={onConfirmation}
                    style={{ fontSize: '15px' }}
                  >
                    Yes
                  </Button>
                </SmallUp>
                {/* For some reason smallDown does not work, this is annoying */}
                {isMobileBrowser ? (
                  <SmallDown
                    className={declineOffer && isMobileBrowser && 'd-flex'}
                    style={{ display: 'flex' }}
                  >
                    <Button
                      className={'h-40 confirmation-modal-decline-btn'}
                      variant='brown'
                      onClick={submitOnClose ? onConfirmationClose : onClose}
                      style={{
                        minWidth: '130px',
                        marginRight: '0.2rem',
                      }}
                    >
                      No
                    </Button>
                    <Button
                      className='select-btn h-40'
                      variant='brown'
                      style={{ minWidth: '130px', marginLeft: '0.2rem' }}
                      onClick={onConfirmation}
                    >
                      Yes
                    </Button>
                  </SmallDown>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
