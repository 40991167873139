/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import {
  Col,
  Row,
  Container,
  Form as FormB,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { Field } from 'formik'
import FormField from './FormField'
import MatRaisedLink from 'components/MatRaisedLink'
import { Link } from 'react-router-dom'
import FormButton from './FormButton'
import { continentsList } from 'constants/CountryList'
import TooltipOnHover from './TooltipOnHover'

const { Row: FormRow } = FormB

export default function FormSections({
  sections = [],
  onFullNameChange,
  onPhoneChange,
  onPhoneChangeCheck,
  applyBenefits,
  values,
  memberBenefitsApplied,
  selectedVehicle,
  handleAction,
  hidden,
  fieldApplyButton,
  isMobileBrowser,
  isCheckRefresh,
  inputClass,
  handleBlur,
}) {
  const onFirstNameChange = values => {
    if (onFullNameChange) {
      onFullNameChange(values)
    }
  }
  const onLastNameChange = values => {
    if (onFullNameChange) {
      onFullNameChange(values)
    }
  }
  const onChangePhone = values => {
    if (onPhoneChange) {
      onPhoneChange(values)
    }
  }
  const checkPhoneValidation = (value, country) => {
    if (onPhoneChangeCheck) {
      onPhoneChangeCheck(value, country)
    }
  }
  return (
    <>
      {sections.map(
        (
          {
            title,
            subTitle = null,
            titleClass = null,
            sectionClass = '',
            actionBtn,
            fields = [],
          },
          i
        ) => (
          <Container
            key={String(i)}
            className={
              title === 'Enter the passenger information'
                ? `form-block passenger-information-special ${sectionClass ||
                    ''}`
                : `form-block ${sectionClass || ''}`
            }
            style={
              title === 'Enter your bank information'
                ? { borderBottom: 'none' }
                : {}
            }
          >
            <div className={titleClass ? '' : 'd-flex justify-content-between'}>
              {!!title && (
                <h4
                  className='sub-header'
                  style={
                    title === 'Personal information'
                      ? { marginBottom: '32px' }
                      : title.includes('Create a new password for')
                      ? { marginBottom: '28px' }
                      : title === 'Enter your bank information'
                      ? { display: 'flex' }
                      : {}
                  }
                >
                  {title}

                  {title === 'Enter your bank information' ? (
                    <div>
                      <OverlayTrigger
                        delay={{ show: 100, hide: 100 }}
                        placement={
                          isMobileBrowser ? 'left-start' : 'right-start'
                        }
                        overlay={
                          <Tooltip
                            id='button-tooltip'
                            className='partnerRegistrationTooltip'
                          >
                            Tax number (if applicable) and bank information are
                            required to process payouts of your earnings.{' '}
                          </Tooltip>
                        }
                      >
                        <img
                          className={'new-info-icon'}
                          style={
                            isMobileBrowser
                              ? {
                                  marginLeft: '0.4rem',
                                  marginTop: '0.1rem',
                                  width: '14px',
                                }
                              : {
                                  marginLeft: '0.4rem',
                                  marginTop: '0.1rem',
                                  height: '0.938rem',
                                }
                          }
                          src='/images/icons/new-info-icon.png'
                          alt=''
                        />
                      </OverlayTrigger>
                      {/* <TooltipOnHover
                        delay={200}
                        show
                        placement={
                          isMobileBrowser ? 'left-start' : 'right-start'
                        }
                        onMouseEnter={() => {}}
                        isTerms={'toolTip-settings'}
                        component={
                          <span className='toolTip-light-grey'>
                            Tax number (if applicable) and bank information are
                            required to process payouts of your earnings.{' '}
                          </span>
                        }
                      >
                        <img
                          className={'new-info-icon'}
                          style={
                            isMobileBrowser
                              ? {
                                  marginLeft: '0.4rem',
                                  marginTop: '0.1rem',
                                  width: '14px',
                                }
                              : {
                                  marginLeft: '0.4rem',
                                  marginTop: '0.1rem',
                                  height: '0.938rem',
                                }
                          }
                          src='/images/icons/new-info-icon.png'
                          alt=''
                        />
                      </TooltipOnHover> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </h4>
              )}
              {title === 'Company’s information'
                ? fields[3] &&
                  fields[3].searchOptions &&
                  fields[3].searchOptions.componentRestrictions &&
                  fields[3].searchOptions.componentRestrictions.country
                  ? 'Region: ' +
                    continentsList[
                      fields[3].searchOptions.componentRestrictions.country.toUpperCase()
                    ]
                  : ''
                : ''}
              {!!subTitle && <p className='sub-header-text'>{subTitle}</p>}
              {!!actionBtn && window.innerWidth > 991 && (
                <MatRaisedLink node={Link} to={`/settings/change-password`}>
                  Change password
                </MatRaisedLink>
              )}
            </div>
            {fields && fields.length && (
              <FormRow
                className={
                  title === 'Enter the passenger information'
                    ? 'form-benefits'
                    : ''
                }
              >
                {fields.map(
                  (
                    { component = FormField, md = 6, className = '', ...field },
                    f
                  ) => (
                    <>
                      <Col key={String(f)} md={md} className={className || ''}>
                        {field.name === 'passengerInfo.firstName' ||
                        field.name === 'passengerInfo.lastName' ? (
                          <Field
                            component={component}
                            {...field}
                            onHandleChange={
                              field.name === 'passengerInfo.firstName'
                                ? onFirstNameChange(values)
                                : onLastNameChange(values)
                            }
                            className={inputClass || ''}
                          />
                        ) : (
                          <>
                            {field.name === 'passengerInfo.phone' ? (
                              <Row noGutters>
                                <Col md={12}>
                                  <Field
                                    component={component}
                                    {...field}
                                    onHandleChange={onChangePhone(values)}
                                    handleChange={(value, country) =>
                                      checkPhoneValidation(value, country)
                                    }
                                  />
                                </Col>
                                {!memberBenefitsApplied && isMobileBrowser ? (
                                  <Col
                                    md={4}
                                    style={{
                                      paddingLeft: '0%',
                                      marginBottom: '1px',
                                    }}
                                  >
                                    <Field
                                      component={FormButton}
                                      {...fieldApplyButton}
                                      hidden={hidden}
                                      handleAction={handleAction}
                                      memberBenefitsApplied={
                                        memberBenefitsApplied
                                      }
                                    />
                                  </Col>
                                ) : (
                                  <>
                                    {isMobileBrowser && (
                                      <Col
                                        md={4}
                                        style={{ marginBottom: '1px' }}
                                      >
                                        <>
                                          {memberBenefitsApplied &&
                                          selectedVehicle.memberBenefits &&
                                          !selectedVehicle.memberBenefits
                                            .status ? (
                                            <>
                                              <div
                                                className='benefits-member-message'
                                                style={{ marginBottom: '1rem' }}
                                              >
                                                {/* <span
                                                  className='close-icon d-flex justify-content-end'
                                                  style={{
                                                    'margin-left': '97%',
                                                  }}
                                                  onClick={() => {
                                                    isCheckRefresh('', '', true)
                                                  }}
                                                >
                                                  <img
                                                    src='/images/icons/close-icon-HD.png'
                                                    alt=''
                                                  />
                                                </span> */}
                                                <div
                                                  className='benefits-body'
                                                  style={{
                                                    marginBottom: '3.3%',
                                                  }}
                                                >
                                                  {`${selectedVehicle.memberBenefits.message}`}
                                                  {}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {memberBenefitsApplied &&
                                              selectedVehicle.memberBenefits &&
                                              selectedVehicle.memberBenefits
                                                .list.length ? (
                                                <>
                                                  <div
                                                    className='benefits-member-message'
                                                    style={{
                                                      marginBottom: '1rem',
                                                    }}
                                                  >
                                                    {/* <span
                                                      className='close-icon d-flex justify-content-end'
                                                      style={{
                                                        marginLeft: '97%',
                                                        marginBottom: '1.5%',
                                                      }}
                                                      onClick={() => {
                                                        isCheckRefresh(
                                                          '',
                                                          '',
                                                          true
                                                        )
                                                      }}
                                                    >
                                                      <img
                                                        src='/images/icons/close-icon-HD.png'
                                                        alt=''
                                                      />
                                                    </span> */}
                                                    <div
                                                      className='d-flex align-self-start'
                                                      style={{
                                                        paddingBottom: isMobileBrowser
                                                          ? '6%'
                                                          : '3%',
                                                      }}
                                                    >
                                                      <div>
                                                        <img
                                                          className='pr-2'
                                                          style={{
                                                            maxWidth: '35px',
                                                          }}
                                                          src='/images/icons/Icon_Benefits.png'
                                                        />
                                                      </div>
                                                      <span
                                                        style={{
                                                          paddingTop: '1.5%',
                                                        }}
                                                      >
                                                        <div
                                                          className='benefits-header'
                                                          style={{
                                                            paddingTop: isMobileBrowser
                                                              ? '2%'
                                                              : '',
                                                            paddingLeft: isMobileBrowser
                                                              ? '5%'
                                                              : ' ',
                                                          }}
                                                        >{`${values.passengerInfo.firstName
                                                          .toLowerCase()
                                                          .split(' ')
                                                          .map(
                                                            s =>
                                                              s
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                              s.slice(1)
                                                          )
                                                          .join(
                                                            ' '
                                                          )} ${values.passengerInfo.lastName
                                                          .toLowerCase()
                                                          .split(' ')
                                                          .map(
                                                            s =>
                                                              s
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                              s.slice(1)
                                                          )
                                                          .join(
                                                            ' '
                                                          )} is eligible for exclusive benefits`}</div>
                                                      </span>
                                                    </div>
                                                    {selectedVehicle.memberBenefits.list.map(
                                                      (item, i) => {
                                                        const { label } = item
                                                        return (
                                                          <div className='description-benefits-mobile'>
                                                            {label}
                                                          </div>
                                                        )
                                                      }
                                                    )}
                                                    <br />
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          )}
                                        </>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>
                            ) : (
                              <Field
                                component={component}
                                {...field}
                                handleBlur={handleBlur}
                              />
                            )}
                          </>
                        )}
                      </Col>
                    </>
                  )
                )}
              </FormRow>
            )}
          </Container>
        )
      )}
    </>
  )
}
