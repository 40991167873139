import {
  CLEAR_CLIENT_DATA,
  SET_CLIENT_BUISNESS_DETAILS,
  SET_CLIENT_CREDENTIALS,
  SET_CLIENT_PERSONAL_DETAILS,
} from './types'

export const clearClientData = payload => dispatch => {
  dispatch({
    type: CLEAR_CLIENT_DATA,
  })
}

export const setClientCredentials = payload => dispatch => {
  dispatch({
    type: SET_CLIENT_CREDENTIALS,
    payload: payload,
  })
}

export const setClientBuisnessDetails = payload => dispatch => {
  dispatch({
    type: SET_CLIENT_BUISNESS_DETAILS,
    payload: payload,
  })
}

export const setClientPersonalDetails = payload => dispatch => {
  dispatch({
    type: SET_CLIENT_PERSONAL_DETAILS,
    payload: payload,
  })
}
