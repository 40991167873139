import { CLOSE_CAR_DROP_DOWN, OPEN_CAR_DROP_DOWN } from '../actions'

const initialState = {
  openedCars: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CAR_DROP_DOWN:
      return {
        ...state,
        openedCars: [...state.openedCars, action.payload],
      }
    case CLOSE_CAR_DROP_DOWN:
      return {
        ...state,
        openedCars: state.openedCars.filter(carId => carId !== action.payload),
      }
    default:
      return state
  }
}
