import { handleActionError } from './helpers'
import { showNotification } from './notifications'
import { logger } from './logger'

export const createOrEditWebsiteItem = (
  values,
  { isEdit = false, editRequest = 'patch', endpoint, successMessage }
) => async dispatch => {
  try {
    let url = `${process.env.REACT_APP_WEBSITE}/${endpoint}`
    let request = isEdit ? editRequest.toLocaleUpperCase() : 'POST'
    let token = process.env.REACT_APP_WEBSITE_JWT
    const options = {
      method: request,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }

    if (options.method !== 'GET') {
      options.body = JSON.stringify(values)
    }
    const result = (await fetch(url, options)) || {}
    if (successMessage) {
      dispatch(showNotification({ message: successMessage }))
    }

    return result
  } catch (error) {
    logger({ fileName: 'form', error: error })
    return handleActionError(error)
  }
}

export const deleteWebsiteItem = ({
  endpoint,
  successMessage,
}) => async dispatch => {
  try {
    let token = process.env.REACT_APP_WEBSITE_JWT
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
    const result =
      (await fetch(`${process.env.REACT_APP_WEBSITE}/${endpoint}`, options)) ||
      {}
    if (successMessage) {
      dispatch(showNotification({ message: successMessage }))
    }
    return result
  } catch (error) {
    logger({ fileName: 'form', error: error })
    return handleActionError(error)
  }
}
