import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import loadable from '../../../loadable'

const routes = [
  {
    path: '/iframe-page/booking/main',
    component: loadable(() => import('../Pages/NewBooking')),
  },
  {
    path: '/iframe-page/booking/details',
    component: loadable(() => import('../Pages/MyBookingDetails')),
  },
  {
    path: '/iframe-page/booking/new-booking',
    component: loadable(() => import('../Pages/NewBookingForm')),
  },
]

const AuthRoutes = () => {
  return (
    <Switch>
      {routes.map(route => {
        const { path, component } = route
        return <Route key={path} path={path} component={component} />
      })}
      <Redirect from={'/iframe'} to={'/iframe'} />
    </Switch>
  )
}

export default withRouter(AuthRoutes)

export { routes }
