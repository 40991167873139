import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import {
  APP_WEBSOCKET_BROKEN,
  APP_WEBSOCKET_CLOSED,
  APP_WEBSOCKET_MESSAGE,
  APP_WEBSOCKET_OPEN,
  SET_ACTIVE_USER,
  UPDATE_CHATS_LIST,
  SET_NOTIFICATIONS_LIST,
  SET_OPERATIONALERT_COUNT,
  SET_BOOKING_PENDING_COUNT,
  SET_UNREAD_CHATS_BY_ADMIN_COUNT,
  SET_SENT_BY_ADMIN_QUOTES_COUNT,
  UNSET_TYPING,
  UPDATE_MESSAGE_LIST,
  SET_PARTNER_COUNT,
  logger,
} from '../actions'

const INITIAL_STATE = {
  connected: false,
  chatsList: [],
  notificationsList: [],
  operationsAlertCount: 0,
  csAlertsCount: 0,
  opsAlertsCount: 0,
  modAlertsCount: 0,
  dispatchAlertsCount: 0,
  supplyAlertsCount: 0,
  pendingBookingsCount: 0,
  todayBookingsCount: 0,
  tomorrowBookingsCount: 0,
  yesterdayBookingsCount: 0,
  dayAfterBookingsCount: 0,
  threeDaysAfterBookingsCount: 0,
  fourDaysAfterBookingsCount: 0,
  fiveDaysAfterBookingsCount: 0,
  unreadChatsByAdminCount: 0,
  sentByAdminQuotesCount: 0,
  notificationLoaded: false,
  operationsAlertLoaded: false,
  pendingBookingsCountLoaded: false,
  unreadChatsByAdminCountLoaded: false,
  sentByAdminQuotesCountLoaded: false,
  activeUserId: null,
  totalChatsCount: 0,
  lastMessage: null,
  editMsg: false,
  totalVerifiedCount: 0,
  totalUnverifiedCount: 0,
  totalInaptCount: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_WEBSOCKET_OPEN:
      return {
        ...state,
        chatsList: [],
        notificationsList: [],
        operationsAlertCount: 0,
        csAlertsCount: 0,
        opsAlertsCount: 0,
        modAlertsCount: 0,
        dispatchAlertsCount: 0,
        supplyAlertsCount: 0,
        pendingBookingsCount: 0,
        todayBookingsCount: 0,
        tomorrowBookingsCount: 0,
        yesterdayBookingsCount: 0,
        dayAfterBookingsCount: 0,
        threeDaysAfterBookingsCount: 0,
        fourDaysAfterBookingsCount: 0,
        fiveDaysAfterBookingsCount: 0,
        unreadChatsByAdminCount: 0,
        sentByAdminQuotesCount: 0,
        notificationLoaded: false,
        operationsAlertLoaded: false,
        pendingBookingsCountLoaded: false,
        unreadChatsByAdminCountLoaded: false,
        sentByAdminQuotesCountLoaded: false,
        connected: true,
        totalVerifiedCount: 0,
        totalUnverifiedCount: 0,
        totalInaptCount: 0,
      }
    case APP_WEBSOCKET_BROKEN:
      return {
        ...state,
        connected: false,
      }
    case APP_WEBSOCKET_CLOSED:
      return {
        ...state,
        connected: false,
      }

    case APP_WEBSOCKET_MESSAGE: {
      let msg = null
      try {
        msg = JSON.parse(action.payload.message)
      } catch (e) {
        logger({ fileName: 'appWebSocket', error: e })
        return state
      }

      if (
        msg !== null &&
        typeof msg === 'object' &&
        msg.hasOwnProperty('type')
      ) {
        const chatId = msg.payload.chatInfo && msg.payload.chatInfo.id
        switch (msg.type) {
          case 'chat_list': {
            return {
              ...state,
              chatsList: msg.payload,
            }
          }
          case 'added_message_to_chat': {
            const foundIndex = state.chatsList.findIndex(chat => {
              if (
                msg &&
                msg.payload &&
                msg.payload.chatInfo &&
                msg.payload.chatInfo.id
              ) {
                return chatId === chat.id || msg.payload.chatInfo.id === chat.id
              } else {
                return chatId === chat.id
              }
            })
            if (foundIndex !== -1) {
              const updatedChatList = cloneDeep(state.chatsList).map(item => {
                if (
                  item.user &&
                  msg.payload.chatInfo.user &&
                  item.user.id === msg.payload.chatInfo.user.id
                )
                  item.user.lastSeen = msg.payload.chatInfo.user.lastSeen

                if (
                  item.attachedAdmin &&
                  msg.payload.chatInfo.attachedAdmin &&
                  item.attachedAdmin.id ===
                    msg.payload.chatInfo.attachedAdmin.id
                ) {
                  item.attachedAdmin.lastSeen =
                    msg.payload.chatInfo.attachedAdmin.lastSeen
                }

                return item
              })
              const updatedChat = cloneDeep(state.chatsList[foundIndex])
              updatedChat.updatedAt =
                msg.payload.chatInfo && msg.payload.chatInfo.updatedAt
              updatedChat.unreadByAdminMessagesCount =
                msg.payload.chatInfo &&
                msg.payload.chatInfo.unreadByAdminMessagesCount
              updatedChat.unreadByUserMessagesCount =
                msg.payload.chatInfo &&
                msg.payload.chatInfo.unreadByUserMessagesCount
              updatedChat.user =
                msg.payload.chatInfo && msg.payload.chatInfo.user
              updatedChat.attachedAdmin =
                msg.payload.chatInfo && msg.payload.chatInfo.attachedAdmin
              if (updatedChat.messages) {
                updatedChat.messages.push(msg.payload.newMessage)
              }

              if (foundIndex !== 0) {
                updatedChatList.splice(foundIndex, 1)
                updatedChatList.unshift(updatedChat)
              } else {
                updatedChatList[foundIndex] = updatedChat
              }
              return {
                ...state,
                lastMessage: msg.payload.newMessage,
                chatsList: updatedChatList,
              }
            }
            return state
          }
          case 'set_conversation_owner_to_chat': {
            const foundIndex = state.chatsList.findIndex(
              chat => chatId === chat.id
            )

            const updatedChatList = cloneDeep(state.chatsList).map(item => {
              if (
                item.user &&
                msg.payload.chatInfo.user &&
                item.user.id === msg.payload.chatInfo.user.id
              )
                item.user.lastSeen = msg.payload.chatInfo.user.lastSeen

              if (
                item.attachedAdmin &&
                msg.payload.chatInfo.attachedAdmin &&
                item.attachedAdmin.id === msg.payload.chatInfo.attachedAdmin.id
              ) {
                item.attachedAdmin.lastSeen =
                  msg.payload.chatInfo.attachedAdmin.lastSeen
              }

              return item
            })
            if (foundIndex !== -1) {
              updatedChatList[foundIndex] = {
                ...updatedChatList[foundIndex],
                attachedAdmin: msg.payload.chatInfo.attachedAdmin,
                unreadByAdminMessagesCount:
                  msg.payload.chatInfo.unreadByAdminMessagesCount,
                unreadByUserMessagesCount:
                  msg.payload.chatInfo.unreadByUserMessagesCount,
                user: msg.payload.chatInfo.user,
              }
            }

            return { ...state, chatsList: updatedChatList }
          }
          case 'marked_as_read_message_in_chat': {
            const foundIndex = state.chatsList.findIndex(
              chat => chatId === chat.id && !!chat.messages
            )
            const updatedChatList = cloneDeep(state.chatsList)

            if (foundIndex !== -1) {
              const updatedChat = cloneDeep(state.chatsList[foundIndex])

              updatedChat.unreadByUserMessagesCount =
                msg.payload.chatInfo &&
                msg.payload.chatInfo.unreadByUserMessagesCount
              updatedChatList[foundIndex] = updatedChat
              const foundMsgIndex = updatedChatList[
                foundIndex
              ].messages.findIndex(
                message => msg.payload.messageId === message.id
              )
              const { readByUser, readByAdmin } = msg.payload
              if (foundMsgIndex !== -1) {
                updatedChatList[foundIndex].messages[foundMsgIndex] = {
                  ...updatedChatList[foundIndex].messages[foundMsgIndex],
                  ...{
                    readByUser: readByUser
                      ? readByUser
                      : updatedChatList[foundIndex].messages[foundMsgIndex]
                          .readByUser,
                    readByAdmin: readByAdmin
                      ? readByAdmin
                      : updatedChatList[foundIndex].messages[foundMsgIndex]
                          .readByAdmin,
                  },
                }
              }
            }
            return {
              ...state,
              chatsList: updatedChatList,
            }
          }
          case 'unset_conversation_owner_in_chat': {
            const foundIndex = state.chatsList.findIndex(
              chat => chatId === chat.id
            )
            const updatedChatList = cloneDeep(state.chatsList)
            if (foundIndex !== -1) {
              updatedChatList[foundIndex] = {
                ...updatedChatList[foundIndex],
                attachedAdmin: null,
              }
            }

            return { ...state, chatsList: updatedChatList }
          }
          case 'edited_message_in_chat': {
            const foundIndex = state.chatsList.findIndex(
              chat => chatId === chat.id
            )
            if (foundIndex !== -1) {
              const updatedChatList = cloneDeep(state.chatsList)
              const updatedChat = cloneDeep(state.chatsList[foundIndex])
              const msgIndex = findIndex(updatedChat.messages, function(o) {
                return o.id === msg.payload.message.id
              })
              updatedChat.messages[msgIndex] = msg.payload.message
              updatedChatList[foundIndex] = updatedChat
              return {
                ...state,
                editMsg: msg.payload.message,
                chatsList: updatedChatList,
              }
            }
            return state
          }
          case 'deleted_message_in_chat': {
            const foundIndex = state.chatsList.findIndex(
              chat => chatId === chat.id
            )
            if (foundIndex !== -1) {
              const updatedChatList = cloneDeep(state.chatsList)
              const updatedChat = cloneDeep(state.chatsList[foundIndex])
              const msgIndex = findIndex(updatedChat.messages, function(o) {
                return o.id === msg.payload.deleteMessage.id
              })
              if (msgIndex) {
                updatedChat.messages[msgIndex] = msg.payload.deleteMessage
                updatedChatList[foundIndex] = updatedChat
              }
              return {
                ...state,
                chatsList: updatedChatList,
              }
            }
            return state
          }
          case 'user_is_typing': {
            const foundIndex = state.chatsList.findIndex(
              chat => msg.payload.chatId === chat.id
            )
            const updatedChatList = cloneDeep(state.chatsList)
            if (foundIndex !== -1) {
              updatedChatList[foundIndex] = {
                ...updatedChatList[foundIndex],
                isTyping: msg.payload.userId !== state.activeUserId,
              }
            }

            return { ...state, chatsList: updatedChatList }
          }
          case 'changed_booking_quote': {
            const foundIndex = state.chatsList.findIndex(
              chat => msg.payload.chatId === chat.id && !!chat.messages
            )
            const foundBookingIndex = state.chatsList.findIndex(chat => {
              if (msg.payload.booking && chat.booking) {
                return (
                  chat.type === 'booking_chat' &&
                  msg.payload.booking.id === chat.booking.id
                )
              }
            })

            const updatedChatList = cloneDeep(state.chatsList)
            if (foundIndex !== -1) {
              const foundMsgIndex = updatedChatList[
                foundIndex
              ].messages.findIndex(
                message => msg.payload.messageId === message.id
              )
              if (updatedChatList[foundIndex].messages[foundMsgIndex]) {
                updatedChatList[foundIndex].messages[
                  foundMsgIndex
                ].quote.searchResults[msg.payload.searchResultIndex] = {
                  ...updatedChatList[foundIndex].messages[foundMsgIndex].quote
                    .searchResults[msg.payload.searchResultIndex],
                  booking: msg.payload.booking,
                }
              }
            }

            if (foundBookingIndex !== -1) {
              updatedChatList[foundBookingIndex].booking = msg.payload.booking
            }

            return {
              ...state,
              chatsList: updatedChatList,
            }
          }
          case 'added_notification': {
            if (msg.payload.newNotification) {
              const updatedNotificationsList = cloneDeep(
                state.notificationsList
              )
              updatedNotificationsList.push(msg.payload.newNotification)
              return {
                ...state,
                notificationsList: updatedNotificationsList,
                notificationLoaded: true,
              }
            }
            return state
          }
          case 'removed_notification': {
            if (msg.payload.notification && msg.payload.notification.id) {
              const delId = msg.payload.notification.id
              const foundIndex = state.notificationsList.findIndex(
                item => item.id === delId
              )

              if (foundIndex !== -1) {
                let updatedNotificationsList = cloneDeep(
                  state.notificationsList
                )
                updatedNotificationsList.splice(foundIndex, 1)
                return {
                  ...state,
                  notificationsList: updatedNotificationsList,
                }
              }
            }
            return state
          }
          case 'added_operation_alert': {
            return {
              ...state,
              operationsAlertCount: msg.payload.newOperationsAlerts,
              csAlertsCount: msg.payload.csAlertsCount,
              opsAlertsCount: msg.payload.opsAlertsCount,
              modAlertsCount: msg.payload.modAlertsCount,
              dispatchAlertsCount: msg.payload.dispatchAlertsCount,
              supplyAlertsCount: msg.payload.supplyAlertsCount,
            }
          }
          case 'removed_operation_alert': {
            return {
              ...state,
              operationsAlertCount: msg.payload.newOperationsAlerts,
              csAlertsCount: msg.payload.csAlertsCount,
              opsAlertsCount: msg.payload.opsAlertsCount,
              modAlertsCount: msg.payload.modAlertsCount,
              dispatchAlertsCount: msg.payload.dispatchAlertsCount,
              supplyAlertsCount: msg.payload.supplyAlertsCount,
            }
          }
          case 'booking_added': {
            return {
              ...state,
              bookingAdded: msg.payload.booking,
              pendingBookingsCount: msg.payload.pendingBookings,
              todayBookingsCount: msg.payload.todayBookingsCount,
              tomorrowBookingsCount: msg.payload.tomorrowBookingsCount,
              yesterdayBookingsCount: msg.payload.yesterdayBookingsCount,
              dayAfterBookingsCount: msg.payload.dayAfterBookingsCount,
              threeDaysAfterBookingsCount:
                msg.payload.threeDaysAfterBookingsCount,
              fourDaysAfterBookingsCount:
                msg.payload.fourDaysAfterBookingsCount,
              fiveDaysAfterBookingsCount:
                msg.payload.fiveDaysAfterBookingsCount,
            }
          }
          case 'booking_list_summary_update': {
            return {
              ...state,
              bookingListUpdate: msg.payload.booking, //to use on admin side
              pendingBookingsCount: msg.payload.pendingBookings,
              todayBookingsCount: msg.payload.todayBookingsCount,
              tomorrowBookingsCount: msg.payload.tomorrowBookingsCount,
              yesterdayBookingsCount: msg.payload.yesterdayBookingsCount,
              dayAfterBookingsCount: msg.payload.dayAfterBookingsCount,
              threeDaysAfterBookingsCount:
                msg.payload.threeDaysAfterBookingsCount,
              fourDaysAfterBookingsCount:
                msg.payload.fourDaysAfterBookingsCount,
              fiveDaysAfterBookingsCount:
                msg.payload.fiveDaysAfterBookingsCount,
            }
          }
          case 'booking_summary_update': {
            return {
              ...state,
              bookingUpdate: msg.payload.booking, //to use on client side
            }
          }
          case 'unread_chats_by_admin_update_count': {
            return {
              ...state,
              unreadChatsByAdminCount: msg.payload.unreadChatsByAdminCount,
            }
          }
          case 'quotes_by_admin_update_count': {
            return {
              ...state,
              sentByAdminQuotesCount: msg.payload.sentByAdminQuotesCount,
            }
          }
          default:
            return state
        }
      }
      break
    }

    case SET_ACTIVE_USER: {
      return {
        ...state,
        activeUserId: action.payload.userId,
      }
    }
    case UPDATE_CHATS_LIST: {
      let { chatsList } = state
      if (action.payload.page === 1) {
        chatsList = []
      }
      let updatedChatsList = chatsList
      if (action.payload) {
        const foundIndex = updatedChatsList.findIndex(chat => {
          return chat.id === action.payload.id
        })
      }
      chatsList = [...chatsList, ...action.payload.data]
      return {
        ...state,
        chatsList: chatsList,
        totalChatsCount: action.payload.meta.totalItems,
        //activeUserId: action.payload.userId,
      }
    }
    case UPDATE_MESSAGE_LIST: {
      let updatedChatsList = state.chatsList
      if (action.payload) {
        const foundIndex = updatedChatsList.findIndex(chat => {
          return chat.id === action.payload.id
        })
        if (foundIndex !== -1) {
          if (
            !updatedChatsList[foundIndex].messages ||
            updatedChatsList[foundIndex].messages.length === 0
          ) {
            updatedChatsList[foundIndex].messages = action.payload.messages
            updatedChatsList[foundIndex].page = action.payload.page
            updatedChatsList[foundIndex].totalMessages =
              action.payload.totalMessages
          } else {
            if (updatedChatsList[foundIndex].page < action.payload.page) {
              updatedChatsList[foundIndex].page = action.payload.page
              updatedChatsList[foundIndex].totalMessages =
                action.payload.totalMessages
              updatedChatsList[foundIndex].messages.unshift(
                ...action.payload.messages
              )
            } else if (action.payload.page === 1) {
              updatedChatsList[foundIndex].page = action.payload.page
              updatedChatsList[foundIndex].messages = action.payload.messages
              updatedChatsList[foundIndex].totalMessages =
                action.payload.totalMessages
            } else if (
              updatedChatsList[foundIndex].messages.length ===
              action.payload.messages.length
            ) {
              updatedChatsList[foundIndex].messages = action.payload.messages
              updatedChatsList[foundIndex].totalMessages =
                action.payload.totalMessages
            } else if (
              updatedChatsList[foundIndex].messages.length !==
              action.payload.messages.length
            ) {
              updatedChatsList[foundIndex].messages = action.payload.messages
              updatedChatsList[foundIndex].totalMessages =
                action.payload.totalMessages
            }
          }
        } else {
          updatedChatsList = [action.payload, ...updatedChatsList]
        }
      }
      return {
        ...state,
        chatsList: [...updatedChatsList],
      }
    }
    case UNSET_TYPING: {
      const foundIndex = state.chatsList.findIndex(
        chat => chat.id === action.payload.chatId
      )
      const updatedChatList = cloneDeep(state.chatsList)
      if (foundIndex !== -1) {
        updatedChatList[foundIndex] = {
          ...updatedChatList[foundIndex],
          isTyping: false,
        }
      }

      return { ...state, chatsList: updatedChatList }
    }
    case SET_NOTIFICATIONS_LIST: {
      return {
        ...state,
        notificationsList: (action.payload && action.payload.data) || [],
        notificationLoaded: true,
      }
    }
    case SET_OPERATIONALERT_COUNT: {
      const data = action.payload && action.payload.data
      return {
        ...state,
        operationsAlertCount: data ? data.totalCount : 0,
        csAlertsCount: data ? data.totalCsAlertsCount : 0,
        opsAlertsCount: data ? data.totalOpsAlertsCount : 0,
        modAlertsCount: data ? data.totalModAlertsCount : 0,
        dispatchAlertsCount: data ? data.totalDispatchAlertsCount : 0,
        supplyAlertsCount: data ? data.totalSupplyAlertsCount : 0,
        operationsAlertLoaded: true,
      }
    }
    case SET_BOOKING_PENDING_COUNT: {
      const data = action.payload && action.payload.data
      return {
        ...state,
        pendingBookingsCount: data ? data.totalCount : 0,
        todayBookingsCount: data ? data.todayBookingsCount : 0,
        tomorrowBookingsCount: data ? data.tomorrowBookingsCount : 0,
        yesterdayBookingsCount: data ? data.yesterdayBookingsCount : 0,
        dayAfterBookingsCount: data ? data.dayAfterBookingsCount : 0,
        threeDaysAfterBookingsCount: data
          ? data.threeDaysAfterBookingsCount
          : 0,
        fourDaysAfterBookingsCount: data ? data.fourDaysAfterBookingsCount : 0,
        fiveDaysAfterBookingsCount: data ? data.fiveDaysAfterBookingsCount : 0,
        pendingBookingsCountLoaded: true,
      }
    }
    case SET_PARTNER_COUNT: {
      const data = action.payload && action.payload.data
      return {
        ...state,
        totalVerifiedCount: data ? data.totalVerifiedCount : 0,
        totalUnverifiedCount: data ? data.totalUnverifiedCount : 0,
        totalInaptCount: data ? data.totalInaptCount : 0,
      }
    }
    case SET_UNREAD_CHATS_BY_ADMIN_COUNT: {
      return {
        ...state,
        unreadChatsByAdminCount:
          action.payload && action.payload.data
            ? action.payload.data.totalCount
            : 0,
        unreadChatsByAdminCountLoaded: true,
      }
    }
    case SET_SENT_BY_ADMIN_QUOTES_COUNT: {
      return {
        ...state,
        sentByAdminQuotesCount:
          action.payload && action.payload.data
            ? action.payload.data.totalCount
            : 0,
        sentByAdminQuotesCountLoaded: true,
      }
    }

    default:
      return state
  }
}
