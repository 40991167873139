import { showNotification } from '../actions/notifications'
import { isString, each } from 'lodash'
// import { logger } from '../actions'
// export const apiHost = process.env.REACT_APP_APIHOST
// export const wsApiHost = process.env.REACT_APP_WSAPIHOST
// export const apiLsLocationsHost = process.env.REACT_APP_LS_LOCATION_HOST

// export const apiHost = process.env.REACT_APP_APIHOST_STAGING
// export const wsApiHost = process.env.REACT_APP_WSAPIHOST_STAGING
// export const apiLsLocationsHost = process.env.REACT_APP_LS_LOCATION_HOST_STAGING
// export const apiLsChatsHost = process.env.REACT_APP_LS_CHAT_HOST_STAGING

// export const apiHost = process.env.REACT_APP_APIHOST_DEMO
// export const wsApiHost = process.env.REACT_APP_WSAPIHOST_DEMO
// export const apiLsLocationsHost = process.env.REACT_APP_LS_LOCATION_HOST_DEMO
// export const apiLsChatsHost = process.env.REACT_APP_LS_CHAT_HOST_DEMO

// export const apiHost = process.env.REACT_APP_APIHOST_BUSINESS
// export const wsApiHost = process.env.REACT_APP_WSAPIHOST_BUSINESS
// export const apiLsLocationsHost = process.env.REACT_APP_LS_LOCATION_HOST_BUSINESS
// export const apiLsChatsHost = process.env.REACT_APP_LS_CHAT_HOST_BUSINESS

export const wsApiHost =
  process.env.REACT_APP_WSAPIHOST || 'wss://business.rolzo.com:8081/web-socket'
export const apiHost =
  process.env.REACT_APP_APIHOST || 'https://business.rolzo.com:8081'
export const apiLsLocationsHost =
  process.env.REACT_APP_LS_LOCATION_HOST ||
  'https://business.rolzo.com:8081/ls-locations'
export const apiLsChatsHost =
  process.env.REACT_APP_LS_CHAT_HOST ||
  'https://business.rolzo.com/api/ls-chats'
export const analyticsHost =
  process.env.REACT_APP_ANALYTICS_HOST ||
  'https://business.rolzo.com/api/analytics'

class ApiService {
  constructor(store) {
    this.store = store
    this.apiUrl = `${apiHost}/api/v1`
    this.apiLsLocationsUrl = `${apiLsLocationsHost}`
    this.apiLsChat = `${apiLsChatsHost}`
    this.apiAnalytics = `${analyticsHost}`
  }

  get authInfo() {
    const { auth } = this.store.getState()
    return auth
  }

  get = async (
    url,
    needAuth = true,
    useLs = false,
    isLogicService = '',
    token
  ) => {
    try {
      const resp = await this.makeRequest({
        url,
        needAuth,
        token,
        useLs,
        isLogicService,
      })
      return this.handleResponse(resp)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  getTableData = async url => {
    try {
      return await this.get(url)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      console.log(error)
      return {}
    }
  }
  getTableDataLS = async (url, isLogicService = '') => {
    try {
      if (isLogicService === 'Analytics') {
        return await this.get(url, false, true, isLogicService)
      } else {
        return await this.get(url, true, true, isLogicService)
      }
    } catch (error) {
      // logger({fileName: 'index', error: error})
      console.log(error)
      return {}
    }
  }

  post = async (url, body, needAuth, token, user, hideErrorMessage = false) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'POST',
        body,
        needAuth,
        token,
        user,
      })
      return this.handleResponse(resp, hideErrorMessage)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }
  post_LS = async (
    url,
    body,
    needAuth,
    token,
    user,
    hideErrorMessage = false,
    useLs = false,
    isLogicService = ''
  ) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'POST',
        body,
        needAuth,
        token,
        user,
        useLs,
        isLogicService,
      })
      return this.handleResponse(resp, hideErrorMessage)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  put = async (url, body, needAuth, token, user) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'PUT',
        body,
        needAuth,
        token,
        user,
      })
      return this.handleResponse(resp)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  del = async url => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'DELETE',
      })
      return this.handleResponse(resp)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  delete = async (url, body) => {
    try {
      const resp = await this.makeRequest({
        url,
        body,
        method: 'DELETE',
      })
      return this.handleResponse(resp)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  patch = async (url, body, needAuth, token, user) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'PATCH',
        body,
        needAuth,
        token,
        user,
      })
      return this.handleResponse(resp)
    } catch (error) {
      // logger({fileName: 'index', error: error})
      this.handleError(error)
      return Promise.reject(error)
    }
  }

  handleResponse = (
    { data, meta, message, ...rest } = {},
    hideErrorMessage = false
  ) => {
    if (meta && meta.success) {
      return {
        data,
        meta,
      }
    }
    var error = meta
      ? {
          ...meta,
        }
      : {
          message: message || 'Something went wrong',
        }
    if (data) {
      var isTrue = isString(data)
      if (isTrue) {
        error = {
          message: data,
        }
      } else {
        error = data.message
          ? {
              message: data.message,
            }
          : data.errors
      }
    }
    if (!hideErrorMessage) {
      this.handleError(error)
    }
    return Promise.reject({
      ...error,
      ...data,
    })
  }

  handleError = error => {
    if (error && error.length) {
      each(error, err => {
        if (err.message !== 'user email not varified') {
          this.store.dispatch(
            showNotification({
              message: err.message || 'Something went wrong...',
              type: 'error',
            })
          )
        }
      })
    } else {
      if (error && error.message !== 'user email not varified') {
        this.store.dispatch(
          showNotification({
            message: error.message || 'Something went wrong...',
            type: 'error',
          })
        )
      }
    }
  }
  checkLogicService = LogicService => {
    var api = ''
    switch (LogicService) {
      case 'partnerGeoLocations':
        api = this.apiLsLocationsUrl
        break
      case 'Chat_LS':
        api = this.apiLsChat
        break
      case 'Analytics':
        api = this.apiAnalytics
        break
      default:
        api = this.apiUrl
        break
    }
    return api
  }
  makeRequest = async ({
    url = '',
    method = 'GET',
    body = null,
    needAuth = true,
    token = null,
    user = null,
    useLs,
    isLogicService,
  }) => {
    try {
      const fullUrl = useLs
        ? `${this.checkLogicService(isLogicService)}/${url}`
        : `${this.apiUrl}/${url}`
      let headers = {
        'Content-Type': 'application/json',
        ...(!needAuth && token ? { 'x-api-key': token } : {}),
      }
      if (needAuth) {
        const {
          userId,
          authToken,
          isMasqueradeUser,
          adminUserId,
        } = this.authInfo
        if (isMasqueradeUser && adminUserId !== '') {
          headers['X-Masquerade-User'] = isMasqueradeUser
            ? isMasqueradeUser
            : false
          headers['X-Admin-User-Id'] = adminUserId ? adminUserId : ''
        }
        headers = {
          ...headers,
          'X-User-Id': user || userId,
          'X-Auth-Token': token || authToken,
        }
      }

      const options = {
        method,
        headers,
      }

      if (method !== 'GET' && body) {
        options.body = JSON.stringify(body || {})
      }

      const response = await fetch(fullUrl, options)

      return response.json()
    } catch (error) {
      // logger({fileName: 'index', error: error})
      return Promise.reject(error)
    }
  }
}

export default ApiService
