import { connect, disconnect, send } from '@giantmachines/redux-websocket'
import cloneDeep from 'lodash/cloneDeep'
import {
  APP_WEBSOCKET_CLOSED,
  APP_WEBSOCKET_BROKEN,
  APP_WEBSOCKET_MESSAGE,
  APP_WEBSOCKET_OPEN,
  LOGGED_IN,
  LOGGED_OUT,
  SET_ACTIVE_USER,
  UPDATE_MESSAGE_LIST,
  fetchNotificationsList,
  logger,
} from './../actions'
import { connectedApiService as api } from '../index'
import { WEBSOCKET_PREFIX } from './../constants'

export const createAppWebsocketMiddleware = (url, reconnectTimeout = 5000) => {
  let hasOpened = false
  let reconnectionInterval = null

  return store => next => action => {
    const handleConnect = (protocols = null) => {
      if (!protocols) {
        const {
          auth: { userId, authToken, isMasqueradeUser, adminUserId },
        } = store.getState()

        protocols = isMasqueradeUser
          ? [userId, authToken, isMasqueradeUser, adminUserId]
          : [userId, authToken]
      }
      store.dispatch(
        connect(
          url,
          protocols,
          WEBSOCKET_PREFIX
        )
      )
    }

    const setActiveUser = userId => ({
      type: SET_ACTIVE_USER,
      payload: {
        userId,
      },
    })

    const updateChatsList = payload => ({
      type: UPDATE_MESSAGE_LIST,
      payload,
    })

    const fetchNewChat = async chatId => {
      const { data } = await api.post(`chat/${chatId}`)
      const {
        auth: { userId },
      } = store.getState()
      store.dispatch(updateChatsList(data, userId))
    }
    const fetchNewChat_LS = async (chatId, page, userId) => {
      const { data } = await api.post_LS(
        `chat/chats/${chatId}`,
        { page, userId },
        false,
        null,
        null,
        false,
        true,
        'Chat_LS'
      )
      store.dispatch(updateChatsList(data, userId))
    }

    switch (action.type) {
      case LOGGED_IN: {
        const {
          appWebsocket: { connected },
        } = store.getState()
        const {
          isLoggedIn,
          authToken,
          userId,
          isMasqueradeUser,
          adminUserId,
        } = action.payload
        if (!connected && isLoggedIn) {
          handleConnect(
            isMasqueradeUser
              ? [userId, authToken, isMasqueradeUser, adminUserId]
              : [userId, authToken]
          )
        }
        break
      }
      case LOGGED_OUT: {
        hasOpened = false
        if (reconnectionInterval) {
          clearInterval(reconnectionInterval)
          reconnectionInterval = null
        }
        disconnect(WEBSOCKET_PREFIX)
        break
      }
      case APP_WEBSOCKET_OPEN: {
        hasOpened = true
        if (reconnectionInterval) {
          clearInterval(reconnectionInterval)
          reconnectionInterval = null
        }
        const {
          auth: { userId, roles },
        } = store.getState()
        if (roles.company && roles.company.length > 0) {
          // client side sending chat_list request
          store.dispatch(
            send(
              {
                command: 'chat_list',
                payload: {
                  userId,
                },
              },
              WEBSOCKET_PREFIX
            )
          )
        }

        fetchNotificationsList()
        store.dispatch(setActiveUser(userId))
        break
      }
      case APP_WEBSOCKET_BROKEN: {
        const {
          auth: { isLoggedIn },
        } = store.getState()
        // if (isLoggedIn && hasOpened && reconnectionInterval === null) {
        if (isLoggedIn && reconnectionInterval === null) {
          // reconnect on an interval
          reconnectionInterval = setInterval(() => {
            handleConnect()
          }, reconnectTimeout)
        }
        break
      }
      case APP_WEBSOCKET_CLOSED: {
        const {
          auth: { isLoggedIn },
        } = store.getState()
        // if (isLoggedIn && hasOpened && reconnectionInterval === null) {
        if (isLoggedIn && reconnectionInterval === null) {
          // reconnect on an interval
          reconnectionInterval = setInterval(() => {
            handleConnect()
          }, reconnectTimeout)
        }
        break
      }
      case APP_WEBSOCKET_MESSAGE: {
        let msg = null
        try {
          msg = JSON.parse(action.payload.message)
        } catch (e) {
          logger({
            fileName: 'appWebsocketMiddleware',
            error: e,
          })
          return next(action)
        }
        if (
          msg !== null &&
          typeof msg === 'object' &&
          msg.hasOwnProperty('type')
        ) {
          const {
            appWebsocket: { chatsList },
            auth: { userId },
          } = store.getState()
          if (msg.payload.type && msg.payload.type === 'booking_chat') {
            // fetchNewChat(msg.payload.id, 1)
            fetchNewChat_LS(msg.payload.id, 1, userId)
          }
          if (msg.payload.chatInfo) {
            const chatId = msg.payload.chatInfo.id
            const foundIndex = chatsList.findIndex(chat => chatId === chat.id)
            if (foundIndex === -1) {
              // fetchNewChat(chatId, 1)
              fetchNewChat_LS(chatId, 1, userId)
            } else {
              const {
                auth: { userId },
              } = store.getState()
              const {
                unreadByUserMessagesCount,
                unreadByAdminMessagesCount,
                user,
                attachedAdmin,
              } = msg.payload.chatInfo
              const updatedChat = cloneDeep(chatsList[foundIndex])
              updatedChat.unreadByUserMessagesCount = unreadByUserMessagesCount
              updatedChat.unreadByAdminMessagesCount = unreadByAdminMessagesCount
              updatedChat.user = user || null
              updatedChat.attachedAdmin = attachedAdmin || null

              store.dispatch(updateChatsList(updatedChat, userId))
            }
          }
        }
        break
      }
      default:
        return next(action)
    }
    return next(action)
  }
}
