import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import loadable from '../../loadable'
import AuthPage from './AuthPage'
import ClientRegisterPage from './RegisterPage'
import ResetPasswordPage from './ResetPasswordPage'

const routes = [
  {
    path: '/auth/login',
    exact: true,
    component: loadable(() => import('../../../pages/Auth/Login')),
    title: 'Login',
    type: 'login',
  },
  {
    path: '/auth/register',
    exact: true,
    component: loadable(() => import('../../../pages/Auth/client/Register')),
    title: 'Client Register',
    type: 'register',
  },
  {
    path: '/auth/activate-account/invited-user',
    exact: true,
    component: loadable(() =>
      import('../../../pages/Auth/ActivateInvitedUser')
    ),
    title: 'Activate Invited User',
    type: 'login',
  },
  {
    path: '/auth/activate-account/:token',
    exact: true,
    component: loadable(() => import('../../../pages/Auth/ActivateAccount')),
    title: 'Activate Account',
    // needLogout: true,
    type: 'login',
  },
  {
    path: '/auth/verify-email/:token',
    exact: true,
    component: loadable(() => import('../../../pages/Auth/verifyEmail')),
    title: 'Email verification',
    type: 'login',
  },
  {
    path: '/auth/mailSent/:email',
    exact: true,
    component: loadable(() => import('../../../pages/Auth/mailSent')),
    title: 'Mail Sent',
    needLogout: true,
    type: 'login',
  },
  {
    path: '/auth/forgot-password',
    component: loadable(() =>
      import('../../../pages/Auth/ResetPassword/ForgotPassword')
    ),
    exact: true,
    title: 'Reset your password',
    type: 'resetPassword',
  },
  {
    path: '/auth/reset-password/:email/:token',
    component: loadable(() =>
      import('../../../pages/Auth/ResetPassword/ResetPassword')
    ),
    title: 'Create a new password',
    type: 'resetPassword',
  },
  {
    path: '/auth/sent-confirm',
    component: loadable(() =>
      import('../../../pages/Auth/ResetPassword/SentEmailConfirm')
    ),
    exact: true,
    title: 'Reset your password',
    type: 'resetPassword',
  },
]

const AuthRoutes = () => (
  <Switch>
    {routes.map(route => {
      const { path, type } = route
      return (
        <Route
          key={path}
          path={path}
          component={() => {
            return type === 'register' ? (
              <ClientRegisterPage {...route} />
            ) : type === 'login' ? (
              <AuthPage {...route} />
            ) : (
              <ResetPasswordPage {...route} />
            )
          }}
        />
      )
    })}
    <Redirect from='/auth' to='/auth/login' />
  </Switch>
)

export default withRouter(AuthRoutes)

export { routes }
