import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useGetTableData } from 'hooks'
import LoadingSpinner from '../LoadingSpinner'
import {
  getLabelFromValue,
  getOptionFromValue,
  isMobile,
  numberWithCommas,
} from '../../helpers/index'
import { defaultCurrencies } from '../../constants'
import FormSelectWithIcon from 'components/FormSelectWithIcon'
import ValueChangeIndicator from './ValueChangeIndicator'

const isMobileBrowser = isMobile()

const Earnings = ({
  companyId,
  dates,
  isSupplier = false,
  partnerToken,
  isPaymentSection = false,
  isAdmin = false,
}) => {
  const [activeTab, setActiveTab] = useState(2)
  const [monthsList, setMonthsList] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [companyURL, setCompanyURL] = useState(null)

  const initialFilters = useMemo(() => ({ year: '', month: '' }), [])

  const {
    data,
    isLoading,
    filters,
    setFilters,
    onFiltersChangeSelect,
  } = useGetTableData(companyURL, initialFilters, {})

  const {
    transfersTotal,
    byTheHourTotal,
    carRentalsTotal,
    meetAndGreetTotal,
    total,
    transfersGrowth,
    byTheHourGrowth,
    carRentalsGrowth,
    meetAndGreetGrowth,
    totalGrowth,

    totalPaidout,
    totalPending,
    companyCurrency = '',

    firstMonth,
    firstYear,
    lastMonth,
    lastYear,
  } = data?.list ?? {}

  const displayCurrency = useMemo(
    () => getLabelFromValue(defaultCurrencies, companyCurrency),
    [companyCurrency]
  )

  const handleTabClick = tab => {
    setActiveTab(tab)
    if (tab === 1) {
      setFilters(prevFilters => ({
        ...prevFilters,
      }))
    } else if (tab === 2) {
      setFilters(prevFilters => ({
        ...prevFilters,
      }))
    }
    // Handle other tabs like 'total' as needed
  }

  useEffect(() => {
    if (dates && companyId) {
      // setFilters({ year: '', month: '' })
      setFilters({ year: `${dates.lastYear}`, month: '' })
      setCompanyURL(`earnings/${companyId}`)

      // Generate year list from first commission year to last commission year
      const startYear = dates.firstYear
      const years = Array.from(
        { length: dates.lastYear - dates.firstYear + 1 },
        (_, i) => ({
          label: String(startYear + i),
          value: String(startYear + i),
        })
      )

      years.unshift({
        label: 'All',
        value: '',
      })

      setYearsList(years)

      // Generate month list from January to the last month
      const months = Array.from({ length: dates.lastMonth }, (_, i) => ({
        label: new Date(2000, i, 1).toLocaleString('default', {
          month: 'long',
        }),
        value: String(i + 1),
      }))

      months.unshift({
        label: 'All',
        value: '',
      })

      setMonthsList(months)
    } else if (companyId) {
      setFilters({ year: '', month: '' })
      setCompanyURL(`earnings/${companyId}`)
    }
  }, [dates])

  useEffect(() => {
    if (dates && filters.year) {
      if (parseInt(filters.year) === dates.firstYear) {
        // Generate month list starting from the first month of the commission year
        const months = Array.from(
          { length: 12 - (dates.firstMonth - 1) },
          (_, i) => ({
            label: new Date(
              2000,
              dates.firstMonth - 1 + i,
              1
            ).toLocaleString('default', { month: 'long' }),
            value: String(dates.firstMonth + i),
          })
        )

        months.unshift({
          label: 'All',
          value: '',
        })

        setMonthsList(months)
      } else if (parseInt(filters.year) === dates.lastYear) {
        // Generate month list up to the last month of the commission year
        const months = Array.from({ length: dates.lastMonth }, (_, i) => ({
          label: new Date(2000, i, 1).toLocaleString('default', {
            month: 'long',
          }),
          value: String(i + 1),
        }))

        months.unshift({
          label: 'All',
          value: '',
        })

        setMonthsList(months)
      } else {
        // Show all 12 months
        const months = Array.from({ length: 12 }, (_, i) => ({
          label: new Date(2000, i, 1).toLocaleString('default', {
            month: 'long',
          }),
          value: String(i + 1),
        }))

        months.unshift({
          label: 'All',
          value: '',
        })

        setMonthsList(months)
      }
    } else {
      // Show all 12 months
      const months = Array.from({ length: 12 }, (_, i) => ({
        label: new Date(2000, i, 1).toLocaleString('default', {
          month: 'long',
        }),
        value: String(i + 1),
      }))

      months.unshift({
        label: 'All',
        value: '',
      })

      setMonthsList(months)
    }
  }, [filters.year])

  if (isMobileBrowser) {
    return (
      <>
        {companyId && !isPaymentSection ? (
          <>
            {isAdmin && isSupplier ? (
              <h4 className='sub-header'> Partner's Earnings </h4>
            ) : (
              <h4 className='sub-header'> Earnings </h4>
            )}
          </>
        ) : (
          <></>
        )}
        {!firstYear ? (
          <LoadingSpinner />
        ) : (
          <div class='container-fluid'>
            <Row style={{ maxWidth: '1000px' }}>
              <Col
                md={3}
                className='pl-0 pr-0'
                onClick={() => handleTabClick(2)}
              >
                <FormSelectWithIcon
                  field={{
                    value: getOptionFromValue(yearsList, filters['year']),
                    name: 'year-search',
                  }}
                  handleChange={onFiltersChangeSelect('year')}
                  options={[...yearsList]}
                  text='Year'
                  inputClass={`form-select-icon ${
                    activeTab === 2 ? 'active-tab' : ''
                  }`}
                />
              </Col>
              <Col
                md={3}
                className='pl-0 pr-0'
                onClick={() => handleTabClick(1)}
              >
                <FormSelectWithIcon
                  field={{
                    value: getOptionFromValue(monthsList, filters['month']),
                    name: 'month-search',
                  }}
                  handleChange={onFiltersChangeSelect('month')}
                  options={[...monthsList]}
                  text='Month'
                  inputClass={`form-select-icon ${
                    activeTab === 1 ? 'active-tab' : ''
                  }`}
                />
              </Col>
            </Row>
          </div>
        )}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div class='earnings-container pr-0'>
              <div class=' grid-item'>
                <div class='card-header'>Total</div>
                <div class='card-body' style={{ fontSize: '24px' }}>
                  <span class='amount'>
                    {total
                      ? numberWithCommas(parseFloat(total).toFixed(2))
                      : '0.00'}
                  </span>
                  <span class='currency'>{displayCurrency}</span>
                </div>
                <div class='card-bottom'>
                  <ValueChangeIndicator percentage={totalGrowth} />
                </div>
              </div>
              <div class='grid-item'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px',
                    marginBottom: '0.5em',
                  }}
                >
                  <div class='card-header mb-0'>Paid out</div>
                  <div style={{ fontSize: '18px' }}>
                    <span style={{ marginRight: '0.3em' }} class='amount'>
                      {totalPaidout
                        ? numberWithCommas(parseFloat(totalPaidout).toFixed(2))
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                </div>
                <div class='card-bottom'>
                  <ValueChangeIndicator percentage={totalGrowth} />
                </div>
              </div>
              <div class='grid-item'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px',
                    marginBottom: '0.5em',
                  }}
                >
                  <div class='card-header mb-0'>Expected</div>
                  <div style={{ fontSize: '18px' }}>
                    <span style={{ marginRight: '0.3em' }} class='amount'>
                      {totalPending
                        ? numberWithCommas(parseFloat(totalPending).toFixed(2))
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                </div>
                <div class='card-bottom'>
                  <ValueChangeIndicator percentage={totalGrowth} />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ width: '50%', marginRight: '15px' }}
                  class='grid-item'
                >
                  <div class='card-header'>Transfers</div>
                  <div class='card-body'>
                    <span class='amount'>
                      {transfersTotal
                        ? numberWithCommas(
                            parseFloat(transfersTotal).toFixed(2)
                          )
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                  <div class='card-bottom'>
                    <ValueChangeIndicator percentage={transfersGrowth} />
                  </div>
                </div>
                <div style={{ width: '50%' }} class='grid-item'>
                  <div class='card-header'>By the hour</div>
                  <div class='card-body'>
                    <span class='amount'>
                      {byTheHourTotal
                        ? numberWithCommas(
                            parseFloat(byTheHourTotal).toFixed(2)
                          )
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                  <div class='card-bottom'>
                    <ValueChangeIndicator percentage={byTheHourGrowth} />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ width: '50%', marginRight: '15px' }}
                  class='grid-item'
                >
                  <div class='card-header'>Car rental</div>
                  <div class='card-body'>
                    <span class='amount'>
                      {carRentalsTotal
                        ? numberWithCommas(
                            parseFloat(carRentalsTotal).toFixed(2)
                          )
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                  <div class='card-bottom'>
                    <ValueChangeIndicator percentage={carRentalsGrowth} />
                  </div>
                </div>
                <div style={{ width: '50%' }} class='grid-item '>
                  <div class='card-header'>VIP Meet & Greet</div>
                  <div class='card-body'>
                    <span class='amount'>
                      {meetAndGreetTotal
                        ? numberWithCommas(
                            parseFloat(meetAndGreetTotal).toFixed(2)
                          )
                        : '0.00'}
                    </span>
                    <span class='currency'>{displayCurrency}</span>
                  </div>
                  <div class='card-bottom'>
                    <ValueChangeIndicator percentage={meetAndGreetGrowth} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {companyId && !isPaymentSection ? (
        <>
          {isAdmin && isSupplier ? (
            <h4 className='sub-header'> Partner's Earnings </h4>
          ) : (
            <h4 className='sub-header'> Earnings </h4>
          )}
        </>
      ) : (
        <></>
      )}
      {!firstYear ? (
        <LoadingSpinner />
      ) : (
        <div class='container-fluid'>
          <Row className='mb-4' style={{ maxWidth: '1000px' }}>
            <Col md={3} className='pl-0' onClick={() => handleTabClick(2)}>
              <FormSelectWithIcon
                field={{
                  value: getOptionFromValue(yearsList, filters['year']),
                  name: 'year-search',
                }}
                handleChange={onFiltersChangeSelect('year')}
                options={[...yearsList]}
                text='Year'
                inputClass={`form-select-icon ${
                  activeTab === 2 ? 'active-tab' : ''
                }`}
              />
            </Col>
            <Col md={3} className='pl-0' onClick={() => handleTabClick(1)}>
              <FormSelectWithIcon
                field={{
                  value: getOptionFromValue(monthsList, filters['month']),
                  name: 'month-search',
                }}
                handleChange={onFiltersChangeSelect('month')}
                options={[...monthsList]}
                text='Month'
                inputClass={`form-select-icon ${
                  activeTab === 1 ? 'active-tab' : ''
                }`}
              />
            </Col>
          </Row>
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div class='earnings-container'>
            <div class='top-left grid-item'>
              <div class='card-header'>Total</div>
              <div class='card-body' style={{ fontSize: '24px' }}>
                <span class='amount'>
                  {total
                    ? numberWithCommas(parseFloat(total).toFixed(2))
                    : '0.00'}
                </span>
                <span class='currency'>{displayCurrency}</span>
              </div>
              <div class='card-bottom'>
                <ValueChangeIndicator percentage={totalGrowth} />
              </div>
            </div>
            <div class='top-right'>
              <div class='grid-item pending-expected'>
                <div class='card-header mb-0'>Paid out</div>
                <div class='pending-expected-body' style={{ fontSize: '18px' }}>
                  <span class='amount'>
                    {totalPaidout
                      ? numberWithCommas(parseFloat(totalPaidout).toFixed(2))
                      : '0.00'}
                  </span>
                  <span class='currency'>{displayCurrency}</span>
                </div>
              </div>
              <div class='grid-item pending-expected'>
                <div class='card-header mb-0'>Expected</div>
                <div class='pending-expected-body' style={{ fontSize: '18px' }}>
                  <span class='amount'>
                    {totalPending
                      ? numberWithCommas(parseFloat(totalPending).toFixed(2))
                      : '0.00'}
                  </span>
                  <span class='currency'>{displayCurrency}</span>
                </div>
              </div>
            </div>
            <div class='bottom-left grid-item'>
              <div class='card-header'>Transfers</div>
              <div class='card-body'>
                <span class='amount'>
                  {transfersTotal
                    ? numberWithCommas(parseFloat(transfersTotal).toFixed(2))
                    : '0.00'}
                </span>
                <span class='currency'>{displayCurrency}</span>
              </div>
              <div class='card-bottom'>
                <ValueChangeIndicator percentage={transfersGrowth} />
              </div>
            </div>
            <div class='bottom-center-left grid-item'>
              <div class='card-header'>By the hour</div>
              <div class='card-body'>
                <span class='amount'>
                  {byTheHourTotal
                    ? numberWithCommas(parseFloat(byTheHourTotal).toFixed(2))
                    : '0.00'}
                </span>
                <span class='currency'>{displayCurrency}</span>
              </div>
              <div class='card-bottom'>
                <ValueChangeIndicator percentage={byTheHourGrowth} />
              </div>
            </div>
            <div class='bottom-center-right grid-item'>
              <div class='card-header'>Car rental</div>
              <div class='card-body'>
                <span class='amount'>
                  {carRentalsTotal
                    ? numberWithCommas(parseFloat(carRentalsTotal).toFixed(2))
                    : '0.00'}
                </span>
                <span class='currency'>{displayCurrency}</span>
              </div>
              <div class='card-bottom'>
                <ValueChangeIndicator percentage={carRentalsGrowth} />
              </div>
            </div>
            <div class='bottom-right grid-item '>
              <div class='card-header'>VIP Meet & Greet</div>
              <div class='card-body'>
                <span class='amount'>
                  {meetAndGreetTotal
                    ? numberWithCommas(parseFloat(meetAndGreetTotal).toFixed(2))
                    : '0.00'}
                </span>
                <span class='currency'>{displayCurrency}</span>
              </div>
              <div class='card-bottom'>
                <ValueChangeIndicator percentage={meetAndGreetGrowth} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

// export default Earnings
export default withRouter(connect(null, {})(Earnings))
