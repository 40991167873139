import React, { useState, useRef, useEffect } from 'react'
import Scrollbar from 'react-scrollbars-custom'
import { isMobile } from '../../../../../helpers'
import _ from 'lodash'
import MessageInputBot from './MessageInputBot'
import moment from 'moment'

const isMobileBrowser = isMobile()

export default function ChatBotRoom({ companyLogo }) {
  const listRef = useRef(null)
  const [keyboadOpen, setkeyboadOpen] = useState(false)
  const [messages, setMessages] = useState([
    {
      text: "Hello! How can I assist you today with Rolzo's services?",
      type: 'bot',
      createdAt: new Date(),
      user: {
        firstName: 'Rolzo',
        lastName: 'Assistant',
        image: '',
      },
    },
  ])

  useEffect(() => {
    if (messages.length > 5) {
      listRef.current.scrollToBottom()
    }
  }, [messages])

  const updateKeyboadState = res => {
    setkeyboadOpen(res)
    if (listRef && listRef.current) {
      listRef.current.scrollToBottom()
    }
  }

  return (
    <div
      className={`client-chat-room ${
        isMobileBrowser && keyboadOpen
          ? 'client-chat-room-open'
          : isMobileBrowser && !keyboadOpen
          ? 'client-chat-room-close'
          : ''
      }`}
    >
      <div className='client-messages-list mx-auto'>
        <Scrollbar
          ref={listRef}
          onScroll={null}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {messages &&
            messages.map(({ type, text, user, createdAt }, i) => {
              return (
                <div key={i}>
                  <div
                    className={`message message-${i} ${
                      type === 'user' ? 'is-current' : ''
                    }`}
                  >
                    <div className='user-avatar'>
                      {type === 'user' ? (
                        <>
                          {user.image || companyLogo ? (
                            <img src={user.image || companyLogo} alt='' />
                          ) : (
                            <div className='default-logo'>
                              {`${user.firstName.substring(0, 1)}${
                                user.lastName
                                  ? user.lastName.substring(0, 1)
                                  : ''
                              }`}
                            </div>
                          )}
                        </>
                      ) : (
                        <img
                          src={'/images/icons/chat/rolzo_avatar.png'}
                          alt=''
                          className='rolzo-logo'
                        />
                      )}
                    </div>
                    <div className='text-wrap'>
                      <div className='text'>
                        <>{text}</>
                      </div>
                      <div className='message-info-bottom'>
                        <div className='message-info'>
                          {user.firstName +
                            ', ' +
                            moment(createdAt).format('HH:mm')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </Scrollbar>
      </div>
      <div className='client-message-input-block'>
        <div className='chat-footer-container'>
          <MessageInputBot
            isMobileBrowser={isMobileBrowser ? true : false}
            updateKeyboadState={updateKeyboadState}
            messages={messages}
            setMessages={setMessages}
          ></MessageInputBot>
        </div>
      </div>
    </div>
  )
}
