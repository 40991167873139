import React, { useCallback, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MentionsTextarea from '../../../../../components/MentionsTextarea'
import { showNotification } from '../../../../../actions'
import {
  sendCommandSendMessageToChat,
  sendCommandTyping,
  emptyIsLateRequestState,
} from '../../../../../actions'
import _ from 'lodash'

const apiHost = process.env.REACT_APP_APIHOST

let urls = {
  'https://staging.rolzo.com/api': 'stag-bot.rolzo.com',
  'https://business.rolzo.com/api': 'bot.rolzo.com',
  'http://localhost:3000': 'stag-bot.rolzo.com',
}
let botUrl = urls[apiHost]

const rolzoData =
  'Rolzo is a global chauffeur company. Rolzo offers over 4 services: By the hour, transfer, car rentals and VIP meet and greet. You can book a vehicle with Rolzo in more than 100 countries. Rolzo operates in 750 cities worldwide. You can book a vehicle at 1,000 airports through Rolzo. Book a car rental with delivery and collection anytime, anywhere. Book a professional chauffeur by the hour for maximum flexibility. Book a professional chauffeur for a one-way or return transfer. Multi-stop transportation is a car service that involves multiple stops in addition to the initial pick-up and the final destination. This service allows for various activities, such as picking up additional passengers or making deliveries along the route. VIP Meet and Greet is where a dedicated greeter attends to you every step of the way, ensuring a smooth and satisfying journey through the airport.'

const MessageInputBot = ({
  isMobileBrowser,
  updateKeyboadState,
  messages,
  setMessages,
  firstName,
  activeUserId,
  lastName,
  companyId,
}) => {
  const [newMessage, setNewMessage] = useState('')
  const [loading, setLoading] = useState(false)

  let optionsTwo = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer sk-proj-zdH0B8DmW2S4BJY7LQUBT3BlbkFJFWsd4YAyb3hrzMzedpio',
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: [
        {
          role: 'system',
          content: `You are a MongoDB expert. Backend is Javascript. Your job is to convert a prompt to valid JSON for mongo query (use only $match and $project). ONLY fetch WHERE userId is ${activeUserId}. Here is the schema: pickUpLocation.fullAddress, dropOffLocation.city, status, type, rate (fetch 'currency: 1' along with rate), currency, userName, companyName, number, vehicleName, createdAt, pickUpDate, userId. When asked about driver or chauffeur, fetch these 3 fields: chauffeur, chauffeurName, chauffeurPhone. Always use $regex when fetching by number or vehicleName or companyName or pickUpLocation.fullAddress or dropOffLocation.city or currency. Here is the prompt: ${newMessage}. You will only return valid JSON. Do not wrap the json codes in JSON markers. If the prompt is asking for documents count, always use 'totalCount' as the name inside the pipeline. Also, use $group if asked about count. Remember that each stage in the MongoDB aggregation pipeline must be an object with a single key that specifies the operation type. There are 5 types of bookings: departure_transfer, return_transfer, car_rentals, chauffeur_services, airport_transfer. There are 4 main booking statuses are: in_review, confirmed, completed, unavailable, transaction_declined, cancellation_requested_by_client, cancelled_by_agent, change_request, quote_expired, quote.`,
        },
        {
          role: 'user',
          content: newMessage,
        },
      ],
    }),
  }

  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer sk-proj-zdH0B8DmW2S4BJY7LQUBT3BlbkFJFWsd4YAyb3hrzMzedpio',
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: [
        {
          role: 'system',
          content: `You are a bot made for Rolzo, a global chauffeur company. Rolzo offers 4 services. Analyze this prompt and return the appropriate keyword. Prompt: ${newMessage}. If about booking, just return 'booking'. If about companies, just return 'company'. If about Rolzo or service, just return 'rolzo'.`,
        },
        {
          role: 'user',
          content: newMessage,
        },
      ],
    }),
  }

  let optionsThree = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer sk-proj-zdH0B8DmW2S4BJY7LQUBT3BlbkFJFWsd4YAyb3hrzMzedpio',
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: [
        {
          role: 'system',
          content: `You are Rolzo expert. You are to answer any queries a user might have regarding Rolzo. Always acknowledge Rolzo as 'Rolzo', not 'ROLZO.' Here is the rolzo-data: ${rolzoData}`,
        },
        {
          role: 'user',
          content: newMessage,
        },
      ],
    }),
  }

  let optionsFour = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer sk-proj-zdH0B8DmW2S4BJY7LQUBT3BlbkFJFWsd4YAyb3hrzMzedpio',
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: [
        {
          role: 'system',
          content: `You are a MongoDB expert. Backend is Javascript. Your job is to convert a prompt to valid JSON for mongo query (use only $match and $project). ONLY fetch where _id is ${companyId}. Here is the schema: name, contact.email, contact.phone, place.city, place.country, settings, type, createdAt, status, settings, active, companySize, industry, isRolzoFirstMember. If any question regarding payment, use the settings field. Here is the prompt: ${newMessage}. You will only return valid JSON. Do not wrap the json codes in JSON markers. Remember that each stage in the MongoDB aggregation pipeline must be an object with a single key that specifies the operation type. There are 2 types of companies: supplier and client.`,
        },
        {
          role: 'user',
          content: newMessage,
        },
      ],
    }),
  }

  const onSendMessage = async evt => {
    evt.preventDefault()

    if (newMessage.length > 0) {
      let obj = {
        text: newMessage,
        type: 'user',
        createdAt: new Date(),
        user: {
          firstName: firstName,
          lastName: lastName,
          image: '',
        },
      }
      setMessages([...messages, obj])
      setLoading(true)
      setNewMessage('')

      const response = await fetch(
        'https://api.openai.com/v1/chat/completions',
        options
      )

      if (response.status === 200) {
        const data = await response.json()

        if (data.choices[0].message.content.trim() === 'booking') {
          const bookingDataFetch = await fetch(
            'https://api.openai.com/v1/chat/completions',
            optionsTwo
          )

          const dataTwo = await bookingDataFetch.json()

          const getBookingData = await fetch(`https://${botUrl}/booking`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: dataTwo.choices[0].message.content,
          })

          const bookingData = await getBookingData.json()

          const botMessage = {
            text: bookingData.answer,
            type: 'bot',
            createdAt: new Date(),
            user: {
              firstName: 'Rolzo',
              lastName: 'Assistant',
              image: '',
            },
          }

          setMessages(prevMessages => [...prevMessages, botMessage])
          setNewMessage('')
          setLoading(false)
        } else if (data.choices[0].message.content.trim() === 'rolzo') {
          const rolzoDataFetch = await fetch(
            'https://api.openai.com/v1/chat/completions',
            optionsThree
          )

          const jsonRolzoData = await rolzoDataFetch.json()

          const botMessage = {
            text: jsonRolzoData.choices[0].message.content.trim(),
            type: 'bot',
            createdAt: new Date(),
            user: {
              firstName: 'Rolzo',
              lastName: 'Assistant',
              image: '',
            },
          }

          setMessages(prevMessages => [...prevMessages, botMessage])
          setNewMessage('')
          setLoading(false)
        } else if (data.choices[0].message.content.trim() === 'company') {
          const companyDataFetch = await fetch(
            'https://api.openai.com/v1/chat/completions',
            optionsFour
          )

          const dataTwo = await companyDataFetch.json()

          const getCompanyData = await fetch(`https://${botUrl}/company`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: dataTwo.choices[0].message.content,
          })

          const companyData = await getCompanyData.json()

          const botMessage = {
            text: companyData.answer,
            type: 'bot',
            createdAt: new Date(),
            user: {
              firstName: 'Rolzo',
              lastName: 'Assistant',
              image: '',
            },
          }

          setMessages(prevMessages => [...prevMessages, botMessage])
          setNewMessage('')
          setLoading(false)
        } else {
          const botMessage = {
            text: data.choices[0].message.content.trim(),
            type: 'bot',
            createdAt: new Date(),
            user: {
              firstName: 'Rolzo',
              lastName: 'Assistant',
              image: '',
            },
          }

          setMessages(prevMessages => [...prevMessages, botMessage])
          setNewMessage('')
          setLoading(false)
        }
      } else {
        const errorMessage = {
          text: 'Sorry, I could not process your request at this time.',
          type: 'bot',
          createdAt: new Date(),
          user: {
            firstName: 'Rolzo',
            lastName: 'Assistant',
            image: '',
          },
        }
        setMessages(prevMessages => [...prevMessages, errorMessage])
        setNewMessage('')
        setLoading(false)
      }
    }
    return
  }

  const lostChatBoxFocus = useCallback(evt => {
    if (newMessage !== '' && newMessage) {
      if (evt.relatedTarget && evt.relatedTarget.className === 'send-btn') {
        onSendMessage(evt)
      }
    }
    setTimeout(() => {
      document.body.classList.remove('chat-box-focus')
      document
        .getElementsByClassName('app-content')[0]
        .classList.remove('keyboard-open')
      updateKeyboadState(false)
    }, 1000)
  })

  const setChatBoxFocus = useCallback(() => {
    document.body.classList.add('chat-box-focus')
    updateKeyboadState(true)
  })

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onSendMessage(event)
    }
  }

  return (
    <>
      <div className='message-input-toolbar client-toolbar'>
        <div className='send-avatar-wrap'>
          <div className='send-avatar'>
            <img src={'/images/icons/chat/rolzo_avatar.png'} alt='' />
          </div>
        </div>
        <div className='message-input-wrap client-input-wrap'>
          <div className='textarea-chat-control'>
            <MentionsTextarea
              onChange={e => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              value={newMessage}
              onFocus={setChatBoxFocus}
              onBlur={lostChatBoxFocus}
              isClient={true}
              placeholder='Type a message...'
              readOnly={loading}
            />
          </div>
        </div>

        <div className='send-btn-wrap pr-4'>
          <a
            href='/'
            className='send-btn'
            disabled={false}
            onClick={evt => {
              onSendMessage(evt)
            }}
          >
            <img src='/images/icons/chat/send.png' alt='' />
          </a>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({
  appWebsocket,
  auth: { firstName, lastName, companyId } = {},
} = {}) => ({
  firstName,
  lastName,
  companyId,
  activeUserId: appWebsocket.activeUserId,
})

export default withRouter(
  connect(mapStateToProps, {
    sendCommandSendMessageToChat,
    sendCommandTyping,
    emptyIsLateRequestState,
    showNotification,
  })(MessageInputBot)
)
