import { createOrEditItem } from './form'
import { connectedApiService as api } from '..'
import { logger } from './logger'
import { handleActionError } from './helpers'

export const updateAccountStatement = values => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        items: values,
      },
      {
        endpoint: 'updateStatment',
        successMessage: 'Invoice was successfully updated',
      }
    )
  )

export const generateAccountInvoice = (info, values) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        companyInfo: info,
        items: values,
      },
      {
        endpoint: 'generateStatementOfAccountInvoice',
        successMessage: 'Invoice was successfully generated',
      }
    )
  )

export const generateExcelFile = (payoutId, type) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'get',
        endpoint: `generateExcelSheet/${payoutId}/${type}`,
        successMessage: 'Excel sheet was successfully generated',
      }
    )
  )

export const getExcelFileBuffer = (payoutId, type) => async () => {
  try {
    const url = `generateExcelSheet/${payoutId}/${type}`
    const { data } = await api.get(url)

    return { buffer: data }
  } catch (err) {
    logger({ fileName: 'check discount error', error: err })
    handleActionError(err)
  }
}
