import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import {
  requiredStringValidator,
  emailValidator,
} from '../../../../../form-validation'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import { isMobile } from '../../../../../helpers'
const isMobileBrowser = isMobile()
const Host = window.location.hostname
const fields = [
  {
    name: 'email',
    label: 'EMAIL*',
    icon: 'account_circle',
  },
]

const validationSchema = Yup.object().shape({
  email: requiredStringValidator().concat(emailValidator()),
})

const initialValues = {
  email: '',
}

function LoginPartner(props) {
  if (props.isLoggedIn) {
    props.history.push(
      `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${props.partnerToken}/home-page`
    )
  }
  const oldUrl = props.state && props.state.oldUrl ? props.state.oldUrl : null
  return (
    <>
      <div className='auth-page'>
        <div className='image-container-new-partner' />
        <div className='auth-container'>
          <AuthForm
            title='Log in'
            initialValues={initialValues}
            validationSchema={validationSchema}
            fields={fields}
            isLoginUser
            buttonLabel='Continue'
            submitStyle='login-btn'
            oldUrl={oldUrl}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth, clientAuth }) => {
  const { isLoggedIn, partnerToken } = auth

  return { isLoggedIn, partnerToken }
}

export default connect(mapStateToProps, {})(LoginPartner)
