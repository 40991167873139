import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { isMobile } from '../helpers/index.js'

export default function FilterButton({
  className = '',
  title = 'Select',
  value = '',
  disabled = false,
  clientId = '',
  handleAction,
  history,
  memberBenefitsApplied,
  selectedVehicle,
  hidden = false,
  ...props
}) {
  const isMobileBrowser = isMobile()

  const onAction = () => {
    handleAction()
  }
  return (
    <>
      {isMobileBrowser ? (
        <>
          {title === 'Clear all' ? (
            <Button
              variant='link'
              className='clear-filter-btn'
              onClick={onAction}
            >
              {title}
            </Button>
          ) : (
            <div className='d-flex align-items-center filter-btn pr-0 mr-2 mb-2'>
              <span
                style={{
                  paddingRight: '0.3rem',
                  marginRight: '0.1rem',
                  marginLeft: '0.1rem',
                }}
              >
                <span
                  className='btnTitle'
                  style={{ fontWeight: 'bolder !important', fontSize: '12px' }}
                >
                  {title}:
                </span>
                <span
                  className='btnValue'
                  style={{
                    fontWeight: 'lighter !important',
                  }}
                >
                  {' '}
                  {value}
                </span>
              </span>
              <Button
                variant='link'
                className=' filter-close-btn mt-0 mr-0 bl-1'
                onClick={onAction}
              >
                <img
                  style={{ maxWidth: '23px' }}
                  src='/images/icons/close.svg'
                  alt=''
                />
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          {title === 'Clear all' ? (
            <Button
              variant='link'
              className='clear-filter-btn'
              onClick={onAction}
            >
              {title}
            </Button>
          ) : (
            <div className='d-flex align-items-center filter-btn pr-0 mr-2 mb-2'>
              <span style={{ paddingRight: '0.3rem' }}>
                <span className='btnTitle'>{title}:</span>
                <span className='btnValue'> {value}</span>
              </span>
              <Button
                variant='link'
                className=' filter-close-btn mt-0 mr-0 bl-1'
                onClick={onAction}
              >
                <img
                  style={{ maxWidth: '23px' }}
                  src='/images/icons/close.svg'
                  alt=''
                />
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}
