import {
  IS_VEHICLE_SEARCH_LATE,
  IS_VEHICLE_SEARCH_NOT_LATE,
  SET_VEHICLE_SEARCH_EMPTY,
} from '../actions'

const INITIAL_STATE = {
  searchValue: null,
  isLateSearch: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_VEHICLE_SEARCH_LATE:
      state.searchValue = action.payload || null
      state.isLateSearch = true
      return state
    case IS_VEHICLE_SEARCH_NOT_LATE:
      return {
        ...state,
        searchValue: null,
        isLateSearch: false,
      }
    default:
      return state
  }
}
