import {
  changePasswordFormData,
  myProfileFormData,
  notificationSettingsData,
  currencySettingsData,
  pricingData,
  billingSettingsData,
} from '../models/user'
import { setUser } from './auth'
import { createOrEditItem, deleteItem } from './form'
import { handleActionError } from './helpers'

export const editMyProfile = (values, companyId, userId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}`,
      formatFormData: myProfileFormData,
      successMessage: 'Your profile was successfully updated',
    })
  )
export const editNotificationSettings = (
  values,
  companyId,
  userId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}/notificationSettings`,
      formatFormData: notificationSettingsData,
      successMessage: 'Your notification settings were successfully updated.',
    })
  )
export const editNotificationSettingsAllUsers = (
  values,
  companyId,
  userId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}/notificationSettings`,
      formatFormData: notificationSettingsData,
      successMessage:
        'The notification settings of all team members were successfully updated.',
    })
  )

export const editCurrencySettings = (
  value,
  companyId,
  userId
) => async dispatch =>
  await dispatch(
    createOrEditItem(value, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}/currencySettings`,
      formatFormData: currencySettingsData,
      successMessage: 'Your currency settings were successfully updated.',
    })
  )
export const editUserPricing = (value, companyId, userId) => async dispatch =>
  await dispatch(
    createOrEditItem(value, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}/pricing`,
      formatFormData: pricingData,
      successMessage: 'Your pricing model has been successfully updated.',
    })
  )
export const editBillingSettings = (
  value,
  companyId,
  userId
) => async dispatch =>
  await dispatch(
    createOrEditItem(value, {
      isEdit: true,
      endpoint: `company/client/${companyId}/user/${userId}/billing`,
      formatFormData: billingSettingsData,
      successMessage: 'Your billing settings were successfully updated.',
    })
  )
export const changeUserPassword = (
  values,
  companyId,
  userId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `company/client/${companyId}/user/${userId}/password`,
      formatFormData: changePasswordFormData,
      successMessage: 'Your password was successfully changed',
    })
  )

export const deleteCompanyUser = (companyId, userId) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `company/client/${companyId}/user/${userId}`,
      successMessage: 'User successfully deleted',
    })
  )

// export const deleteUserAccount = userId => async dispatch =>
//   await dispatch(
//     deleteItem({
//       endpoint: `user/${userId}/account`,
//       successMessage: 'User successfully deleted',
//     })
//   )

export const deleteUserAccount = userId => async dispatch => {
  try {
    await dispatch(
      deleteItem({
        endpoint: `user/${userId}/account`,
        successMessage: 'User successfully deleted',
      })
    )
    await dispatch(setUser())
  } catch (error) {
    return handleActionError(error)
  }
}
// export const editPassengerInfoSettings = (value, userId) => async dispatch =>
//   await dispatch(
//     createOrEditItem(
//       { value: value },
//       {
//         isEdit: true,
//         endpoint: `company/passengerInfoSettings/${userId}`,
//       }
//     )
//   )
