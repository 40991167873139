import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import {
  clearClientData,
  createPartner,
  registerClientUser,
  setClientUserDetails,
  setMessageOpen,
} from '../../../../../actions'
import FormInputPhoneNew, {
  phoneNumberRequiredValidator,
  phoneNumberValidator,
} from '../../../../../components/FormInputPhoneNew'
import './../../../../../assets/styles/pages/_clientAuth.scss'
import {
  requiredStringValidator,
  emailValidator,
  stringValidator,
  booleanValidator,
  requiredObject,
} from '../../../../../form-validation'
import LocationAutocomplete from 'components/LocationAutocomplete'
import FormSelect from '../../../../../components/FormSelect'
import {
  defaultCurrencies,
  defaultFleetOptions,
  defaultPassengerTitles,
  europeanCountriesCodes,
} from '../../../../../constants/index'
import { isMobile } from '../../../../../helpers'
import { Button, Col } from 'react-bootstrap'
import FormSections from 'components/FormSections'
import FormCheckbox from 'components/FormCheckbox'
import SubmitBtn from 'components/SubmitBtn'
import { SmallUp } from 'components/Responsive/breakpoints'
import SuccessMsg from 'components/SuccessMsg'
import ResponsiveFixedComponents from 'components/Responsive/ResponsiveFixedComponents'

const vatCountries = ['GB', 'AE']
const gstCountries = ['AU', 'CA']
const jctCountries = ['JP']

const isMobileBrowser = isMobile()
const fields = [
  {
    name: 'place',
    label: 'City*',
    component: LocationAutocomplete,
    searchOptions: { types: ['(cities)'] },
    confirmOnSelect: true,
    needFullAddress: true,
    displayKey: 'fullAddress',
    showPin: true,
    needBounds: true,
  },
]

const getAddressOptions = place => {
  return {
    bounds: place ? place.bounds : null,
    componentRestrictions:
      place && place.country
        ? {
            country: place.country.toLowerCase(),
          }
        : null,
  }
}

function RegisterPartner({ history, createPartner, setMessageOpen, ...props }) {
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const [location, setLocation] = useState(false)

  //Validation schema
  const validationSchema = showRegisterForm =>
    showRegisterForm
      ? Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
          phoneNumber: Yup.string().required('Phone number is required'),
          companyName: Yup.string().required('Company name is required'),
          fleetSize: Yup.object()
            .required('Fleet size is required')
            .nullable(),
          website: Yup.string()
            .required('Website is required')
            .nullable(),
          // taxNumber: Yup.string().nullable(),
          isTaxRegistered: Yup.boolean().default(false),
          address: Yup.object()
            .required('Address is required')
            .nullable(),
          currency: Yup.object()
            .required('Currency is required')
            .nullable(),
          iban: Yup.string().when('currency', {
            is: currency => currency?.label === 'EUR',
            then: Yup.string().required('iBAN is required'),
          }),
          swiftCode: Yup.string().when('currency', {
            is: currency => currency?.label === 'EUR',
            then: Yup.string().required('Swift code is required'),
          }),

          accountNumber: Yup.string().when('currency', {
            is: currency => ['USD', 'GBP', 'AUD'].includes(currency?.label),
            then: Yup.string().required('Account Number is required'),
          }),
          routingNumber: Yup.string().when('currency', {
            is: currency => currency?.label === 'USD',
            then: Yup.string().required('Routing Number is required'),
          }),
          sortCode: Yup.string().when('currency', {
            is: currency => currency?.label === 'GBP',
            then: Yup.string().required('Sort Code is required'),
          }),
          institutionNumber: Yup.string().when('currency', {
            is: currency => currency?.label === 'CAD',
            then: Yup.string().required('Institution Number is required'),
          }),
          transitNumber: Yup.string().when('currency', {
            is: currency => currency?.label === 'CAD',
            then: Yup.string().required('Transit Number is required'),
          }),
          bsbCode: Yup.string().when('currency', {
            is: currency => currency?.label === 'AUD',
            then: Yup.string().required('Transit Number is required'),
          }),
        })
      : Yup.object().shape({
          place: Yup.string().required('Place is required'),
        })

  const initialValues = showRegisterForm
    ? {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        fleetSize: null, // or an appropriate default object
        website: '',
        taxNumber: '',
        isTaxRegistered: false,
        address: {
          city: '',
          state: '',
          country: '',
        },
        currency: null,
        // iban: '',
        // swiftCode: '',
      }
    : { place: '' }

  //Format tax label
  const country = location?.country || ''
  const isEurope = europeanCountriesCodes.includes(country)
  const taxLabel =
    vatCountries.includes(country) || isEurope
      ? 'VAT'
      : gstCountries.includes(country)
      ? 'GST'
      : jctCountries.includes(country)
      ? 'JCT'
      : 'tax'

  //Registration form fields
  const sections = [
    {
      title: 'Enter your personal information',
      ...(isMobileBrowser ? { sectionClass: 'pt-2' } : {}),
      fields: [
        {
          name: 'firstName',
          label: 'FIRST NAME*',
        },
        {
          name: 'lastName',
          label: 'LAST NAME*',
        },
        {
          name: 'email',
          label: 'EMAIL*',
          isRegisterEmail: true,
        },
        {
          name: 'phoneNumber',
          label: 'PHONE NUMBER*',
          component: FormInputPhoneNew,
        },
      ],
    },
    {
      title: 'Enter your business information',
      fields: [
        {
          name: 'companyName',
          label: 'COMPANY NAME*',
          inputClass: 'grey-field-input',
        },
        {
          name: 'fleetSize',
          label: 'FLEET SIZE*',
          component: FormSelect,
          options: defaultFleetOptions,
        },
        {
          name: 'address',
          label: 'ADDRESS LINE 1*',
          component: LocationAutocomplete,
          searchOptions: getAddressOptions(location),
          showPin: true,
          confirmOnSelect: true,
          needFullAddress: true,
          needBounds: false,
          displayKey: 'fullAddress',
        },
        {
          name: 'website',
          label: 'website*',
        },
        {
          name: `taxNumber`,
          label: `${taxLabel} number`,
        },
        {
          name: 'isTaxRegistered',
          label: `Business not ${taxLabel} registered`,
          component: FormCheckbox,
          className: 'd-flex align-items-center',
          groupClassName: 'mb-0',
        },
      ],
    },
    {
      title: 'Enter your bank information',
      fields: [
        {
          name: 'currency',
          label: 'CURRENCY',
          component: FormSelect,
          options: defaultCurrencies,
          // isSearchable: true,
        },
        {
          name: 'country',
          label: 'Country*',
          component: LocationAutocomplete,
          placeholder: 'Type country...',
          searchOptions: {
            types: ['administrative_area_level_3', 'political', 'locality'],
          },
          showPin: false,
          confirmOnSelect: true,
          needFullAddress: true,
          countriesList: true,
          needCityState: false,
          displayKey: 'fullAddress',
        },
        {
          name: 'iban',
          label: 'iBAN*',
        },
        {
          name: 'swiftCode',
          label: 'swift code*',
        },
      ],
    },
  ]

  const onSubmit = async (values, form) => {
    // if (values.currency) values.currency = values.currency.value
    values.place = location
    values.address = values.address.fullAddress
    values.fleetSize = values.fleetSize.label
    values.name = `${values.firstName.trim()} ${values.lastName.trim()}`
    values.company = `${values.companyName.trim()}`
    values.email = `${values.email.trim()}`

    delete values.companyName
    await createPartner(values)
    setMessageOpen({
      title: `Thank you`,
      message: isMobileBrowser
        ? `We will review your application and get back to \nyou at ${values.email}.`
        : `We will review your application and get back to you at ${values.email}.`,
      onlyMessage: true,
    })

    form.setSubmitting(false)
  }

  //scroll to the top of the page
  useEffect(() => {
    if (showRegisterForm) {
      window.scrollTo(0, 0)
    }
  }, [showRegisterForm])

  if (showRegisterForm) {
    return (
      <>
        <div className='auth-wrap'>
          <Formik
            {...props}
            initialValues={initialValues}
            validationSchema={validationSchema(showRegisterForm)}
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              isValid,
              isSubmitting,
              errors,
              setFieldTouched,
              touched,
            }) => {
              const handleBlur = () => {
                if (!touched.email && values.email) {
                  setFieldTouched('email', true, false)
                }
              }

              //Check the currency and set the appropriate fields
              if (values.currency && values.currency.label) {
                let newFields = []

                if (values.currency.label === 'USD') {
                  newFields = [
                    { name: 'accountNumber', label: 'Account Number*' },
                    { name: 'routingNumber', label: 'Routing Number*' },
                  ]
                } else if (values.currency.label === 'GBP') {
                  newFields = [
                    { name: 'sortCode', label: 'Sort Code*' },
                    { name: 'accountNumber', label: 'Account Number*' },
                  ]
                } else if (values.currency.label === 'CAD') {
                  newFields = [
                    { name: 'institutionNumber', label: 'Institution Number*' },
                    { name: 'transitNumber', label: 'Transit Number*' },
                  ]
                } else if (values.currency.label === 'EUR') {
                  newFields = [
                    { name: 'iban', label: 'iBAN*' },
                    { name: 'swiftCode', label: 'SWIFT Code*' },
                  ]
                } else {
                  newFields = [
                    { name: 'iban', label: 'iBAN*' },
                    { name: 'swiftCode', label: 'Swift code*' },
                  ]
                }

                sections.forEach(section => {
                  if (section.title === 'Enter your bank information') {
                    section.fields = [
                      {
                        name: 'currency',
                        label: 'CURRENCY*',
                        component: FormSelect,
                        options: defaultCurrencies,
                      },
                      {
                        name: 'country',
                        label: 'Country*',
                        component: LocationAutocomplete,
                        placeholder: 'Type country...',
                        searchOptions: {
                          types: [
                            'administrative_area_level_3',
                            'political',
                            'locality',
                          ],
                        },
                        showPin: false,
                        confirmOnSelect: true,
                        needFullAddress: true,
                        countriesList: true,
                        needCityState: false,
                        displayKey: 'fullAddress',
                      },
                      ...newFields,
                    ]
                  }
                })
              }
              return (
                <Form noValidate className='d-flex justify-content-center mt-0'>
                  <Col className='register-cool-responsive'>
                    {isMobileBrowser ? (
                      <span
                        onClick={() => setShowRegisterForm(false)}
                        className='layout-btn back-btn position-relative'
                        style={{ height: '1.5rem', display: 'inline-block' }}
                      >
                        <img
                          className='back-btn-img'
                          src={`/images/icons/back-arrow.svg`}
                          alt=''
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                    <FormSections
                      values={values}
                      sections={sections}
                      inputClass='grey-field-input'
                      handleBlur={handleBlur}
                      isMobileBrowser={isMobileBrowser}
                    />

                    {/* {errors.email && touched.email && (
                      <div className='text-danger'>{errors.email}</div>
                    )} */}

                    <SmallUp>
                      <dev className='container d-flex justify-content-end align-items-center px-0'>
                        <Button
                          className={`back-btn-brown mr-2`}
                          variant='outline'
                          onClick={() => setShowRegisterForm(false)}
                        >
                          {'BACK'}
                        </Button>
                        <SubmitBtn
                          className='submit-btn-profile'
                          disabled={!isValid || isSubmitting}
                          title='Submit'
                        />
                      </dev>
                    </SmallUp>
                    {isMobileBrowser ? (
                      <>
                        <ResponsiveFixedComponents>
                          <SubmitBtn
                            disabled={!isValid || isSubmitting}
                            className='continue-right book-continue-btn'
                            title='Submit'
                          />
                        </ResponsiveFixedComponents>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Form>
              )
            }}
          </Formik>
          <SuccessMsg />
        </div>
      </>
    )
  }

  return (
    <>
      <div className='auth-page'>
        {/* {!isMobileBrowser && <div className='image-container-new-partner' />} */}
        <div className={`image-container-new-partner`} />
        <div
          className={
            isMobileBrowser ? 'auth-container' : 'auth-container-partner'
          }
        >
          <AuthForm
            title='Partner Register'
            initialValues={initialValues}
            validationSchema={validationSchema}
            fields={fields}
            isRegistration
            buttonLabel='Next'
            submitStyle='login-btn'
            setShowRegisterForm={setShowRegisterForm}
            setLocation={setLocation}
          />
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  return {
    clientAuth: state.clientAuth,
  }
}

export default connect(mapStateToProps, {
  registerClientUser,
  setClientUserDetails,
  clearClientData,
  createPartner,
  setMessageOpen,
})(RegisterPartner)
