import React from 'react'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import {
  emailValidator,
  requiredStringValidator,
} from '../../../../../form-validation'

function ForgotPassword({
  title,
  history,
  parentPath,
  match,
  hideSidebar,
  ...props
}) {
  const validationSchema = Yup.object().shape({
    email: requiredStringValidator().concat(emailValidator()),
  })
  const initialValues = validationSchema.cast()
  const formSections = [
    {
      title: `Enter the email associated with your account and \nwe’ll send you a link to reset your password.`,
      sectionClass: 'pb-0 reset-password',
      fields: [
        {
          name: 'email',
          type: 'email',
          label: 'EMAIL *',
        },
      ],
    },
  ]

  return (
    <>
      {/* <div className='auth-page'> */}
      <AuthForm
        title='Partner Register'
        initialValues={initialValues}
        validationSchema={validationSchema}
        formSections={formSections}
        isForgotPassword
        buttonLabel='Submit'
        submitStyle='login-btn'
      />
      {/* </div> */}
    </>
  )
}
export default withRouter(ForgotPassword)
