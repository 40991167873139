import { useCallback, useEffect, useRef, useState } from 'react'
import { connectedApiService } from '..'
import { logger } from '../actions'
export const useGetInitialFormValues = (
  url = '',
  validationSchema,
  parseItem
) => {
  const getInitialValues = useCallback(
    () => (validationSchema ? validationSchema.cast() : {}),
    [validationSchema]
  )

  const [initialValues, setInitialValues] = useState(getInitialValues())
  const [isLoading, setIsloading] = useState(false)

  const cachedUrl = useRef(url)
  const currentItem = useRef(null)

  const getCurrentItem = useCallback(
    async (reload = true, triggerIsLoading = true) => {
      try {
        if (
          url &&
          (!currentItem ||
            !currentItem.current ||
            reload ||
            (cachedUrl && cachedUrl.current && url !== cachedUrl.current))
        ) {
          if (triggerIsLoading) {
            setIsloading(true)
          }
          cachedUrl.current = url
          const { data } = (await connectedApiService.get(url)) || {}
          currentItem.current = data
          const formatedItem = parseItem
            ? parseItem(getInitialValues(), data)
            : { ...getInitialValues(), data }
          setInitialValues(formatedItem)
          setIsloading(false)
        }
      } catch (error) {
        logger({ fileName: 'useGetInitialFormValues', error: error })
        setIsloading(false)
      }
    },
    [parseItem, currentItem, getInitialValues, url, cachedUrl]
  )

  useEffect(() => {
    getCurrentItem(false)
  }, [getCurrentItem, url])

  useEffect(() => {
    setInitialValues(getInitialValues())
  }, [getInitialValues])

  return {
    isLoading,
    currentItem: currentItem.current,
    initialValues,
    getCurrentItem,
  }
}
