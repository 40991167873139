import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal, Col, Row, Container } from 'react-bootstrap'
import FormField from '../FormField'
import { updateOutsourcedCharges, logger } from '../../actions'
import { Field, Form, Formik } from 'formik'
import {
  getFormattedPrice,
  getLabelFromValue,
  isMobile,
  numberWithCommas,
} from 'helpers'
import { defaultCurrencies } from '../../constants'
import { requiredStringValidator } from 'form-validation'
import * as Yup from 'yup'
import moment from 'moment'

let isMobileBrowser = isMobile()

const AdditionalChargesModal = ({
  show,
  onHide,
  updateOutsourcedCharges,
  supplierCurrency,
  charges = [],
  bookingId,
  bookingPartner,
  reloadList,
}) => {
  const [initialValues, setInitialValues] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [marginPercentage, setMarginPercentage] = useState(null)
  const [marginAmount, setMarginAmount] = useState(null)
  const changeTab = tab => {
    setSelectedTab(tab)
  }
  const displayCurrency = getLabelFromValue(defaultCurrencies, supplierCurrency)
  const chargeSchema = Yup.object().shape({
    description: requiredStringValidator(),
    quantity: requiredStringValidator(),
    rate: requiredStringValidator(),
    amount: requiredStringValidator(),
  })

  const validationSchema = Yup.object().shape({
    items: Yup.array()
      .of(chargeSchema)
      .default([chargeSchema.cast()]),
  })

  useEffect(() => {
    if (charges.length) {
      let array = []
      for (let charge of charges) {
        if (charge.localSupplier) {
          let item = charge.localSupplier
          array.push({
            id: item.id,
            description: item['description'],
            quantity: item['quantity'],
            rate: item['rate'],
            amount: item['amount'],
            originalPrice: item['originalPrice'],
            sentDate: charge['outsource'] ? charge['outsource'].sentDate : null,
          })
        } else {
          array.push({
            id: charge.id,
            description: charge['description'],
            quantity: charge['quantity'],
            rate: charge['outsourceRate'],
            amount: charge['outsourceAmount'],
            originalPrice: charge['amount'],
            sentDate: charge['outsource'] ? charge['outsource'].sentDate : null,
          })
        }
      }

      setInitialValues({ items: array })
    } else {
      onHide()
    }
  }, [charges.length])

  const onClose = () => {
    onHide()
    setInitialValues([])
  }

  const onCancelEarning = async (values, index) => {
    try {
      await updateOutsourcedCharges(
        { operation: 'remove', charge: values.items[index] },
        bookingId,
        'Earning(s) cancelled'
      )
      reloadList()
    } catch (error) {
      console.log('charges modal error', error)
    }
  }
  const onSendEarning = async (values, index) => {
    try {
      await updateOutsourcedCharges(
        {
          operation: 'add',
          charge: values.items[index],
          bookingPartner: bookingPartner,
          localSupplierCurrency: supplierCurrency,
        },
        bookingId,
        'Earning(s) sent'
      )

      reloadList()
    } catch (error) {
      console.log('charges modal error', error)
    }
  }

  const renderTabContent = (values, onCalculateTotal) => {
    if (values.items[selectedTab]) {
      let rate = parseFloat(values.items[selectedTab].amount)
      let originalPrice = parseFloat(values.items[selectedTab].originalPrice)

      setMarginPercentage(
        +(((originalPrice - rate) / originalPrice) * 100).toFixed(2)
      )
      setMarginAmount((originalPrice - rate).toFixed(2))
    }

    return (
      <>
        <div>
          <Row>
            <Col
              key={`description-${selectedTab}`}
              md={12}
              className={isMobileBrowser ? 'pl-0' : ''}
            >
              <Field
                label='ITEM & DESCRIPTION'
                component={FormField}
                name={`items[${selectedTab}].description`}
                controlId={`items[${selectedTab}].description`}
                disabled={
                  values.items[selectedTab] &&
                  values.items[selectedTab].sentDate
                    ? true
                    : false
                }
              />
            </Col>
            <Col
              key={`quantity-${selectedTab}`}
              md={4}
              className={isMobileBrowser ? 'pl-0 charge-form' : 'charge-form'}
            >
              <Field
                label='QUANTITY'
                component={FormField}
                name={`items[${selectedTab}].quantity`}
                controlId={`items[${selectedTab}].quantity`}
                handleChange={evt => {
                  onCalculateTotal(
                    evt.target.value,
                    values.items[selectedTab].rate,
                    selectedTab
                  )
                }}
                disabled
              />
            </Col>
            <div style={{ display: 'contents' }}>
              <Col
                md={4}
                key={`rate-${selectedTab}`}
                className='charge-form pl-0'
              >
                <Field
                  label={`Buying Amount (${displayCurrency})`}
                  component={FormField}
                  name={`items[${selectedTab}].rate`}
                  controlId={`items[${selectedTab}].rate`}
                  handleChange={evt => {
                    onCalculateTotal(
                      values.items[selectedTab]['quantity'],
                      evt,
                      selectedTab
                    )
                  }}
                  disabled={
                    values.items[selectedTab] &&
                    values.items[selectedTab].sentDate
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                md={4}
                key={`amount-${selectedTab}`}
                className='charge-form pl-0'
              >
                <Field
                  label={`Buying Total (${displayCurrency})`}
                  component={FormField}
                  name={`items[${selectedTab}].amount`}
                  controlId={`items[${selectedTab}].amount`}
                  disabled
                />
                <Col className='pl-0 pr-0 mb-4 d-flex align-items-center'>
                  <span className={'margin-label'}>
                    {`INCL. ${marginPercentage}% MARGIN (${numberWithCommas(
                      getFormattedPrice(parseFloat(marginAmount))
                    )} ${getLabelFromValue(
                      defaultCurrencies,
                      supplierCurrency
                    )})`}
                  </span>
                </Col>
              </Col>
            </div>
          </Row>
        </div>
        <div
          className={`booking-total justify-content-start ${
            isMobileBrowser ? 'row' : 'pt-3 mt-2'
          }`}
        >
          <div className='total-wrap'>
            <div className='total-title'>{`Total (${displayCurrency})`}</div>
            <div className='total-value'>
              {numberWithCommas(
                getFormattedPrice(values.items[selectedTab].amount)
              )}
            </div>
          </div>
        </div>
        {values.items[selectedTab].sentDate ? (
          <div
            className={`${
              isMobileBrowser
                ? 'row d-block'
                : 'd-flex justify-content-between align-items-center'
            }`}
          >
            <span
              className={`partner-sentDate ${isMobileBrowser ? 'mb-3' : ''}`}
            >
              {`Sent on ${moment(values.items[selectedTab].sentDate).format(
                'ddd, Do MMMM YYYY, HH:mm'
              )}`}
              <img
                src='/images/icons/rolzo-first-success.png'
                alt=''
                style={{
                  height: '14px',
                  width: '14px',
                  marginLeft: '0.5rem',
                  marginBottom: '0.2rem',
                }}
              />
            </span>
            <div
              className={
                isMobileBrowser ? 'd-flex justify-content-end mt-3' : ''
              }
            >
              <Button
                className='select-vehicle partner-btn-desktop-red mt-3'
                variant='brown'
                onClick={() => onCancelEarning(values, selectedTab)}
              >
                Cancel earning
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-end mt-3'>
            <Button
              style={isMobileBrowser ? { minWidth: '100%' } : {}}
              className='select-btn select-vehicle partner-btn-desktop'
              variant='brown'
              onClick={() => onSendEarning(values, selectedTab)}
            >
              Send
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='charges-partner-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
      id={'modal'}
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <>
            <Row className='d-flex align-items-center'>
              <Col style={isMobileBrowser ? { paddingLeft: '0px' } : {}}>
                <div
                  className={isMobileBrowser ? 'additional-earning-modal' : ''}
                  style={{ marginTop: '2rem' }}
                >
                  {bookingPartner && bookingPartner.name ? (
                    <h1
                      style={
                        isMobileBrowser
                          ? { paddingLeft: '0.5rem' }
                          : { paddingLeft: '0.313rem' }
                      }
                      className='dispatch-title mb-0 pb-0'
                    >
                      Send additional earning(s) to ({bookingPartner.name})
                    </h1>
                  ) : (
                    <></>
                  )}
                  <Button
                    style={
                      isMobileBrowser
                        ? {
                            display: 'flex',
                            justifyContent: 'end',
                            marginRight: '20px',
                            marginBottom: '20px',
                          }
                        : { marginRight: '20px' }
                    }
                    variant='link'
                    className=''
                    onClick={onClose}
                  >
                    <img src='/images/icons/close-icon-black.png' alt='' />
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {initialValues.items && initialValues.items.length ? (
          <>
            <div className='partner-modal-tab'>
              <div className='d-flex justify-content-start'>
                <div className='custom-tabs-nav light-tabs nav'>
                  {initialValues.items.map((item, index) => (
                    <Button
                      key={index}
                      onClick={() => changeTab(index)}
                      className={`nav-link ${
                        selectedTab === index ? 'active' : ''
                      }`}
                    >
                      Earning {index + 1}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              isInitialValid
            >
              {({ values, setFieldValue }) => {
                const onCalculateTotal = (quantity, rate, index) => {
                  if (quantity && rate) {
                    values.items[index].amount = (
                      parseFloat(quantity) * parseFloat(rate)
                    ).toFixed(2)
                  } else {
                    values.items[index].amount = 0
                  }
                }

                return (
                  <Form
                    noValidate
                    className='form-wrap booking-form-wrap book-form'
                    style={isMobileBrowser ? { marginTop: '0px' } : {}}
                  >
                    <Container
                      className='form-block'
                      style={{ padding: '1rem' }}
                    >
                      {renderTabContent(values, onCalculateTotal)}
                    </Container>
                  </Form>
                )
              }}
            </Formik>
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  )
}
const mapStateToProps = ({ auth } = {}) => ({})

// export default AdditionalChargesModal
export default withRouter(
  connect(mapStateToProps, {
    updateOutsourcedCharges,

    logger,
  })(AdditionalChargesModal)
)
