import { createOrEditItem, deleteItem } from './form'

export const addCity = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rolzoCitiesList',
      successMessage: 'City was successfully added',
    })
  )

export const editCity = (values, cityId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `rolzoCities/${cityId}`,
      successMessage: 'City was successfully updated',
    })
  )

export const deleteCity = cityId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `rolzoCities/${cityId}`,
      successMessage: 'City was successfully deleted',
    })
  )

export const addCountry = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rolzoCountriesList',
      successMessage: 'Country was successfully added',
    })
  )

export const editCountry = (values, countryId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `rolzoCountries/${countryId}`,
      successMessage: 'Country was successfully updated',
    })
  )

export const deleteCountry = countryId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `rolzoCountries/${countryId}`,
      successMessage: 'Country was successfully deleted',
    })
  )
