import React from 'react'

const PartnerAcceptedModal = ({
  isBookingAccepted,
  isBookingDeclined = false,
  isBookingChangesAccpted = false,
  isBookingChangesDeclined = false,
  isCarRentals = false,
  isSubmitted = false,
}) => {
  return (
    <div className='partner-accepted-modal'>
      <img
        src={
          isBookingDeclined || isBookingChangesDeclined
            ? '/images/icons/offer-declined.png'
            : '/images/icon-partner-check.png'
        }
        width={80}
        alt=''
      />
      <p>
        {isBookingDeclined
          ? 'The offer has been declined.'
          : isBookingChangesAccpted
          ? 'The booking change(s) have been accepted successfully.'
          : isBookingChangesDeclined
          ? 'The booking change(s) have been declined.'
          : isBookingAccepted
          ? 'You have accepted this booking successfully.'
          : isCarRentals
          ? 'The agent has been assigned successfully.'
          : isSubmitted
          ? 'Submission successful'
          : 'The chauffeur has been assigned successfully.'}
      </p>
    </div>
  )
}

export default PartnerAcceptedModal
