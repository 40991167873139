import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal, Col, Row, Container } from 'react-bootstrap'
import FormField from '../FormField'
import {
  updateOutsourcedCharges,
  logger,
  updateOutsourcedPrice,
} from '../../actions'
import { Field, Form, Formik } from 'formik'
import { getFormattedPrice, getLabelFromValue, numberWithCommas } from 'helpers'
import { defaultCurrencies } from '../../constants'

const UpdateOutsourcePrice = ({
  show,
  onHide,
  logger,
  supplierCurrency,
  updateBooking,
  buyingPrice,
  originalPrice,
  bookingId,
  updateOutsourcedPrice,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [updatedRate, setUpdatedRate] = useState(buyingPrice)
  const [marginPercentage, setMarginPercentage] = useState(null)
  const [marginAmount, setMarginAmount] = useState(null)

  useEffect(() => {
    let rate = updatedRate ? updatedRate : 0
    setMarginPercentage(
      +(((originalPrice - rate) / originalPrice) * 100).toFixed(2)
    )
    setMarginAmount((originalPrice - rate).toFixed(2))

    setUpdatedRate(parseFloat(updatedRate))
  }, [updatedRate, originalPrice])

  const onClose = () => {
    onHide()
  }

  const onSubmit = async () => {
    try {
      let rate = updatedRate ? updatedRate : 0
      let currency = supplierCurrency
      setSubmitting(true)
      await updateOutsourcedPrice({ rate, currency }, bookingId)
      await updateBooking()
      onHide()
    } catch (err) {
      logger({ fileName: 'UpdateOutsourcePrice', error: err })
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      size='md'
      show={show}
      onHide={onClose}
      className='outsourced-rate-modal  modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
      // onSubmit={onSubmit}
      id={'modal'}
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <>
            <Row className='d-flex align-items-center'>
              <Col className='pl-0'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <h1
                    style={{ paddingTop: '0px', paddingLeft: '0.75%' }}
                    className='dispatch-title mb-0 pb-0'
                  >
                    Edit outsourced price
                  </h1>
                  <Button
                    className='mt-0 mr-0'
                    variant='link'
                    onClick={() => onClose()}
                  >
                    <img src='/images/icons/close-icon-black.png' alt='' />
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={null}
          validateOnBlur={false}
          validateOnChange={false}
          isInitialValid
        >
          <div>
            <Col
              style={{ marginTop: '2rem', paddingLeft: '0px' }}
              className='d-flex align-items-center'
            >
              <Field
                component={FormField}
                name='dispatchPrice'
                controlId={'dispatchPrice'}
                className='payment-field-dispatch hide-number-spinners'
                label='Buying price'
                type='number'
                value={updatedRate}
                groupClassName='mb-1'
                handleChange={evt => {
                  if (!evt) {
                    setUpdatedRate(null)
                  } else {
                    setUpdatedRate(parseFloat(evt))
                  }
                }}
              />
              <span className='ml-2 mt-3'>
                {`${getLabelFromValue(defaultCurrencies, supplierCurrency)}`}
              </span>
            </Col>
            <Col className='d-flex align-items-center mb-4 pl-0'>
              <span
                className={`${
                  marginPercentage > 20 || marginPercentage === 20
                    ? 'most-margin-label'
                    : marginPercentage > 0 && marginPercentage <= 10
                    ? 'less-margin-label'
                    : 'least-margin-label'
                }`}
              >
                {`INCL. ${marginPercentage}% MARGIN (${numberWithCommas(
                  getFormattedPrice(parseFloat(marginAmount))
                )} ${getLabelFromValue(defaultCurrencies, supplierCurrency)})`}
              </span>
            </Col>
            <div>
              <div
                className={'share-button dispatch-modal-button border-top-0'}
                style={{ paddingTop: '0.5rem' }}
              >
                <>
                  <Button
                    className='outsource-btn-partner select-vehicle'
                    style={{ maxWidth: '129px' }}
                    onClick={() => {
                      onHide()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='select-btn h-40 share ml-3 mr-0'
                    style={{ maxWidth: '129px' }}
                    variant='brown'
                    onClick={() => onSubmit()}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </>
              </div>
            </div>
          </div>
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

// export default UpdateOutsourcePrice

const mapStateToProps = ({ auth } = {}) => ({})

export default withRouter(
  connect(mapStateToProps, {
    updateOutsourcedCharges,
    updateOutsourcedPrice,
    logger,
  })(UpdateOutsourcePrice)
)
