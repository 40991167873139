import LoadingSpinner from 'components/LoadingSpinner'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import React, { useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { connectedApiService as api } from '../../../../../index'
const Host = window.location.hostname
let verifyTimer = null

function VerifyPartnerEmail({ history }) {
  const { email } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [icon, setIcon] = useState('/images/icons/Icon_Email_verified.png')
  const [title, setTitle] = useState('Email Verified')
  const [countDown, setCountDown] = useState(3)
  const [isVerified, setIsVerified] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    const checkEmail = async () => {
      const { data } = await api.patch(`partner/emailverification/${email}`)
      if (data.emailVerified) {
        setToken(data.token)
        setIsVerified(true)
        setIsLoading(false)
      } else {
        setIcon('/images/icons/Icon_Broken.png')
        setIsVerified(false)
        setTitle('')
        setIsLoading(false)
      }
    }
    if (email) {
      checkEmail()
    }
  }, [email])

  useEffect(() => {
    verifyTimer = setInterval(() => {
      setCountDown(countDown => countDown - 1)
    }, 1000)
  }, [isVerified])

  useEffect(() => {
    if (countDown === 0) {
      history.push({
        pathname: `${
          Host === partnerHostLive || Host === partnerHostStaging
            ? '/'
            : '/partner/'
        }auth/setpassword/${token}`,
        email: email,
      })
      clearInterval(verifyTimer)
    }
  }, [countDown])

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className='success-msg-verification'>
          <div className='content-wrap'>
            <Image src={icon} />
            <h4 className='success-msg-header'>{title}</h4>
            {isVerified ? (
              <>
                <p className='success-msg-description'>
                  Your email address has been verified{' '}
                </p>
                <p className='success-msg-description'>
                  successfully. You're being redirected,
                </p>
                <p className='success-msg-description'>please, wait...</p>
                {countDown !== 0 && <LoadingSpinner isCenter={true} />}
              </>
            ) : (
              <>
                <h4 className='success-msg-header'>Oops</h4>
                <p className='success-msg-description'>
                  This link has expired.
                </p>

                <Button
                  variant='link'
                  onClick={() => {
                    history.push(
                      `${
                        Host === partnerHostLive || Host === partnerHostStaging
                          ? '/'
                          : '/partner/'
                      }auth/login`
                    )
                  }}
                  className='inline-edit-btn text-brown btn-anchor mt-3'
                  style={{ alignSelf: 'center' }}
                >
                  Log in
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(
  connect(
    null,
    {}
  )(VerifyPartnerEmail)
)
