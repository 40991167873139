import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { declineOffer } from '../actions'
import FormRadio from '../components/FormRadio'

const isMobileBrowser = isMobile()

const DeclineOfferModal = ({
  show,
  onHide,
  declineOffer,
  bookingId = null,
  supplierId = null,
  afterDecline = () => {},
}) => {
  const [noAvailability, setNoAvailability] = useState(true)
  const [wrongPrice, setWrongPrice] = useState(false)
  const [other, setOther] = useState(false)
  const [declineReason, setDeclineReason] = useState('No availability')
  const [isCommentEmpty, setIsCommentEmpty] = useState(false)

  const onChangeRadio = async option => {
    setNoAvailability(option === 1)
    setWrongPrice(option === 2)
    setOther(option === 3)
    setDeclineReason(
      option === 3 ? 'Other' : option === 2 ? 'Wrong price' : 'No availability'
    )
  }
  const onClose = () => {
    onHide()
  }
  const onConfirmation = () => {
    onDeclineOffer()
  }

  const onDeclineOffer = async () => {
    try {
      if (bookingId && supplierId) {
        var declineComment = document.getElementById('declineComment').innerText

        if (declineComment === '') {
          setIsCommentEmpty(true)
          return
        } else {
          setIsCommentEmpty(false)
        }

        let declineInfo = {
          declineReason: declineReason,
          declineComment: declineComment,
        }
        await declineOffer(declineInfo, bookingId, supplierId)
        afterDecline()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='decline-offer-booking-modal modal-90w'
      dialogClassName={
        isMobileBrowser ? 'modal-90w decline-offer' : 'modal-90w '
      }
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          {isMobileBrowser && (
            <div className='driver-booking-container-header-booking'>
              <img
                className='driver-back-icon mx-50%'
                src='/images/icon-back.png'
                onClick={() => {
                  onHide()
                }}
                alt=''
              />
              <span style={{ paddingTop: '0.2em' }}>Decline offer</span>
              <span></span>
            </div>
          )}
          {!isMobileBrowser && <h1 className='title'>Decline offer</h1>}
          <div className='close-btn-wrap'>
            <img
              src={`${
                isMobileBrowser
                  ? '/images/icons/close-icon-black.png'
                  : '/images/icons/modal-close-icon.svg'
              }`}
              alt=''
              height={12}
              width={12}
              style={
                isMobileBrowser
                  ? { marginRight: '12px', marginTop: '10px' }
                  : {}
              }
              onClick={onClose}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='modal-description'>Choose the reason</p>
        <div className='modal-options'>
          <FormRadio
            name='noAvailability'
            id='noAvailability'
            label='No availability'
            labelClass='options'
            handleChange={() => onChangeRadio(1)}
            checked={noAvailability}
          />
          <FormRadio
            name='wrongPrice'
            id='wrongPrice'
            label='Wrong price'
            labelClass='options'
            handleChange={() => onChangeRadio(2)}
            checked={wrongPrice}
          />
          <FormRadio
            name='other'
            id='other'
            label='Other'
            labelClass='options'
            handleChange={() => onChangeRadio(3)}
            checked={other}
          />
        </div>
        <div style={{ marginTop: '36px' }}>
          <label> LEAVE A COMMENT* </label>
          <span
            id='declineComment'
            className='form-group notes-expandable-partner'
            as='textarea'
            contentEditable='plaintext-only'
            style={
              isMobileBrowser
                ? { marginRight: '1em', minHeight: '200px' }
                : { marginBottom: 0 }
            }
          ></span>
        </div>
        {isCommentEmpty && (
          <span className='mismatch-text mt-1'>Please leave a comment.</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isMobileBrowser ? (
          <Button
            className='select-btn select-vehicle partner-btn-desktop'
            variant='brown'
            onClick={onConfirmation}
            style={{ margin: 0 }}
          >
            Decline
          </Button>
        ) : (
          <div className='driver-booking-cta-footer-assign'>
            <Button
              className='select-btn select-vehicle'
              style={{
                width: '100%',
                marginBottom: '15px',
                fontWeight: '900',
                fontSize: '16px',
                textShadow: '0.25px 0 #ffffff',
              }}
              variant='brown'
              onClick={onConfirmation}
            >
              Decline
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
export default withRouter(
  connect(null, {
    declineOffer,
  })(DeclineOfferModal)
)
