import React from 'react'
import { useParams, withRouter } from 'react-router'
import { useGetTableData } from '../.././../../../hooks'
import DefaultDriverLayout from '../../../../../components/layout/DefaultDriverLayout'
import { connect } from 'react-redux'

const Cars = ({ isCarRental }) => {
  const { token } = useParams()
  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const {
    data: { list },
    isLoading,
    onFiltersChange,
    filters,
  } = useGetTableData(
    partnerType === 'rental'
      ? `external/car-rentals/${token}`
      : `external/car/${token}`
  )

  return (
    <DefaultDriverLayout
      data={list}
      isAccountLoading={isLoading}
      onFiltersChange={onFiltersChange}
      filters={filters}
    />
  )
}

const mapStateToProps = ({ auth: { isCarRental = null } }) => ({
  isCarRental,
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Cars)
)
