import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as notifications } from 'react-notification-system-redux'
import auth from './auth'
import globalFleet from './globalFleet'
import successMsg from './successMsg'
import appWebsocket from './appWebsocket'
import clientAuth from './clientAuth'
import searchCars from './searchCars'
import vehicleSearch from './vehicleSearch'
import adminTabs from './adminTabs'

export default history =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    auth,
    clientAuth,
    globalFleet,
    successMsg,
    appWebsocket,
    searchCars,
    vehicleSearch,
    adminTabs,
  })
