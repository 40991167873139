import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { createOrEditItem } from './form'
import { logger } from './logger'

export const createB2CBooking = (values = {}) => async () => {
  try {
    const url = `booking/b2c`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle', error: err })
    handleActionError(err)
  }
}

export const fetchB2CSearch = searchId => async () => {
  try {
    const url = `search/b2c/${searchId}`
    const { data } = await api.get(
      url,
      false,
      false,
      '',
      process.env.REACT_APP_B2C_TOKEN
    )

    return { data }
  } catch (err) {
    logger({ fileName: 'check discount error', error: err })
    handleActionError(err)
  }
}

export const updateB2CSearch = (values, searchId) => async () => {
  try {
    const url = `search/b2c/${searchId}`
    const { data } = await api.patch(
      url,
      values,
      false,
      '',
      process.env.REACT_APP_B2C_TOKEN
    )

    return { data }
  } catch (err) {
    logger({ fileName: 'check discount error', error: err })
    handleActionError(err)
  }
}

export const searchB2CVehicle = (filters = {}) => async () => {
  const queryParams = Object.keys(filters)
    .map(key => {
      if (key === 'to[fullAddress]' || key === 'from[fullAddress]') {
        filters[key] = filters[key].replace('&', 'and')
      }
      if (Array.isArray(filters[key])) {
        return filters[key].map(item => `${key}[]=${item}`).join('&')
      }
      return `${key}=${filters[key] || ''}`
    })
    .join('&')
  const url = `booking/vehicle?${queryParams}`
  try {
    const { data } = await api.get(
      url,
      false,
      false,
      '',
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle error', error: err })
    handleActionError(err)
  }
}

export const searchB2CVehicleWithStops = (values = {}) => async () => {
  try {
    const url = `booking/vehicle-multiStops`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle multi-stops error', error: err })
    handleActionError(err)
  }
}

export const searchB2CVehicleWithStopsHourly = (values = {}) => async () => {
  try {
    const url = 'booking/vehicle-multiStops-byTheHour'
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle hourly multi-stops error', error: err })
    handleActionError(err)
  }
}

export const searchB2CVehicleWithStopsDaily = (values = {}) => async () => {
  try {
    const url = 'booking/vehicle-multiStops-daily'
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle daily multi-stops error', error: err })
    handleActionError(err)
  }
}

export const getB2CGlobalPolicy = () => async () => {
  try {
    const url = `globalPolicy`
    const { data } = await api.get(
      url,
      false,
      false,
      '',
      process.env.REACT_APP_B2C_TOKEN
    )

    return { data }
  } catch (err) {
    logger({ fileName: 'searchVehicle daily multi-stops error', error: err })
    handleActionError(err)
  }
}

export const sendB2CEnquiry = (values = {}) => async () => {
  try {
    const url = `bespoke/b2c`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'b2c sendB2CEnquiry', error: err })
    handleActionError(err)
  }
}

export const checkB2CDiscount = (email = null) => async () => {
  try {
    const url = `booking/b2c/discount-check/${email}`
    const { data } = await api.get(
      url,
      false,
      false,
      '',
      process.env.REACT_APP_B2C_TOKEN
    )

    return { data }
  } catch (err) {
    logger({ fileName: 'check discount error', error: err })
    handleActionError(err)
  }
}

export const applyB2CPromoCode = (values = null) => async () => {
  try {
    const url = `benefits/promoCode`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'check discount error', error: err })
    handleActionError(err)
  }
}

export const searchB2CBanners = (values = {}) => async () => {
  try {
    const url = `warningBanner`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchB2CBanners error', error: err })
    handleActionError(err)
  }
}

export const declinedEmail = (values = {}) => async () => {
  try {
    const url = `payment/b2c/declined`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchB2CBanners error', error: err })
    handleActionError(err)
  }
}
