import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AppPage from '../routes/App/AppPage'

export const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  isPrivate,
  childRoutes,
  path,
  exact = true,
  hasAccess = true,
  title,
  renderSidebar,
  renderBottomMenu,
  companyId = null,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      exact={exact}
      render={props => {
        if (!isPrivate || (isPrivate && isLoggedIn)) {
          return (
            <>
              {!!childRoutes && !!childRoutes.length ? (
                <Switch>
                  {childRoutes.map(route => {
                    const {
                      path: childPath,
                      isPrivate: isChildPrivate,
                      hasAccess: childHasAccess,
                      tabs,
                    } = route

                    return (
                      <ProtectedRoute
                        key={childPath}
                        isLoggedIn={isLoggedIn}
                        isPrivate={isChildPrivate}
                        path={`${path}${childPath}`}
                        childRoutes={route.childRoutes}
                        exact={route.exact}
                        hasAccess={childHasAccess}
                        renderSidebar={renderSidebar}
                        renderBottomMenu={renderBottomMenu}
                        component={() => (
                          <AppPage
                            isLoggedIn={isLoggedIn}
                            parentPath={path}
                            tabs={tabs}
                            renderSidebar={renderSidebar}
                            renderBottomMenu={renderBottomMenu}
                            companyId={companyId}
                            {...route}
                          />
                        )}
                      />
                    )
                  })}
                  <Redirect to={`${path}${childRoutes[0].path}`} />
                </Switch>
              ) : (
                <Component {...props} />
              )}
            </>
          )
        }

        //redirect to partner login page instead or the normal client page
        if (props.location.pathname.search(/\bpartner\b/) >= 0) {
          return (
            <>
              <Redirect
                to={{
                  pathname: '/partner/auth/login',
                  from: props.location.pathname,
                  state: { oldUrl: props.location.pathname },
                }}
              />
            </>
          )
        } else {
          return (
            <Redirect
              to={{ pathname: '/auth/login', from: props.location.pathname }}
            />
          )
        }
      }}
    />
  )
}
