import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'react-bootstrap'
import { useGetTableData } from '../../../../../hooks'
import DefaultDriverDesktopLayout from '../../../../../components/layout/DefaultDriverDesktopLayout'
import {
  SmallUp,
  SmallDown,
} from '../../../../../components/Responsive/breakpoints'
import ConfirmationModal from 'components/confirmationModal'
import SupportModal from 'components/SupportModal'
import PartnerAcceptedModal from 'components/PartnerAcceptedModal'

const Support = ({ props, history, isCarRental }) => {
  const { token, bookingId } = useParams()

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const [supportNumber, setSupportNumber] = useState('')
  const [passengerNumber, setPassengerNumber] = useState('')
  const [passengerName, setPassengerName] = useState('')
  const [bookingAccepted, setBookingAccepted] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showNoShowModal, setshowNoShowModal] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    data: { list },
    reloadList,
  } = useGetTableData(
    token && bookingId
      ? `external/partnerToken/${token}/${bookingId}/support`
      : `external/partnerToken/${props.token}/${props.bookingId}/support`
  )

  const afterSubmissionFtn = () => {
    setIsSubmitted(true)
    setTimeout(
      function() {
        setIsSubmitted(false)
      }.bind(this),
      1500
    )
  }

  const { data } = useGetTableData(`external/partner/${token}`)

  let supplierId = null

  if (data && data.list && data.list[0]) {
    supplierId = data.list[0]._id
  }

  useEffect(() => {
    if (list && list.passenger && list.support) {
      setSupportNumber(list.support)
      setPassengerNumber(list.passenger)
      setPassengerName(list.passengerName)
      setBookingAccepted(list.isAccepted)
    }
  }, [list])

  const renderContent = () => {
    return (
      <>
        {!isSubmitted ? (
          <div
            className='d-flex flex-wrap driver-booking-container'
            style={{ margin: 0, maxWidth: '1000px' }}
          >
            {list &&
              list.isAccepted &&
              list.status !== 'cancellation_accepted_by_agent' &&
              list.status !== 'cancelled_by_agent' &&
              list.status !== 'completed' && (
                <Col
                  className='support-card'
                  style={{ marginRight: '0.875rem', maxWidth: '410px' }}
                  md={6}
                >
                  <div className='support-icon-container'>
                    <img
                      className='support-img'
                      src='/images/icons/icon-call.png'
                      alt=''
                    />
                  </div>
                  <div className='d-flex flex-column mr-5'>
                    <span className='support-title mt-3 mb-2'>
                      Call {passengerName}
                    </span>
                    <span
                      className='support-message mb-4'
                      style={{ whiteSpace: 'pre' }}
                    >
                      Press the button below to get in touch with <br />
                      {passengerName}.
                    </span>
                  </div>
                  {list && list.canCall ? (
                    <a href={`tel:${passengerNumber}`}>
                      <Button
                        className='partner-btn-desktop-primary-sm select-vehicle'
                        variant='brown'
                        style={{
                          fontSize: '10px !important',
                          paddingLeft: '2.5rem',
                          paddingRight: '2.5rem',
                        }}
                      >
                        Call the passenger
                      </Button>
                    </a>
                  ) : (
                    <Button
                      className='partner-btn-desktop-primary-sm select-vehicle'
                      variant='brown'
                      onClick={() => {
                        setShowConfirmationModal(true)
                      }}
                      style={{
                        fontSize: '10px !important',
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                      }}
                    >
                      Call passenger
                    </Button>
                  )}
                </Col>
              )}
            <Col
              className='support-card'
              style={
                bookingAccepted
                  ? { maxWidth: '410px' }
                  : { maxWidth: '410px', marginRight: '14px' }
              }
              md={6}
            >
              <div className='support-icon-container '>
                <img
                  className='support-img'
                  src='/images/icons/icon-call.png'
                  alt=''
                />
              </div>
              <div className='d-flex flex-column mr-5'>
                <span className='support-title mt-3 mb-2'>Call ROLZO</span>
                <span
                  className='support-message mb-4'
                  style={{ whiteSpace: 'pre' }}
                >
                  Press the button below to get in touch with the <br />
                  ROLZO Operations team.
                </span>
              </div>
              <a href={`tel:${supportNumber}`}>
                <Button
                  className='partner-btn-desktop-primary-sm select-vehicle'
                  variant='brown'
                  style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                >
                  Call ROLZO
                </Button>
              </a>
            </Col>
            {partnerType !== 'rental' && (
              <Col
                className='support-card'
                style={{
                  marginRight: '0.875rem',
                  maxWidth: '410px',
                  minWidth: '410px',
                }}
                md={6}
              >
                <div className='support-icon-container '>
                  <img
                    className='support-img'
                    src='/images/icons/icon-noshow.png'
                    alt=''
                    style={{ width: '22.8px', height: '22.8px' }}
                  />
                </div>
                <div className='d-flex flex-column mr-5'>
                  <span className='support-title mt-3 mb-2'>No-show</span>
                  <span
                    className='support-message mb-4'
                    style={{ whiteSpace: 'pre' }}
                  >
                    Press the button below if {passengerName} didn't <br />
                    show up and cannot be contacted.
                  </span>
                </div>
                <Button
                  className='partner-btn-desktop-primary-sm select-vehicle'
                  variant='brown'
                  onClick={() => {
                    setshowNoShowModal(true)
                  }}
                  style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                >
                  No-show
                </Button>
              </Col>
            )}
            <Col
              className='support-card'
              style={{ maxWidth: '410px', minWidth: '410px' }}
              md={6}
            >
              <div className='support-icon-container '>
                <img
                  className='support-img'
                  src='/images/icons/icon-report.png'
                  alt=''
                  style={{ width: '22.8px', height: '22.8px' }}
                />
              </div>
              <div className='d-flex flex-column mr-5'>
                <span className='support-title mt-3 mb-2'>Report</span>
                <span
                  className='support-message mb-4'
                  style={{ whiteSpace: 'pre', minHeight: '48px' }}
                >
                  Press the button to report.
                  <hr style={{ border: 'none' }} />
                </span>
              </div>
              <Button
                className='partner-btn-desktop-primary-sm select-vehicle'
                variant='brown'
                onClick={() => {
                  setShowReportModal(true)
                }}
                style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
              >
                Report
              </Button>
            </Col>
            <ConfirmationModal
              onHide={() => {
                setShowConfirmationModal(false)
              }}
              show={showConfirmationModal}
              confirmationText={
                'You will be able to call the passenger within 60 minutes of the pick-up time.'
              }
              confirmationTitle={'Call passenger'}
              changeConfirmationTitle={true}
              partnerSupport
            />
            <SupportModal
              onHide={() => {
                setshowNoShowModal(false)
              }}
              show={showNoShowModal}
              modalTitle={'No-show'}
              modalLabel={'ADD A COMMENT (OPTIONAL)'}
              bookingId={bookingId}
              supplierId={supplierId}
              afterSubmission={() => {
                afterSubmissionFtn()
              }}
            />
            <SupportModal
              onHide={() => {
                setShowReportModal(false)
              }}
              show={showReportModal}
              modalTitle={'Report'}
              modalLabel={'ADD A COMMENT*'}
              bookingId={bookingId}
              supplierId={supplierId}
              afterSubmission={() => {
                afterSubmissionFtn()
              }}
            />
          </div>
        ) : (
          <PartnerAcceptedModal isSubmitted={isSubmitted} />
        )}
      </>
    )
  }
  return (
    <>
      <SmallUp>
        <DefaultDriverDesktopLayout renderContent={renderContent} />
      </SmallUp>
      <SmallDown>
        {!isSubmitted ? (
          <>
            {showNoShowModal ? (
              <div style={{ height: '100vh' }}>
                <SupportModal
                  onHide={() => {
                    setshowNoShowModal(false)
                  }}
                  show={showNoShowModal}
                  modalTitle={'No-show'}
                  modalLabel={'ADD A COMMENT (OPTIONAL)'}
                  bookingId={bookingId}
                  supplierId={supplierId}
                  afterSubmission={() => {
                    afterSubmissionFtn()
                  }}
                />
              </div>
            ) : showReportModal ? (
              <SupportModal
                onHide={() => {
                  setShowReportModal(false)
                }}
                show={showReportModal}
                modalTitle={'Report'}
                modalLabel={'ADD A COMMENT*'}
                bookingId={bookingId}
                supplierId={supplierId}
                afterSubmission={() => {
                  afterSubmissionFtn()
                }}
              />
            ) : (
              <>
                <div className='driver-booking-container-header-booking-support'>
                  <img
                    className='driver-back-icon mx-50%'
                    src='/images/icon-back.png'
                    alt=''
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                  <span style={{ paddingTop: '0.2em' }}>Support</span>
                  <span style={{ paddingTop: '0.2em' }}></span>
                </div>
                <div
                  className='driver-booking-container'
                  style={{ margin: '5%' }}
                >
                  {list &&
                    list.isAccepted &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    list.status !== 'completed' && (
                      <div>
                        {list && list.canCall ? (
                          <a href={`tel:${passengerNumber}`}>
                            <Button
                              className='select-btn select-vehicle'
                              style={{
                                width: '100%',
                                marginBottom: '15px',
                                paddingLeft: '1.8rem',
                                paddingRight: '1.8rem',
                                fontWeight: '900',
                                fontSize: '16px',
                              }}
                              variant='brown'
                            >
                              CALL {passengerName.toUpperCase()}
                            </Button>
                          </a>
                        ) : (
                          <Button
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              marginBottom: '15px',
                              paddingLeft: '1.8rem',
                              paddingRight: '1.8rem',
                              fontWeight: '900',
                              fontSize: '16px',
                            }}
                            variant='brown'
                            onClick={() => {
                              setShowConfirmationModal(true)
                            }}
                          >
                            CALL {passengerName.toUpperCase()}
                          </Button>
                        )}
                      </div>
                    )}
                  <div>
                    <a href={`tel:${supportNumber}`}>
                      <Button
                        className='select-btn select-vehicle'
                        style={{
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '2.5rem',
                          paddingRight: '2.5rem',
                          fontWeight: '900',
                          fontSize: '16px',
                        }}
                        variant='brown'
                      >
                        CALL ROLZO
                      </Button>
                    </a>
                  </div>
                  <div>
                    <Button
                      className='select-btn select-vehicle'
                      variant='brown'
                      onClick={() => {
                        setShowReportModal(false)
                        setshowNoShowModal(true)
                      }}
                      style={{
                        width: '100%',
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                      }}
                    >
                      NO-SHOW
                    </Button>
                  </div>
                  <div>
                    <Button
                      className='select-btn select-vehicle'
                      variant='brown'
                      onClick={() => {
                        setshowNoShowModal(false)
                        setShowReportModal(true)
                      }}
                      style={{
                        width: '100%',
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                      }}
                    >
                      REPORT
                    </Button>
                  </div>
                </div>
                <ConfirmationModal
                  onHide={() => {
                    setShowConfirmationModal(false)
                  }}
                  show={showConfirmationModal}
                  confirmationText={`You will be able to call the passenger <br> within 60 minutes of the pick-up time.`}
                  confirmationTitle={'Call passenger'}
                  changeConfirmationTitle={true}
                  partnerSupport
                />
              </>
            )}
          </>
        ) : (
          <PartnerAcceptedModal isSubmitted={isSubmitted} />
        )}
      </SmallDown>
    </>
  )
}

const mapStateToProps = ({ auth: { isCarRental = null } }) => ({
  isCarRental,
})

export default withRouter(connect(mapStateToProps, {})(Support))
