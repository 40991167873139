import { fileToBase64 } from '../helpers'
import { logger } from '../actions'
export const driversFormData = async ({
  fullName,
  firstName,
  lastName,
  phone,
  title,
  driverAge,
  insuranceNumber,
  drivingLicenceFrontFile,
  drivingLicenceBackFile,
  passportFile,
  insuranceFile,
}) => {
  try {
    let data = {
      title: title ? title.value : '',
      phone,
      fullName,
      firstName,
      lastName,
      driverAge: driverAge ? driverAge.value : '',
    }
    if (insuranceNumber) {
      data.insuranceNumber = insuranceNumber
    }
    if (
      drivingLicenceFrontFile &&
      typeof drivingLicenceFrontFile === 'object' &&
      drivingLicenceFrontFile.size
    ) {
      const fileBase64 = await fileToBase64(drivingLicenceFrontFile)
      data.drivingLicenceFrontFile = fileBase64 || null
    }

    if (
      drivingLicenceBackFile &&
      typeof drivingLicenceBackFile === 'object' &&
      drivingLicenceBackFile.size
    ) {
      const fileBase64 = await fileToBase64(drivingLicenceBackFile)
      data.drivingLicenceBackFile = fileBase64 || null
    }

    if (passportFile && typeof passportFile === 'object' && passportFile.size) {
      const fileBase64 = await fileToBase64(passportFile)
      data.passportFile = fileBase64 || null
    }

    if (
      insuranceFile &&
      typeof insuranceFile === 'object' &&
      insuranceFile.size
    ) {
      const fileBase64 = await fileToBase64(insuranceFile)
      data.insuranceFile = fileBase64 || null
    }
    return data
  } catch (error) {
    logger({ fileName: 'driver', error: error })
    console.log(error)
    return {}
  }
}

export class DriversInitialValues {
  constructor({ driversInfo } = {}) {
    this.driversInfo = driversInfo.map(
      ({
        title,
        driverAge,
        drivingLicenceFrontFileId,
        drivingLicenceBackFileId,
        passportFileId,
        insuranceFileId,
        ...driver
      }) => {
        return {
          ...driver,
          title: { value: title, label: title },
          driverAge: { value: driverAge, label: driverAge },
          drivingLicenceFrontFile: drivingLicenceFrontFileId
            ? {
                name: drivingLicenceFrontFileId,
              }
            : null,
          drivingLicenceBackFile: drivingLicenceBackFileId
            ? {
                name: drivingLicenceBackFileId,
              }
            : null,
          passportFile: passportFileId
            ? {
                name: passportFileId,
              }
            : null,
          insuranceFile: insuranceFileId
            ? {
                name: insuranceFileId,
              }
            : null,
        }
      }
    )
  }
}
