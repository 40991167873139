import {
  CLEAR_CLIENT_DATA,
  SET_CLIENT_BUISNESS_DETAILS,
  SET_CLIENT_CREDENTIALS,
  SET_CLIENT_PERSONAL_DETAILS,
} from '../actions/types'

const INITIAL_STATE = {
  credentials: null,
  personalDetails: null,
  buisnessDetails: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CLIENT_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      }
    case SET_CLIENT_BUISNESS_DETAILS:
      return {
        ...state,
        buisnessDetails: state.credentials,
      }
    case SET_CLIENT_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: state.credentials,
      }
    case CLEAR_CLIENT_DATA:
      return {
        ...state,
        credentials: null,
        personalDetails: null,
        buisnessDetails: null,
      }
    default:
      return state
  }
}
