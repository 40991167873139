import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { createOrEditItem } from './form'

export const applyPromoCode = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      // isEdit: true,
      // editRequest: 'get',
      endpoint: 'benefits/promoCode',
    })
  )

export const applyMemberEligibility = (values, companyId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `checkMemberEligibility/${companyId}`,
    })
  )
export const checkBenefitsAvaiable = companyId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `checkBenefitsAvaiable/${companyId}`,
      }
    )
  )
