import { connectedApiService } from '..'
import { globalFleetFormData } from '../models/globalFleet'
import {
  globalFleetAssetFormData,
  globalFleetAssetImageFormData,
} from '../models/globalFleetAsset'
import { handleActionError } from './helpers'
import { supplyFleetFormData } from '../models/supplyFleet'
import { fileToBase64 } from '../helpers'
import { createOrEditItem, deleteItem } from './form'
import { logger } from './logger'
export const isModelExists = id => async dispatch => {
  try {
    return (
      (await connectedApiService.get(`global-fleet/brand/${id}/model`)) || {}
    )
  } catch (error) {
    logger({ fileName: 'fleet', error: error })
    return handleActionError(error)
  }
}

export const createGlobalFleet = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'global-fleet/vehicle',
      formatFormData: globalFleetFormData,
      successMessage: 'Car was successfully created',
    })
  )

export const editGlobalFleet = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `global-fleet/vehicle/${id}`,
      formatFormData: globalFleetFormData,
      successMessage: 'Car was successfully updated',
    })
  )

// export const deleteGlobalFleet = id => async dispatch =>
//   await dispatch(
//     deleteItem({
//       endpoint: `global-fleet/vehicle/${id}`,
//       successMessage: 'Car was successfully deleted',
//     })
//   )

export const toggleFleetStatus = (
  id,
  type = 'global',
  active = false
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { active },
      {
        isEdit: true,
        endpoint: `${type}-fleet/vehicle/${id}`,
        successMessage: 'Car was successfully updated',
      }
    )
  )

export const createGlobalFleetAsset = (
  globalFleetId,
  values
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-fleet/vehicle/${globalFleetId}/asset`,
      formatFormData: globalFleetAssetFormData,
      successMessage: 'Asset was successfully created',
    })
  )

export const deleteAssetImage = (
  imageId,
  assetId,
  globalFleetId
) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `global-fleet/vehicle/${globalFleetId}/asset/${assetId}/image/${imageId}`,
      successMessage: 'Asset was successfully deleted',
    })
  )

export const createAssetImage = (
  assetId,
  globalFleetId,
  values
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-fleet/vehicle/${globalFleetId}/asset/${assetId}/image`,
      formatFormData: globalFleetAssetImageFormData,
      successMessage: 'Asset was successfully created',
    })
  )

export const createSupplyFleet = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'supply-fleet/vehicle',
      formatFormData: supplyFleetFormData,
      successMessage: 'Car was successfully created',
    })
  )

export const editSupplyFleet = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `supply-fleet/vehicle/${id}`,
      formatFormData: supplyFleetFormData,
      successMessage: 'Car was successfully created',
    })
  )

export const addSupplyFleetImage = (
  supplierVehicleId,
  image
) => async dispatch => {
  try {
    const values = {
      image: await fileToBase64(image),
    }
    return await dispatch(
      createOrEditItem(values, {
        endpoint: `supply-fleet/vehicle/${supplierVehicleId}/image/`,
        successMessage: 'Image was successfully added',
      })
    )
  } catch (error) {
    logger({ fileName: 'fleet', error: error })
    return handleActionError(error)
  }
}

export const deleteSupplyFleetImage = (
  imageId,
  supplierVehicleId
) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `supply-fleet/vehicle/${supplierVehicleId}/image/${imageId}`,
      successMessage: 'Image was successfully deleted',
    })
  )
