import React, { useCallback, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { fileToBase64 } from '../../../../../helpers'
import MentionsTextarea from '../../../../../components/MentionsTextarea'
import { showNotification } from '../../../../../actions'
import FormInputFile from '../../../../../components/FormInputFile'
import {
  sendCommandSendMessageToChat,
  sendCommandTyping,
  emptyIsLateRequestState,
} from '../../../../../actions'
import { useGetTableData } from '../../../../../hooks'
import { debounce, each } from 'lodash'
import moment from 'moment'
import _ from 'lodash'

const MessageInput = ({
  sendCommandSendMessageToChat,
  showNotification,
  chatId,
  sendCommandTyping,
  emptyIsLateRequestState,
  company = null,
  isPanel = false,
  updateKeyboadState,
  isLateSearch,
  vehicleSearch,
  history,
  isMobileBrowser,
  activeUserId,
  companyId,
}) => {
  const [newMessage, setNewMessage] = useState('')
  const [textContent, setTextContent] = useState('')
  const [files, setFiles] = useState([])
  const [links, setLinks] = useState([])
  const {
    data: { list },
  } = useGetTableData(`bookingTriggersLinks`, { companyId: '' })

  // const fetchUrl = `chat/chats/bookingTriggersLinks/${activeUserId}/${companyId}`
  // const { currentItem: list } = useGetInitialFormValues_LS(fetchUrl, 'Chat_LS')

  const eachDay = data => {
    var string = ``
    each(data.days, (day, index) => {
      string =
        string +
        `
Day ${index + 1}:

From: 
${day.pickUpLocation.fullAddress}
${day.stops && day.stops.length ? eachStop(day) : ''}
${
  day.dropOffLocation && day.dropOffLocation.fullAddress
    ? `To: 
${day.dropOffLocation.fullAddress}`
    : ''
}
        
Pick up date: 
${moment(moment(day.pickUpDate, 'DD-MM-YYYY').toDate()).format('Do MMM YYYY')}
        
Pick up time:
${day.pickUpTime}

Duration:
${day.hours.value} hour${day.hours.value > 1 ? 's' : ''}
    `
    })
    return string
  }

  const eachStop = data => {
    var string = ``
    each(data.stops, (stop, index) => {
      string =
        string +
        `
Stop ${index + 1}:
${stop.location.fullAddress} (${stop.duration.label})
`
    })
    return string
  }

  useEffect(() => {
    if (
      vehicleSearch.searchValue &&
      isLateSearch &&
      isLateSearch.isLateSearch
    ) {
      var text = ''
      if (isLateSearch.searchValue.type === 'transfer') {
        text = `Hello, can you please assist me with the request below?

Transfer, ${
          isLateSearch.searchValue.title === 'Bespoke request'
            ? 'bespoke request'
            : 'less than 2 hours'
        }

From: 
${isLateSearch.searchValue.pickUpLocation.fullAddress}
`
        if (
          isLateSearch.searchValue.stops &&
          isLateSearch.searchValue.stops.length
        ) {
          text += eachStop(isLateSearch.searchValue)
        }

        text += `
To: 
${isLateSearch.searchValue.dropOffLocation.fullAddress}

Pick up date: 
${moment(
  moment(isLateSearch.searchValue.pickUpDate, 'DD-MM-YYYY').toDate()
).format('Do MMM YYYY')}

Pick up time:
${isLateSearch.searchValue.pickUpTime}
      `
      } else if (isLateSearch.searchValue.type === 'carRental') {
        text = `Hello, can you please assist me with the request below?

Car rental, ${
          isLateSearch.searchValue.title === 'Bespoke request'
            ? 'bespoke request'
            : 'less than 2 hours'
        }
        
From: 
${isLateSearch.searchValue.pickUpLocation.fullAddress}
        
To: 
${isLateSearch.searchValue.dropOffLocation.fullAddress}
        
Pick up date: 
${moment(
  moment(isLateSearch.searchValue.pickUpDate, 'DD-MM-YYYY').toDate()
).format('Do MMM YYYY')}
        
Pick up time:
${isLateSearch.searchValue.pickUpTime}

Drop off date:
${moment(
  moment(isLateSearch.searchValue.dropOffDate, 'DD-MM-YYYY').toDate()
).format('Do MMM YYYY')}

Drop off time:
${isLateSearch.searchValue.dropOffTime}
              `
      } else if (
        isLateSearch.searchValue.type === 'chauffeurService' &&
        isLateSearch.searchValue.durationType &&
        isLateSearch.searchValue.durationType.value === 'hourly'
      ) {
        text = `Hello, can you please assist me with the request below?

By the hour, ${
          isLateSearch.searchValue.title === 'Bespoke request'
            ? 'bespoke request'
            : 'less than 2 hours'
        }
        
From: 
${isLateSearch.searchValue.pickUpLocation.fullAddress}
`
        if (
          isLateSearch.searchValue.stops &&
          isLateSearch.searchValue.stops.length
        ) {
          text += eachStop(isLateSearch.searchValue)
        }

        text += `
${
  isLateSearch.searchValue.dropOffLocation &&
  isLateSearch.searchValue.dropOffLocation.fullAddress
    ? `To: 
${isLateSearch.searchValue.dropOffLocation.fullAddress}`
    : ''
}
        
Pick up date: 
${moment(
  moment(isLateSearch.searchValue.pickUpDate, 'DD-MM-YYYY').toDate()
).format('Do MMM YYYY')}
        
Pick up time:
${isLateSearch.searchValue.pickUpTime}

Duration:
${isLateSearch.searchValue.hours.value} hour${
          isLateSearch.searchValue.hours.value > 1 ? 's' : ''
        }
              `
      } else {
        text = `Hello, can you please assist me with the request below?

By the hour, ${
          isLateSearch.searchValue.title === 'Bespoke request'
            ? 'bespoke request'
            : 'less than 2 hours'
        }  
${
  isLateSearch.searchValue.days && isLateSearch.searchValue.days.length
    ? eachDay(isLateSearch.searchValue)
    : ''
}
              `
      }
      setNewMessage(text)
      setTextContent(text)
    } else {
      setNewMessage('')
      setTextContent('')
    }
  }, [isLateSearch, vehicleSearch])

  const getFormattedBookingList = useCallback(
    search => {
      const formattedSearch = search.includes('@booking')
        ? search.substring(8)
        : search.substring(6)
      return list
        .filter(({ number }) => number.toString().includes(formattedSearch))
        .map(
          ({
            id,
            number,
            pickUpLocation: { country, city },
            user,
            linkType,
          }) => {
            return {
              id,
              title:
                linkType === 'quote_link'
                  ? `quote ${number}`
                  : `booking ${number}`,
              location: `${city}, ${country}`,
              fullName: `${user.firstName} ${user.lastName}`,
              linkType: linkType,
            }
          }
        )
    },
    [list]
  )

  const onSendMessage = async evt => {
    evt.preventDefault()
    if (!chatId) {
      return
    }
    const messageData = {
      text: textContent,
    }
    if (files.length) {
      const formattedFileData = await Promise.all(
        files.map(async file => {
          let fileData = {}
          if (typeof file === 'object' && file.size) {
            fileData.content = await fileToBase64(file)
            fileData.name = file.name
          } else {
            fileData = {
              name: file ? file.name : '',
              content: file,
            }
          }
          return fileData
        })
      )
      messageData.files = formattedFileData
    }
    if (links.length) {
      messageData.links = links.map(({ id, display }) => {
        let messageNumber = ''
        let linkType = ''

        if (display.includes('@booking')) {
          messageNumber = display.substring(8)
          linkType = 'booking_link'
        } else if (display.includes('@quote')) {
          messageNumber = display.substring(6)
          linkType = 'quote_link'
        }

        const info = list.filter(({ number }) => number === messageNumber)

        if (info && info[0] && info[0].chatId) {
          return {
            type: 'booking_link',
            bookingId: id,
            bookingNumber: `${messageNumber}`,
            linkType: linkType,
            chatId: info[0].chatId,
            quoteIndex: info[0].quoteIndex,
            bookingType: info[0].bookingType,
          }
        } else {
          return {
            type: 'booking_link',
            bookingId: id,
            bookingNumber: `${messageNumber}`,
            linkType: linkType,
          }
        }
      })
    }
    if (textContent === '' && files.length === 0 && links.length === 0) {
      return false
    }

    sendCommandSendMessageToChat(chatId, messageData)
    setNewMessage('')
    setTextContent('')
    setFiles([])
    setLinks([])
    if (isLateSearch && isLateSearch.isLateSearch) {
      await emptyIsLateRequestState()
    }
  }

  const onChangeNewMessageText = useCallback(
    (e, newValue, newPlainTextValue, mentions) => {
      if (
        (mentions && mentions.length && links.length !== mentions.length) ||
        links.length < mentions.length
      ) {
        setLinks(mentions)
      }
      setTextContent(newPlainTextValue)
      setNewMessage(newValue)
    },
    [setNewMessage, setLinks, links]
  )

  const typeHandler = useCallback(debounce(sendCommandTyping, 300), [])

  const onKeyPressMessageText = useCallback(
    evt => {
      if (isMobileBrowser) {
        if (evt.charCode === 13 && evt.shiftKey) {
          evt.stopPropagation()
          typeHandler(chatId)
        } else {
          typeHandler(chatId)
        }
      } else {
        if (evt.charCode === 13 && evt.shiftKey) {
          evt.stopPropagation()
          typeHandler(chatId)
        } else if (evt.charCode === 13) {
          onSendMessage(evt)
        } else {
          typeHandler(chatId)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendCommandTyping, chatId, newMessage, onSendMessage]
  )

  const onFileChange = file => {
    if (file && file.size > 20000000) {
      showNotification({
        message: 'File exceeded 20 MB limit.',
        type: 'error',
      })
    } else {
      if (_.isArray(file)) {
        setFiles([...files, ...file])
      } else {
        setFiles([...files, file])
      }
    }
  }

  const setChatBoxFocus = useCallback(() => {
    document.body.classList.add('chat-box-focus')
    updateKeyboadState(true)
  })

  const lostChatBoxFocus = useCallback(evt => {
    if (newMessage !== '' && newMessage) {
      if (evt.relatedTarget && evt.relatedTarget.className === 'send-btn') {
        onSendMessage(evt)
      }
    }
    setTimeout(() => {
      document.body.classList.remove('chat-box-focus')
      document
        .getElementsByClassName('app-content')[0]
        .classList.remove('keyboard-open')
      updateKeyboadState(false)
    }, 1000)
  })
  return (
    <>
      <div
        className={`collected-files ${
          files && files.length ? '' : 'empty-collect-files'
        }`}
      >
        {files &&
          files.map((file, index) => (
            <div
              key={index}
              className='file-preview align-items-baseline justify-content-space-between'
            >
              <FormInputFile
                field={{
                  name: `files[${index}]`,
                  value: file,
                }}
                accept='application/pdf, image/*'
                needPreview={true}
                previewOnly={true}
                previewWidth='30px'
                needPdfPreview
                onlyPdfIconPreview
              />
              <div className='image-preview-label chat-room'>{file.name}</div>
              <Button
                variant='link'
                className='remove-file-btn align-content-end'
                onClick={() => {
                  files.splice(index, 1)
                  setFiles([...files])
                }}
              >
                <img src='/images/icons/remove.png' alt='' />
              </Button>
            </div>
          ))}
      </div>

      <div className='message-input-toolbar client-toolbar'>
        <div className='send-avatar-wrap'>
          <div className='send-avatar'>
            <img
              src={
                company ? company.logo : '/images/icons/chat/rolzo_avatar.png'
              }
              alt=''
            />
          </div>
        </div>
        <div className='message-input-wrap client-input-wrap'>
          <FormInputFile
            field={{
              name: isPanel ? 'files_p' : 'files',
              value: files,
              onChange: onFileChange,
            }}
            accept='application/pdf, image/*'
            multiple={true}
            handleChange={onFileChange}
            needPreview={true}
            chatPaperclip={true}
            renderField={() => (
              <div className='placeholder-icon-wrap'>
                <img
                  className='placeholder-icon'
                  src='/images/icons/chat/attachment.svg'
                  alt=''
                />
              </div>
            )}
          />
          <div className='textarea-chat-control'>
            <MentionsTextarea
              onChange={onChangeNewMessageText}
              onKeyPress={onKeyPressMessageText}
              data={search => getFormattedBookingList(search)}
              value={newMessage}
              onFocus={setChatBoxFocus}
              onBlur={lostChatBoxFocus}
              isClient={true}
              placeholder='Type a message...'
              trigger={
                /((@booking\d{1,4}-R\b|@quote\d{1,4}-R\b|@quote\d{1,4}-Z\b|@booking\d{1,4}-Z\b|@booking\d{1,4}|@quote\d{1,4}))$/
              }
              formatMention={(targetId, display) => {
                const [booking] = display.split(',')
                let bookingString = booking.split(' ').join('')
                let bookingNum = bookingString.split('#').join('')
                return `@${bookingNum}`
                //return `@${substrs[0]}`
              }}
              formatData={search => {
                return getFormattedBookingList(search).map(
                  ({ title, location, fullName, id }) => {
                    return {
                      id,
                      display: `${title}, ${location}, ${fullName}`,
                    }
                  }
                )
              }}
            />
          </div>
        </div>
        <div className='send-btn-wrap pr-4'>
          <a
            href='/'
            className='send-btn'
            disabled={chatId}
            onClick={evt => {
              onSendMessage(evt)
            }}
          >
            <img src='/images/icons/chat/send.png' alt='' />
          </a>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({
  appWebsocket,
  auth: { company = null, firstName, lastName, companyId } = {},
  vehicleSearch,
} = {}) => ({
  firstName,
  lastName,
  company,
  companyId,
  vehicleSearch: vehicleSearch,
  activeUserId: appWebsocket.activeUserId,
})

export default withRouter(
  connect(mapStateToProps, {
    sendCommandSendMessageToChat,
    sendCommandTyping,
    emptyIsLateRequestState,
    showNotification,
  })(MessageInput)
)
