import React, { useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { getIn, setIn } from 'formik'
import { DropdownIndicator, MultiValueContainer } from './FormSelectComponents'
import Currency from 'pages/App/CompanyPages/Payments/Currency'
import { updateSmartRates } from 'actions'

const { Control, Group, Label, Image } = Form

export default function FormSelectCurrency({
  field,
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  icon,
  options = [],
  handleChange,
  isMulti = false,
  // showSelectAll = false,
  isSearchable = false,
  disabled = false,
  hideSelectedOptions,
  showLabel = false,
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const wrapperRef = useRef(null)
  const selectRef = useRef(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [menuState, setMenuState] = useState(false)
  const onChange = value => {
    setFieldValue(field.name, value)
    if (handleChange && value) {
      setSelectedValue(null)
      handleChange(value)
    }
  }
  const onBlur = event => {
    if (!field.value) {
      setFieldValue(field.name, selectedValue)
      if (
        handleChange &&
        selectedValue &&
        !selectRef.current.state.menuIsOpen
      ) {
        handleChange(selectedValue)
      } else {
        setMenuState(true)
      }
    }
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }

  const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div
          className=' scrollable-page-content'
          style={{ maxHeight: '250px', height: 'auto' }}
        >
          {props.children}
        </div>
      </components.MenuList>
    )
  }
  const Option = props => {
    return (
      <div className='selectOptionButton  ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY'>
        <components.Option {...props}>
          <div className='d-flex align-items-center'>
            <img
              className='pb-1 pr-1'
              style={{ marginBottom: '0.5%', width: '30px' }}
              src={`/images/currencyFlags/${
                props.data.value.split(' ')[
                  props.data.value.split(' ').length - 1
                ]
              }.png`}
              alt=''
            />
            <span>{`${props.data.label} (${props.data.value
              .split(' ')
              [props.data.value.split(' ').length - 1].toUpperCase()})`}</span>
          </div>
        </components.Option>
      </div>
    )
  }
  const ValueContainerSearch = ({ children, ...props }) => {
    if (children) {
      if (props.selectProps.menuIsOpen && props.hasValue) {
        if (selectRef && selectRef.current) {
          selectRef.current.select.focus()
        }
      }
    }
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && !props.hasValue && (
            <img
              className='pb-1 pr-1 mr-1'
              style={{ width: '20px' }}
              src={
                props.hasValue
                  ? `/images/icons/Icon_Search_Active.png`
                  : `/images/icons/Icon_Search_Inactive.png`
              }
              alt=''
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }

  const ValueContainer = isMulti ? MultiValueContainer : ValueContainerSearch

  const onMenuOpen = (ref, evt) => {
    if (
      ref.current.select &&
      ref.current.select.commonProps &&
      ref.current.select.commonProps.hasValue
    ) {
      setSelectedValue(ref.current.props.value)
      ref.current.select.clearValue()
      setMenuState(true)
      var continueBtns = document.getElementsByClassName(
        'react-currencySelect__value-container'
      )
      continueBtns[0].classList.add(
        'react-currencySelect__value-container-hide'
      )
    }
  }
  return (
    <Group controlId={field.name} ref={wrapperRef}>
      <div style={{ borderBlockColor: 'red' }}>
        {showLabel ? (
          <Label className='form-label'>{props.label}</Label>
        ) : (
          <></>
        )}
        <Select
          components={{ DropdownIndicator, ValueContainer, Option, MenuList }}
          className={`react-currencySelect-container ${
            showError ? 'is-invalid' : ''
          } ${props.className || ''}`}
          classNamePrefix='react-currencySelect'
          placeholder={<div className={'pl-4'}>Choose currency...</div>}
          hideSelectedOptions={
            hideSelectedOptions !== undefined ? !!hideSelectedOptions : !isMulti
          }
          {...field}
          ref={selectRef}
          inputId={field.name}
          onBlur={e => onBlur(e)}
          blurInputOnSelect={false}
          onChange={e => onChange(e)}
          // openMenuOnFocus={true}
          onMenuOpen={evt => {
            onMenuOpen(selectRef, evt)
          }}
          onMenuClose={() => {
            setMenuState(false)
            var continueBtns = document.getElementsByClassName(
              'react-currencySelect__value-container'
            )
            continueBtns[0].classList.remove(
              'react-currencySelect__value-container-hide'
            )
          }}
          options={options}
          getOptionLabel={option => {
            return (
              <div className='selectOptionButtonCurrency'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img
                      style={{ width: '30px' }}
                      className='pb-1 pr-1'
                      src={`/images/currencyFlags/${
                        option.value.split(' ')[
                          option.value.split(' ').length - 1
                        ]
                      }.png`}
                      alt=''
                    />
                  </div>
                  <span>
                    {`${option.label} (${option.value
                      .split(' ')
                      [
                        option.value.split(' ').length - 1
                      ].toUpperCase()})`}{' '}
                  </span>
                </div>
              </div>
            )
          }}
          isSearchable={isSearchable}
          isDisabled={disabled}
          // menuIsOpen={menuState}
        />
      </div>
      {showError && (
        <Control.Feedback className='d-block' type='invalid'>
          {getIn(errors, field.name)}
        </Control.Feedback>
      )}
    </Group>
  )
}
