import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { logger } from './logger'
export const getContinent = values => async dispatch => {
  try {
    return (await api.post(`continents`, values)) || {}
  } catch (error) {
    logger({ fileName: 'continents', error: error })
    return handleActionError(error)
  }
}
