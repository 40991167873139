import { brandFormData } from '../models/brands'
import { createOrEditItem } from './form'

export const createBrand = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'global-fleet/brand',
      formatFormData: brandFormData,
      successMessage: 'Brand was successfully created',
    })
  )

export const editBrand = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `global-fleet/brand/${id}`,
      formatFormData: brandFormData,
      successMessage: 'Brand was successfully updated',
    })
  )
