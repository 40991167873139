import { createOrEditWebsiteItem, deleteWebsiteItem } from './websiteForm'

// image/uploadb64
// /cms-api/cities

export const addCity = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      endpoint: `cms-api/cities/`,
      successMessage: 'City was successfully Added',
    })
  )
export const updateCity = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      isEdit: true,
      endpoint: `cms-api/cities/${id}/update`,
      successMessage: 'City was successfully Updated',
    })
  )
export const deleteCity = id => async dispatch =>
  await dispatch(
    deleteWebsiteItem({
      endpoint: `cms-api/cities${id}`,
      successMessage: 'City was successfully Deleted',
    })
  )
export const uploadImage = values => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      endpoint: `cms-api/image/uploadb64`,
      successMessage: 'Image was successfully Uploaded',
    })
  )
export const publishCity = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      endpoint: `cms-api/cities/${id}/publish`,
      successMessage: 'City was successfully Published',
    })
  )
export const unPublishCity = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      endpoint: `cms-api/cities/${id}/unpublish`,
      successMessage: 'City was successfully Unpublished',
    })
  )
export const linkCity = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditWebsiteItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `cms-api/cities/${id}`,
      successMessage: `City was successfully ${
        values.is_linked ? 'Linked' : 'Unlinked'
      }.`,
    })
  )
