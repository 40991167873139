import React, { useState, useEffect } from 'react'
import { Form, Field, Formik } from 'formik'
import FormField from '../../../../../components/FormField'
import FormSelectAsync from '../../../../../components/FormSelectAsync'
import { CarInitialValues } from 'models/partner'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { withRouter, useLocation } from 'react-router-dom'
import SubmitBtn from '../../../../../components/SubmitBtn'
import { useGetInitialFormValues } from 'hooks'
import { requiredObject, requiredStringValidator } from 'form-validation'
import { createCar, editCar, deleteCar } from '../../../../../actions'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { connectedApiService as api } from '../../../../../index'
import * as Yup from 'yup'
import _ from 'lodash'
import { SmallDown } from 'components/Responsive/breakpoints'
import { SmallUp } from 'components/Responsive/breakpoints'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import ConfirmationModal from 'components/confirmationModal'
import {
  defaultExteriorColour,
  defaultInteriorColour,
  defaultModelYear,
  partnerHostLive,
  partnerHostStaging,
} from '../../../../../constants'
import FormSelect from 'components/FormSelect'

const Host = window.location.hostname
const validationSchema = Yup.object().shape({
  make: requiredObject(),
  model: requiredObject(),
  interiorColour: requiredStringValidator(),
  exteriorColour: requiredStringValidator(),
  year: requiredStringValidator(),
  plateNumber: requiredStringValidator(),
})

const itemDataParser = (initialValues, data) =>
  new CarInitialValues({ ...initialValues, ...data })

const EditCar = ({
  history,
  match,
  createCar,
  editCar,
  deleteCar,
  isCarRental,
}) => {
  const { params: { carId, token } = {} } = match || {}

  const carUrl = carId ? `external/car/edit/${carId}` : null

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const { currentItem, isLoading, initialValues } = useGetInitialFormValues(
    carUrl,
    validationSchema,
    itemDataParser
  )

  const [makeId, setMakeId] = useState(null)
  const [modelId, setModelId] = useState(null)
  const [vehicleImage, setVehicleImage] = useState(null)
  const [showModal, setShowModal] = useState(false)

  let location = useLocation()
  let currentLocation = location.pathname
  let isEdit = currentLocation && currentLocation.includes('/edit')

  if (initialValues && typeof initialValues.year === 'object') {
    initialValues.year = initialValues.year.value
  }

  const onConfirmationModal = async () => {
    await deleteCar(
      currentItem._id,
      currentItem.make,
      currentItem.model,
      currentItem.supplierId
    )
    setShowModal(false)
    history.push(
      `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/cars/account`
    )
    // await deleteCompanyUser(clientId, userId)
    // reloadList()
    // setShowConfirmationModal(false)
    // setUsertId(null)
  }

  const onSubmit = async (values, form) => {
    try {
      let id = currentItem ? currentItem._id : null

      if (values.plateNumber) {
        values.plateNumber = values.plateNumber.toUpperCase()
      }
      if (values.other) {
        values.year = values.other
        delete values.other
      }
      if (values.year && values.year.value) {
        values.year = values.year.value
      }
      if (values.exteriorColour && values.exteriorColour.value) {
        values.exteriorColour = values.exteriorColour.value
      }
      if (values.interiorColour && values.interiorColour.value) {
        values.interiorColour = values.interiorColour.value
      }
      if (id) {
        await editCar(values, id)
        history.goBack()
      } else {
        const res = await createCar(values, token, partnerType === 'rental')
        history.goBack()
      }

      form.setSubmitting(false)
    } catch (error) {}
  }

  const formatBrandItems = itm => {
    return {
      value: itm.id,
      label: itm.name,
    }
  }

  const onChangeMakeParams = (itm, values) => {
    setMakeId(itm.value)
    if (values && values.model) {
      values.model = null
    }
  }

  const onChangeModelParams = itm => {
    setModelId(itm.value)
  }

  useEffect(() => {
    const fetchVehicleImage = async () => {
      const url = `external/vehicleImage/${makeId}/${modelId}`
      const { data } = await api.get(url)

      if (data) setVehicleImage(data.imageUrl)
    }
    if (makeId && modelId) fetchVehicleImage()
  }, [makeId, modelId])

  const renderContent = () => {
    return (
      <div className='driver-booking-container' style={{ margin: 0 }}>
        <div className='driver-service-card assign-card-top-half'>
          <div className='driver-service-card-header pb-3 pt-3'>
            <span>
              {currentItem
                ? `${currentItem.make.label} ${currentItem.model.label}`
                : 'Add vehicle'}
            </span>
            {currentItem && (
              <img
                className='delete-icon-desktop'
                src='/images/icons/icon-delete.png'
                onClick={() => {
                  setShowModal(true)
                }}
                alt=''
              />
            )}
          </div>
        </div>
        <div className='driver-service-card assign-card-half'>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values }) => {
              return (
                <>
                  <Form noValidate>
                    <div className='driver-edit-container-desktop'>
                      {currentItem && (
                        <div className='car-container mb-3 ml-3'>
                          {vehicleImage ? (
                            <img src={vehicleImage} />
                          ) : currentItem && currentItem.vehicleImage ? (
                            <img src={currentItem.vehicleImage} />
                          ) : null}
                        </div>
                      )}
                      <div className='driver-edit-wrap'>
                        <div className='driver-fields'>
                          <Field
                            name={'make'}
                            label={'MAKE*'}
                            component={FormSelectAsync}
                            endpoint={`global-fleet/partnerBrands/${token}`}
                            formatItems={formatBrandItems}
                            isSearchable={true}
                            searchableParamKey={'name'}
                            handleChange={itm => {
                              onChangeMakeParams(itm, values)
                            }}
                          />
                          <Field
                            name={'model'}
                            label={'MODEL*'}
                            component={FormSelectAsync}
                            endpoint={
                              makeId
                                ? `global-fleet/partnerBrands/${makeId}/model/${token}`
                                : ''
                            }
                            formatItems={formatBrandItems}
                            editPartnerCar
                            isSearchable={true}
                            searchableParamKey={'name'}
                            handleChange={onChangeModelParams}
                          />
                          {fields.map((field, i, { component = FormField }) => {
                            return (
                              <>
                                {(!field.otherField ||
                                  (field.otherField &&
                                    values.year &&
                                    values.year.value === 'Other')) && (
                                  <Field
                                    key={i}
                                    component={component}
                                    {...field}
                                  />
                                )}
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='partner-next-container-form'>
                      <Button
                        className='partner-btn-desktop-tertiary select-vehicle'
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <SubmitBtn
                        className='partner-btn-desktop-primary select-vehicle'
                        variant='brown'
                        disabled={isSubmitting}
                        style={{
                          border: '1px solid #a79779',
                          backgroundColor: '#a79779',
                        }}
                      >
                        Save
                      </SubmitBtn>
                    </div>
                  </Form>
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }

  const fields = [
    {
      name: 'plateNumber',
      label: 'PLATE NUMBER*',
    },
    {
      name: 'year',
      label: 'YEAR*',
      component: FormSelect,
      options: defaultModelYear,
    },
    {
      name: 'other',
      label: 'SPECIFY YEAR*',
      otherField: true,
    },
    {
      name: 'exteriorColour',
      label: 'EXTERIOR COLOUR*',
      component: FormSelect,
      options: defaultExteriorColour,
    },
    {
      name: 'interiorColour',
      label: 'INTERIOR COLOUR*',
      component: FormSelect,
      options: defaultInteriorColour,
    },
  ]

  return (
    <>
      <SmallUp>
        {isEdit ? (
          [
            currentItem ? (
              <DefaultDriverDesktopLayout
                data={currentItem}
                renderContent={renderContent}
              />
            ) : (
              <> </>
            ),
          ]
        ) : (
          <DefaultDriverDesktopLayout
            data={null}
            renderContent={renderContent}
          />
        )}
      </SmallUp>
      <SmallDown>
        <div className='driver-edit-container'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <img
                className='driver-back-icon-account'
                src='/images/icon-back.png'
                onClick={history.goBack}
                alt=''
              />
              <div className='driver-booking-container-header'>
                {currentItem && currentItem.make && currentItem.model
                  ? currentItem.make.label + ' ' + currentItem.model.label
                  : 'Vehicle'}
                {currentItem && (
                  <img
                    style={{
                      position: 'absolute',
                      top: '19px',
                      width: '22px',
                      right: '30px',
                    }}
                    src='/images/icons/icon-delete.png'
                    onClick={() => {
                      setShowModal(true)
                    }}
                    alt=''
                  />
                )}
              </div>

              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, values }) => {
                  if (values.year && !values.year.label)
                    values.year = {
                      label: values.year,
                      value: values.year,
                    }
                  if (values.exteriorColour && !values.exteriorColour.label)
                    values.exteriorColour = {
                      label: values.exteriorColour,
                      value: values.exteriorColour,
                    }
                  if (values.interiorColour && !values.interiorColour.label)
                    values.interiorColour = {
                      label: values.interiorColour,
                      value: values.interiorColour,
                    }
                  return (
                    <>
                      {!currentItem && <h1>Add vehicle</h1>}

                      <div className='car-container'>
                        {currentItem && (
                          <>
                            {vehicleImage ? (
                              <img src={vehicleImage} />
                            ) : currentItem && currentItem.vehicleImage ? (
                              <img src={currentItem.vehicleImage} />
                            ) : null}
                          </>
                        )}
                      </div>
                      <Form noValidate>
                        <div className='driver-edit-wrap'>
                          <div className='driver-fields'>
                            <Field
                              name={'make'}
                              label={'MAKE*'}
                              component={FormSelectAsync}
                              endpoint={`global-fleet/partnerBrands/${token}`}
                              formatItems={formatBrandItems}
                              isSearchable={true}
                              searchableParamKey={'name'}
                              handleChange={itm => {
                                onChangeMakeParams(itm, values)
                              }}
                            />
                            <Field
                              name={'model'}
                              label={'MODEL*'}
                              component={FormSelectAsync}
                              endpoint={
                                makeId
                                  ? `global-fleet/partnerBrands/${makeId}/model/${token}`
                                  : ''
                              }
                              editPartnerCar
                              formatItems={formatBrandItems}
                              isSearchable={true}
                              searchableParamKey={'name'}
                              handleChange={onChangeModelParams}
                            />
                            {fields.map(
                              (field, i, { component = FormField }) => {
                                return (
                                  <>
                                    {(!field.otherField ||
                                      (field.otherField &&
                                        values.year &&
                                        values.year.value === 'Other')) && (
                                      <Field
                                        key={i}
                                        component={component}
                                        {...field}
                                      />
                                    )}
                                  </>
                                )
                              }
                            )}
                          </div>
                        </div>
                        <div className='driver-booking-cta-footer-acc'>
                          <SubmitBtn
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                      </Form>
                      <ConfirmationModal
                        onHide={() => {
                          setShowModal(false)
                        }}
                        handleFunction={onConfirmationModal}
                        show={showModal}
                        confirmationText={
                          'Are you sure you want to delete this chauffeur?'
                        }
                        confirmationTitle={'Delete chauffeur'}
                        changeConfirmationTitle
                      />
                    </>
                  )
                }}
              </Formik>
              <ConfirmationModal
                onHide={() => {
                  setShowModal(false)
                }}
                handleFunction={onConfirmationModal}
                show={showModal}
                confirmationText={
                  'Are you sure you want to delete this vehicle?'
                }
                confirmationTitle={'Delete vehicle'}
                changeConfirmationTitle
              />
            </>
          )}
        </div>
      </SmallDown>
    </>
  )
}

const mapStateToProps = ({ auth: { isCarRental = null } }) => ({
  isCarRental,
})

export default withRouter(
  connect(
    mapStateToProps,
    { createCar, editCar, deleteCar }
  )(EditCar)
)
