import { SET_MESSAGE_CLOSE, SET_MESSAGE_OPEN } from '../actions'

const INITIAL_STATE = {
  alertData: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE_OPEN:
      return {
        ...state,
        alertData: action.payload || null,
      }
    case SET_MESSAGE_CLOSE:
      return {
        ...state,
        alertData: null,
      }
    default:
      return state
  }
}
