import { connectedApiService } from '..'
import { handleActionError } from './helpers'
import { showNotification } from './notifications'
import { logger } from './logger'
export const createOrEditItem = (
  values,
  {
    isEdit = false,
    editRequest = 'patch',
    endpoint,
    formatFormData,
    successMessage,
    hideErrorMessage = false,
    authToken = null,
    userId,
    needAuth = true,
  }
) => async dispatch => {
  try {
    // console.log("values", values)
    // console.log("endPoint", endpoint)
    const formData = formatFormData ? await formatFormData(values) : values
    const request = isEdit ? editRequest : 'post'
    const result =
      (await connectedApiService[request](
        endpoint,
        formData,
        needAuth,
        authToken,
        userId,
        hideErrorMessage
      )) || {}
    if (successMessage) {
      dispatch(showNotification({ message: successMessage }))
    }
    return result
  } catch (error) {
    logger({ fileName: 'form', error: error })
    return handleActionError(error)
  }
}

export const deleteItem = ({ endpoint, successMessage }) => async dispatch => {
  try {
    const result = (await connectedApiService.del(endpoint)) || {}
    if (successMessage) {
      dispatch(showNotification({ message: successMessage }))
    }
    return result
  } catch (error) {
    logger({ fileName: 'form', error: error })
    return handleActionError(error)
  }
}
