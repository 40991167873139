import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { Button, Col, Form as FormB, Image } from 'react-bootstrap'
import FormField from '../../../../../components/FormField'
import FormSelect from '../../../../../components/FormSelect'
import SubmitBtn from '../../../../../components/SubmitBtn'
import {
  createPartner,
  setPartnerPassword,
  getDominapprove,
  logger,
  setMessageOpen,
  checkPartner,
  verifyPartnerPassword,
  loginUser,
  resetPartnerPassword,
  showNotification,
} from '../../../../../actions'
import SuccessMsg from '../../../../../components/SuccessMsg'
import { handleActionError } from '../../../../../actions/helpers'
import { connectedApiService as api } from '../../../../../index'
import { handleSubmitValidationErrors } from '../../../../../form-validation'
import FormSections from '../../../../../components/FormSections'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import { isMobile } from 'helpers'
const Host = window.location.hostname

const isMobileBrowser = isMobile()

function AuthForm({
  fields = [],
  formSections = [],
  history,
  buttonLabel = 'Submit',
  isRegistration = false,
  isLoginUser = false,
  isSetPassword = false,
  isEnterPassword = false,
  isForgotPassword = false,
  isResetPassword = false,
  email,
  logger,
  validationSchema,
  initialValues,
  setMessageOpen,
  createPartner,
  checkPartner,
  setPartnerPassword,
  verifyPartnerPassword,
  token,
  partnerEmail,
  loginUser,
  oldUrl = null,
  resetPartnerPassword,
  showNotification,
  setShowRegisterForm = () => {},
  setLocation = () => {},
  ...props
}) {
  const { Row: FormRow } = FormB
  const [isVerifyEmail, setIsVerifyEmail] = useState(false)
  // const [isRegisterEmail, setIsRegisterEmail] = useState(false)

  const backImage = window.innerWidth < 991 ? 'step_back.png' : 'back_svg.svg'
  const closeImage = window.innerWidth < 991 ? 'close_svg.svg' : 'close_svg.svg'
  const logIn = async obj => {
    const { partnerToken } = await loginUser(obj)
    if (!!oldUrl) {
      history.push(`${oldUrl}`)
    } else {
      history.push(
        `${
          Host === partnerHostLive || Host === partnerHostStaging
            ? '/'
            : '/partner/'
        }driver/app/${partnerToken}/home-page`
      )
    }
  }
  const onSuccessMessageClose = () => {
    history.goBack()
  }
  const onSubmit = async (values, form) => {
    try {
      if (isRegistration) {
        let size = values.fleetSize.label
        values.fleetSize = size
        await createPartner(values)
        // setIsRegisterEmail(true)
        setMessageOpen({
          title: `Thank you`,
          message: isMobileBrowser
            ? `We will review your application and get back to \nyou at ${values.email}.`
            : `We will review your application and get back to you at ${values.email}.`,
        })
      } else if (isLoginUser) {
        const { data } = await checkPartner(values)
        if (!data.companyVerified || !data.emailVerified) {
          if (!data.emailVerified && data.verifyEmailSent) {
            setIsVerifyEmail(true)
            setMessageOpen({
              title: `check your email`,
              message: `We sent an email to you at ${values.email}. it\n has a link to verify your email address and complete your \nregistration. Remember to look in your spam folder.`,
            })
          } else {
            setIsVerifyEmail(true)
            setMessageOpen({
              title: `Thank you`,
              message: `We are currently reviewing your application and get back to you at ${values.email}.`,
            })
          }
        } else if (data.emailVerified) {
          if (data.hasPassword) {
            history.push({
              pathname: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }auth/password`,
              email: values.email,
            })
          } else {
            history.push({
              pathname: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }auth/setpassword/${data.partnerToken}`,
              email: values.email,
            })
          }
        } else if (!data.emailVerified) {
          setMessageOpen({
            title: `Thank you`,
            message: `We are currently reviewing your application and get back to you at ${values.email}.`,
          })
        } else {
          if (data.verifyEmailSent) {
            setIsVerifyEmail(true)
            setMessageOpen({
              title: `check your email`,
              message: `We sent an email to you at ${values.email}. it\n has a link to verify your email address and complete your \nregistration. Remember to look in your spam folder.`,
            })
          }
        }
      } else if (isSetPassword) {
        await setPartnerPassword(values, token)
        history.push(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }auth/login`
        )
      } else if (isEnterPassword) {
        values.email = partnerEmail
        const { data } = await checkPartner(values)
        if (!data.companyVerified) {
          setMessageOpen({
            title: `Thank you`,
            message: `We are currently reviewing your application and get back to you at ${values.email}.`,
          })
        } else {
          const { partnerToken } = await loginUser(values)
          if (!!oldUrl) {
            history.push(`${oldUrl}`)
          } else {
            history.push(
              `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${partnerToken}/home-page`
            )
          }
        }
      } else if (isForgotPassword) {
        values.isPartner = true
        await api
          .post('password-reset-request', values, false)
          .then(function(response) {
            form.setSubmitting(false)
            if (response.meta.success) {
              setMessageOpen({
                title: `Now, check your email`,
                message: `Thank you, check your email for instructions \nto reset your password.`,
              })
            }
          })
          .catch(function(error) {
            logger({ fileName: 'partner forgot password', error: error })
            console.log(error)
            handleSubmitValidationErrors(error, form)
          })
      } else if (isResetPassword) {
        try {
          const { password } = values
          await resetPartnerPassword(token, password)
          form.setSubmitting(false)
          // history.push(`${Host === partnerHostLive || Host === partnerHostStaging  ? '/' : '/partner/'}auth/login`)
        } catch (error) {
          logger({ fileName: 'reset password', error: error })
          console.log(error)
          handleSubmitValidationErrors(error, form)
        }
      }
    } catch (error) {
      console.log('Error', error)
      logger({ fileName: 'partner a form', error: error })

      return handleActionError(error)
    } finally {
      form.setSubmitting(false)
    }
  }

  const onNextPage = values => {
    setLocation(values.place)
    setShowRegisterForm(true)
  }

  return (
    <>
      {isRegistration && (
        <>
          <div className='auth-wrap'>
            <header
              className='rolzo-header text-center'
              style={isMobileBrowser ? { marginTop: '0px' } : {}}
            >
              {!isMobileBrowser ? (
                // <div style={{ marginBottom: '40px' }}>
                <div className='height150'>
                  <img src='/images/rolzo_logo_black.png' alt='' />
                </div>
              ) : (
                <></>
              )}
              {isMobileBrowser ? <h3>Register</h3> : <></>}
              <p className='mb-0' style={{ marginTop: '0.8em' }}>
                <span className='text-dark'>Already using ROLZO? </span>
                <Link
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }auth/login`}
                  className='btn btn-anchor ml-1'
                >
                  Log in
                </Link>
              </p>
            </header>
            {!isMobileBrowser && (
              <h5
                className='success-msg-header d-flex justify-content-center'
                style={
                  isMobileBrowser
                    ? { marginBottom: '55px' }
                    : { marginTop: '4rem', marginBottom: '0.5rem' }
                }
              >
                Register
              </h5>
            )}
            <Formik
              {...props}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnBlur={true}
            >
              {({ values, isValid, isSubmitting, errors }) => {
                return (
                  <Form
                    noValidate
                    style={
                      isMobileBrowser
                        ? { marginTop: '39px', marginBottom: '58px' }
                        : {}
                    }
                  >
                    {fields.map(
                      ({ component = FormField, ...field }, index) => {
                        return (
                          <Field
                            key={index}
                            component={component}
                            {...field}
                            name={field.name}
                            controlId={field.name}
                          />
                        )
                      }
                    )}

                    {!!errors && !!errors.submitError && (
                      <div className='text-danger'>{errors.submitError}</div>
                    )}

                    <Button
                      className={`submit-btn ${props.submitStyle} ${
                        !isValid || isSubmitting ? 'disabled' : ''
                      }  `}
                      variant='brown'
                      disabled={!isValid || isSubmitting}
                      onClick={() => onNextPage(values)}
                    >
                      {buttonLabel}
                    </Button>
                    {/* <SubmitBtn
                      title={buttonLabel}
                      disabled={!isValid || isSubmitting}
                      className={props.submitStyle}
                    /> */}
                  </Form>
                )
              }}
            </Formik>
            {/* <SuccessMsg
              isRegisterEmail={isRegisterEmail}
              icon='/images/icons/Icon_Verify_email.png'
              closeHandler={onSuccessMessageClose}
            /> */}
          </div>
        </>
      )}
      {isLoginUser && (
        <>
          <div className='auth-wrap'>
            <header className='rolzo-header text-center'>
              <div className='height150'>
                <img src='/images/rolzo_logo_black.png' alt='' />
              </div>
              {window.innerWidth < 991 && <h3>Log in</h3>}
              <p>
                <span className='text-dark'>New to ROLZO? </span>
                <Link
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }auth/register`}
                  className='btn btn-anchor ml-1'
                >
                  Register
                </Link>
              </p>
            </header>
            {!isMobileBrowser && (
              <h5
                className='success-msg-header d-flex justify-content-center'
                style={{ marginTop: '4rem' }}
              >
                Log in
              </h5>
            )}
            <Formik
              {...props}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnBlur={true}
            >
              {({ values, isValid, isSubmitting, errors }) => {
                return (
                  <Form noValidate>
                    {fields.map(
                      ({ component = FormField, ...field }, index) => {
                        return (
                          <Field
                            key={index}
                            component={component}
                            {...field}
                            name={field.name}
                            controlId={field.name}
                          />
                        )
                      }
                    )}
                    <SubmitBtn
                      title={buttonLabel}
                      disabled={isSubmitting}
                      className={props.submitStyle}
                    />
                  </Form>
                )
              }}
            </Formik>
            <SuccessMsg
              isVerifyEmail={isVerifyEmail}
              icon='/images/icons/Icon_Verify_email.png'
              closeHandler={onSuccessMessageClose}
            />
            <header className='rolzo-header rolzo-login-footer text-center'>
              <p>
                <Link
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }auth/forgot-password`}
                  className='btn btn-anchor'
                >
                  {' '}
                  Forgot your password?{' '}
                </Link>
              </p>
            </header>
          </div>
        </>
      )}
      {isSetPassword && (
        <>
          <div className='auth-wrap'>
            <header className='rolzo-header text-center'>
              <div className='height150'>
                <img src='/images/rolzo_logo_black.png' alt='' />
              </div>
              {window.innerWidth < 991 && <h3>Log in</h3>}
            </header>
            <h5
              className='success-msg-header d-flex justify-content-center'
              style={{ marginTop: '4rem' }}
            >
              Set your password
            </h5>
            <Formik
              {...props}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnBlur={true}
            >
              {({ values, isValid, isSubmitting, errors }) => {
                return (
                  <Form noValidate>
                    {fields.map(
                      ({ component = FormField, ...field }, index) => {
                        return (
                          <Field
                            key={index}
                            component={component}
                            {...field}
                            name={field.name}
                            controlId={field.name}
                          />
                        )
                      }
                    )}
                    <SubmitBtn
                      title={buttonLabel}
                      disabled={isSubmitting}
                      className={props.submitStyle}
                    />
                  </Form>
                )
              }}
            </Formik>
          </div>
        </>
      )}
      {isEnterPassword && (
        <>
          <div className='auth-wrap'>
            <header className='rolzo-header text-center'>
              <div className='height150'>
                <img src='/images/rolzo_logo_black.png' alt='' />
              </div>
              {window.innerWidth < 991 && <h3>Log in</h3>}
            </header>
            <h5
              className='success-msg-header d-flex justify-content-center'
              style={{ marginTop: '4rem' }}
            >
              Enter password
            </h5>
            <Formik
              {...props}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnBlur={true}
            >
              {({ values, isValid, isSubmitting, errors }) => {
                return (
                  <Form noValidate>
                    {fields.map(
                      ({ component = FormField, ...field }, index) => {
                        return (
                          <Field
                            key={index}
                            component={component}
                            {...field}
                            name={field.name}
                            controlId={field.name}
                          />
                        )
                      }
                    )}
                    <SubmitBtn
                      title={buttonLabel}
                      disabled={isSubmitting}
                      className={props.submitStyle}
                    />
                  </Form>
                )
              }}
            </Formik>
            <SuccessMsg isLogin={true} closeHandler={onSuccessMessageClose} />
            <header className='rolzo-header rolzo-login-footer text-center'>
              <p>
                <Link
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }auth/forgot-password`}
                  className='btn btn-anchor'
                >
                  {' '}
                  Forgot your password?{' '}
                </Link>
              </p>
            </header>
          </div>
        </>
      )}
      {isForgotPassword && (
        <>
          <Formik
            {...props}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            isInitialValid
          >
            {({ isSubmitting }) => {
              return (
                <>
                  <div className='app-content'>
                    <div className='page-wrap w-100'>
                      <div className='scrollable-page-content nested-layout light-theme reset-password-layout'>
                        <nav className='navbar navbar-expand navbar-light'>
                          <span className='w-100 navbar-brand'>
                            <header className='mx-auto d-flex container align-items-center flex-nowrap justify-content-between'>
                              <Link
                                to={`${
                                  Host === partnerHostLive ||
                                  Host === partnerHostStaging
                                    ? '/'
                                    : '/partner/'
                                }auth/login`}
                                className='d-block d-sm-none m-0 reset-password-back-btn'
                              >
                                <img
                                  src={`/images/icons/reset-password-back.svg`}
                                  alt=''
                                  className='w-100'
                                />
                              </Link>
                              <h1 className='page-title h-100 justify-content-center d-flex justify-content-sm-start reset-passowrd-page-title p-0'>
                                {'Reset your password'}
                              </h1>
                              <Link
                                to={`${
                                  Host === partnerHostLive ||
                                  Host === partnerHostStaging
                                    ? '/'
                                    : '/partner/'
                                }auth/login`}
                                className='d-none d-sm-flex align-items-center light-close-btn'
                              >
                                <img
                                  src={`/images/icons/${closeImage}`}
                                  alt=''
                                />
                              </Link>
                            </header>
                            {/* <header className='mx-0 1'>
                              <div>
                                <Link
                                  to={`${
                                    Host === partnerHostLive ||
                                    Host === partnerHostStaging
                                      ? '/'
                                      : '/partner/'
                                  }auth/login`}
                                  className='layout-btn back-btn'
                                >
                                  <img
                                    src={`/images/icons/${backImage}`}
                                    alt=''
                                  />
                                </Link>

                                <h1 className='page-title'>
                                  {' '}
                                  {'Reset your password'}
                                </h1>
                              </div>
                              <Link
                                to={`${
                                  Host === partnerHostLive ||
                                  Host === partnerHostStaging
                                    ? '/'
                                    : '/partner/'
                                }auth/login`}
                                className='d-block d-sm-none m-0 reset-password-back-btn'
                              >
                                <img
                                  src={`/images/icons/reset-password-back.svg`}
                                  alt=''
                                  className='w-100'
                                />
                              </Link>
                              <h1 className='page-title h-100 justify-content-center d-flex justify-content-sm-start reset-passowrd-page-title p-0'>
                                {'Reset your password'}
                              </h1>
                              <Link
                                to={`${
                                  Host === partnerHostLive ||
                                  Host === partnerHostStaging
                                    ? '/'
                                    : '/partner/'
                                }auth/login`}
                                className='d-none d-sm-flex align-items-center light-close-btn'
                              >
                                <img
                                  src={`/images/icons/${closeImage}`}
                                  alt=''
                                />
                              </Link>
                            </header> */}
                          </span>
                        </nav>
                        <main className='hide-sidebar p-0'>
                          <Form
                            noValidate
                            className='form-wrap container reset-password p-0'
                          >
                            <div
                              className='sub-header'
                              style={{ marginBottom: '0px' }}
                            >
                              <FormSections sections={formSections} />
                            </div>
                            <div className='form-block wide rates-block container mt-0 pt-0'>
                              <FormRow>
                                <Col md='6 text-right'>
                                  <SubmitBtn
                                    className='forgot-password mt-3'
                                    title='Continue'
                                    disabled={isSubmitting}
                                  />
                                </Col>
                              </FormRow>
                            </div>
                          </Form>
                        </main>
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
          </Formik>
          <SuccessMsg closeHandler={onSuccessMessageClose} />
        </>
      )}
      {isResetPassword && (
        <>
          <Formik
            {...props}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            isInitialValid
          >
            {({ isSubmitting, values }) => {
              return (
                <>
                  <div className='app-content'>
                    <div className='page-wrap w-100'>
                      <div className='scrollable-page-content nested-layout light-theme reset-password-layout'>
                        <nav className='navbar navbar-expand navbar-light'>
                          <span className='w-100 navbar-brand'>
                            <header className='mx-0'>
                              <div>
                                <Link
                                  to={`${
                                    Host === partnerHostLive ||
                                    Host === partnerHostStaging
                                      ? '/'
                                      : '/partner/'
                                  }auth/login`}
                                  className='layout-btn back-btn'
                                >
                                  <img
                                    src={`/images/icons/${backImage}`}
                                    alt=''
                                  />
                                </Link>

                                <h1 className='page-title'>
                                  {'Create a new password'}
                                </h1>
                              </div>
                              <Link
                                to={`${
                                  Host === partnerHostLive ||
                                  Host === partnerHostStaging
                                    ? '/'
                                    : '/partner/'
                                }auth/login`}
                                className='layout-btn light-close-btn'
                              >
                                <img
                                  src={`/images/icons/${closeImage}`}
                                  alt=''
                                />
                              </Link>
                            </header>
                          </span>
                        </nav>
                        <main className='hide-sidebar'>
                          <Form noValidate className='form-wrap'>
                            <div className='sub-header'>
                              <FormSections sections={formSections} />
                            </div>
                            <div className='form-block wide rates-block container mt-0 pt-0'>
                              <FormRow>
                                <Col md='6 text-right'>
                                  <SubmitBtn
                                    className='forgot-password mt-3'
                                    title='Continue'
                                    disabled={isSubmitting}
                                  />
                                </Col>
                              </FormRow>
                            </div>
                          </Form>
                        </main>
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )
}

export default withRouter(
  connect(null, {
    getDominapprove,
    logger,
    setMessageOpen,
    createPartner,
    setPartnerPassword,
    checkPartner,
    verifyPartnerPassword,
    loginUser,
    resetPartnerPassword,
    showNotification,
  })(AuthForm)
)
