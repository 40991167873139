import { connectedApiService } from '..'
import { handleActionError } from './helpers'
import { createOrEditItem } from './form'
import { logger } from './logger'

export const getLocationAutoComplete = (values, cords) => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `googleAutoCompleteScript?searchValue=${values}&pickUpCords=${cords}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'autoComplete', error: error })
    return handleActionError(error)
  }
}

export const getGeoCodeByAddress = values => async dispatch => {
  try {
    var newValue = values.split('&').join('and')
    return (
      (await connectedApiService.get(
        `GeoCodeByAddress?location=${newValue}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'autoComplete', error: error })
    return handleActionError(error)
  }
}
export const saveGeoCodeByAddress = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'GeoCodeByAddress',
    })
  )
export const getPlaceDetailsById = placeId => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `GeoPlaceDetailsById?placeId=${placeId}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'autoComplete getPlaceDetailsById', error: error })
    return handleActionError(error)
  }
}
export const getPlaceDetailsByTextSearch = location => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `GeoPlaceDetailsByTextSearch?location=${location}`
      )) || {}
    )
  } catch (error) {
    logger({
      fileName: 'autoComplete getPlaceDetailsByTextSearch',
      error: error,
    })
    return handleActionError(error)
  }
}
export const getDirectionsFromGoogle = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'getDirectionsFromGoogle',
    })
  )
