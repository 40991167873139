import { createOrEditItem, deleteItem } from './form'
import { logger } from './logger'
export const setDefaultPaymentMethod = values => async (dispatch, getState) => {
  try {
    const { companyId, userId } = getState().auth
    return await dispatch(
      createOrEditItem(values, {
        endpoint: `company/client/${companyId}/user/${userId}/default-payment-credentials`,
        successMessage: 'Payment method was successfully saved',
      })
    )
  } catch (error) {
    logger({ fileName: 'payments', error: error })
    console.log(error)
    return null
  }
}

export const deletePaymentMethod = id => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `payment-credentials/${id}`,
      successMessage: 'Payment method was successfully deleted',
    })
  )

export const refundBookingPayment = (bookingId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/bookingRefund`,
    })
  )

export const refundMeetAndAssistPayment = (
  bookingId,
  values
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${bookingId}/vipRefund`,
    })
  )

export const changeBookingPrice = (bookingId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/changeBookingPrice`,
    })
  )

export const changeMeetAndAssistPrice = (bookingId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/changeMeetAndAssistPrice`,
    })
  )

export const changeBuyingPrice = (bookingId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${bookingId}/changeBuyingPrice`,
    })
  )
export const changeVipDispatchPrice = (bookingId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vip-rates/${bookingId}/changeBuyingPrice`,
    })
  )
