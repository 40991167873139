import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal, Col, Row, Form as FormB } from 'react-bootstrap'
import FormField from 'components/FormField'
import { Field, Form, Formik } from 'formik'
import { logger } from 'actions'
import { editCity, addCity } from 'actions/rolzoLocations'
import * as Yup from 'yup'
import {
  requiredNumberValidator,
  requiredStringValidator,
  stringValidator,
} from 'form-validation'
import { rolzoCitiesInitialVals } from 'models/rolzoLocations'

const { Row: FormRow } = FormB

const RolzoCityModal = ({
  show,
  onHide,
  editCity,
  addCity,
  isEdit,
  cityId,
  cityInfo,
  ...props
}) => {
  const onClose = () => {
    onHide()
  }

  const cityValidationSchema = Yup.object().shape({
    city: requiredStringValidator(),
    country: requiredStringValidator(),
    countryCode: requiredStringValidator(),
    region: stringValidator(),
    state: stringValidator(),
    lat: requiredNumberValidator(),
    lng: requiredNumberValidator(),
    locationName: stringValidator(),
  })

  const initialValues = cityInfo
    ? new rolzoCitiesInitialVals(cityInfo)
    : cityValidationSchema.cast()

  const onSubmit = async (values, form) => {
    try {
      let res = null
      if (values.countryCode !== 'US' || values.countryCode !== 'CA') {
        delete values.state
      }
      if (isEdit) {
        res = await editCity(values, cityId)
      } else {
        res = await addCity(values)
      }

      if (res) {
        onHide()
      }
    } catch (error) {
      console.log('onSubmit error', error)
      logger({ fileName: 'add city', error: error })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='dispatch-booking-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
      id={'modal'}
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <>
            <Row className='d-flex align-items-center'>
              <Col>
                <div>
                  <h1
                    className='dispatch-title mb-0 pb-0'
                    style={{
                      paddingLeft: '17px',
                      paddingTop: '24px',
                      fontSize: '24px',
                    }}
                  >
                    {isEdit ? 'Edit' : 'Add'} city
                  </h1>
                  <Button
                    variant='link'
                    className=''
                    onClick={onClose}
                    style={{ marginRight: '22px' }}
                  >
                    <img src='/images/icons/close-icon-black.png' alt='' />
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: '12px' }}>
        <Formik
          {...props}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={cityValidationSchema}
          validateOnBlur={true}
          initialValues={initialValues}
        >
          {({ values, isSubmitting, setFieldValue, isValid }) => {
            const fields = [
              {
                name: 'country',
                label: 'Country name*',
                md: 6,
              },
              {
                name: 'countryCode',
                label: 'Country code*',
                md: 6,
              },
              {
                name: 'state',
                label: 'State',
                md: 6,
              },
              {
                name: 'city',
                label: 'City name*',
                md: 6,
              },
              {
                name: 'lat',
                label: 'Lat*',
                type: 'number',
                step: 'any',
                md: 6,
              },
              {
                name: 'lng',
                label: 'Lng*',
                type: 'number',
                step: 'any',
                md: 6,
              },
              {
                name: 'locationName',
                label: 'Location',
                md: 6,
              },
              {
                name: 'region',
                label: 'Region',
                md: 6,
              },
            ]
            return (
              <Form noValidate style={{ padding: '24px', marginTop: '20px' }}>
                {fields && fields.length && (
                  <FormRow>
                    {fields.map(
                      (
                        {
                          component = FormField,
                          md = 6,
                          className = '',
                          ...field
                        },
                        f
                      ) => (
                        <>
                          <Col
                            key={String(f)}
                            md={md}
                            className={className || ''}
                          >
                            <Field component={component} {...field} />
                          </Col>
                        </>
                      )
                    )}
                  </FormRow>
                )}
                <div className='add-partner-submit'>
                  <Button
                    className='outsource-btn-partner select-vehicle'
                    style={{ width: '130px' }}
                    onClick={() => {
                      onHide()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='select-btn h-40 share ml-3 mr-0'
                    style={{ width: '130px', height: '35px' }}
                    variant='brown'
                    type='submit'
                    disabled={isSubmitting || !isValid}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(
  connect(null, { logger, editCity, addCity })(RolzoCityModal)
)
