import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { createOrEditItem, deleteItem } from './form'
import { bookingNotesFormData } from '../models/bookingNotes'
import { driversFormData } from '../models/driver'
import {
  TravelDocumentsFormData,
  TravelInformationFormData,
} from '../models/travelDocument'
import { chargesFormData, updateChargesFormData } from '../models/charges'
import { IS_VEHICLE_SEARCH_LATE, IS_VEHICLE_SEARCH_NOT_LATE } from './types'
import { logger } from './logger'
export const setIsLateRequestState = (payload, type) => dispatch => {
  payload.type = type
  dispatch({ type: IS_VEHICLE_SEARCH_LATE, payload: payload })
}
export const emptyIsLateRequestState = () => dispatch => {
  dispatch({ type: IS_VEHICLE_SEARCH_NOT_LATE })
}
export const searchWarningBanner = (
  pickUpLocation,
  pickUpDate,
  dropOffLocation
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        pickUpLocation: pickUpLocation,
        pickUpDate: pickUpDate,
        dropOffLocation: dropOffLocation,
      },
      {
        endpoint: 'warningBanner',
      }
    )
  )
export const searchVehicle = (filters = {}) => async () => {
  const queryParams = Object.keys(filters)
    .map(key => {
      if (key === 'to[fullAddress]' || key === 'from[fullAddress]') {
        filters[key] = filters[key].replace('&', 'and')
      }
      if (Array.isArray(filters[key])) {
        return filters[key].map(item => `${key}[]=${item}`).join('&')
      }
      return `${key}=${filters[key] || ''}`
    })
    .join('&')
  const url = `booking/vehicle?${queryParams}`
  try {
    const { data } = await api.get(url)
    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle', error: err })
    handleActionError(err)
  }
}
export const searchVehicleWithStops = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking/vehicle-multiStops',
    })
  )

export const searchVehicleWithStopsHourly = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking/vehicle-multiStops-byTheHour',
    })
  )
export const searchVehicleWithStopsDaily = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking/vehicle-multiStops-daily',
    })
  )

export const createNote = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/note`,
      formatFormData: bookingNotesFormData,
    })
  )

export const createBooking = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking',
    })
  )

export const addShareBookingUser = (id, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `sharebooking/${bookingId}/shareUser/${id}`,
      }
    )
  )
export const addDispatchBooking = (bookingId, list) => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {
        selectedSupplier: list.invites,
        price: list.price,
        serviceNotes: list.serviceNotes,
        localRate: list.localRate,
        localCurrency: list.localCurrency,
        isOperativeSupplier: list.isOperativeSupplier,
      },
      {
        endpoint: `booking/${bookingId}/dispatchBooking`,
      }
    )
  )
}
export const addDispatchBookingMobile = (bookingId, list) => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {
        selectedSupplier: list.invites,
        price: list.price,
        serviceNotes: list.serviceNotes,
        localRate: list.localRate,
        localCurrency: list.localCurrency,
        isOperativeSupplier: list.isOperativeSupplier,
      },
      {
        endpoint: `booking/${bookingId}/localSupplier/dispatchBooking`,
      }
    )
  )
}
export const addManualDispatch = (bookingId, list) => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {
        selectedSupplier: list.invites,
        price: list.price,
        serviceNotes: list.serviceNotes,
        localRate: list.localRate,
        localCurrency: list.localCurrency,
      },
      {
        endpoint: `booking/${bookingId}/manualDispatch`,
      }
    )
  )
}
export const addVipDispatch = (bookingId, list) => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {
        selectedSupplier: list.invites,
        price: list.price,
        vipServiceNotes: list.vipServiceNotes,
        localRate: list.localRate,
        localCurrency: list.localCurrency,
      },
      {
        endpoint: `booking/${bookingId}/vipDispatch`,
      }
    )
  )
}

export const cancelDispatchBooking = bookingId => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `booking/${bookingId}/dispatchBooking`,
      }
    )
  )
}
export const cancelDispatchBookingMobile = bookingId => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `booking/${bookingId}/localSupplier/dispatchBooking`,
      }
    )
  )
}
export const cancelVipDispatchBooking = bookingId => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `booking/${bookingId}/vipDispatch`,
      }
    )
  )
}
export const cancelDispatchPartner = (
  bookingId,
  supplierId
) => async dispatch => {
  return await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `booking/cancelDispatchPartner/${bookingId}/${supplierId}`,
      }
    )
  )
}

export const followShareBooking = (
  bookingNumber,
  bookingId,
  id,
  status
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'put',
        endpoint: `followSharebooking/${bookingId}/shareUser/${id}/${status}`,
        successMessage: `${
          status === 'Unfollow'
            ? `You have unfollowed booking #${bookingNumber}.`
            : `You are following booking #${bookingNumber}.`
        }`,
      }
    )
  )

export const removeShareBookingUser = (id, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'put',
        endpoint: `sharebooking/${bookingId}/shareUser/${id}`,
      }
    )
  )
export const editBooking = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${id}`,
    })
  )

export const removeStop = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/removeStop`,
    })
  )

export const addStop = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/addStop`,
    })
  )
export const removeDay = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/removeDay`,
    })
  )

export const addDay = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/addDay`,
    })
  )

export const sendChangeRequest = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, { endpoint: `booking/changeRequest/${id}` })
  )
export const confirmChangeRequest = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { values: values },
      { isEdit: true, endpoint: `booking/changeRequest/${id}` }
    )
  )

export const triggerManualPaymentBooking = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${id}/manualPayment`,
    })
  )
export const triggerManualPaymentAdditionalCharge = (
  values,
  id,
  chargeId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${id}/charge/${chargeId}/manualPayment`,
    })
  )

export const confirmSelfDrive = (values, id, messageId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${id}/confirm-selfDrive/${messageId}`,
    })
  )

export const addDriver = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/driver`,
    })
  )
export const addCollectionAgent = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/collectionAgent`,
    })
  )
export const addGreeter = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/greeter`,
    })
  )
export const msgToPassenger = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/msgToPassenger`,
    })
  )

export const greeterMsgToPassenger = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/greeterMsgToPassenger`,
    })
  )

export const agentMsgToPassenger = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/agentMsgToPassenger`,
    })
  )

export const cancelBooking = id => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `booking/${id}/cancel`,
      }
    )
  )
export const autoCancelBooking = id => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `booking/${id}/autoCancel`,
      }
    )
  )
export const completedBooking = id => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `booking/${id}/complete`,
      }
    )
  )

export const addMeetAndGreetToBooking = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/addMeetAndGreet`,
    })
  )

export const addCoverageToBooking = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/addCoverage`,
    })
  )

export const removeCancelCoverage = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/removeCancelCoverage`,
    })
  )

export const removeWaitCoverage = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${id}/removeWaitCoverage`,
    })
  )

export const editRentalCarDriver = (values, bookingId, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${bookingId}/drivers-info/${id}`,
      formatFormData: driversFormData,
    })
  )

export const addRentalCarDriver = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/drivers-info`,
      formatFormData: driversFormData,
    })
  )

export const confirmPaymentByAdmin = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/confirm`,
    })
  )
export const confirmPaymentMotoByAdmin = (
  values,
  bookingId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/confirmMoto`,
    })
  )
export const confirmDeferredPayment = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/deferredPayment`,
    })
  )

export const sentQuoteRequest = (values, bookingId) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/confirmRequest`,
    })
  )
}
export const createCharges = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/charge`,
      formatFormData: chargesFormData,
    })
  )

export const updateCharge = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/${bookingId}/updateCharge`,
      formatFormData: updateChargesFormData,
    })
  )

export const deleteCharge = (bookingId, chargeId) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `booking/${bookingId}/deleteCharge/${chargeId}`,
      successMessage: 'Charge was successfully Deleted',
    })
  )

export const refundChargePayment = (
  bookingId,
  chargeId,
  values
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/${chargeId}/chargeRefund`,
    })
  )

export const confirmChargesPayment = (
  bookingId,
  chargeId,
  isMoto,
  chargeNum
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { isMoto: isMoto, chargeNum: chargeNum },
      {
        endpoint: `booking/${bookingId}/charge/${chargeId}/confirm`,
      }
    )
  )
export const confirmChargesDeferredPayment = (
  bookingId,
  chargeId,
  chargeNum
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { chargeNum: chargeNum },
      {
        endpoint: `booking/${bookingId}/charge/${chargeId}/deferredPayment`,
      }
    )
  )
export const confirmChargesPaymentAuthorize = (
  bookingId,
  chargeId,
  isMoto
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { isMoto: isMoto },
      {
        // endpoint: `booking/${bookingId}/charge/${chargeId}/confirm`,
        endpoint: `booking/${bookingId}/charge/${chargeId}/confirm-authorize-only`,
      }
    )
  )
export const confirmChargesPaymentCapture = (
  bookingId,
  chargeId,
  amount
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { amount: amount },
      {
        // endpoint: `booking/${bookingId}/charge/${chargeId}/confirm`,
        // endpoint: `booking/${bookingId}/charge/${chargeId}/confirm-authorize-only`,
        endpoint: `booking/${bookingId}/charge/${chargeId}/confirm-capture`,
      }
    )
  )
export const confirmChargesPaymentRefundAll = (
  bookingId,
  chargeId
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { amount: 0 },
      {
        // endpoint: `booking/${bookingId}/charge/${chargeId}/confirm`,
        // endpoint: `booking/${bookingId}/charge/${chargeId}/confirm-authorize-only`,
        endpoint: `booking/${bookingId}/charge/${chargeId}/refundAll`,
      }
    )
  )

export const getBookingTimeZone = values => async dispatch => {
  try {
    return (await api.post(`booking-timeZone`, values)) || {}
  } catch (error) {
    logger({ fileName: 'getBookingTimeZone', error: error })
    return handleActionError(error)
  }
}

export const changeBookingVehicle = (
  bookingId,
  vehicleId,
  type
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `booking/${bookingId}/changeVehicle/${vehicleId}/${type}`,
        successMessage: `Vehicle successfully ${
          type === 'change' ? 'changed' : 'upgraded'
        }`,
      }
    )
  )

export const confirmMeetAndAssist = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/confirm`,
    })
  )

export const confirmMeetAndAssistMoto = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/confirmMoto`,
    })
  )

export const confirmMeetAndAssistManual = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vip-rates/${id}/confirmManual`,
    })
  )

export const confirmMeetAndAssistDeferred = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/deferredPayment`,
    })
  )

export const cancelMeetAndAssist = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/cancel`,
    })
  )
export const autoCancelMeetAndAssist = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/autoCancel`,
    })
  )

export const modifyBookingStatus = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/modifyStatus`,
    })
  )

export const modifyMeetAssistStatus = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `MeetAssist/${bookingId}/modifyStatus`,
    })
  )

export const confirmDeferredCardPayment = (
  values,
  bookingId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/deferredPaymentCard`,
    })
  )

export const confirmChargesDeferredCardPayment = (
  bookingId,
  chargeId
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `booking/${bookingId}/${chargeId}/deferredPaymentCard`,
      }
    )
  )

export const confirmMeetAndAssistDeferredCard = (
  values,
  id
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${id}/deferredPaymentCard`,
    })
  )

export const updateTravelDocuments = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${bookingId}/travelDocuments`,
      formatFormData: TravelDocumentsFormData,
    })
  )

export const updateTravelInformation = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/${bookingId}/travelInformation`,
      formatFormData: TravelInformationFormData,
    })
  )

export const modifyVehicleStatus = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vehicle/${bookingId}/modifyStatus`,
    })
  )

export const cancelQuote = (values, messageId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `quotes/${messageId}/cancel`,
    })
  )

export const markQuoteAsUnavailable = (values, messageId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `quotes/${messageId}/unavailable`,
    })
  )

export const getGlobalPolicy = () => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'get',
        endpoint: `globalPolicy`,
      }
    )
  )

export const addAdminNotes = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/adminNotes/${bookingId}`,
      successMessage: 'Note was successfully created',
    })
  )

export const editAdminNote = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/adminNotes/${bookingId}`,
      successMessage: 'Note was successfully updated',
    })
  )

export const deleteAdminNote = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `booking/adminNotes/${bookingId}`,
      successMessage: 'Note was successfully Deleted',
    })
  )

export const addQANotes = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `qaReport/notes/${bookingId}`,
      successMessage: 'Note was successfully created',
    })
  )

export const editQANote = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `qaReport/notes/${bookingId}`,
      successMessage: 'Note was successfully updated',
    })
  )

export const deleteQANote = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `qaReport/notes/${bookingId}`,
      successMessage: 'Note was successfully deleted',
    })
  )

export const switchFlightApiKey = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `switchFlightApi`,
    })
  )

export const createDraft = values => async dispatch =>
  await dispatch(
    createOrEditItem(
      { ...values },
      {
        endpoint: `draft`,
      }
    )
  )

export const updateDraft = (values, draftId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { ...values },
      {
        isEdit: true,
        endpoint: `draft/${draftId}`,
      }
    )
  )
export const cancelDraft = draftId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `draft/${draftId}/cancelled`,
      }
    )
  )
export const deleteDraft = draftId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'delete',
        endpoint: `draft/${draftId}`,
      }
    )
  )

export const linkDraft = (draftId, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `draft/link/${draftId}/${bookingId}`,
      }
    )
  )

export const createQuoteDraft = (values, messageId, index) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { ...values },
      {
        endpoint: `quoteDraft/${messageId}/${index}`,
      }
    )
  )

export const updateQuoteDraft = (values, draftId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { ...values },
      {
        isEdit: true,
        endpoint: `quoteDraft/${draftId}`,
      }
    )
  )
export const cancelQuoteDraft = draftId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        endpoint: `quoteDraft/${draftId}/cancelled`,
      }
    )
  )
export const deleteQuoteDraft = draftId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'delete',
        endpoint: `quoteDraft/${draftId}`,
      }
    )
  )

export const bookingChangesAdmin = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `bookingChanges/admin/${id}`,
      successMessage: 'Change was successfully added',
    })
  )

export const sendBookingChangesAdmin = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `bookingChanges/admin/${id}`,
      successMessage: 'Changes were successfully sent',
    })
  )
export const deleteBookingChangesAdmin = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'delete',
      endpoint: `bookingChanges/admin/${id}`,
      successMessage: 'Change was successfully deleted',
    })
  )
export const sendBookingChangesAdminManual = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `bookingChanges/admin/manual/${id}`,
      successMessage: `Changes marked as ${values.status}`,
    })
  )
export const updateQaReport = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `qaReport/${id}`,
    })
  )

export const getTrajectoryData = async url => {
  try {
    const { data } = await api.get(url)
    return data
  } catch (err) {
    handleActionError(err)
  }
}

export const markPassengerAsVip = id => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      isEdit: true,
      endpoint: `endClients/${id}`,
    })
  )

export const markBookingAsImportant = id => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      isEdit: true,
      endpoint: `booking/important/${id}`,
    })
  )

export const markEscalationBooking = id => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      isEdit: true,
      endpoint: `booking/escalation/${id}`,
    })
  )

export const markFollowUp = id => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      isEdit: true,
      endpoint: `booking/followUp/${id}`,
    })
  )

export const updateCancellationPolicy = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `booking/cancellationPolicy/${id}`,
      successMessage: 'Cancellation policy was successfully updated',
    })
  )

export const getGlobalRateLink = values => async dispatch => {
  try {
    return (await api.post(`getGlobalRateLink`, values)) || {}
  } catch (error) {
    logger({ fileName: 'getGlobalRateLink', error: error })
    return handleActionError(error)
  }
}

export const generatePaymentLink = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payment-link/${id}`,
      successMessage: 'Payment link was successfully generated ',
    })
  )
export const updatePaymentStatus = (values, id, type) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payment/status/${id}/${type}`,
      successMessage: 'Payment status was updated successfully.',
    })
  )

// export const updateFlightCounter = values => async dispatch =>
//   await dispatch(
//     createOrEditItem(values, {
//       endpoint: 'booking/flightApiCounter',
//     })
//   )

export const updateFlightCounter = (values = {}) => async () => {
  try {
    const url = `booking/flightApiCounter`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle', error: err })
    handleActionError(err)
  }
}
// export const airlabFlightCounter = values => async dispatch =>
//   await dispatch(
//     createOrEditItem(values, {
//       endpoint: 'booking/airlabApiCounter',
//     })
//   )

export const airlabFlightCounter = (values = {}) => async () => {
  try {
    const url = `booking/airlabApiCounter`
    const body = values || {}
    const data = await api.post(
      url,
      body,
      false,
      process.env.REACT_APP_B2C_TOKEN
    )

    return data
  } catch (err) {
    logger({ fileName: 'searchVehicle', error: err })
    handleActionError(err)
  }
}
export const editInternalBooking = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking/editInternalBooking',
    })
  )

export const fetchWeather = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'booking/weather',
    })
  )

export const fetchTraffic = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/traffic`,
    })
  )

export const saveSmartSuggestions = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/${bookingId}/saveSmartSuggestions`,
    })
  )
