import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import NotificationsClient from '../../pages/App/CompanyPages/NotificationsClient'
import ChatClient from '../../pages/App/CompanyPages/ChatClient/ChatClient'
import ClientChatBot from '../../pages/App/CompanyPages/ChatClient/ClientChatBot'
import { withRouter } from 'react-router-dom'
import { SmallUp } from '../Responsive/breakpoints'
import { emptyIsLateRequestState, setMessageOpen } from '../../actions'
import SuccessMsg from 'components/SuccessMsg'
import EventChat from 'components/Events/EventChat'

function Supportbar({
  // unreadMessages,
  notifications,
  history,
  location,
  chatsList,
  onBack = null,
  emptyIsLateRequestState,
  isIframeView = false,
  showComPopup,
  setMessageOpen,
  isEventPage,
  company,
  eventTabs = () => {},
  setCreatedId,
  setCreatedBooking,
  setDoorToDoorValues,
  setFlightTrackingPickUp,
  setFlightTrackingDropOff,
  ...prop
}) {
  const [bookingChat, setBookingChat] = useState()
  const [unreadMessages, setUnreadMessages] = useState(0)
  const checkEventNotification = location => {
    var search = location.search
    var data = search.split('?')
    if (data) {
      var expand = false
      data.forEach(element => {
        if (element.search('show_notifications') !== -1) {
          expand = element.split('=')[1] === '1' ? true : false
        }
      })
      return expand
    } else {
      return false
    }
  }
  const checkEventChat = location => {
    var search = location.search
    var data = search.split('?')
    if (data) {
      var expand = false
      data.forEach(element => {
        if (element.search('show_chat') !== -1) {
          expand = element.split('=')[1] === '1' ? true : false
        }
      })
      return expand
    } else {
      return false
    }
  }
  const handleMobileEventNavigation = location => {
    var search = location.search
    var data = search.split('&')
    if (data) {
      var chat = false
      var notification = false
      data.forEach(element => {
        if (element.search('mobileChat') !== -1) {
          chat = element.split('=')[1] === '1' ? true : false
        }
        if (element.search('mobileNotification') !== -1) {
          notification = element.split('=')[1] === '1' ? true : false
        }
      })
      if (chat) {
        history.push('/chat-client/')
      } else if (notification) {
        history.push('/notifications-client/')
      }
    }
  }
  let [isChatExpanded, setChatExpanded] = useState(false)
  let [isEventChatExpanded, setEventChatExpanded] = useState(false)
  let [isNotificationExpanded, setNotificationExpanded] = useState(false)
  let [isChatBotExpanded, setChatBotExpanded] = useState(false)

  var expanded = isChatExpanded || isNotificationExpanded || isChatBotExpanded
  onPanelExpanded(expanded)

  useEffect(() => {
    const found = chatsList.find(chat => chat.type === 'chat_with_admin')
    if (found && found.messages && bookingChat !== found) {
      if (
        found &&
        bookingChat &&
        found.messages.length !== bookingChat.messages.length
      ) {
        setUnreadMessages(found.unreadByUserMessagesCount)
        setBookingChat(found)
      } else if (!bookingChat) {
        setUnreadMessages(found.unreadByUserMessagesCount)
        setBookingChat(found)
      } else if (isChatExpanded) {
        if (
          found &&
          bookingChat &&
          found.messages.length === bookingChat.messages.length
        ) {
          setUnreadMessages(found.unreadByUserMessagesCount)
          setBookingChat(found)
        }
      }
    }
  }, [isChatExpanded, chatsList, setBookingChat])

  useEffect(() => {
    setTimeout(() => {
      // if (location.search) {
      setChatExpanded(checkEventChat(location))
      setNotificationExpanded(checkEventNotification(location))
      handleMobileEventNavigation(location)
      // }
    }, 500)
  }, [])

  function closePanel() {
    setNotificationExpanded(false)
    setChatExpanded(false)
    setEventChatExpanded(false)
    setChatBotExpanded(false)
    onPanelExpanded(false)
    emptyIsLateRequestState()
  }

  function onBackClick(e) {
    e.preventDefault()
    if (onBack) {
      onBack()
    } else {
      if (location.alertData === 'success-message') {
        const chauffeurType = location.chauffeurType
        const journeyInfo = location.journeyInfo
        const returnInfo = location.returnInfo

        setMessageOpen({
          title: `Thank you for your ${
            chauffeurType === 'carRental' ? 'request' : 'booking'
          }`,
          message: `You will receive a ${
            chauffeurType === 'carRental'
              ? 'quote shortly'
              : 'booking confirmation shortly'
          }`,
          textLink: `View ${
            chauffeurType === 'carRental' ? 'request' : 'booking'
          }`,
          return: location.returnId ? true : false,
          ...(journeyInfo ? { airportTo: journeyInfo.airportTo } : {}),
          ...(journeyInfo ? { airportFrom: journeyInfo.airportFrom } : {}),
          ...(returnInfo ? { airportTo: returnInfo.airportTo } : {}),
          ...(returnInfo ? { airportFrom: returnInfo.airportFrom } : {}),
        })

        setCreatedId(location.createdId)
        setCreatedBooking(location.createdBooking)
        setDoorToDoorValues(location.doorToDoorValues)
        setFlightTrackingDropOff(location.flightTrackingDropOff)
        setFlightTrackingPickUp(location.flightTrackingPickUp)
      } else {
        if (history.location.pathname === '/booking/main/new-booking') {
          history.push('/')
        } else {
          history.goBack()
        }
      }
    }
  }

  function onForwardClick(e) {
    e.preventDefault()
    history.goForward()
  }

  function onPanelExpanded(expanded) {
    // if (document.getElementById('app-page') === null) {
    //   return
    // }
    // if (expanded) {
    //   document
    //     .getElementById('app-page')
    //     .firstElementChild.classList.add('page-with-panel')
    // } else {
    //   document
    //     .getElementById('app-page')
    //     .firstElementChild.classList.remove('page-with-panel')
    // }
  }

  return (
    <SmallUp>
      <div
        className={`supportbar-wrap ${
          expanded ? 'supportbar-wrap-expaned page-with-panel' : ''
        }`}
      >
        <div
          className={isEventPage ? 'event-supportbar-gap' : 'supportbar-gap'}
        >
          {isEventPage ? (
            <>
              <div
                className='event-supportbar-btn-wrap'
                onClick={e => {
                  onBackClick(e)
                }}
              >
                <img
                  className='event-supportbar-img'
                  src='/images/icon_arrow_back_bold.png'
                  alt=''
                />
              </div>
              <span className='event-supportbar-company'>{company.name}</span>
            </>
          ) : (
            //prettier-ignore
            <div
            className='supportbar-btn-wrap'
            onClick={e => {
              onBackClick(e)
            }}
          >
            <img
              className='supportbar-img'
              src='/images/icons/Arrow-Left.png'
              alt=''
            />
          </div>
          )}

          {isEventPage ? (
            <></>
          ) : (
            //prettier-ignore
            <div
            className='supportbar-btn-wrap'
            onClick={e => {
              onForwardClick(e)
            }}
          >
            <img
              className='supportbar-img'
              src='/images/icons/Arrow-Right.png'
              alt=''
            />
          </div>
          )}
        </div>
        {eventTabs()}
        {isEventPage ? <></> : <></>}
        {!isIframeView ? (
          // prettier-ignore
          <> 
          {/* <div 
          className='supportbar-btn-wrap'          
          onClick={() => {
            setNotificationExpanded(false)
            setChatExpanded(false)
            setChatBotExpanded(true)
            onPanelExpanded(true)
          }}> 
            <img
              className='supportbar-img'
              src={`${
                isChatBotExpanded ? '/images/icons/chat-ai-active.png' : '/images/icons/chat-ai-inactive.png'
              }`}
              alt=''
              style={{ height: '20px', width: '25px' }}
            />
          </div>
          <div className='supportbar-btn-splitter' /> */}
        <div
          className='supportbar-btn-wrap'
          onClick={() => {
            setNotificationExpanded(false)
            setChatBotExpanded(false)
            if (isEventPage) {
              console.log('isEventChat')
              setEventChatExpanded(true)
            } else {
              setChatExpanded(true)
            }
            onPanelExpanded(true)
          }}
        >
          <img
            className='supportbar-img'
            src={`${
              isChatExpanded ? '/images/icon_chat_active.png' : '/images/icon_chat_inactive.png'
            }`}
            alt=''
          />
          {unreadMessages && unreadMessages > 0 && !isChatExpanded ? (
            <div className='supportbar-unread-indicator' />
          ) : (
            ''
          )}
        </div>
        <div className='supportbar-btn-splitter' />
        <div
          className={`supportbar-btn-wrap`}
          style={{
            paddingRight: `${isNotificationExpanded || isChatBotExpanded || isChatExpanded || isEventPage ? '15px' : '35px'}`,
          }}
          onClick={() => {
            setChatExpanded(false)
            setChatBotExpanded(false)
            setNotificationExpanded(true)
            onPanelExpanded(true)
          }}
        >
          <img
            className='supportbar-img'
            src={`${
              isNotificationExpanded
                ? '/images/icon_notification_active.png'
                : '/images/icon_notification_inactive.png'
            }`}
            alt=''
          />
          {notifications && notifications > 0 ? (
            <div className='supportbar-unread-indicator' />
          ) : (
            ''
          )}
        </div>
        </>
        ) : (
          <></>
        )}
        <NotificationsClient
          isPanel={true}
          isExpanded={isNotificationExpanded}
          closePanel={closePanel}
        />
        <ChatClient
          isPanel={true}
          isExpanded={isChatExpanded}
          closePanel={closePanel}
        />
        {/* <ClientChatBot
          isPanel={true}
          isExpanded={isChatBotExpanded}
          closePanel={closePanel}
        /> */}
        <EventChat
          isPanel={true}
          isExpanded={isEventChatExpanded}
          closePanel={closePanel}
        />
      </div>
      {<SuccessMsg isPayment />}
    </SmallUp>
  )
}

// const getUnreadMsgLength = appWebsocket => {
//   if (appWebsocket.chatsList.length) {
//     const found = appWebsocket.chatsList.find(
//       chat => chat.type === 'chat_with_admin'
//     )
//     return found ? found.unreadByUserMessagesCount : 0
//   }
// }
const getUnReadNotificationsLength = appWebsocket => {
  if (appWebsocket.notificationsList && appWebsocket.notificationsList.length) {
    const found = appWebsocket.notificationsList.filter(
      notification => notification.readStatus === false
    )
    return found.length
  } else {
    return 0
  }
}

const mapStateToProps = ({ appWebsocket }) => ({
  chatsList: appWebsocket.chatsList,
  // unreadMessages: getUnreadMsgLength(appWebsocket),
  notifications: getUnReadNotificationsLength(appWebsocket),
  showComPopup: appWebsocket.showComPopup,
})

export default withRouter(
  connect(mapStateToProps, {
    emptyIsLateRequestState,
    setMessageOpen,
  })(Supportbar)
)
