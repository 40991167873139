import React from 'react'
import { components } from 'react-select'

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src='/images/icons/caret-down.svg' alt='' />
      </components.DropdownIndicator>
    )
  )
}

export const MultiValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue()

  const { onSelectAll, selectedAll, menuIsOpen, placeholder } =
    props.selectProps || {}

  return (
    <components.ValueContainer {...props}>
      {!menuIsOpen && !!values.length && (
        <div className='select-multiple-value'>
          {values.map(({ label }, i) => label).join(', ')}
        </div>
      )}
      {!menuIsOpen && !values.length && (
        <div className='react-select__placeholder'>{placeholder}</div>
      )}
      {!!menuIsOpen && !!onSelectAll && (
        <div
          className={`select-all-btn ${selectedAll ? 'selected-all' : ''}`}
          onMouseDown={onSelectAll}
        >
          <span className='btn-title'>Select all</span>
        </div>
      )}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  )
}
