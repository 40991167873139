import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Row, Badge } from 'react-bootstrap'
import { useGetTableData } from '../../hooks'
import useBottomScrollListener from '../../hooks/useBottomScrollListener'
import FormField from '../FormField'
import FormCheckbox from '../FormCheckbox'
import {
  addDispatchBookingMobile,
  addManualDispatch,
  logger,
  updateServiceNotes,
} from '../../actions'
import { Field } from 'formik'
import { getFormattedPrice, getLabelFromValue, numberWithCommas } from 'helpers'
import { defaultCurrencies } from '../../constants'
import LoadingSpinner from '../LoadingSpinner'
import FormFieldWithIcon from '../FormFieldWithIcon'
import { Toggle } from 'components/toggle'
import SubmitBtn from 'components/SubmitBtn'

let order = ['EXCLUSIVE', 'NEW', 'GOLD', 'ACTIVE', 'SILVER', 'BACKUP']

const DispatchPartnerModalMobile = ({
  show,
  onHide,
  currentItem,
  addDispatchBookingMobile,
  serviceNotes = '',
  logger,
  localCurrency,
  dispatchedSuppliers,
  supplierCurrency,
  updateBooking,
  buyingPrice,
  originalPrice,
  updateServiceNotes,
  isApiBooking = false,
  bookingId,
}) => {
  const scrollRef = useRef(null)
  const filterOperators = useMemo(
    () => ({
      verified: 'eq',
      selectRental: 'eq',
      selectChauffeur: 'eq',
      active: 'eq',
      twentyFourHourService: 'eq',
    }),
    []
  )
  const initialFilters = {
    name: '',
    verified: 'true',
    vehicleName: currentItem.vehicleName,
  }
  const [invites, setInvites] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [noSupplierSelected, setNoSupplierSelected] = useState(false)
  const [newLocalList, setNewLocalList] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [isEditingNotes, setIsEditingNotes] = useState(
    serviceNotes ? false : true
  )
  const [updatedRate, setUpdatedRate] = useState(0)
  const [marginPercentage, setMarginPercentage] = useState(null)
  const [marginAmount, setMarginAmount] = useState(null)
  const [showDispatchCancelModal, setshowDispatchCancelModal] = useState(false)
  const [isTwentyFourService, setIsTwentyFourService] = useState(false)
  // console.log('currentItem', currentItem)
  // const changeTab = tab => {
  //   setSelectedTab(tab)
  //   setInvites([])
  //   setSelectAll(false)
  // }

  let setUrl =
    currentItem.type === 'chauffeur_services'
      ? `company/partner/${currentItem.pickUpLocation.lng}/${
          currentItem.pickUpLocation.lat
        }/${null}/${null}`
      : `company/partner/${currentItem.pickUpLocation.lng}/${currentItem.pickUpLocation.lat}/${currentItem.dropOffLocation.lng}/${currentItem.dropOffLocation.lat}`

  const {
    data: { list: localList },
    getNextPage,
    filters,
    reloadList,
    onFiltersChange,
    setFilters,
    isLoading,
  } = useGetTableData(setUrl, initialFilters, { filterOperators })

  useEffect(() => {
    if (currentItem) {
      if (currentItem.type === 'car_rentals') {
        setFilters({ ...filters, selectRental: true, active: true })
      } else {
        setFilters({ ...filters, selectChauffeur: true, active: true })
      }
    }

    setUpdatedRate(parseFloat(buyingPrice))
  }, [currentItem, buyingPrice])

  useEffect(() => {
    if (localList && localList.length > 0) {
      let mutantList = localList.sort(({ kpiInfo: a }, { kpiInfo: b }) => {
        if (!a.score) a.score = 0
        if (!b.score) b.score = 0
        return b.score - a.score
      })

      if (dispatchedSuppliers && dispatchedSuppliers.length > 0) {
        mutantList.forEach(listItems => {
          dispatchedSuppliers.forEach(item => {
            if (
              listItems.id === item.supplierId &&
              (item.status === 'dispatched' || item.status === 'opened')
            ) {
              listItems.dispatched = true
              if (!invites.some(item => item.supplierId === listItems.id)) {
                const emails = listItems.contactDetails.map(
                  item => item.contactsEmail
                )
                setInvites(prev => [
                  ...prev,
                  {
                    supplierId: listItems.id,
                    name: listItems.name,
                    emails: emails,
                    status: 'dispatched',
                    supplierCurrency: listItems.currency,
                    bookingCurrency: currentItem.bookingTimeRateInfo.currency,
                    buyingPrice: parseFloat(updatedRate),
                  },
                ])
              }
            }
          })
        })
      } else if (!dispatchedSuppliers.length && invites.length) {
        mutantList.forEach(item => {
          delete item.dispatched
        })

        // setInvites([])
      }

      if (mutantList && mutantList.length) {
        mutantList.sort((a, b) => {
          // Compare dispatched status first (true values first)
          const aDispatched = a.dispatched ? 1 : 0
          const bDispatched = b.dispatched ? 1 : 0
          if (aDispatched !== bDispatched) {
            return bDispatched - aDispatched
          }
          // Then compare based on the 'order' array
          return (
            order.indexOf(a.status.value.toUpperCase()) -
            order.indexOf(b.status.value.toUpperCase())
          )
        })
      }
      setNewLocalList(mutantList)
    }
  }, [newLocalList, localList, dispatchedSuppliers.length])

  useEffect(() => {
    let rate = updatedRate ? updatedRate : buyingPrice
    setMarginPercentage(
      +(((originalPrice - rate) / originalPrice) * 100).toFixed(2)
    )
    setMarginAmount((originalPrice - rate).toFixed(2))
  }, [updatedRate, originalPrice])
  const onSubmit = async values => {
    try {
      setSubmitting(true)
      let serviceNotes = document.getElementById('serviceNotes')
        ? document.getElementById('serviceNotes').innerText
        : ''

      // let updatedArray = invites.map(item => {
      //   return {
      //     ...item,
      //     buyingPrice: updatedRate,
      //   }
      // })

      if (invites.length) {
        if (selectedTab === 0) {
          await addDispatchBookingMobile(currentItem._id, {
            invites: invites,
            price: parseFloat(updatedRate),
            serviceNotes: serviceNotes,
            localRate: parseFloat(updatedRate),
            localCurrency: localCurrency,
            isOperativeSupplier: true,
          })
        }

        if (currentItem) {
          if (currentItem.type === 'car_rentals') {
            setFilters({
              name: '',
              verified: 'true',
              selectRental: true,
              active: true,
            })
          } else {
            setFilters({
              name: '',
              verified: 'true',
              selectChauffeur: true,
              active: true,
            })
          }
        }

        setInvites([])
        onHide()
        reloadList()
      } else {
        setNoSupplierSelected(true)
      }

      await updateBooking()
    } catch (err) {
      logger({ fileName: 'DispatchBookingModal', error: err })
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  const addToDispatch = async (id, name, emails, currency) => {
    if (selectAll) {
      setSelectAll(false)
    }
    let isManual = selectedTab === 2 && invites.length === 1
    if (invites.some(item => item.supplierId === id)) {
      const inviteIndex = invites.findIndex(item => item.supplierId === id)
      invites.splice(inviteIndex, 1)
      setInvites([...invites])
    } else {
      if (!isManual) {
        setNoSupplierSelected(false)
        setInvites([
          ...invites,
          {
            supplierId: id,
            name: name,
            emails: emails,
            status: 'dispatched',
            supplierCurrency: currency,
            bookingCurrency: currentItem.bookingTimeRateInfo.currency,
            buyingPrice: parseFloat(updatedRate),
          },
        ])
      }
    }
  }

  const toggleAllSuppliers = async () => {
    if (!selectAll) {
      setNoSupplierSelected(false)
      setSelectAll(true)
      let inviteList = []
      localList.map(item =>
        inviteList.push({
          supplierId: item.id,
          name: item.name,
          emails: item.contactDetails
            .filter(contact => contact.excludeComms !== true)
            .map(contact => contact.contactsEmail),
          status: 'dispatched',
          supplierCurrency: item.currency,
          bookingCurrency: currentItem.bookingTimeRateInfo.currency,
          buyingPrice: parseFloat(updatedRate),
        })
      )
      setInvites(inviteList)
    } else {
      setSelectAll(false)
      setInvites([])
    }
  }

  useBottomScrollListener(getNextPage, scrollRef)

  const onClose = () => {
    if (currentItem) {
      if (currentItem.type === 'car_rentals') {
        setFilters({
          name: '',
          verified: 'true',
          selectRental: true,
          active: true,
        })
      } else {
        setFilters({
          name: '',
          verified: 'true',
          selectChauffeur: true,
          active: true,
        })
      }
    }

    setInvites([])
    onHide()
    // setNoSupplierSelected(false)
  }

  const toggleState = state => {
    if (state) {
      onFiltersChange('twentyFourHourService')({
        target: { value: state },
      })
      setIsTwentyFourService(state)
    } else {
      onFiltersChange('twentyFourHourService')({
        target: { value: '' },
      })
      setIsTwentyFourService(state)
    }
  }

  //this function is a failure just like ... my code
  const onSendChanges = async values => {
    await updateServiceNotes(
      isApiBooking
        ? { services_location_notes: values }
        : { serviceNotes: values },
      bookingId
    )
    setIsEditingNotes(false)
    await updateBooking()
  }
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      {isLoading && <LoadingSpinner />}
      <img
        className='driver-back-icon-assign'
        src='/images/icon-back.png'
        onClick={() => onClose()}
        alt=''
      />
      <div className='driver-booking-container-header'>
        {currentItem && <span> Booking #{currentItem.number} </span>}
      </div>

      <div className='driver-booking-assign-body'>
        <h5 style={{ paddingTop: '5%', paddingBottom: '5%' }}>Outsource</h5>

        <Col className='pl-0 mt-3 d-flex align-items-center'>
          <Field
            component={FormField}
            name='dispatchPrice'
            controlId={'dispatchPrice'}
            className='payment-field-dispatch hide-number-spinners'
            label='Buying price'
            type='number'
            value={updatedRate}
            handleChange={evt => {
              if (!evt) {
                setUpdatedRate(null)
              } else {
                setUpdatedRate(parseFloat(evt))
              }
            }}
          />
          <span className='ml-2'>
            {`${getLabelFromValue(defaultCurrencies, supplierCurrency)}`}
          </span>
        </Col>
        <Col className='pl-0 mb-4 d-flex align-items-center'>
          <span
            className={`${
              marginPercentage > 20 || marginPercentage === 20
                ? 'most-margin-label'
                : marginPercentage > 0 && marginPercentage <= 10
                ? 'less-margin-label'
                : 'least-margin-label'
            }`}
          >
            {`INCL. ${marginPercentage}% MARGIN (${numberWithCommas(
              getFormattedPrice(parseFloat(marginAmount))
            )} ${getLabelFromValue(defaultCurrencies, supplierCurrency)})`}
          </span>
        </Col>

        <Col className='px-0'>
          <div className='d-flex justify-content-between'>
            <label> Service notes </label>

            {!isEditingNotes ? (
              <Button
                variant='link'
                onClick={() => {
                  setIsEditingNotes(true)
                }}
                className='inline-edit-btn text-brown'
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
          </div>
          {!isEditingNotes ? (
            <>
              <pre
                className={'form-group notes-expandable-dispatch-accepted'}
                id={'serviceNotes'}
                style={{ whiteSpace: 'break-spaces' }}
              >
                {serviceNotes}
              </pre>
            </>
          ) : (
            <>
              <span
                id={'serviceNotes'}
                className={'form-group notes-expandable-dispatch'}
                style={{ minHeight: '80px' }}
                as={'textarea'}
                contentEditable={'plaintext-only'}
                suppressContentEditableWarning={true}
              >
                {serviceNotes}
              </span>
            </>
          )}
        </Col>
        {isEditingNotes && serviceNotes !== '' ? (
          <Col className='px-0'>
            <div className='d-flex justify-content-end'>
              <Button
                variant='link'
                onClick={evt => {
                  setIsEditingNotes(false)
                }}
                className='cancel-btn mr-4 text-brown'
              >
                Cancel
              </Button>
              <div
                onClick={() =>
                  onSendChanges(
                    document.getElementById('serviceNotes') &&
                      document.getElementById('serviceNotes').innerText
                      ? document.getElementById('serviceNotes').innerText
                      : serviceNotes
                  )
                }
              >
                <SubmitBtn
                  style={{
                    height: '35px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    marginTop: '0px',
                    fontSize: '14px',
                  }}
                  title={'Save'}
                />
              </div>
            </div>
          </Col>
        ) : (
          <></>
        )}
        <div className='d-flex align-items-center justify-content-between mb-3 mt-0 px-0'>
          <h4 className='sub-header-dispatch mb-0 mt-0'>
            {` Select partners (${invites.length}/${localList.length})`}
          </h4>
        </div>
        <Row>
          <Col
            className='mb-3'
            // style={{
            //   width: '87%',
            // }}
            md={12}
          >
            <FormFieldWithIcon
              field={{
                name: 'name',
                placeholder: 'Name...',
                value: filters['name'],
                onChange: onFiltersChange('name'),
              }}
              icon='icons/Icon_Search_Inactive.png'
              roundFormField={true}
            />
          </Col>
          <Col
            className='mb-3'
            style={{
              width: '87%',
            }}
          >
            <FormFieldWithIcon
              field={{
                name: 'name',
                placeholder: 'Vehicle...',
                value: filters['vehicles.make.label'],
                onChange: onFiltersChange('vehicles.make.label'),
              }}
              icon='icons/Icon_Search_Inactive.png'
              roundFormField={true}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              marginLeft: '1rem',
              marginRight: '1.9rem',
              justifyContent: 'space-between',
            }}
            md='3'
            className='pr-0 pl-0'
          >
            <Toggle
              label='24/7'
              toggled={isTwentyFourService}
              className={isTwentyFourService ? 'active ml-2' : 'ml-2'}
              onClick={toggleState}
              isPartner
            />
            <Field
              component={FormCheckbox}
              name={'select all'}
              label=''
              className='vehicle-checkbox partner-checkbox-mobile'
              checked={selectAll}
              onClick={() => toggleAllSuppliers()}
              onSubmit={onSubmit}
            />
          </Col>
        </Row>
        {newLocalList && newLocalList.length ? (
          <>
            {newLocalList.map(
              ({
                id,
                place,
                name,
                currency,
                status,
                kpiInfo,
                contactDetails,
                dispatched,
              }) => {
                let emails = ''
                if (contactDetails)
                  emails = contactDetails
                    .filter(contact => contact.excludeComms !== true)
                    .map(contact => contact.contactsEmail)

                return (
                  <div
                    className={`dispatch-list-partner mb-3 ${
                      dispatched ? 'dispatch-grey-row' : ''
                    }`}
                  >
                    <div className='d-flex'>
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        {status && (
                          <Badge
                            href='#'
                            variant={`${status.value}-partner badge-outlined py-1 p1-2 mb-0`}
                            style={{ width: '5rem', marginRight: '0.938rem' }}
                          >
                            {status.label}
                          </Badge>
                        )}
                      </span>
                      <span style={{ marginRight: '0.938rem' }}>
                        <p className='header mr-1 mb-0'>{name}</p>
                        <p
                          style={{
                            marginTop: '0px',
                            marginBottom: '5px',
                            color: '#8b959e',
                            fontSize: '14px',
                          }}
                          className='header mb-0'
                        >
                          {place.city + ', ' + place.state}
                        </p>
                      </span>
                    </div>

                    <div
                      className={'share-button'}
                      style={{
                        marginTop: '0.65rem',
                      }}
                    >
                      <Field
                        component={FormCheckbox}
                        name={`dispatch[${id}]`}
                        checked={invites.some(
                          invite => invite.supplierId === id
                        )}
                        label=''
                        className='vehicle-checkbox'
                        onClick={() =>
                          addToDispatch(id, name, emails, currency)
                        }
                      />
                    </div>

                    {/* <div className='dispatch-container'>
                    <div className={'dispatch-info d-flex'}>
                      <span className='d-flex align-items-center'>
                        {status && (
                          <Badge
                            href='#'
                            variant={`${status.value}-admin badge-outlined py-1 p1-2 mb-2 mr-2`}
                          >
                            {status.label}
                          </Badge>
                        )}
                        <p className='header mr-1'>{name}</p>{' '}
                        <p className='header'>
                          {'- ' + place.city + ', ' + place.state}
                        </p>
                        <TooltipOnHover
                          delay={200}
                          placement='right-start'
                          isMobileBrowser={false}
                          onMouseEnter={() => {}}
                          component={
                            <>
                              <div>
                                <div className='kpi-dispatch-end'>
                                  <span>
                                    {kpiInfo.totalAssignedBookings
                                      ? `${
                                          kpiInfo.totalAssignedBookings
                                        } booking${
                                          kpiInfo.totalAssignedBookings === 1
                                            ? ''
                                            : 's'
                                        }`
                                      : ''}
                                  </span>
                                </div>
                                <div className='kpi-dispatch-end'>
                                  <span>
                                    {kpiInfo.acceptanceRate && (
                                      <span>{`${kpiInfo.acceptanceRate}% acceptance rate`}</span>
                                    )}
                                  </span>
                                </div>
                                {kpiInfo.totalAcceptedBookings ? (
                                  <>
                                    <div className='kpi-dispatch-end'>
                                      <span>{`${kpiInfo.marginAverage}% average net margin`}</span>
                                    </div>
                                    <div className='kpi-dispatch-end'>
                                      <span>
                                        {kpiInfo.averageAcceptanceTime} avg.
                                        acceptance time
                                      </span>
                                    </div>
                                    <div className='kpi-dispatch-end'>
                                      <span>{`Last booking accepted ${moment(
                                        kpiInfo.dateOfRecentDispatch
                                      ).fromNow()}`}</span>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          }
                        >
                          <img
                            className={'new-info-icon'}
                            style={{
                              marginLeft: '0.5rem',
                              marginBottom: '0.2rem',
                            }}
                            src='/images/icons/new-info-icon.png'
                            alt=''
                          />
                        </TooltipOnHover>
                      </span>
                    </div>
                  </div> */}
                  </div>
                )
              }
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className='driver-booking-cta-footer-assign'>
        <Button
          className='select-btn select-vehicle'
          style={{
            width: '100%',
            marginBottom: '15px',
            fontWeight: '900',
            fontSize: '16px',
            textShadow: '0.25px 0 #ffffff',
          }}
          variant='brown'
          onClick={() => {
            onSubmit()
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

// export default DispatchPartnerModalMobile

const mapStateToProps = ({ auth } = {}) => ({})

export default withRouter(
  connect(mapStateToProps, {
    addDispatchBookingMobile,
    addManualDispatch,
    logger,
    updateServiceNotes,
  })(DispatchPartnerModalMobile)
)
