import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import loadable from '../../../loadable'

const routes = [
  {
    path: '/pay/:paymentId',
    exact: true,
    component: loadable(() => import('../Pages/PaymentsLink')),
    type: 'register',
  },
]

const AuthRoutes = () => {
  return (
    <Switch>
      {routes.map(route => {
        const { path, component } = route
        return <Route key={path} path={path} component={component} />
      })}
      <Redirect from={'/pay'} to={'/pay/null'} />
    </Switch>
  )
}

export default withRouter(AuthRoutes)

export { routes }
