import React from 'react'
import { Field, Form } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Col, Modal } from 'react-bootstrap'
import FormField from 'components/FormField'
import FormSelectCurrency from 'components/FormSelectCurrency'
import { currencies } from 'constants/CurrencyCountry'
import _ from 'lodash'
import { countries } from 'constants/CountryList'
import FormSelectCountry from 'components/formSelectCountry'
import LocationAutocomplete from 'components/LocationAutocomplete'
import { isMobile } from 'helpers'

const isMobileBrowser = isMobile()
const BankAccountModal = ({ onHide, show, values, handleFunction }) => {
  const onClose = () => {
    onHide()
  }

  const onConfirmation = () => {
    handleFunction()
  }

  const updateOnCurrencySelect = (values, field) => {
    values.currency = field
  }
  const updateOnCountrySelect = (values, field) => {
    values.country = field
  }

  const currencyName = str => {
    return str.toLowerCase()
  }
  const currencyList = () => {
    var list = []
    _.each(currencies, item => {
      list.push({
        label: item.description,
        value: `${item.description} ${currencyName(item.code)}`,
        code: item.code,
      })
    })
    return list
  }
  const countryList = () => {
    var list = []
    _.each(countries, item => {
      list.push({
        label: item.label,
        value: item.value,
      })
    })
    return list
  }

  const fields =
    values.currency && values.currency.label
      ? values.currency.label === 'Euro'
        ? [
            {
              name: 'iban',
              label: 'iBan',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'swiftCode',
              label: 'Swift code',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
        : values.currency.label === 'United States Dollar'
        ? [
            {
              name: 'accountNumber',
              label: 'Acount number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'routingNumber',
              label: 'Routing number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
        : values.currency.label === 'British Pound'
        ? [
            {
              name: 'sortCode',
              label: 'sort code',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'accountNumber',
              label: 'Account Number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
        : values.currency.label === 'Canadian Dollar'
        ? [
            {
              name: 'institutionNumber',
              label: 'Institution Number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'transitNumber',
              label: 'Transit Number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'accountNumber',
              label: 'Account Number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
        : values.currency.label === 'Australian Dollar'
        ? [
            {
              name: 'bsbCode',
              label: 'BSB code',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'accountNumber',
              label: 'Account Number',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
        : [
            {
              name: 'iban',
              label: 'iBan',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
            {
              name: 'swiftCode',
              label: 'Swift code',
              component: FormField,
              // md: 8,
              className: 'px-2',
            },
          ]
      : [
          {
            name: 'iban',
            label: 'iBan',
            component: FormField,
            // md: 8,
            className: 'px-2',
          },
          {
            name: 'swiftCode',
            label: 'Swift code',
            component: FormField,
            // md: 8,
            className: 'px-2',
          },
        ]

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='booking-preview-info filters-booking-modal modal-90w bank-deatil-modal'
      dialogClassName='modal-90w'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        style={{ paddingTop: '40px' }}
        className='booking-preview-info-section mb-0'
      >
        <Modal.Title id='c-lg' className='modal-title'>
          <div className='d-flex'>
            <span
              style={isMobileBrowser ? { alignItems: 'center ' } : {}}
              className={`title pb-0 text-nowrap px-2`}
            >
              Add bank account details
            </span>
            <div className='close-btn-wrap justify-content-end align-items-end'>
              <Button variant='link' className='mt-1 mr-0' onClick={onClose}>
                <img
                  style={{ maxWidth: '45px', transform: 'translateX(6px)' }}
                  src='/images/icons/close.svg'
                  alt=''
                />
              </Button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={
          isMobileBrowser
            ? { paddingBottom: '8px', paddingTop: '10px' }
            : { paddingBottom: '8px', paddingTop: '24px' }
        }
        className='booking-preview-info-section mb-0'
      >
        {/* <Form noValidate> */}
        <Col
          // md='8' style={{ marginLeft: '7.3rem' }}
          className='px-2'
        >
          <Field
            component={FormField}
            name='legalName'
            label='Company legal name'
            controlId='legalName'
          />
        </Col>
        <Col
          // md='8'
          // style={{ marginLeft: '7.3rem' }}
          className='px-2'
        >
          <Field
            name={`country`}
            component={LocationAutocomplete}
            searchOptions={{
              types: ['(regions)'],
            }}
            controlId={`country`}
            needCityState={false}
            needFullAddress={true}
            countriesList={true}
            displayKey={'fullAddress'}
            confirmOnSelect={true}
            label='Country'
            needBounds={true}
          />
        </Col>
        <Col
          // md='8' style={{ marginLeft: '7.3rem' }}
          className='px-2'
        >
          <Field
            component={FormSelectCurrency}
            name='currency'
            isSearchable={true}
            options={currencyList()}
            label='Currency'
            handleChange={field => {
              updateOnCurrencySelect(values, field)
            }}
            showLabel
          />
        </Col>
        {fields.map((field, f) => (
          <Col
            md={field.md}
            // style={{ marginLeft: '7.3rem' }}
            className={field.className || ''}
          >
            <Field
              name={`${field.name}`}
              component={field.component}
              controlId={`${field.name}`}
              label={`${field.label}`}
              {...field}
            />
          </Col>
        ))}
        {/* </Form> */}
      </Modal.Body>
      <Modal.Footer
        style={{ paddingLeft: '1.2rem' }}
        className='footerBackground pt-0'
      >
        <>
          <Button
            style={isMobileBrowser ? { width: '83%' } : { width: '89%' }}
            className='ml-0'
            variant='brown'
            onClick={onConfirmation}
          >
            Confirm
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  )
}

// export default AddBankAccount
export default withRouter(connect(null, {})(BankAccountModal))
