import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'

const DispatchCancelModal = ({
  show,
  onHide,
  handleCancelDispatch,
  oneSupplier,
  supplierInfo,
  islocalSupplier,
  isMobileBrowser,
}) => {
  if (islocalSupplier) {
    return (
      <Modal
        size='lg'
        show={show}
        onHide={onHide}
        className='cancel-booking-modal modal-90w'
        dialogClassName='modal-90w '
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title
            id='example-modal-sizes-title-lg'
            className='modal-title mb-5'
          >
            <div className='close-btn-wrap'>
              <Button variant='link' onClick={onHide}>
                <img
                  style={{ maxWidth: '10px', maxHeight: '10px' }}
                  src='/images/icons/close-icon-HD.png'
                  alt=''
                />
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {oneSupplier ? (
            <>
              {' '}
              <>
                <p className='refund-modal-description'>
                  {`Are you sure you want to cancel the dispatch link for partner ${supplierInfo.name}?`}
                </p>
              </>
            </>
          ) : (
            <>
              {isMobileBrowser ? (
                <p className='modal-description'>
                  All offers will expire for all invited partners, are you sure
                  you want to cancel?
                </p>
              ) : (
                <p className='refund-modal-description'>
                  All offers will expire for all invited partners, are you sure
                  you want to cancel?
                </p>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='sharedBooking-and-Refund-modal-btn'
            variant='brown'
            onClick={() => handleCancelDispatch(true)}
          >
            Yes, cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <Modal
      size='lg'
      show={show}
      onHide={onHide}
      className='cancel-booking-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title
          id='example-modal-sizes-title-lg'
          className='modal-title mb-5'
        >
          <div className='close-btn-wrap'>
            <Button variant='link' onClick={onHide}>
              <img
                style={{ maxWidth: '10px', maxHeight: '10px' }}
                src='/images/icons/close-icon-HD.png'
                alt=''
              />
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {oneSupplier ? (
          <>
            {' '}
            <>
              <p className='refund-modal-description'>
                {`Are you sure you want to cancel the dispatch link for partner ${supplierInfo.name}?`}
              </p>
            </>
          </>
        ) : (
          <>
            <p className='refund-modal-description'>
              All dispatch links will expire for all invited partners, are you
              sure you want to cancel?
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='sharedBooking-and-Refund-modal-btn'
          variant='brown'
          onClick={() => handleCancelDispatch(true)}
        >
          Yes, cancel dispatch
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = ({ auth } = {}) => ({})

export default withRouter(connect(mapStateToProps, {})(DispatchCancelModal))
