import React from 'react'
import { useParams } from 'react-router'
import { useGetTableData } from '../.././../../../hooks'
import DefaultDriverLayout from '../../../../../components/layout/DefaultDriverLayout'

const ChauffeurOnly = () => {
  const { token } = useParams()

  const {
    data: { list },
    isLoading,
    onFiltersChange,
    filters,
  } = useGetTableData(`external/chauffeurOnly/${token}`)

  return (
    <DefaultDriverLayout
      data={list}
      isAccountLoading={isLoading}
      onFiltersChange={onFiltersChange}
      filters={filters}
    />
  )
}

export default ChauffeurOnly
