import { fileToBase64 } from '../helpers'
import { logger } from '../actions'
export const brandFormData = async ({ image, ...values }) => {
  try {
    let data = values

    if (image && typeof image === 'object' && image.size) {
      const logoBase64 = await fileToBase64(image)
      data.image = logoBase64 || null
    }

    return data
  } catch (error) {
    logger({ fileName: 'brands', error: error })
    console.log(error)
    return {}
  }
}

export class BrandInitialValues {
  constructor({ name = '', image = '' } = {}) {
    this.name = name
    this.image = image
      ? {
          name: image,
        }
      : null
  }
}
