import React, { useMemo, useState, useEffect, useCallback } from 'react'
import FormInputDatepicker from '../FormInputDatepicker'
import { getFormattedPrice, getLabelFromValue, numberWithCommas } from 'helpers'
import { useGetTableData } from 'hooks'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import { defaultCurrencies } from '../../constants'
import _ from 'lodash'
import NoData from '../NoData'
import moment from 'moment'
import MatRaisedBtn from 'components/MatRaisedBtn'
import { getPrivateFileURL, logger } from 'actions'
import { UAParser } from 'ua-parser-js'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PayoutHelpModal from 'components/PayoutHelpModal'

const PartnerPayout = ({ partnerToken, getPrivateFileURL }) => {
  const [currency, setCurrency] = useState(null)
  const [paidAmount, setPaidAmount] = useState(0)
  const [unPaidAmount, setUnPaidAmount] = useState(0)
  const [expand, setExpand] = useState([])
  const [showPdf, setShowPdf] = useState(false)
  const [isTypeImg, setTypeImg] = useState(false)
  const [pdfLink, setPdfLink] = useState('')

  const filterOperators = useMemo(
    () => ({
      pickUpDate: 'gte',
      dropOffDate: 'lte',
    }),
    []
  )
  const initialFilters = useMemo(
    () => ({
      pickUpDate: '',
      dropOffDate: '',
    }),
    []
  )

  const companyURL = partnerToken ? `payout/partner/${partnerToken}` : null

  const {
    data: { list },
    filters,
    onFiltersChange,
  } = useGetTableData(companyURL, initialFilters, { filterOperators })

  const onGetFile = useCallback(
    fileId => async () => {
      try {
        const { url, extension } = await getPrivateFileURL(fileId)
        if (url) {
          if (extension !== 'pdf') {
            setTypeImg(true)
          }
          let parser = new UAParser()
          parser.setUA(navigator.userAgent)
          const browser = parser.getBrowser().name
          const result = parser.getOS()
          const name = result.name
          if (
            name.search('iOS') !== -1 ||
            browser.search('Safari') !== -1 ||
            browser.search('safari') !== -1
          ) {
            setShowPdf(true)
            setPdfLink(url)
          } else {
            window.open(url, '_blank')
          }
        }
      } catch (error) {
        logger({ fileName: 'invoices', error: error })
        console.log(error)
      }
    },
    [getPrivateFileURL]
  )

  const onDateChange = key => e => {
    onFiltersChange(key)({
      target: {
        value: e,
        isDate: true,
        isStart: key === 'pickUpDate',
      },
    })
  }

  useEffect(() => {
    if (list.length) {
      let totalPaid = 0
      let totalUnpaid = 0
      _.each(list, item => {
        if (item.status === 'paid') {
          totalPaid += parseFloat(item.currencyAdjustedTotal)
        } else if (item.status === 'not_paid') {
          totalUnpaid += parseFloat(item.currencyAdjustedTotal)
        }

        setExpand(prevState => [...prevState, false])
      })

      setPaidAmount(totalPaid)
      setUnPaidAmount(totalUnpaid)
      setCurrency(getLabelFromValue(defaultCurrencies, list[0].currency))
    }
  }, [list.length])

  const renderItem = (item, index) => {
    const updateValue = pos => {
      let array = expand
      array[pos] = !expand[pos]
      setExpand([...array])
    }
    return (
      <React.Fragment>
        <Row
          style={{ marginBottom: '1.5rem' }}
          className={expand[index] ? 'expanded mt-3' : 'mt-3'}
        >
          <Col md={12} className='d-flex justify-content-between'>
            {item.payout_terms !== 'payout_term_monthly' &&
            item.payout_terms !== 'payout_term_weekly' ? (
              <div>
                <span
                  style={{ fontWeight: '900', fontSize: '14px' }}
                  className='mr-3'
                >{`${item.fromDate} — ${item.toDate}`}</span>
                <Badge
                  href='#'
                  variant={`${item.status}-partner badge-outlined`}
                  style={{ display: 'inline-block', fontSize: '11px' }}
                >
                  {item.status === 'paid' ? 'PAID OUT' : 'EXPECTED'}
                </Badge>
              </div>
            ) : (
              <div>
                <span
                  style={{ fontWeight: '900' }}
                  className='mr-3'
                >{`${item.payoutDate}`}</span>
                <Badge
                  href='#'
                  variant={`${item.status}-partner badge-outlined`}
                  style={{ display: 'inline-block' }}
                >
                  {item.status === 'paid' ? 'PAID OUT' : 'EXPECTED'}
                </Badge>
              </div>
            )}

            <div className='d-flex'>
              <span className='payout-text-black'>{`${numberWithCommas(
                getFormattedPrice(item.total)
              )} ${getLabelFromValue(defaultCurrencies, item.currency)}`}</span>
              {/* <Button
                variant='link'
                onClick={() => {
                  if (item.paidOn) {
                    setShowModal(true)
                    setDate(item.paidOn)
                  }
                }}
                className='btn-help'
              >
                {`${item.total} ${getLabelFromValue(
                  defaultCurrencies,
                  item.currency
                )}`}
              </Button> */}
              <div
                // className={`payout-expandable ${
                //   expand[index] ? 'expanded' : ''
                // }`}
                style={{
                  cursor: 'pointer',
                  paddingRight: '40px',
                  position: 'relative',
                }}
                onClick={() => {
                  updateValue(index)
                }}
              >
                <img
                  style={expand[index] ? { transform: 'rotate(180deg)' } : {}}
                  src='/images/icons/caret-down.svg'
                  class='caret-black'
                  alt=''
                />
              </div>
            </div>
          </Col>
        </Row>
        {expand[index] ? (
          <Row style={{ marginBottom: '4  rem' }}>
            <Col md={12}>
              {item.commissionFileId ? (
                <div className='payout-details'>
                  <span>{`Statment of commissions`}</span>
                  <div className='text-center h5 mt-2 mb-2'>
                    <MatRaisedBtn
                      title={'Download statement'}
                      onClick={onGetFile(item.commissionFileId)}
                      style={{ fontSize: '14px' }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {item.invoiceFileId ? (
                <div className='payout-details'>
                  <span>{`Invoice`}</span>
                  <div className='text-center h5 mt-2 mb-2'>
                    <MatRaisedBtn
                      title={'Download invoice'}
                      onClick={onGetFile(item.invoiceFileId)}
                      style={{ fontSize: '14px' }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {item.numTransfers ? (
                <div className='payout-details'>
                  <span>{`Transfer (${item.numTransfers})`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(item.transfersTota)
                  )} ${getLabelFromValue(
                    defaultCurrencies,
                    item.currency
                  )}`}</span>
                </div>
              ) : (
                <></>
              )}
              {item.numBytheHour ? (
                <div className='payout-details'>
                  <span>{`By the hour (${item.numBytheHour})`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(item.byTheHourTotal)
                  )} ${getLabelFromValue(
                    defaultCurrencies,
                    item.currency
                  )}`}</span>
                </div>
              ) : (
                <></>
              )}
              {item.numCarRentals ? (
                <div className='payout-details'>
                  <span>{`Car rental (${item.numCarRentals})`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(item.carRentalsTotal)
                  )} ${getLabelFromValue(
                    defaultCurrencies,
                    item.currency
                  )}`}</span>
                </div>
              ) : (
                <></>
              )}
              {item.numMeetAndGreet ? (
                <div className='payout-details'>
                  <span>{`VIP Meet & Greet (${item.numMeetAndGreet})`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(item.meetAndGreetTotal)
                  )} ${getLabelFromValue(
                    defaultCurrencies,
                    item.currency
                  )}`}</span>
                </div>
              ) : (
                <></>
              )}
              {item.status === 'paid' ? (
                <>
                  <div className='payout-details'>
                    <span>{`Total`}</span>
                    <span>{`${numberWithCommas(
                      getFormattedPrice(item.total)
                    )} ${getLabelFromValue(
                      defaultCurrencies,
                      item.currency
                    )}`}</span>
                  </div>
                  <div className='payout-details'>
                    <span>{`Payee`}</span>
                    <span>{`${item.payee}`}</span>
                  </div>
                  <div className='payout-details'>
                    <span>{`Payout method`}</span>
                    <span>{`${item.paymentMethod} (${getLabelFromValue(
                      defaultCurrencies,
                      item.currency
                    )})`}</span>
                  </div>
                  <div className='payout-details border-bottom-0'>
                    <span>{`Payout date`}</span>
                    <span>{`${moment
                      .utc(item.paidOn)
                      .format('DD MMMM YYYY')}`}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className='payout-details '>
                    <span>{`Total`}</span>
                    <span>{`${numberWithCommas(
                      getFormattedPrice(item.total)
                    )} ${getLabelFromValue(
                      defaultCurrencies,
                      item.currency
                    )}`}</span>
                  </div>
                  <div className='payout-details border-bottom-0'>
                    <span>{`Payout due date`}</span>
                    <span>{`${moment
                      .utc(item.dueDate)
                      .format('DD MMMM YYYY')}`}</span>
                  </div>
                </>
              )}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      {list && list.length ? (
        <div class='container-fluid'>
          <Row style={{ maxWidth: '1000px', marginBottom: '2rem' }}>
            <Col md={3} className='pr-1 pl-0'>
              <FormInputDatepicker
                field={{
                  value: filters['pickUpDate'],
                  onChange: onDateChange('pickUpDate'),
                  name: 'date-from-search',
                  placeholder: 'From',
                }}
                label='From'
                isISODate
                isSearchField={true}
                extendCalenderWidth={true}
                isClearable
                className='mb-4'
              />
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: '900',
                  marginRight: '0.3rem',
                }}
              >{`Paid out:`}</span>
              <span>{`${numberWithCommas(
                getFormattedPrice(paidAmount)
              )} ${currency}`}</span>
            </Col>
            <Col md={3} className='pr-0 pl-1'>
              <FormInputDatepicker
                field={{
                  value: filters['dropOffDate'],
                  onChange: onDateChange('dropOffDate'),
                  name: 'date-to-search',
                  placeholder: 'To',
                }}
                label='To'
                isISODate
                isToDate={true}
                isSearchField={true}
                extendCalenderWidth={true}
                isClearable
                className='mb-4'
              />
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: '900',
                  marginRight: '0.3rem',
                }}
              >{`Expected:`}</span>
              <span>{`${numberWithCommas(
                getFormattedPrice(unPaidAmount)
              )} ${currency}`}</span>
            </Col>
          </Row>
          <Row className='partner-payout-card'>
            <>
              {list.map((item, index) => (
                <>
                  <Col
                    md={12}
                    className={`partner-payout-details ${
                      expand[index] ? '' : 'payout-border-bottom'
                    }`}
                  >
                    {renderItem(item, index)}
                  </Col>
                  <Col md={12} className='pl-0 pr-0'>
                    {expand[index] && index !== list.length - 1 ? (
                      // <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
                      <hr className='mt-0 mb-0' />
                    ) : (
                      <></>
                    )}
                  </Col>
                </>
              ))}
            </>
          </Row>
        </div>
      ) : (
        <NoData
          className='no-payouts'
          image='/images/icons/car-booking.png'
          width={40}
          title='No payouts found'
        />
      )}

      {/* {showModal ? (
        <PayoutHelpModal
          onHide={() => {
            setShowModal(false)
          }}
          show={showModal}
          date={Date}
        />
      ) : (
        <></>
      )} */}
    </>
  )
}

// export default PartnerPayout

const mapStateToProps = ({ auth: { companyId } }) => ({
  companyId,
})

export default withRouter(
  connect(mapStateToProps, {
    getPrivateFileURL,
    logger,
  })(PartnerPayout)
)
