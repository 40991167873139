import { useCallback, useEffect } from 'react'
import lodashDebounce from 'lodash.debounce'

const createCallback = (debounce, handleOnScroll) => {
  if (debounce) {
    return lodashDebounce(handleOnScroll, debounce, { trailing: true })
  } else {
    return handleOnScroll
  }
}

function useBottomScrollListener(
  onBottom,
  containerRef,
  offset = 100,
  debounce = 300
) {
  const handleOnScroll = useCallback(() => {
    if (containerRef.current != null) {
      const scrollNode = containerRef.current
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + scrollNode.clientHeight
      )
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset)

      if (scrollPosition <= scrollContainerBottomPosition) {
        onBottom()
      }
    } else {
      const scrollNode = document.scrollingElement || document.documentElement
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + window.innerHeight
      )
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset)

      if (scrollPosition <= scrollContainerBottomPosition) {
        onBottom()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, onBottom, containerRef.current])

  useEffect(() => {
    const callback = createCallback(debounce, handleOnScroll)
    const ref = containerRef.current

    if (ref != null) {
      ref.addEventListener('scroll', callback)
    } else {
      window.addEventListener('scroll', callback)
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener('scroll', callback)
      } else {
        window.removeEventListener('scroll', callback)
      }
    }
  }, [handleOnScroll, debounce, containerRef])

  return containerRef
}

export default useBottomScrollListener
