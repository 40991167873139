import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const ResetPasswordPage = ({
  title = '',
  isLoggedIn,
  history,
  onBack = null,
  noTitle,
  parentPath = '/auth/login',
  needLogout,
  logout = () => {},
  component: View = null,
}) => {
  const onBackClick = e => {
    e.preventDefault()
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }
  const closeImage = window.innerWidth < 991 ? 'close.svg' : 'close_svg.svg'
  return (
    <div className='app-content'>
      <div className='page-wrap w-100'>
        <div className='scrollable-page-content nested-layout light-theme reset-password-layout'>
          <nav className='navbar navbar-expand navbar-light'>
            <header className='mx-auto d-flex container align-items-center justify-content-between'>
              <a
                href='/auth/login'
                onClick={onBackClick}
                className='d-block d-sm-none m-0 reset-password-back-btn'
              >
                <img
                  src={`/images/icons/reset-password-back.svg`}
                  alt=''
                  className='w-100'
                />
              </a>
              <h1 className='page-title justify-content-center  justify-content-sm-start reset-passowrd-page-title p-0'>
                {title}
              </h1>
              <Link
                to={parentPath || '/'}
                className='d-none d-sm-block light-close-btn'
              >
                <img src={`/images/icons/${closeImage}`} alt='' />
              </Link>
            </header>
          </nav>
          <main className='hide-sidebar p-0'>
            <View />
          </main>
        </div>
      </div>
    </div>
  )
}
export default withRouter(ResetPasswordPage)
