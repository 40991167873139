import moment from 'moment'
import { defaultPaymentCardTypes, defaultVehicleCategories } from '../constants'
import { connectedApiService as api } from '../index'
import { logger } from '../actions'
import _ from 'lodash'

const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = e => {
      const binary = window.btoa(e.target.result)
      resolve(`data:${file.type};base64,${binary}`)
    }

    reader.onerror = reject

    reader.readAsBinaryString(file)
  })
}

export const fileToBase64 = async file => {
  let base64 = ''
  try {
    if (file && file.size) {
      base64 = await readFileAsync(file)
    }
  } catch (error) {
    logger({ fileName: 'helpers-index', error: error })
    console.log(error)
  } finally {
    return base64
  }
}

export const getOptionFromValue = (defaultData = [], val) =>
  defaultData.find(item => item.value === val) || {}

export const getLabelFromValue = (defaultData = [], val) =>
  getOptionFromValue(defaultData, val).label || val

export const getLabelFromCode = (defaultData = [], val) =>
  defaultData.find(item => item.code === val) || {}

export const getCategoryLabelFromValue = val => {
  return getOptionFromValue(defaultVehicleCategories, val).label || val
}
export const getPaymentCardIcon = cardType =>
  (
    defaultPaymentCardTypes.find(
      ({ type, altType }) => cardType === type || cardType === altType
    ) || {}
  ).icon || 'placeholder.svg'

export const getDefaultPaymentCredentialsId = (payments = []) => {
  if (!payments || !payments.length) {
    return null
  }
  return (
    payments.find(({ isDefaultPaymentMethod }) => !!isDefaultPaymentMethod) ||
    {}
  ).id
}

export const getRoundedTime = (time, roundMinutes = 15) => {
  const start = moment(time)
  const remainder = roundMinutes - (start.minute() % roundMinutes)
  return moment(start).add(remainder, 'minutes')
}

export const getFormattedTripInfo = tripInfo => {
  if (!tripInfo) {
    return
  }
  let formattedInfo = {}
  if (!tripInfo.duration) {
    formattedInfo.duration = '0 h 0 min'
  } else {
    const parsedDuration = moment.utc(tripInfo.duration * 1000).format('H:mm')
    const splittedValue = parsedDuration.split(':')
    formattedInfo.duration = `${
      splittedValue[0] !== '0'
        ? `${splittedValue[0]} h ${splittedValue[1]} min`
        : `${splittedValue[1]} min`
    }`
  }

  if (!tripInfo.distance) {
    formattedInfo.distance = tripInfo.unitSystem === 'KM' ? '0 km' : '0 miles'
  } else {
    let value =
      tripInfo && tripInfo.unitSystem === 'KM'
        ? tripInfo.distance / 1000
        : (tripInfo.distance / 1000) * 0.621371
    formattedInfo.distance = `${Math.round(value).toFixed(2)} ${
      tripInfo && tripInfo.unitSystem === 'KM' ? 'km' : 'miles'
    }`
  }
  return formattedInfo
}

export const getFormattedPrice = price => {
  if (!price) {
    return '0.00'
  }

  // return `${parseFloat(price).toFixed()}`
  return `${parseFloat(price).toFixed(2)}`
}

export const numberWithCommas = price => {
  var parts = price.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatSentenceCase = str => {
  if (!str) return ''
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const isAndroid = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/android/i.test(userAgent)) {
    return true
  } else {
    return false
  }
}
export const isIos = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true
  } else {
    return false
  }
}
export const isMobile = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return true
  }

  if (/android/i.test(userAgent)) {
    return true
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true
  }

  return false
}

export const hideResponsiveButton = () => {
  if (
    !isMobile() ||
    document.getElementsByClassName('book-continue-btn') === null
  ) {
    return
  }

  var continueBtns = document.getElementsByClassName('book-continue-btn')
  for (var i = 0; i < continueBtns.length; i++) {
    continueBtns[i].classList.add('hide-bottom-button')
  }
}

export const showResponsiveButton = () => {
  if (
    !isMobile() ||
    document.getElementsByClassName('book-continue-btn') === null
  ) {
    return
  }

  var continueBtns = document.getElementsByClassName('book-continue-btn')
  for (var i = 0; i < continueBtns.length; i++) {
    continueBtns[i].classList.remove('hide-bottom-button')
  }
}

export const requestFullscreen = element => {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullScreen) {
    element.msRequestFullScreen()
  }
}

export const getMeetAssistMeesage = message => {
  let separateLines = message.split(/\r?\n|\r|\n/g)
  return separateLines
}

export const formatChargesText = (text, desktop) => {
  if (text.includes(',')) {
    const [vehicleName, waitingTime] = text.split(',')

    if (waitingTime.length > 21 && desktop) {
      return waitingTime.substring(0, 21) + '...'
    }
    return waitingTime
  }

  if (text.length > 21) {
    return text.substring(0, 21) + '...'
  }
  return text
}

//cancellation policy functions
export const kilometersToMiles = kilometers => {
  return kilometers / 1.60934
}
export const toRadius = angle => {
  return (angle / 180) * Math.PI
}
export const getDistance = (latitude1, longitude1, latitude2, longitude2) => {
  let earth_radius = 6371
  let dLat = toRadius(latitude2 - latitude1)
  let dLon = toRadius(longitude2 - longitude1)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadius(latitude1)) *
      Math.cos(toRadius(latitude2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.asin(Math.sqrt(a))
  let d = earth_radius * c
  return kilometersToMiles(d)
}

export const isMoreThanTwoHours = tripDuration => {
  const duration = moment.duration(tripDuration, 'seconds')
  const numHours = duration.hours()
  const numMinutes = duration.minutes()

  if (numHours > 2 || (numHours === 2 && numMinutes > 0)) {
    return true
  } else {
    return false
  }
}

export const findCityInGroups = (pickUpLocation, cityGroups) => {
  for (const group of cityGroups) {
    for (const item of group.cities) {
      let distance = getDistance(
        item.city.lat,
        item.city.lng,
        pickUpLocation.lat,
        pickUpLocation.lng
      )

      if (distance <= item.radius) {
        return group
      }
    }
  }
  return null
}

export const findCountryInGroups = (pickUpLocation, countryGroups) => {
  for (const group of countryGroups) {
    const foundCountry = group.countries.find(
      item => item.country === pickUpLocation.country
    )
    if (foundCountry) {
      return group
    }
  }
  return null
}

// {globalPolicies.globalCitiesPolicy &&
//   globalPolicies.globalCitiesPolicy.some(
//     item =>
//       item.city ===
//       values.pickUpLocation.city
//   ) &&
//   globalPolicies.citiesTransferPolicy ? (
//     <p>{`${globalPolicies.citiesTransferPolicy} before pick-up`}</p>
//   ) : globalPolicies.globalTransferPolicy ? (
//     <p>{`${globalPolicies.globalTransferPolicy} before pick-up`}</p>
//   ) : (
//     <>
//       {/* Policies for certain vehicles */}
//       {vehicleTypesPolicy.includes(
//         info.vehicleCategory
//       ) ? (
//         <>
//           <p>48 hours before pick-up</p>
//         </>
//       ) : (
//         <>
//           {info.vehicle
//             .categoryChauffeured ===
//             'van' ||
//           values.pickUpLocation.country ===
//             'JP' ? (
//             <p>24 hours before pick-up</p>
//           ) : (
//             <p>4 hours before pick-up</p>
//           )}
//         </>
//       )}
//     </>
//   )}
export const getGlobalCancellationPolicy = values => {
  try {
    const {
      bookingType,
      pickUpLocation,
      durationType,
      globalPolicy,
      vehicleCategory,
    } = values

    if (bookingType === 'airport_transfer') {
      const vehicleTypesPolicy = ['coach', 'luxe', 'minibus', 'stretch']

      let cityPolicy = findCityInGroups(
        pickUpLocation,
        globalPolicy.citiesGroup
      )
      let countryPolicy = findCountryInGroups(
        pickUpLocation,
        globalPolicy.countriesGroup
      )

      if (cityPolicy && cityPolicy.transferPolicy) {
        return cityPolicy.transferPolicy
      } else if (countryPolicy && countryPolicy.transferPolicy) {
        return countryPolicy.transferPolicy
      } else {
        //default cancellation policy
        return vehicleTypesPolicy.includes(vehicleCategory)
          ? '48 hours'
          : vehicleCategory === 'van'
          ? '24 hours'
          : '4 hours'
      }
    } else if (bookingType === 'chauffeur_services') {
      const vehicleTypesPolicy = ['coach', 'luxe', 'minibus', 'stretch']

      if (durationType === 'daily') {
        //Global policies
        let cityPolicy = findCityInGroups(
          pickUpLocation,
          globalPolicy.citiesGroup
        )
        let countryPolicy = findCountryInGroups(
          pickUpLocation,
          globalPolicy.countriesGroup
        )

        if (cityPolicy && cityPolicy.dailyPolicy) {
          return cityPolicy.dailyPolicy
        } else if (countryPolicy && countryPolicy.dailyPolicy) {
          return countryPolicy.dailyPolicy
        } else {
          //default cancellation policy
          return vehicleTypesPolicy.includes(vehicleCategory)
            ? '48 hours'
            : '24 hours'
        }
      } else if (durationType === 'hourly') {
        let cityPolicy = findCityInGroups(
          pickUpLocation,
          globalPolicy.citiesGroup
        )
        let countryPolicy = findCountryInGroups(
          pickUpLocation,
          globalPolicy.countriesGroup
        )

        if (cityPolicy && cityPolicy.hourlyPolicy) {
          return cityPolicy.hourlyPolicy
        } else if (countryPolicy && countryPolicy.hourlyPolicy) {
          return countryPolicy.hourlyPolicy
        } else {
          //default cancellation policy
          return vehicleTypesPolicy.includes(vehicleCategory)
            ? '48 hours'
            : '24 hours'
        }
      }
    } else if (bookingType === 'car_rentals') {
      let cityPolicy = findCityInGroups(
        pickUpLocation,
        globalPolicy.citiesGroup
      )
      let countryPolicy = findCountryInGroups(
        pickUpLocation,
        globalPolicy.countriesGroup
      )

      if (cityPolicy && cityPolicy.carRentalPolicy) {
        return cityPolicy.carRentalPolicy
      } else if (countryPolicy && countryPolicy.carRentalPolicy) {
        return countryPolicy.carRentalPolicy
      } else {
        //default cancellation policy
        return '48 hours'
      }
    }
  } catch (error) {
    console.log('Get cancellation policy', error)
    logger.error('Get cancellation policy', error)
  }
}

export const getVipMeetGreetCancellation = values => {
  try {
    const { event, pickUpLocation, globalPolicy } = values

    //Check events cancellation policy
    if (event && event.meetAndGreetPolicy) {
      return event.meetAndGreetPolicy
    }

    //Check cities cancellation policy
    let cityPolicy = findCityInGroups(pickUpLocation, globalPolicy.citiesGroup)
    if (cityPolicy && cityPolicy.meetAndGreetPolicy) {
      return cityPolicy.meetAndGreetPolicy
    }

    //Check countries cancellation policy
    let countryPolicy = findCountryInGroups(
      pickUpLocation,
      globalPolicy.countriesGroup
    )
    if (countryPolicy && countryPolicy.meetAndGreetPolicy) {
      return countryPolicy.meetAndGreetPolicy
    }

    //default cancellation policy
    return '48 hrs'
  } catch (error) {
    console.log('Get cancellation policy Terms', error)
    logger.error('Get cancellation policy Terms', error)
  }
}
export const getGlobalCancellationPolicyTerms = values => {
  try {
    const {
      bookingType,
      pickUpLocation,
      durationType,
      globalPolicy,
      vehicleCategory,
    } = values

    if (bookingType === 'airport_transfer') {
      const vehicleTypesPolicy = ['coach', 'luxe', 'minibus', 'stretch']

      let cityPolicy = findCityInGroups(
        pickUpLocation,
        globalPolicy.citiesGroup
      )
      let countryPolicy = findCountryInGroups(
        pickUpLocation,
        globalPolicy.countriesGroup
      )

      if (cityPolicy && cityPolicy.transferPolicy) {
        return cityPolicy.transferPolicy
      } else if (countryPolicy && countryPolicy.transferPolicy) {
        return countryPolicy.transferPolicy
      } else {
        //default cancellation policy
        return vehicleTypesPolicy.includes(vehicleCategory)
          ? '48 hrs'
          : vehicleCategory === 'van'
          ? '24 hrs'
          : '4 hrs'
      }
    } else if (bookingType === 'chauffeur_services') {
      const vehicleTypesPolicy = ['coach', 'luxe', 'minibus', 'stretch']

      if (durationType === 'daily') {
        //Global policies
        let cityPolicy = findCityInGroups(
          pickUpLocation,
          globalPolicy.citiesGroup
        )
        let countryPolicy = findCountryInGroups(
          pickUpLocation,
          globalPolicy.countriesGroup
        )

        if (cityPolicy && cityPolicy.dailyPolicy) {
          return cityPolicy.dailyPolicy
        } else if (countryPolicy && countryPolicy.dailyPolicy) {
          return countryPolicy.dailyPolicy
        } else {
          //default cancellation policy
          return vehicleTypesPolicy.includes(vehicleCategory)
            ? '48 hrs'
            : '24 hrs'
        }
      } else if (durationType === 'hourly') {
        let cityPolicy = findCityInGroups(
          pickUpLocation,
          globalPolicy.citiesGroup
        )
        let countryPolicy = findCountryInGroups(
          pickUpLocation,
          globalPolicy.countriesGroup
        )

        if (cityPolicy && cityPolicy.hourlyPolicy) {
          return cityPolicy.hourlyPolicy
        } else if (countryPolicy && countryPolicy.hourlyPolicy) {
          return countryPolicy.hourlyPolicy
        } else {
          //default cancellation policy
          return vehicleTypesPolicy.includes(vehicleCategory)
            ? '48 hrs'
            : '24 hrs'
        }
      }
    } else if (bookingType === 'car_rentals') {
      let cityPolicy = findCityInGroups(
        pickUpLocation,
        globalPolicy.citiesGroup
      )
      let countryPolicy = findCountryInGroups(
        pickUpLocation,
        globalPolicy.countriesGroup
      )

      if (cityPolicy && cityPolicy.carRentalPolicy) {
        return cityPolicy.carRentalPolicy
      } else if (countryPolicy && countryPolicy.carRentalPolicy) {
        return countryPolicy.carRentalPolicy
      } else {
        //default cancellation policy
        return '48 hrs'
      }
    }
  } catch (error) {
    console.log('Get cancellation policy Terms', error)
    logger.error('Get cancellation policy Terms', error)
  }
}
