import { createOrEditItem } from './form'

export const logger = values => async dispatch => {
  return await dispatch(
    createOrEditItem(values, {
      endpoint: 'webLog/errorLogger',
    })
  )
}

// export const logger = (list) => {
//     console.log("errorMessage", list)
//     console.log("errorMessage", list.error.message)
//     console.log("errorMessage", list.error.stack)
// }
