import React from 'react'

export default function AddLink({
  node,
  children,
  className,
  icon = 'icons/add-circle',
  imgStyle = {},
  ...props
}) {
  const Node = node || 'a'
  return (
    <Node
      className={`d-flex align-items-center add-link text-brown ${className ||
        ''}`}
      {...props}
    >
      {!!icon && (
        <img
          style={imgStyle}
          className='mr-1 add-link-icon'
          src={`/images/${icon}.png`}
          alt=''
        />
      )}
      {children}
    </Node>
  )
}
