import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SmallUp } from './Responsive/breakpoints'
import { Button } from 'react-bootstrap'
const storedUserKey = 'RolzoCurrentUser'
const storedMainUserKey = 'RolzoMainUser'
function MasquradeBanner({ ...prop }) {
  const [masqueradedUser, setMasqueradedUser] = useState('')
  useEffect(() => {
    const storedUser = localStorage.getItem(storedUserKey)
    var data = null
    if (storedUser) {
      data = JSON.parse(storedUser)
      setMasqueradedUser(`${data.firstName} ${data.lastName}`)
    }
  }, [])
  const stopMasquerading = () => {
    const reStoredMainUser = localStorage.getItem(storedMainUserKey)
    localStorage.setItem(storedUserKey, reStoredMainUser)
    localStorage.removeItem(storedMainUserKey)
    window.location = '/auth/login'
  }
  return (
    <SmallUp>
      <div className={`masqurade-banner`}>
        <div className='inner-masqurader mt-2'>
          {/* <span>You are Viewing {masqueradedUser}</span> */}
          <Button
            className='download-link'
            variant='link'
            onClick={() => stopMasquerading()}
          >
            Stop viewing
          </Button>
        </div>
      </div>
    </SmallUp>
  )
}

export default withRouter(
  connect(
    null,
    {}
  )(MasquradeBanner)
)
