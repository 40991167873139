import { createOrEditItem, deleteItem } from './form'

export const addTaxRate = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `salesTax`,
      successMessage: 'Tax rate was successfully created',
    })
  )

export const updateTaxRate = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `salesTax/${id}`,
      successMessage: 'Tax rate was successfully updated',
    })
  )

export const deleteTaxRate = id => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `salesTax/${id}`,
      successMessage: 'Tax rate was successfully Deleted',
    })
  )
