import React from 'react'
import LoadingSpinner from './LoadingSpinner'

export default function PageLoading() {
  return (
    <div className='page-loading'>
      <LoadingSpinner />
    </div>
  )
}
