import { fileToBase64, getOptionFromValue } from '../helpers'
import { defaultModes } from '../constants'
import { logger } from '../actions'
export const supplyFleetFormData = async ({
  supplierId,
  mode,
  globalVehicleId,
  year,
  images,
  id,
  ...values
}) => {
  try {
    let data = {
      ...values,
      supplierId: supplierId ? supplierId.value : '',
      mode: mode.map(({ value }) => value),
      globalVehicleId: globalVehicleId ? globalVehicleId.value : '',
      year: year ? year.value : '',
      active: true,
    }

    if (!id && images && images.length) {
      const imagesList = await Promise.all(
        images
          .map(image => {
            if (image && typeof image === 'object' && image.size) {
              return fileToBase64(image)
            }
            return null
          })
          .filter(img => !!img)
      )

      data.images = imagesList.length ? imagesList : []
    }

    return data
  } catch (error) {
    logger({ fileName: 'supplyFleet', error: error })
    console.log(error)
    return {}
  }
}

export class SupplyFleetInitialValues {
  constructor({
    id,
    supplierId,
    supplierName,
    globalVehicleId,
    year,
    exteriorColor,
    interiorColor,
    images,
    globalVehicle,
    mode = [],
  }) {
    this.id = id
    this.supplierId = {
      value: supplierId,
      label: supplierName,
    }
    this.mode = mode.map(
      item => getOptionFromValue(defaultModes, item) || { value: item }
    )
    this.globalVehicleId = {
      value: globalVehicleId,
      label: globalVehicle.label,
      data: globalVehicle,
    }
    this.year = {
      value: year,
      label: year,
    }
    this.exteriorColor = exteriorColor
    this.interiorColor = interiorColor
    this.images =
      images && images.length
        ? images.map(({ image, id }) => {
            if (image) {
              return {
                name: image,
                id,
              }
            }
            return null
          })
        : []
  }
}
