import React from 'react'
import { Button } from 'react-bootstrap'

export default function SubmitBtn({
  className = '',
  title = 'Save',
  disabled = false,
  ...props
}) {
  return (
    <Button
      className={`submit-btn ${className} ${disabled ? 'disabled' : ''}  `}
      variant='brown'
      disabled={disabled}
      type='submit'
      {...props}
    >
      {title}
    </Button>
  )
}
