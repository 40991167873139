import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import reduxWebsocket from '@giantmachines/redux-websocket'
import ApiService, { wsApiHost } from './api'
import App from './App'
import './assets/styles/styles.scss'
import NotificationsSystem from './components/layout/NotificationsSystem'
import { WEBSOCKET_PREFIX } from './constants'
import { createAppWebsocketMiddleware } from './middlewares'
const history = createBrowserHistory()
const reduxWebsocketMiddleware = reduxWebsocket({
  prefix: WEBSOCKET_PREFIX,
  reconnectOnClose: true,
  reconnectOnError: true,
  /**
   * @param socket WebSocket
   */
  onOpen: socket => {},
})
const middlewares = [
  thunk,
  reduxWebsocketMiddleware,
  // createAppWebsocketMiddleware('ws://35.176.197.19:8080/web-socket'),
  createAppWebsocketMiddleware(wsApiHost),
]
const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(routerMiddleware(history), ...middlewares))
)

export const connectedApiService = new ApiService(store)
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
      <NotificationsSystem />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
