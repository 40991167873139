import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { isMobile } from '../helpers/index.js'

export default function FormButton({
  className = '',
  title = 'Select',
  disabled = false,
  clientId = '',
  handleAction,
  history,
  memberBenefitsApplied,
  selectedVehicle,
  hidden = false,
  ...props
}) {
  const isMobileBrowser = isMobile()
  const [showPanel, setShowPanel] = useState(false)

  useEffect(() => {
    setShowPanel(false)
  }, [])

  const onAction = () => {
    setShowPanel(true)
    if (handleAction) {
      handleAction()
    }
  }
  return (
    <>
      {!isMobileBrowser &&
      (title === 'Manage benefits' || title === 'View benefits') ? (
        <Button
          onClick={() => onAction()}
          className='justify-content-center align-items-center benefits-btn'
          style={{ height: '3.1rem' }}
          variant='brown'
          hidden={hidden}
        >
          {title}
        </Button>
      ) : (
        <button
          onClick={() => onAction()}
          className='btn btn-anchor mt-2 mb-4'
          //variant='link'
          type='button'
          hidden={hidden}
        >
          {title === 'Manage benefits' || title === 'View benefits'
            ? title
            : 'Apply benefits'}
        </button>
      )}
    </>
  )
}
