import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import { loginUser } from '../../../../../actions'
import { minLengthStringValidator } from '../../../../../form-validation'
import { useParams } from 'react-router-dom'
const fields = [
  {
    name: 'newPassword',
    type: 'password',
    label: 'NEW PASSWORD*',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    label: 'CONFIRM PASSWORD*',
  },
]

const validationSchema = Yup.object().shape({
  newPassword: minLengthStringValidator({
    limit: 6,
    message: 'Password must be at least 6 characters',
  }),
  confirmPassword: minLengthStringValidator({
    limit: 6,
    message: 'Password must be at least 6 characters',
  }).oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

function SetPassword({ history }) {
  const partnerEmail = history.location.email ? history.location.email : null
  const { token } = useParams()
  return (
    <div className='auth-page'>
      <div className='image-container-new-partner' />
      <div className='auth-container'>
        <AuthForm
          title='Log in'
          initialValues={initialValues}
          validationSchema={validationSchema}
          fields={fields}
          isSetPassword
          buttonLabel='Log in'
          submitStyle='login-btn'
          token={token}
          partnerEmail={partnerEmail}
        />
      </div>
    </div>
  )
}

export default connect(
  null,
  { loginUser }
)(SetPassword)
