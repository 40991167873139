import React, { useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { getIn, setIn } from 'formik'
import { DropdownIndicator } from './FormSelectComponents'

const { Control, Group, Label } = Form

export default function FormSelectWithIcon({
  field,
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  text, // Add a text prop
  options = [],
  groupProps = {},
  controlId,
  handleChange,
  isMulti = false,
  isSearchable = false,
  disabled = false,
  isClearable = false,
  placeholder = 'Select...',
  classNamePrefix = 'react-select',
  roundFormField = false,
  inputClass = '',
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const selectRef = useRef(null)

  const hasValue = !!field.value

  const onChange = value => {
    setFieldValue(field.name, value)
    if (handleChange) {
      handleChange(value)
    }
  }

  const onBlur = () => {
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }

  const Option = props => {
    if (props.data.type && props.data.type === 'button') {
      return (
        <div className='selectOptionButton'>
          <components.Option {...props}>
            <div>
              <span>{props.data.label}</span>
              <img src={`/images/icons/${props.data.icon}`} alt='' />
            </div>
          </components.Option>
        </div>
      )
    } else {
      return <components.Option {...props} />
    }
  }
  // const ValueContainer = components.ValueContainer

  const ValueContainerSearch = ({ children, ...props }) => {
    if (children) {
      if (props.selectProps.menuIsOpen && props.hasValue) {
        if (selectRef && selectRef.current) {
          selectRef.current.select.focus()
        }
      }
    }
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && !props.hasValue && (
            <img
              className='pb-1 pr-1 mr-1'
              style={{ width: '20px' }}
              src={
                props.hasValue
                  ? `/images/icons/Icon_Search_Active.png`
                  : `/images/icons/Icon_Search_Inactive.png`
              }
              alt=''
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }
  const ValueContainer = ValueContainerSearch

  return (
    <>
      <Group
        controlId={controlId || field.name}
        className={`${roundFormField ? 'round-select-group' : ''} ${
          inputClass ? inputClass : ''
        }
        `}
        {...groupProps}
      >
        {props.label && <Label className='form-label'>{props.label}</Label>}
        <InputGroup>
          {text && (
            <InputGroup.Prepend>
              <InputGroup.Text className={`${hasValue ? 'black-text' : ''}`}>
                {text}
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Select
            ref={selectRef}
            components={{ DropdownIndicator, Option }}
            className={`${classNamePrefix}-container ${
              showError ? 'is-invalid' : ''
            }`}
            classNamePrefix={classNamePrefix}
            {...field}
            inputId={controlId || field.name}
            onBlur={onBlur}
            onChange={onChange}
            isClearable={isClearable}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isDisabled={disabled}
            hideSelectedOptions={true}
            // menuIsOpen={true}
          />
          {showError && (
            <Control.Feedback className='d-block' type='invalid'>
              {getIn(errors, field.name)}
            </Control.Feedback>
          )}
        </InputGroup>
      </Group>
    </>
  )
}
