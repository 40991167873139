import * as Yup from 'yup'
import { getIn, setIn } from 'formik'
import { logger } from '../actions'
const emailRegexp = getIn(Yup.string().email(), 'tests[0].OPTIONS.params.regex')

export const defaultErrors = {
  required: 'Required',
  email: 'Invalid email',
  emailsList: 'One or more email addresses are invalid',
  checkExisting: 'Such item has already exists!',
  minLength: 'Too short field value!',
  requiredLocation: 'Please enter a location',
  requiredDate: 'Please enter a date',
  requiredTime: 'Please enter a time',
}

export const stringValidator = ({ defaultValue = '' } = {}) =>
  Yup.string()
    .nullable()
    .default(defaultValue)

export const requiredStringValidator = ({
  message = defaultErrors.required,
  defaultValue = '',
} = {}) => stringValidator({ defaultValue }).required(message)

export const requiredNumberValidator = ({
  message = defaultErrors.required,
  defaultValue = 0,
} = {}) =>
  Yup.number()
    .default(defaultValue)
    .required(message)

export const requiredStringValidatorNum = ({
  message = defaultErrors.required,
  defaultValue = '',
} = {}) =>
  stringValidator({ defaultValue })
    .required(message)
    .matches(/^[a-zA-Z\s]*$/, 'Only alphabetic characters are allowed')

export const booleanValidator = ({ defaultValue = false } = {}) =>
  Yup.boolean().default(defaultValue)

export const emailValidator = ({
  message = defaultErrors.email,
  defaultValue = '',
} = {}) =>
  Yup.string()
    .email(message)
    .default(defaultValue)

const testEmailsList = value => {
  let invalidList = []
  let emails = (value ? value.split(',') : []).map(email => email.trim())

  emails.forEach(email => {
    if (!emailRegexp.test(email)) {
      invalidList.push(email)
    }
  })

  return !invalidList.length
}

Yup.addMethod(Yup.string, 'emailsList', function(message) {
  return this.test({
    name: 'emailsList',
    message: message || defaultErrors.emailsList,
    test: testEmailsList,
  })
})

export const multipleEmailValidator = ({ message } = {}) =>
  Yup.string().emailsList(message)

export const fileRequired = ({
  message = defaultErrors.required,
  defaultValue = null,
} = {}) =>
  Yup.mixed()
    .required(message)
    .default(defaultValue)

export const requiredObject = ({
  message = defaultErrors.required,
  defaultValue = null,
} = {}) =>
  Yup.object()
    .required(message)
    .default(defaultValue)
    .nullable()

const checkExisting = async (func, params) => {
  try {
    const result = await func(params)
    return !!result
  } catch (error) {
    logger({ fileName: 'formValidation-index', error: error })
    console.log(error)
  }
}

Yup.addMethod(Yup.string, 'checkExisting', function(func, params, message) {
  return this.test({
    name: 'checkExisting',
    message: message || defaultErrors.checkExisting,
    test: () => {
      checkExisting(func, params)
    },
  })
})

export const checkExistingValidator = ({ func, params, message } = {}) =>
  Yup.checkExisting(func, params, message)

export const minLengthStringValidator = ({
  limit,
  message,
  defaultValue = '',
} = {}) =>
  stringValidator({ defaultValue }).min(
    limit,
    (message = message || defaultErrors.minLength)
  )

export const requiredDependOnSelectValue = (name, dependOnValue) =>
  function(value) {
    const dependField = getIn(this.parent, name)

    return (
      !dependField ||
      (dependField.value !== dependOnValue ||
        (dependField.value === dependOnValue && !!value))
    )
  }

export const handleSubmitValidationErrors = (
  { errors = {} } = {},
  form = {},
  parseName
) => {
  const { setErrors = () => {}, setSubmitting = () => {} } = form

  if (errors && errors.length) {
    let formErrors = {}

    errors.forEach(({ name, message }) => {
      try {
        let _name = parseName ? parseName(name) : name
        formErrors = setIn(formErrors, _name, message)
      } catch (error) {
        logger({ fileName: 'formValidation-index', error: error })
        console.log(error)
      }
    })

    setErrors(formErrors)
  }

  setSubmitting(false)
}
