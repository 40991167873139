import { fileToBase64 } from '../helpers'
import { logger } from '../actions'
export class Access {
  constructor({
    firstName = '',
    lastName = '',
    email,
    authToken = null,
    userId = null,
    roles = {},
    isLoggedIn,
    phone,
    image,
  } = {}) {
    const getFormattedRole = role => {
      if (role === 'system_admin') {
        return { label: 'Admin', value: [`${role}`] }
      } else if (role === 'system_agent') {
        return { label: 'Agent', value: [`${role}`] }
      } else if (role === 'system_manager') {
        return { label: 'Manager', value: [`${role}`] }
      } else {
        return {}
      }
    }
    this.firstName = firstName
    this.lastName = lastName
    this.authToken = authToken
    this.userId = userId
    this.roles = getFormattedRole(roles[0])
    this.email = email
    this.phone = phone
    this.isLoggedIn = !!isLoggedIn
    this.image = image
      ? {
          name: image,
        }
      : null
  }
}

export const myAccessFormData = async ({ image, ...values }) => {
  try {
    let data = {
      ...values,
    }
    if (image && typeof image === 'object' && image.size) {
      const logoBase64 = await fileToBase64(image)
      data.image = logoBase64 || null
    }

    return data
  } catch (error) {
    logger({ fileName: 'access', error: error })
    console.log(error)
    return {}
  }
}
export const changePasswordFormData = values => {
  return values
}
