import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useParams, withRouter } from 'react-router-dom'
import { useGetTableData, useGetInitialFormValues } from '../../../../../hooks'
import { connectedApiService as api } from '../../../../../index'
import AddCard from '../../../../../components/DriversApp/AddCard'
import FormRadio from '../../../../../components/FormRadio'
import LocationType from '../../../../../components/locationType'
import PartnerAcceptedModal from '../../../../../components/PartnerAcceptedModal'
import {
  addPickUpInstructions,
  assignChauffeur,
  assignVehicle,
  completeAssign,
} from 'actions'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { isMobile, formatSentenceCase } from '../../../../../helpers'
import LoadingSpinner from 'components/LoadingSpinner'
import FormFieldWithIcon from 'components/FormFieldWithIcon'
import { partnerHostLive, partnerHostStaging } from '../../../../../constants'
import _ from 'lodash'
import FormField from 'components/FormField'
import { Field, Form, Formik } from 'formik'
import FormSelect from 'components/FormSelect'

const Host = window.location.hostname
const isMobileBrowser = isMobile()

const Assign = ({
  history,
  assignChauffeur,
  assignVehicle,
  addPickUpInstructions,
  completeAssign,
}) => {
  const { token, bookingId } = useParams()

  let location = useLocation()
  let currentLocation = location.pathname

  const [isLoading, setIsLoading] = useState(false)
  const [selectedChauffeur, setSelectedChauffeur] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [multipleRowsList, setMultipleRowsList] = useState([])
  const [driverExists, setDriverExists] = useState(true)
  const [showAssignedModal, setShowAssignedModal] = useState(false)
  const [locationType, setLocationType] = useState(false)
  const [sameChauffeurAndVehicle, setSameChauffeurAndVehicle] = useState(false)
  const [matchingVehicles, setMatchingVehicles] = useState(null)
  const [isPickUpNotesEmpty, setIsPickUpNotesEmpty] = useState(false)
  const [pickUpInstructions, setPickUpInstructions] = useState()

  const { currentItem, getCurrentItem } = useGetInitialFormValues(
    `external/partnerBooking/${bookingId}/${token}`
  )

  const {
    data: { list },
    reloadList,
    onFiltersChange,
    filters,
  } = useGetTableData(
    currentLocation.includes('chauffeur')
      ? `external/chauffeur/${token}`
      : currentLocation.includes('vehicle')
      ? `external/cars/${token}/${bookingId}`
      : `external/confirm/${bookingId}`
  )

  useEffect(() => {
    if (currentItem) {
      if (currentLocation.includes('chauffeur')) {
        setSelectedChauffeur(
          currentItem.partnerChauffeurIdTemp
            ? currentItem.partnerChauffeurIdTemp
            : currentItem.partnerChauffeurId
            ? currentItem.partnerChauffeurId
            : null
        )
        setSelectedTab(0)
      } else if (currentLocation.includes('vehicle')) {
        setSelectedVehicle(
          currentItem.partnerVehicleIdTemp
            ? currentItem.partnerVehicleIdTemp
            : null
        )
        setSelectedTab(1)
        if (
          !currentItem.partnerChauffeurId &&
          !currentItem.partnerChauffeurIdTemp
        ) {
          setSelectedTab(0)
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/chauffeur/${token}/${bookingId}`
          )
        }
      } else if (currentLocation.includes('pickup')) {
        setSelectedTab(2)
        if (
          !currentItem.partnerChauffeurId &&
          !currentItem.partnerChauffeurIdTemp
        ) {
          setSelectedTab(0)
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/chauffeur/${token}/${bookingId}`
          )
        } else if (
          !currentItem.partnerVehicleId &&
          !currentItem.partnerVehicleIdTemp
        ) {
          setSelectedTab(1)
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/vehicle/${token}/${bookingId}`
          )
        }
      } else if (currentLocation.includes('confirm')) {
        setSelectedTab(3)
        if (
          !currentItem.partnerVehicleIdTemp &&
          !currentItem.partnerChauffeurIdTemp
        ) {
          setSameChauffeurAndVehicle(true)
        } else {
          setSameChauffeurAndVehicle(false)
        }
      }
    }
  }, [currentItem, currentLocation])

  useEffect(() => {
    const fetchLocationType = async () => {
      let location = null
      if (currentItem.dropOffLocation) {
        location = !(
          currentItem.pickUpLocation.locationType ||
          currentItem.dropOffLocation.locationType
        )
          ? await LocationType(
              currentItem.pickUpLocation,
              currentItem.dropOffLocation
            )
          : null
      } else {
        location = !currentItem.pickUpLocation.locationType
          ? await LocationType(currentItem.pickUpLocation)
          : null
      }
      setLocationType(location)
      if (
        currentItem.airportPickUpInfo &&
        ((location && location.pickup && location.pickup.isAirport === false) ||
          (currentItem.pickUpLocation.LocationType &&
            currentItem.pickUpLocation.LocationType !== 'isAirport'))
      )
        delete currentItem.airportPickUpInfo
      if (
        currentItem.airportDropOffInfo &&
        ((location &&
          location.dropOff &&
          location.dropOff.isAirport === false) ||
          (currentItem.dropOffLocation.LocationType &&
            currentItem.dropOffLocation.LocationType !== 'isAirport'))
      )
        delete currentItem.airportDropOffInfo
      if (
        currentItem.trainPickUpInfo &&
        ((location && location.pickup && location.pickup.isTrain === false) ||
          (currentItem.pickUpLocation.LocationType &&
            currentItem.pickUpLocation.LocationType !== 'isTrain'))
      )
        delete currentItem.trainPickUpInfo
      if (
        currentItem.trainDropOffInfo &&
        ((location && location.dropOff && location.dropOff.isTrain === false) ||
          (currentItem.dropOffLocation.LocationType &&
            currentItem.dropOffLocation.LocationType !== 'isTrain'))
      )
        delete currentItem.trainDropOffInfo
    }
    if (currentItem && currentItem.pickUpLocation) fetchLocationType()
  }, [currentItem])

  useEffect(() => {
    const checkDriverExists = async phoneNumber => {
      const url = `external/findDriver/${phoneNumber}`
      const { data } = await api.get(url)

      setDriverExists(data.valid)
    }
    if (list) {
      let length = 2
      let rest = list.length % 2
      let size = Math.floor(list.length / 2)
      let index = 0
      setMultipleRowsList(
        Array.from({ length }, (_, i) =>
          list.slice(index, (index += size + (i < rest)))
        )
      )
      if (currentLocation.includes('confirm') && list[0] && list[0].phoneNumber)
        checkDriverExists(list[0].phoneNumber)
      if (
        currentLocation.includes('vehicle') &&
        currentItem &&
        currentItem.vehicleName
      ) {
        let allVehicles = []
        let bookingVehicle = currentItem.vehicleName.trim()
        _.each(list, vehicle => {
          if (
            vehicle.make &&
            vehicle.model &&
            vehicle.make.label &&
            vehicle.model.label
          ) {
            let currentVehicle =
              vehicle.make.label.trim() + ' ' + vehicle.model.label.trim()
            if (bookingVehicle === currentVehicle) allVehicles.push(vehicle._id)
          }
        })
        setMatchingVehicles(allVehicles)
      }
    }
    if (list && list[0] && list[0].firstName && list[0].lastName) {
      list[0].firstName = formatSentenceCase(list[0].firstName)
      list[0].lastName = formatSentenceCase(list[0].lastName)
    }
  }, [list, currentItem])

  const sendSMS = async () => {
    try {
      if (
        currentLocation.includes('confirm') &&
        list[0] &&
        list[0].phoneNumber
      ) {
        const url = `external/sendSmsToDriver/${list[0].phoneNumber}`
        await api.get(url)
        reloadList()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const redirectAssign = () => {
    try {
      if (currentLocation.includes('chauffeur')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('vehicle')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/chauffeur/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('pickup')) {
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/vehicle/${token}/${bookingId}`
        )
      } else if (currentLocation.includes('confirm')) {
        if (
          currentItem &&
          (currentItem.airportPickUpInfo || currentItem.trainPickUpInfo)
        ) {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/pickup/${token}/${bookingId}`
          )
        } else {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/vehicle/${token}/${bookingId}`
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const selectChauffeur = id => {
    if (selectedChauffeur !== id) setSelectedChauffeur(id)
  }
  const selectVehicle = id => {
    if (selectedVehicle !== id) setSelectedVehicle(id)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    if (currentLocation.includes('chauffeur')) {
      // const geo = `51.4933,-0.1684`
      const geo = `00.00-00.00`
      let values = {
        chauffeurId: selectedChauffeur,
        token: token,
        action: 'accepted',
        geoLocation: geo,
        isLiveLocation: false,
      }
      let data = await assignChauffeur(values, bookingId)
      if (data) {
        await getCurrentItem()
        setIsLoading(false)
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/vehicle/${token}/${bookingId}`
        )
      }
    } else if (currentLocation.includes('vehicle')) {
      let values = {
        vehicleId: selectedVehicle,
      }
      let data = await assignVehicle(values, bookingId)
      if (data) {
        await getCurrentItem()
        setIsLoading(false)
        if (
          currentItem &&
          (currentItem.airportPickUpInfo || currentItem.trainPickUpInfo)
        ) {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/pickup/${token}/${bookingId}`
          )
        } else {
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/confirm/${token}/${bookingId}`
          )
        }
      }
    } else if (currentLocation.includes('pickup')) {
      if (
        pickUpInstructions &&
        pickUpInstructions !== '' &&
        pickUpInstructions.value
      ) {
        let values = {
          pickUpInstructions: pickUpInstructions.value,
        }

        if (pickUpInstructions.value === 'Other') {
          var pickUpInstructionsText = document.getElementById(
            'pickUpInstructionsText'
          ).innerText

          if (pickUpInstructionsText && pickUpInstructionsText !== '') {
            values = {
              pickUpInstructions: pickUpInstructionsText,
            }
          } else {
            setIsPickUpNotesEmpty(true)
          }
        }

        await addPickUpInstructions(values, bookingId)
        await getCurrentItem()
        history.replace(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/confirm/${token}/${bookingId}`
        )
      } else {
        setIsPickUpNotesEmpty(true)
      }
      setIsLoading(false)
    } else {
      // const geo = `51.4933,-0.1684`
      const geo = `00.00-00.00`
      let values = {
        token: token,
        action: 'accepted',
        geoLocation: geo,
        isLiveLocation: false,
      }
      await completeAssign(values, bookingId)
      setShowAssignedModal(true)
      setTimeout(
        async function() {
          await getCurrentItem()
          history.replace(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/${token}/${bookingId}`
          )
        }.bind(this),
        1500
      )
    }
  }

  const renderContent = () => {
    return (
      <>
        {isLoading && <LoadingSpinner />}
        {list &&
          (locationType ||
            (currentItem && currentItem.pickUpLocation.locationType)) && (
            <div className='driver-booking-container' style={{ margin: 0 }}>
              <div className='tabs-container' style={{ width: 'fit-content' }}>
                <div className='d-flex justify-content-start'>
                  <div className='assign-stepper'>
                    {currentItem && currentItem.type === 'car_rentals' ? (
                      <div className={`nav-link completed`}>Agent</div>
                    ) : (
                      <div className={`nav-link completed`}>Chauffeur</div>
                    )}
                    <div
                      className={`nav-link ${
                        selectedTab >= 1 ? 'completed' : 'not-completed'
                      }`}
                    >
                      Vehicle
                    </div>
                    {currentItem &&
                      (currentItem.airportPickUpInfo ||
                        currentItem.trainPickUpInfo) && (
                        <div
                          className={`nav-link ${
                            selectedTab >= 2 ? 'completed' : 'not-completed'
                          }`}
                        >
                          {currentItem && currentItem.type === 'car_rentals'
                            ? 'Delivery instructions'
                            : 'Pick-up instructions'}
                        </div>
                      )}
                    <div
                      className={`nav-link ${
                        selectedTab === 3 ? 'completed' : 'not-completed'
                      }`}
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>

              {selectedTab === 0 && (
                <>
                  <div
                    className={`driver-service-card ${
                      list.length <= 3
                        ? 'assign-select-card'
                        : 'assign-select-card-multiple'
                    }`}
                  >
                    <div className='driver-service-card-header pb-4 pt-3'>
                      {currentItem && currentItem.type === 'car_rentals' ? (
                        <span className='select-title'>Select an agent</span>
                      ) : (
                        <span className='select-title'>Select a chauffeur</span>
                      )}
                    </div>
                    {list.length ? (
                      <div className='partner-search-container'>
                        {currentItem && currentItem.type === 'car_rentals' ? (
                          <div class='col pl-0'>
                            <FormFieldWithIcon
                              field={{
                                name: 'search-chauffeur',
                                placeHolder: 'Search agent name',
                                value: filters['chauffeurName'],
                                onChange: onFiltersChange('chauffeurName'),
                              }}
                              icon='icons/Icon_Search_Inactive.png'
                              className='partner-search-bar'
                            />
                          </div>
                        ) : (
                          <div class='col pl-0'>
                            <FormFieldWithIcon
                              field={{
                                name: 'search-chauffeur',
                                placeHolder: 'Search chauffeur name',
                                value: filters['chauffeurName'],
                                onChange: onFiltersChange('chauffeurName'),
                              }}
                              icon='icons/Icon_Search_Inactive.png'
                              className='partner-search-bar'
                            />
                          </div>
                        )}
                        <Link
                          to={`${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/chauffeur/add`}
                          className='partner-link-desktop'
                        >
                          <Button
                            className='partner-btn-desktop-secondary-assign select-vehicle'
                            variant='brown'
                          >
                            {currentItem && currentItem.type === 'car_rentals'
                              ? 'Add agent'
                              : ' Add chauffeur'}
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {list.length ? (
                      <>
                        {list.length <= 3 ? (
                          list.map(item => (
                            <div
                              className={`chauffeur-card-item ${
                                item._id === selectedChauffeur
                                  ? 'selected-item'
                                  : ''
                              }`}
                            >
                              {item.profilePicture ? (
                                <div className='add-pic-container-uploaded'>
                                  <img
                                    className='profile-img'
                                    src={item.profilePicture}
                                  />
                                </div>
                              ) : (
                                <div className='add-pic-container-uploaded'>
                                  <img
                                    className='car-img-chauffeur'
                                    src={
                                      '/images/icons/icon-chauffeur-black.png'
                                    }
                                  />
                                </div>
                              )}
                              <span
                                className='info-span-chauffeur'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {item.firstName && item.lastName ? (
                                  <span
                                    className='chauffeur-title d-block'
                                    style={{
                                      fontWeight: 'bolder',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {formatSentenceCase(item.firstName)}{' '}
                                    {formatSentenceCase(item.lastName)}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <span style={{ color: '#8b959e' }}>
                                  {item.phoneNumber}
                                </span>
                              </span>
                              <FormRadio
                                name={`driver${item._id}`}
                                id={`driver${item._id}`}
                                label=''
                                className='mb-0 mt-3'
                                checked={selectedChauffeur === item._id}
                                handleChange={() => {
                                  selectChauffeur(item._id)
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <div className='partner-grid'>
                            {multipleRowsList.map(items => (
                              <div>
                                {items.map(item => (
                                  <div
                                    className={`chauffeur-card-item ${
                                      item._id === selectedChauffeur
                                        ? 'selected-item'
                                        : ''
                                    }`}
                                  >
                                    {item.profilePicture ? (
                                      <div className='add-pic-container-uploaded'>
                                        <img
                                          className='profile-img'
                                          src={item.profilePicture}
                                        />
                                      </div>
                                    ) : (
                                      <div className='add-pic-container-uploaded'>
                                        <img
                                          className='car-img-chauffeur'
                                          src={
                                            '/images/icons/icon-chauffeur-black.png'
                                          }
                                        />
                                      </div>
                                    )}
                                    <span
                                      className='info-span-chauffeur'
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      {item.firstName && item.lastName ? (
                                        <span
                                          className='chauffeur-title d-block'
                                          style={{
                                            fontWeight: 'bolder',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {formatSentenceCase(item.firstName)}{' '}
                                          {formatSentenceCase(item.lastName)}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      <span style={{ color: '#8b959e' }}>
                                        {item.phoneNumber}
                                      </span>
                                    </span>
                                    <FormRadio
                                      name={`driver${item._id}`}
                                      id={`driver${item._id}`}
                                      label=''
                                      className='mb-0 mt-3'
                                      checked={selectedChauffeur === item._id}
                                      handleChange={() => {
                                        selectChauffeur(item._id)
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='chauffeur-card-item extra-spacing'>
                        <div className='d-flex align-items-center'>
                          <div className='add-pic-container'>
                            <img
                              className='car-img-chauffeur'
                              src={'/images/icons/icon-chauffeur-grey.png'}
                            />
                          </div>
                          <span
                            className='info-span-small ml-3'
                            style={{ whtieSpace: 'pre' }}
                          >
                            <span style={{ color: '#8b959e' }}>
                              No active{' '}
                              {currentItem.type === 'car_rentals'
                                ? 'agent'
                                : 'chauffeur'}
                            </span>
                          </span>
                        </div>
                        <Link
                          to={`${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/chauffeur/add`}
                        >
                          <Button
                            className='partner-btn-desktop-small select-vehicle'
                            variant='brown'
                            style={{
                              border: '1px solid #a79779',
                              backgroundColor: '#a79779',
                            }}
                          >
                            Add{' '}
                            {currentItem.type === 'car_rentals'
                              ? 'agent'
                              : 'chauffeur'}
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                  {list.length ? (
                    <div
                      className={
                        list.length <= 3
                          ? 'partner-next-container-assign'
                          : 'partner-next-container-assign-multi'
                      }
                    >
                      <Button
                        className='partner-btn-desktop-primary select-vehicle'
                        variant='brown'
                        disabled={!selectedChauffeur}
                        onClick={() => {
                          onSubmit()
                        }}
                        style={{
                          border: '1px solid #a79779',
                          backgroundColor: '#a79779',
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <> </>
                  )}
                </>
              )}
              {selectedTab === 1 && (
                <>
                  <div
                    className={`driver-service-card ${
                      list.length <= 3
                        ? 'assign-select-card'
                        : 'assign-select-card-multiple'
                    }`}
                  >
                    {' '}
                    <div className='driver-service-card-header pb-4 pt-3'>
                      <span className='select-title'>Select a vehicle</span>
                    </div>
                    {list.length ? (
                      <div className='partner-search-container'>
                        <div class='col pl-0'>
                          <FormFieldWithIcon
                            field={{
                              name: 'search-vehicle',
                              placeHolder: 'Search vehicle',
                              value: filters['vehicleName'],
                              onChange: onFiltersChange('vehicleName'),
                            }}
                            icon='icons/Icon_Search_Inactive.png'
                            className='partner-search-bar'
                          />
                        </div>
                        <Link
                          to={`${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/car/add`}
                          className='partner-link-desktop'
                        >
                          <Button
                            className='partner-btn-desktop-secondary-assign select-vehicle'
                            variant='brown'
                          >
                            Add vehicle
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <> </>
                    )}
                    {list.length ? (
                      <>
                        {list.length <= 3 ? (
                          list.map(item => (
                            <div
                              className={`chauffeur-card-item ${
                                matchingVehicles.indexOf(item._id) !== -1 &&
                                !selectedVehicle
                                  ? 'assigned-item'
                                  : item._id === selectedVehicle
                                  ? 'selected-item'
                                  : ''
                              }`}
                            >
                              <img
                                className=' d-block car-edit-img shift-image'
                                src={item.vehicleImage && item.vehicleImage}
                                alt=''
                              />
                              <span className='info-span'>
                                <span
                                  className='d-block'
                                  style={{ fontWeight: 'bolder' }}
                                >
                                  {item.make && item.make.label}{' '}
                                  {item.model && item.model.label}
                                </span>
                                <span
                                  style={{
                                    color: '#8b959e',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {item.plateNumber}
                                </span>
                              </span>
                              <FormRadio
                                name={`vehicle${item._id}`}
                                id={`vehicle${item._id}`}
                                label=''
                                className='mb-0 mt-3'
                                checked={selectedVehicle === item._id}
                                handleChange={() => {
                                  selectVehicle(item._id)
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <div className='partner-grid'>
                            {multipleRowsList.map(items => (
                              <div>
                                {items.map(item => (
                                  <div
                                    className={`chauffeur-card-item ${
                                      matchingVehicles.indexOf(item._id) !==
                                        -1 && !selectedVehicle
                                        ? 'assigned-item'
                                        : item._id === selectedVehicle
                                        ? 'selected-item'
                                        : ''
                                    }`}
                                  >
                                    <img
                                      className=' d-block car-edit-img shift-image'
                                      src={
                                        item.vehicleImage && item.vehicleImage
                                      }
                                      alt=''
                                    />
                                    <span className='info-span'>
                                      <span
                                        className='d-block'
                                        style={{ fontWeight: 'bolder' }}
                                      >
                                        {item.make && item.make.label}{' '}
                                        {item.model && item.model.label}
                                      </span>
                                      <span
                                        style={{
                                          color: '#8b959e',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        {item.plateNumber}
                                      </span>
                                    </span>
                                    <FormRadio
                                      name={`vehicle${item._id}`}
                                      id={`vehicle${item._id}`}
                                      label=''
                                      className='mb-0 mt-3'
                                      checked={selectedVehicle === item._id}
                                      handleChange={() => {
                                        selectVehicle(item._id)
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='chauffeur-card-item extra-spacing'>
                        <div className='d-flex align-items-center'>
                          <div className='add-pic-container'>
                            <img
                              className='car-img'
                              src={'/images/icons/icon-vehicle-grey.png'}
                              alt=''
                            />
                          </div>
                          <span
                            className='info-span-small ml-3'
                            style={{ whiteSpace: 'pre' }}
                          >
                            <span style={{ color: '#8b959e' }}>
                              No active vehicle
                            </span>
                          </span>
                        </div>

                        <Link
                          to={`${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/car/add`}
                        >
                          <Button
                            className='partner-btn-desktop-small select-vehicle'
                            variant='brown'
                            style={{
                              border: '1px solid #a79779',
                              backgroundColor: '#a79779',
                            }}
                          >
                            Add vehicle
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                  {list.length ? (
                    <div
                      className={
                        list.length <= 3
                          ? 'partner-next-container-assign'
                          : 'partner-next-container-assign-multi'
                      }
                    >
                      {' '}
                      <Button
                        className='partner-btn-desktop-tertiary select-vehicle'
                        onClick={() =>
                          history.push(
                            `${
                              Host === partnerHostLive ||
                              Host === partnerHostStaging
                                ? '/'
                                : '/partner/'
                            }driver/app/details/chauffeur/${token}/${bookingId}`
                          )
                        }
                      >
                        Back
                      </Button>
                      <Button
                        className='partner-btn-desktop-primary select-vehicle'
                        variant='brown'
                        disabled={!selectedVehicle}
                        onClick={() => {
                          onSubmit()
                        }}
                        style={{
                          border: '1px solid #a79779',
                          backgroundColor: '#a79779',
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <> </>
                  )}
                </>
              )}
              {selectedTab === 2 && (
                <Row className='assign-confirm-container'>
                  <Col
                    md={6}
                    className='driver-service-card assign-select-card-confirm'
                  >
                    <div className='driver-service-card-header pb-4 pt-3'>
                      <span className='select-title'>
                        Enter the{' '}
                        {currentItem && currentItem.type === 'car_rentals'
                          ? 'delivery instructions'
                          : 'pick-up instructions'}
                      </span>
                    </div>
                    {/* web view */}
                    <p style={{ fontSize: '15px' }}>
                      Specify if the{' '}
                      {currentItem && currentItem.type === 'car_rentals'
                        ? 'agent'
                        : 'chauffeur'}{' '}
                      will be waiting inside or outside the terminal, the exact
                      meeting point and the gate number (if applicable).
                    </p>
                    <FormSelect
                      field={{
                        value: pickUpInstructions,
                        name: 'pickUpInstructions',
                      }}
                      handleChange={val => {
                        setPickUpInstructions(val)
                      }}
                      options={
                        currentItem &&
                        currentItem.airportPickUpInfo &&
                        currentItem.airportPickUpInfo.flightType &&
                        currentItem.airportPickUpInfo.flightType === 'private'
                          ? [
                              {
                                label: 'Tarmac by the aircraft',
                                value: 'Tarmac by the aircraft',
                              },
                              { label: 'Other', value: 'Other' },
                            ]
                          : [
                              {
                                label: 'Arrivals hall M&G',
                                value: 'Arrivals hall M&G',
                              },
                              {
                                label: 'Curbside pick-up',
                                value: 'Curbside pick-up',
                              },
                              { label: 'Other', value: 'Other' },
                            ]
                      }
                      placeholder={'Select an option'}
                    />
                    {pickUpInstructions &&
                    pickUpInstructions.value === 'Other' ? (
                      <span
                        id='pickUpInstructionsText'
                        className='form-group notes-expandable-partner'
                        as='textarea'
                        contentEditable='plaintext-only'
                        // data-placeholder='e.g. arrivals hall, curbside, etc.'
                      >
                        {currentItem && currentItem.meetingPoint
                          ? currentItem.meetingPoint
                          : ''}
                      </span>
                    ) : (
                      <></>
                    )}
                    {isPickUpNotesEmpty && (
                      <span className='mismatch-text mt-1'>
                        Please enter the pick-up instructions.
                      </span>
                    )}
                    <div
                      className='partner-next-container'
                      style={isPickUpNotesEmpty ? { marginTop: '3em' } : {}}
                    >
                      <Button
                        className='partner-btn-desktop-tertiary select-vehicle'
                        onClick={() =>
                          history.push(
                            `${
                              Host === partnerHostLive ||
                              Host === partnerHostStaging
                                ? '/'
                                : '/partner/'
                            }driver/app/details/vehicle/${token}/${bookingId}`
                          )
                        }
                      >
                        Back
                      </Button>
                      <Button
                        className='partner-btn-desktop-primary select-vehicle'
                        variant='brown'
                        onClick={() => {
                          onSubmit()
                        }}
                        style={{
                          border: '1px solid #a79779',
                          backgroundColor: '#a79779',
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              {selectedTab === 3 && list.length && (
                <Row className='assign-confirm-container'>
                  <Col
                    md={6}
                    className='driver-service-card assign-select-card-confirm'
                  >
                    {currentItem && currentItem.type === 'car_rentals' ? (
                      <div className='driver-service-card-header pb-4 pt-3'>
                        <span className='select-title'>
                          Assign agent delivering the vehicle
                        </span>
                      </div>
                    ) : (
                      <div className='driver-service-card-header pb-4 pt-3'>
                        <span className='select-title'>
                          Assign the chauffeur and vehicle
                        </span>
                      </div>
                    )}
                    <div className='chauffeur-card-item-confirm'>
                      {list[0].profilePicture ? (
                        <div style={{ width: '100vw', paddingLeft: '13px' }}>
                          <div className='add-pic-container-uploaded'>
                            <img
                              className='profile-img'
                              src={list[0].profilePicture}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ width: '100vw', paddingLeft: '13px' }}>
                          <div className='add-pic-container'>
                            <img
                              className='car-img-chauffeur'
                              src={'/images/icons/icon-chauffeur-grey.png'}
                            />
                          </div>
                        </div>
                      )}
                      <span className='info-span'>
                        <span
                          className='d-block'
                          style={{
                            fontWeight: 'bolder',
                            textTransform: 'capitalize',
                          }}
                        >
                          {list[0].firstName} {list[0].lastName}
                        </span>
                        <span style={{ color: '#8b959e' }}>
                          {list[0].phoneNumber}
                        </span>
                      </span>
                      <img
                        width={20}
                        src='/images/icons/icon-checklist-black.png'
                      />
                    </div>
                    <div className='chauffeur-card-item-confirm'>
                      <div style={{ width: '100vw' }}>
                        <div>
                          <img
                            style={{ width: '61px' }}
                            src={list[1].vehicleImage && list[1].vehicleImage}
                            alt=''
                          />
                        </div>
                      </div>
                      <span className='info-span'>
                        <span
                          className='d-block'
                          style={{ fontWeight: 'bolder' }}
                        >
                          {list[1].make && list[1].make.label}{' '}
                          {list[1].model && list[1].model.label}
                        </span>
                        <span
                          style={{
                            color: '#8b959e',
                            textTransform: 'uppercase',
                          }}
                        >
                          {list[1].plateNumber}
                        </span>
                      </span>
                      <img
                        width={20}
                        src='/images/icons/icon-checklist-black.png'
                      />
                    </div>
                    {sameChauffeurAndVehicle && (
                      <span className='mismatch-text mt-1'>
                        The chauffeur and vehicle selected are already assigned
                        to this booking.
                      </span>
                    )}
                    <div
                      className='partner-next-container'
                      style={
                        sameChauffeurAndVehicle ? { marginTop: '3em' } : {}
                      }
                    >
                      <Button
                        className='partner-btn-desktop-tertiary select-vehicle'
                        onClick={() => {
                          if (
                            currentItem &&
                            (currentItem.airportPickUpInfo ||
                              currentItem.trainPickUpInfo)
                          )
                            history.push(
                              `${
                                Host === partnerHostLive ||
                                Host === partnerHostStaging
                                  ? '/'
                                  : '/partner/'
                              }driver/app/details/pickup/${token}/${bookingId}`
                            )
                          else {
                            history.push(
                              `${
                                Host === partnerHostLive ||
                                Host === partnerHostStaging
                                  ? '/'
                                  : '/partner/'
                              }driver/app/details/vehicle/${token}/${bookingId}`
                            )
                          }
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        className='partner-btn-desktop-primary select-vehicle'
                        disabled={sameChauffeurAndVehicle}
                        variant='brown'
                        onClick={() => {
                          onSubmit()
                        }}
                        style={{
                          border: '1px solid #a79779',
                          backgroundColor: '#a79779',
                        }}
                      >
                        Assign
                      </Button>
                    </div>
                  </Col>

                  {!driverExists && (
                    <Col
                      md={5}
                      className='driver-service-card'
                      style={{ marginTop: '2em', height: 'fit-content' }}
                    >
                      <div className='driver-service-card-header-app'>
                        <img src='/images/icons/icon-app.png' />
                        <span>
                          ROLZO Partner App for{' '}
                          {currentItem.type === 'car_rentals'
                            ? 'agents'
                            : 'chauffeurs'}
                        </span>
                      </div>
                      <div>
                        {currentItem && currentItem.type === 'car_rentals' ? (
                          <p className='partner-app-para'>
                            {list[0].firstName} {list[0].lastName} is required
                            to download the ROLZO Partner App to complete the
                            real-time delivery status updates. Please note that
                            no price will be displayed to the agent.
                          </p>
                        ) : (
                          <p className='partner-app-para'>
                            {list[0].firstName} {list[0].lastName} is required
                            to download the ROLZO Partner App for chauffeurs to
                            be able to accept the booking and complete real-time
                            trip status updates. Please note that no price will
                            be displayed to the chauffeur.
                          </p>
                        )}
                      </div>
                      <Button
                        className='partner-btn-desktop-secondary select-vehicle'
                        variant='brown'
                        onClick={() => sendSMS()}
                      >
                        Send app download link by SMS
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
            </div>
          )}
      </>
    )
  }

  const renderModalContent = () => {
    return (
      <PartnerAcceptedModal
        isCarRentals={
          currentItem && currentItem.type === 'car_rentals' ? true : false
        }
        isBookingAccepted={false}
      />
    )
  }

  return (
    <>
      {!isMobileBrowser ? (
        <>
          {currentItem ? (
            <DefaultDriverDesktopLayout
              data={currentItem}
              renderContent={
                !showAssignedModal ? renderContent : renderModalContent
              }
            />
          ) : (
            <> </>
          )}
        </>
      ) : (
        <>
          {!showAssignedModal ? (
            <div style={{ height: '100vh', overflow: 'hidden' }}>
              {isLoading && <LoadingSpinner />}
              <img
                className='driver-back-icon-assign'
                src='/images/icon-back.png'
                onClick={() => redirectAssign()}
                alt=''
              />
              <div className='driver-booking-container-header'>
                {currentItem && <span> Booking #{currentItem.number} </span>}
              </div>

              {currentLocation.includes('chauffeur') ? (
                <>
                  <div className='driver-booking-assign-body'>
                    {currentItem && currentItem.type === 'car_rentals' ? (
                      <h1>Select an agent</h1>
                    ) : (
                      <h1>Select a chauffeur</h1>
                    )}
                    <div className='mb-3'>
                      <FormFieldWithIcon
                        field={{
                          name: 'search-chauffeur',
                          placeHolder:
                            currentItem && currentItem.type === 'car_rentals'
                              ? 'Search agent'
                              : 'Search chauffeur',
                          value: filters['chauffeurName'],
                          onChange: onFiltersChange('chauffeurName'),
                        }}
                        icon='icons/Icon_Search_Inactive.png'
                        roundFormField={true}
                      />
                    </div>
                    <AddCard type={'chauffeur'} token={token} />
                    <div>
                      {list &&
                        list.map(item => (
                          <div
                            className={`driver-add-card mb-3 ${
                              selectedChauffeur === item._id ? 'add-border' : ''
                            }`}
                          >
                            {item.profilePicture ? (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='profile-img'
                                  src={item.profilePicture}
                                />
                              </div>
                            ) : (
                              <div className='add-pic-container-uploaded'>
                                <img
                                  className='car-img-chauffeur'
                                  src={'/images/icons/icon-chauffeur-black.png'}
                                />
                              </div>
                            )}
                            <span className='info-span'>
                              <span
                                className='d-block'
                                style={{
                                  fontWeight: 'bolder',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </span>
                              <span> {item.phoneNumber} </span>
                            </span>
                            <FormRadio
                              name={`driver${item._id}`}
                              id={`driver${item._id}`}
                              label=''
                              className='mb-0 mt-3'
                              labelClass='custom-radio-partner'
                              checked={selectedChauffeur === item._id}
                              handleChange={() => {
                                selectChauffeur(item._id)
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='driver-booking-cta-footer-assign'>
                    <Button
                      className='select-btn select-vehicle'
                      style={{
                        width: '100%',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                        textShadow: '0.25px 0 #ffffff',
                      }}
                      variant='brown'
                      onClick={() => {
                        onSubmit()
                      }}
                      disabled={!selectedChauffeur}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : currentLocation.includes('vehicle') ? (
                <>
                  <div className='driver-booking-assign-body'>
                    <h1>Select a vehicle</h1>
                    <div className='mb-3'>
                      <FormFieldWithIcon
                        field={{
                          name: 'search-vehicle',
                          placeHolder: 'Search vehicle',
                          value: filters['vehicleName'],
                          onChange: onFiltersChange('vehicleName'),
                        }}
                        icon='icons/Icon_Search_Inactive.png'
                        roundFormField={true}
                      />
                    </div>
                    <AddCard type={'car'} token={token} />
                    <div>
                      {list &&
                        list.map(item => (
                          <div
                            className={`driver-add-card mb-3 ${
                              selectedVehicle === item._id ? 'add-border' : ''
                            }`}
                          >
                            <img
                              className='d-block car-edit-img shift-image '
                              src={item.vehicleImage && item.vehicleImage}
                              alt=''
                            />
                            <span className='info-span'>
                              <span
                                className='d-block'
                                style={{ fontWeight: 'bolder' }}
                              >
                                {item.make && item.make.label}{' '}
                                {item.model && item.model.label}
                              </span>
                              <span style={{ textTransform: 'uppercase' }}>
                                {' '}
                                {item.plateNumber}{' '}
                              </span>
                            </span>
                            <FormRadio
                              name={`vehicle${item._id}`}
                              id={`vehicle${item._id}`}
                              label=''
                              className='mb-0 mt-3'
                              labelClass='custom-radio-partner'
                              checked={selectedVehicle === item._id}
                              handleChange={() => {
                                selectVehicle(item._id)
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='driver-booking-cta-footer-assign'>
                    <Button
                      className='select-btn select-vehicle'
                      style={{
                        width: '100%',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                        textShadow: '0.25px 0 #ffffff',
                      }}
                      variant='brown'
                      onClick={() => {
                        onSubmit()
                      }}
                      disabled={!selectedVehicle}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : currentLocation.includes('pickup') ? (
                <>
                  <div className='driver-booking-assign-body'>
                    <h1>
                      Enter the{' '}
                      {currentItem && currentItem.type === 'car_rentals'
                        ? 'delivery instructions'
                        : 'pick-up instructions'}
                    </h1>
                    <div className='driver-service-card'>
                      <div className='important-info'>
                        {/* mobile view */}
                        <p>
                          Specify if the{' '}
                          {currentItem && currentItem.type === 'car_rentals'
                            ? 'agent'
                            : 'chauffeur'}{' '}
                          will be waiting inside or outside the terminal, the
                          exact meeting point and the gate number (if
                          applicable).
                        </p>
                      </div>
                    </div>
                    <FormSelect
                      field={{
                        value: pickUpInstructions,
                        name: 'pickUpInstructions',
                      }}
                      handleChange={val => {
                        setPickUpInstructions(val)
                      }}
                      options={
                        currentItem &&
                        currentItem.airportPickUpInfo &&
                        currentItem.airportPickUpInfo.flightType &&
                        currentItem.airportPickUpInfo.flightType === 'private'
                          ? [
                              {
                                label: 'Tarmac by the aircraft',
                                value: 'Tarmac by the aircraft',
                              },
                              { label: 'Other', value: 'Other' },
                            ]
                          : [
                              {
                                label: 'Arrivals hall M&G',
                                value: 'Arrivals hall M&G',
                              },
                              {
                                label: 'Curbside pick-up',
                                value: 'Curbside pick-up',
                              },
                              { label: 'Other', value: 'Other' },
                            ]
                      }
                      placeholder={'Select an option'}
                    />
                    {pickUpInstructions &&
                    pickUpInstructions.value === 'Other' ? (
                      <span
                        id='pickUpInstructionsText'
                        className='form-group notes-expandable-partner'
                        as='textarea'
                        contentEditable='plaintext-only'
                        // data-placeholder='e.g. arrivals hall, curbside, etc.'
                      >
                        {currentItem && currentItem.meetingPoint
                          ? currentItem.meetingPoint
                          : ''}
                      </span>
                    ) : (
                      <></>
                    )}
                    {isPickUpNotesEmpty && (
                      <span className='mismatch-text mt-1'>
                        Please enter the pick-up instructions.
                      </span>
                    )}
                  </div>
                  <div className='driver-booking-cta-footer-assign'>
                    <Button
                      className='select-btn select-vehicle'
                      style={{
                        width: '100%',
                        marginBottom: '15px',
                        fontWeight: '900',
                        fontSize: '16px',
                        textShadow: '0.25px 0 #ffffff',
                      }}
                      variant='brown'
                      onClick={() => {
                        onSubmit()
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {list && list[0] && list[1] && (
                    <>
                      <div className='driver-booking-assign-body'>
                        {currentItem && currentItem.type === 'car_rentals' ? (
                          <h1>Assign agent delivering the vehicle</h1>
                        ) : (
                          <h1>Assign the chauffeur and vehicle</h1>
                        )}
                        <div className='driver-add-card mb-3'>
                          {list[0].profilePicture ? (
                            <div
                              className='add-pic-container-uploaded'
                              style={{ marginLeft: '1em' }}
                            >
                              <img
                                className='profile-img'
                                src={list[0].profilePicture}
                              />
                            </div>
                          ) : (
                            <div
                              className='add-pic-container-uploaded'
                              style={{ marginLeft: '1em' }}
                            >
                              <img
                                className='car-img-chauffeur'
                                src={'/images/icons/icon-chauffeur-black.png'}
                              />
                            </div>
                          )}
                          <span
                            className='info-span'
                            style={{ marginLeft: '2em' }}
                          >
                            <span
                              className='d-block'
                              style={{
                                fontWeight: 'bolder',
                                textTransform: 'capitalize',
                              }}
                            >
                              {list[0].firstName} {list[0].lastName}
                            </span>
                            <span> {list[0].phoneNumber} </span>
                          </span>
                          <img
                            width={20}
                            src='/images/icons/icon-checklist-black.png'
                            alt=''
                          />
                        </div>
                        <div className='driver-add-card mb-3'>
                          <img
                            className=' d-block car-edit-img shift-image '
                            src={list[1].vehicleImage && list[1].vehicleImage}
                            alt=''
                          />
                          <span className='info-span'>
                            <span
                              className='d-block'
                              style={{ fontWeight: 'bolder' }}
                            >
                              {list[1].make && list[1].make.label}{' '}
                              {list[1].model && list[1].model.label}
                            </span>
                            <span style={{ textTransform: 'uppercase' }}>
                              {' '}
                              {list[1].plateNumber}{' '}
                            </span>
                          </span>
                          <img
                            width={20}
                            src='/images/icons/icon-checklist-black.png'
                          />
                        </div>
                        {sameChauffeurAndVehicle && (
                          <span className='mismatch-text mt-1'>
                            The chauffeur and vehicle selected are already
                            assigned to this booking.
                          </span>
                        )}
                      </div>
                      <div className='driver-booking-cta-footer-assign'>
                        <Button
                          disabled={sameChauffeurAndVehicle}
                          className='select-btn select-vehicle'
                          style={{
                            width: '100%',
                            marginBottom: '15px',
                            fontWeight: '900',
                            fontSize: '16px',
                            textShadow: '0.25px 0 #ffffff',
                          }}
                          variant='brown'
                          onClick={() => {
                            onSubmit()
                          }}
                        >
                          Assign
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <PartnerAcceptedModal
              isCarRentals={
                currentItem && currentItem.type === 'car_rentals' ? true : false
              }
              isBookingAccepted={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default withRouter(
  connect(null, {
    assignChauffeur,
    assignVehicle,
    addPickUpInstructions,
    completeAssign,
  })(Assign)
)
