import React from 'react'

export default function Step(props) {
  return (
    <div
      className={`step ${props.active ? 'active' : ''}`}
      style={
        props.isQuote && !props.isThreeSteps
          ? { padding: '0' }
          : props.isFirstIndex
          ? { padding: '0' }
          : props.isQuote && props.isThreeSteps
          ? { padding: '0.25rem' }
          : props.hideTitle
          ? { verticalAlign: 'middle' }
          : {}
      }
    >
      {!props.isQuote && !props.hideTitle && (
        <h1 className='page-title stepper-label'>{props.title}</h1>
      )}
      <div
        className='indicator'
        style={props.isQuoteClient ? { width: '108%' } : {}}
      >
        <div className='round-indicator'></div>
        <div className='info'>
          {props.isQuote ? props.title : `step ${props.indicator}`}
        </div>
      </div>
    </div>
  )
}
