import React, { useMemo, useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router'
import { useGetInitialFormValues, useGetTableData } from '../../../../../hooks'
import DefaultDriverLayout from '../../../../../components/layout/DefaultDriverLayout'
import { isMobile } from '../../../../../helpers'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

const isMobileBrowser = isMobile()

const Planned = ({ isCarRental }) => {
  const { token } = useParams()
  let storedFilters = JSON.parse(localStorage.getItem('plannedFilters'))
  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const initialFilters = useMemo(
    () => ({ number: '', pickUpDate: null, dropOffDate: null }),
    []
  )

  const filterOperators = useMemo(
    () => ({ pickUpDate: 'gte', dropOffDate: 'lte' }),
    []
  )

  const {
    data: { list },
    getNextPage,
    isLoading,
    filters,
    onFiltersChange,
    onFiltersChangeSelect,
    setFilters,
    reloadList,
  } = useGetTableData(
    partnerType === 'rental'
      ? `external/partnerToken/${token}/planned-rentals`
      : `external/partnerToken/${token}/planned`,
    isMobileBrowser ? storedFilters : initialFilters,
    { filterOperators, limit: 40 }
  )

  const { currentItem: partnerVehicles } = useGetInitialFormValues(
    `external/car/${token}`
  )

  const { currentItem: partnerChauffeurs } = useGetInitialFormValues(
    `external/chauffeur/${token}`
  )

  const [filtersApplied, setFiltersApplied] = useState(false)

  const onDateChange = key => e => {
    onFiltersChange(key)({
      target: {
        value: e,
        isDate: true,
        isStart: key === 'pickUpDate',
      },
    })
  }

  const onClearFilters = () => {
    setFilters({
      number: '',
      pickUpDate: null,
      dropOffDate: null,
    })
    delete filters['vehicleName']
    delete filters['chauffeurName']
    reloadList()
  }

  useEffect(() => {
    if (filters && isMobileBrowser) {
      localStorage.setItem('plannedFilters', JSON.stringify(filters))
      if (
        JSON.stringify(filters) ===
          JSON.stringify({
            number: '',
            pickUpDate: null,
            dropOffDate: null,
          }) ||
        isEmpty(filters)
      ) {
        setFiltersApplied(false)
      } else {
        setFiltersApplied(true)
      }
    }
  }, [filters])

  return (
    <DefaultDriverLayout
      data={list}
      isBookingsLoading={isLoading}
      filters={filters}
      onFiltersChange={onFiltersChange}
      onFiltersChangeSelect={onFiltersChangeSelect}
      onDateChange={onDateChange}
      onClearFilters={onClearFilters}
      partnerVehicles={partnerVehicles}
      partnerChauffeurs={partnerChauffeurs}
      filtersApplied={filtersApplied}
      onScrollBottom={getNextPage}
      isPlannedTab
    />
  )
}

const mapStateToProps = ({ auth: { isCarRental = null } }) => ({
  isCarRental,
})

export default withRouter(connect(mapStateToProps, null)(Planned))
