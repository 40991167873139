import React, { useState, useEffect, useMemo } from 'react'
import { useParams, withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { connectedApiService as api } from '../../../../index'
import TabsNav from '../../../../components/TabsNav'

const DriverApp = ({ match }) => {
  const { token } = useParams()
  const [details, setDetails] = useState({})

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const url = `external/partnerToken/${token}`
        const { data } = await api.get(url)
        setDetails(data.isValid)
      } catch (err) {
        console.log(err)
      }
    }
    checkTokenValidity()
  }, [])

  const tabs = [
    {
      title: 'New offers',
      path: `/new-bookings`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Planned',
      path: `/planned`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Completed',
      path: `/completed`,
      hasAccess: true,
      isPrivate: false,
    },
  ]

  const tabsToNav = useMemo(
    () =>
      !tabs
        ? null
        : tabs
            .filter(tab => {
              return !!tab.hasAccess
            })
            .map(tab => ({ ...tab, path: `${match.url}${tab.path}` })),
    [tabs, match]
  )

  return (
    <div>
      {details && (
        <>
          <TabsNav align='start' tabs={tabsToNav} className='light-tabs' />
          {/* <Redirect replace to={`${match.url}${tabs[0].path}`} /> */}
          <Redirect replace to={`${match.url}/home-page`} />
        </>
      )}
    </div>
  )
}

export default withRouter(connect(null, {})(DriverApp))
