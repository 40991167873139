export default function MentionedDefaultStyle(minHeight, isClient) {
  return {
    control: isClient
      ? {
          backgroundColor: '#fff',

          fontSize: 14,
          fontWeight: 'normal',
        }
      : {
          backgroundColor: '#fff',

          fontSize: 14,
          fontWeight: 'normal',
          maxHeight: '100px',
        },

    highlighter: {
      overflow: 'hidden',
    },

    input: {
      margin: 0,
      marginLeft: 2,
      overflow: 'auto',
    },

    '&singleLine': {
      control: {
        display: 'inline-block',

        width: 130,
      },

      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },

      input: {
        padding: 1,

        border: '2px inset',
      },
    },

    '&multiLine': {
      control: {
        border: '1px solid rgba(12, 12, 12, 0.1)',
      },

      highlighter: {
        padding: 9,
      },

      input: {
        padding: 9,
        minHeight: minHeight,
        outline: 0,
        border: 0,
      },
    },

    suggestions: {
      list: {
        position: 'absolute',
        bottom: '100%',
        width: 'max-content',
        'margin-bottom': '26%',
        'margin-left': '35px',
        backgroundColor: 'white',
        border: '1px solid rgba(12, 12, 12, 0.1)',
        fontSize: 14,
      },

      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(12, 12, 12, 0.1)',

        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }
}
