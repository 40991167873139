import React from 'react'
import { Form, InputGroup, FormControl } from 'react-bootstrap'
import { getIn } from 'formik'
import {
  isMobile,
  hideResponsiveButton,
  showResponsiveButton,
} from '../helpers'
import { useRef } from 'react'

const { Control, Group, Label } = Form

export default function FormFieldWithIcon({
  field: { value = '', ...field } = {},
  form: { touched = {}, errors = {} } = {},
  icon,
  groupProps = {},
  handleChange,
  hintText,
  label,
  controlId,
  disabled = false,
  vehicleMismatch = false,
  roundFormField = false,
  bookingField = false,
  className,
  text = '',
  ...props
}) {
  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)
  const isMobileBrowser = isMobile()
  const fieldRef = useRef(null)
  const windowHeight = window.innerHeight
  let isFocused = false
  let isScrolled = false

  const onChange = e => {
    field.onChange(e)
    if (handleChange) {
      handleChange(e.target.value)
    }
  }

  const onFocus = () => {
    isScrolled = false
    isFocused = true
    hideResponsiveButton()
  }

  const onBlur = () => {
    isFocused = false
    showResponsiveButton()
  }

  let timer = null

  if (isMobileBrowser) {
    if (timer) {
      clearInterval(timer)
    }
    timer = setInterval(() => {
      let allScrollables = document.getElementsByClassName(
        'scrollable-page-content'
      )

      let elScrollable = null

      if (allScrollables.length > 0) {
        elScrollable = allScrollables[allScrollables.length - 1]
      }

      if (!fieldRef || !fieldRef.current || !elScrollable) {
        clearInterval(timer)
        return
      }

      if (!isFocused) {
        return
      }

      if (windowHeight > window.innerHeight && !isScrolled) {
        setTimeout(() => {
          let bottom_gap =
            elScrollable.offsetHeight -
            fieldRef.current.getBoundingClientRect().bottom

          if (bottom_gap < 150) {
            const addScroll = 150 - bottom_gap
            elScrollable.scrollTop = elScrollable.scrollTop + addScroll
          }
        }, 200)
        isScrolled = true
      }
    }, 100)
  }

  return (
    <>
      <InputGroup
        className={
          roundFormField ? 'round-input-group' : className ? className : ''
        }
      >
        <InputGroup.Prepend
          className={bookingField ? 'booking-icon-field' : ''}
        >
          <InputGroup.Text
            className={
              roundFormField
                ? 'round-input-span pt-2'
                : bookingField
                ? 'pt-1'
                : 'pt-2'
            }
            style={props.isSelectedField ? { borderColor: '#0c0c0c' } : {}}
          >
            {icon ? (
              <img
                className='pr-1'
                src={'/images/' + icon}
                alt=''
                style={{ width: '19px', paddingBottom: '0.1rem' }}
              />
            ) : (
              text
            )}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          ref={fieldRef}
          {...field}
          value={value || ''}
          {...props}
          isInvalid={showError}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />

        {!!hintText && (
          <Form.Text className='text-muted textarea-hint'>{hintText}</Form.Text>
        )}
        {!!showError && (
          <Control.Feedback type='invalid'>
            {getIn(errors, field.name)}
          </Control.Feedback>
        )}
      </InputGroup>
    </>
  )
}
