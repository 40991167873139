import { fileToBase64 } from 'helpers'
import { changePasswordFormData, myAccessFormData } from '../models/access'
import { createOrEditItem, deleteItem } from './form'

export const uploadFilesToCDN = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'uploadFilesToCDN',
      formatFormData: uploadFilesFormData,
      successMessage: 'Client was successfully created',
    })
  )

export const uploadFilesFormData = async ({ files, ...values }) => {
  try {
    let data = {
      ...values,
    }
    var listOfFiles = []
    for (const file of files) {
      if (file && typeof file === 'object' && file.size) {
        const base64 = await fileToBase64(file)
        listOfFiles.push({ base64: base64, name: file.name })
      }
    }
    data.files = listOfFiles
    return data
  } catch (error) {
    return {}
  }
}
